import {createSlice} from '@reduxjs/toolkit';
export const StaffSlice = createSlice({
  name: 'staff',
  initialState: {
    value: [],
  },
  reducers: {
    populate_Staff: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_Staff} = StaffSlice.actions;
export default StaffSlice.reducer;
