import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_severity_codes } from "./Redux/SeverityCodesSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";

function SeverityCodes() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON +
            "severityCodesDictionary?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  //Post Url
  const post_url =
    process.env.REACT_APP_BASE_API_PYTHON + "severityCodesDictionary";
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };

  let severityCodesDictionary = useSelector(
    (state) => state.callSeverityCodes.value
  );
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [severityCodesObjectErrors, setSeverityCodesObjectErrors] = useState({
    severityCodeError: "",
    levelError: "",
    impactError: "",
    descriptionError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "severityCodeError");
    OnChangeErrorValues("", "levelError");
    OnChangeErrorValues("", "impactError");
    OnChangeErrorValues("", "descriptionError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "severityCodeError":
        setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
          ...severityCodesObjectErrors,
          severityCodeError: val,
        }));
        break;
      case "descriptionError":
        setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
          ...severityCodesObjectErrors,
          descriptionError: val,
        }));
        break;
      case "levelError":
        setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
          ...severityCodesObjectErrors,
          levelError: val,
        }));
        break;
      case "impactError":
        setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
          ...severityCodesObjectErrors,
          impactError: val,
        }));
        break;
      case "effDateFromError":
        setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
          ...severityCodesObjectErrors,
          effDateFromError: val,
        }));
        break;
      case "effDateToError":
        setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
          ...severityCodesObjectErrors,
          effDateToError: val,
        }));
        break;
    }
  };

  //Searching
  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < severityCodesDictionary.length; i++) {
  //     if (
  //       severityCodesDictionary[i]
  //         .severityCode !== null
  //     ) {
  //       if (
  //         severityCodesDictionary[i].severityCode
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(severityCodesDictionary[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = severityCodesDictionary.filter((e1) =>
      e1.severityCode.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (
        severityCodesDictionary.length > process.env.REACT_APP_ITEMS_PER_PAGE
      ) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(severityCodesDictionary[i]);
        }
      } else {
        for (let i = 0; i < severityCodesDictionary.length; i++) {
          tempArray.push(severityCodesDictionary[i]);
        }
      }
      total_pages.current = Math.ceil(
        severityCodesDictionary.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };
  ///////////

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [severityCodesDictionaryArray, setSeverityCodesDictionaryArray] =
    useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  let product_array_ref = useRef([]);
  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [severityCodesObject, setSeverityCodesObject] = useState({
    severityCode: "",
    description: "",
    level: "",
    impact: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  //Declaration Of Delete States
  const [severityCodesObjectDelete, setSeverityCodesObjectDelete] = useState({
    severityCode: "",
    description: "",
    level: "",
    impact: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });

  const onResetValue = () => {
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      severityCode: "",
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      description: "",
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      level: "",
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      impact: "",
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      effDateFrom: new Date(),
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "severityCode":
        setSeverityCodesObject((severityCodesObject) => ({
          ...severityCodesObject,
          severityCode: e.target.value,
        }));
        break;
      case "description":
        setSeverityCodesObject((severityCodesObject) => ({
          ...severityCodesObject,
          description: e.target.value,
        }));
        break;
      case "level":
        setSeverityCodesObject((severityCodesObject) => ({
          ...severityCodesObject,
          level: e.target.value,
        }));
        break;
      case "impact":
        setSeverityCodesObject((severityCodesObject) => ({
          ...severityCodesObject,
          impact: e.target.value,
        }));
        break;
      case "effDateFrom":
        setSeverityCodesObject((severityCodesObject) => ({
          ...severityCodesObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setSeverityCodesObject((severityCodesObject) => ({
          ...severityCodesObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          severityCodesDictionaryArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            severityCodesDictionaryArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(severityCodesDictionaryArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            severityCodesDictionaryArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (severityCodesDictionaryArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(severityCodesDictionaryArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(severityCodesDictionaryArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);
  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = severityCodesDictionary.findIndex(
      (e) => e.severityDbId === _code
    );
    select_deselect_the_record(severityCodesDictionary[idx]);
    set_item_to_delete(severityCodesDictionary[idx].severityDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "severityCodesDictionary?size=1000",
              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "severityCodesDictionary?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          severityCode: severityCodesObject.severityCode,
          description: severityCodesObject.description,
          level: severityCodesObject.level,
          impact: severityCodesObject.impact,
          effDateFrom: severityCodesObject.effDateFrom,
          effDateTo:
            status === true
              ? new Date("1900-01-01")
              : severityCodesObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : "",
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : "",
          updatedTimestamp: new Date().toISOString(),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "severityCodesDictionary?size=1000",
              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditSeverity = () => {
    let deleteData = {
      severityCode: severityCodesObjectDelete.severityCode,
      description: severityCodesObjectDelete.description,
      level: severityCodesObjectDelete.level,
      impact: severityCodesObjectDelete.impact,
      effDateFrom: GetServerDate(severityCodesObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      severityCode: severityCodesObject.severityCode,
      description: severityCodesObject.description,
      level: severityCodesObject.level,
      impact: severityCodesObject.impact,
      effDateFrom: GetServerDate(severityCodesObject.effDateFrom),
      effDateTo: GetServerDate(severityCodesObject.effDateTo),
      createdBy: isAuthenticated == true ? user.name : "",
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    axios
      .patch(post_url + "/" + selectedseverityDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON +
                    "severityCodesDictionary?size=1000",
                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(
          error.message + ":" + post_url + "/" + selectedseverityDbId
        );
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Severity Codes Dictionary") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "severityCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              severityCodesObject.severityCode.length == 0
            ) {
              temp++;
              setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
                ...severityCodesObjectErrors,
                severityCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              severityCodesDictionary.findIndex(
                (e) => e.severityCode === severityCodesObject.severityCode
              ) != -1
            ) {
              temp++;
              setSeverityCodesObjectErrors((severityCodesObjectErrors) => ({
                ...severityCodesObjectErrors,
                severityCodeError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = severityCodesDictionary.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        severityCodesDictionary.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (
        severityCodesDictionary.length <= process.env.REACT_APP_ITEMS_PER_PAGE
      ) {
        current_page.current = 1;
        for (let i = 0; i < severityCodesDictionary.length; i++) {
          temp_display_array.push(severityCodesDictionary[i]);
        }
      } else {
        if (
          severityCodesDictionary.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          severityCodesDictionary.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < severityCodesDictionary.length;
            i++
          ) {
            temp_display_array.push(severityCodesDictionary[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(severityCodesDictionary[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);

    let temp_severityCodesObject = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_severityCodesObject.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ==
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            if (
              new Date(response.data[i].effDateTo).getTime() ==
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_severity_codes(temp_severityCodesObject));
          setSeverityCodesDictionaryArray(tempNonDraftArray);
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectedseverityDbId, setSelectedseverityDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (severityDbId, type) => {
    setSelectedseverityDbId(severityDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(severityDbId);
  };
  const SupplyValuesToModal = (severityDbId) => {
    let dateFrom = severityCodesDictionary.find(
      (e) => e.severityDbId == severityDbId
    ).effDateFrom;
    let dateTo = severityCodesDictionary.find(
      (e) => e.severityDbId == severityDbId
    ).effDateTo;
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      severityCode: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).severityCode,
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      description: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).description,
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      level: severityCodesDictionary.find((e) => e.severityDbId == severityDbId)
        .level,
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      impact: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).impact,
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      effDateFrom: dateFrom,
    }));
    setSeverityCodesObject((severityCodesObject) => ({
      ...severityCodesObject,
      effDateTo: dateTo,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      severityCode: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).severityCode,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      description: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).description,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      level: severityCodesDictionary.find((e) => e.severityDbId == severityDbId)
        .level,
    }));
    setSeverityCodesObjectDelete((severityCodesObject) => ({
      ...severityCodesObject,
      impact: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).impact,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      effDateFrom: dateFrom,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      effDateTo: dateTo,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      updatedBy: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).updatedBy,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      createdBy: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).createdBy,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      createdTimestamp: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).createdTimestamp,
    }));
    setSeverityCodesObjectDelete((severityCodesObjectDelete) => ({
      ...severityCodesObjectDelete,
      updatedTimestamp: severityCodesDictionary.find(
        (e) => e.severityDbId == severityDbId
      ).updatedTimestamp,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortSeverityCodes = [...severityCodesDictionaryArray];
    if (sortStyle === "Descending") {
      let UpdatedSeverityCodes = SortSeverityCodes.sort((a, b) => {
        const a_res = action === "Code" ? a.severityCode : a.description;
        const b_res = action === "Code" ? b.severityCode : b.description;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedSeverityCodes);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let UpdatedSeverityCodes = SortSeverityCodes.sort((a, b) => {
        const a_res = action === "Code" ? a.severityCode : a.description;
        const b_res = action === "Code" ? b.severityCode : b.description;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedSeverityCodes);
      setSortStyle("Descending");
    }

    let tempArray = [];
    if (SortSeverityCodes.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortSeverityCodes[i]);
      }
    } else {
      for (let i = 0; i < SortSeverityCodes.length; i++) {
        tempArray.push(SortSeverityCodes[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortSeverityCodes.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setSeverityCodesDictionaryArray(SortSeverityCodes);
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="flex flex-row w-full">
              <div className="container-fluid ">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                        multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className="green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                  <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                    <div className="manageHeader"> Manage Severity Codes</div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UncheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Severity Codes"
                        value={search_content}
                        onChange={(e) => SearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "33%" }} />
                        <col span="1" style={{ width: "37%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Severity Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Severity Code Description
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.severityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {item.severityCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.severityDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.severityDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.severityDbId
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.severityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {item.severityCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.severityDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.severityDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.severityDbId
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.severityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {item.severityCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.severityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.severityDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.severityDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.severityDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.severityDbId
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <div className="addTheFormSplits">
                    <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={
                            current_page.current == 1
                              ? PreviousHiddenIcon
                              : PreviousIcon
                          }
                          onClick={() => onPressPrevious()}
                          className="PreviouesNextIcons"
                          alt=""
                          style={{
                            cursor:
                              current_page.current == 1 ? "auto" : "pointer",
                          }}
                        />
                        <img
                          src={
                            current_page.current == total_pages.current
                              ? NextHiddenIcon
                              : NextIcon
                          }
                          onClick={() => onPressNext()}
                          className="PreviouesNextIcons"
                          style={{
                            cursor:
                              current_page.current == total_pages.current
                                ? "auto"
                                : "pointer",
                          }}
                          alt=""
                        />
                      </div>
                      <p>
                        Page {current_page.current} of{" "}
                        {paginatedArray.length === 0
                          ? current_page.current
                          : total_pages.current}
                      </p>
                    </div>
                    <div className="specebutton">
                      <button
                        className="addBtn"
                        onClick={() => onClickAddItem()}
                      >
                        Add
                      </button>
                      <button className="uploadBtn">Upload</button>
                      <button
                        className="deleteBtn"
                        onClick={() => OnDeleteSelectionClicked()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {/* //View box */}
                {open_view_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      View Severity Codes Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Severity Code</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={severityCodesObject.severityCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Severity Code Description
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={severityCodesObject.description}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Level</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={severityCodesObject.level}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Impact</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={severityCodesObject.impact}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                          </div>
                          <input
                            type="text"
                            value={
                              new Date(
                                severityCodesObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    severityCodesObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  severityCodesObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                          </div>
                          <input
                            type="text"
                            value={
                              new Date(
                                severityCodesObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    severityCodesObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  severityCodesObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="editButton"
                          onClick={() =>
                            OpenModal(selectedseverityDbId, "Edit")
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Edit Popup column */}
                {open_edit_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Edit Severity Codes Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Severity Code</label>
                            <label className="error">
                              {severityCodesObjectErrors.severityCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.severityCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Severity Code Description
                            </label>
                            <label className="error">
                              {severityCodesObjectErrors.descriptionError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.description}
                            onChange={(e) =>
                              onChangeValue(e, "description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Level</label>
                            <label className="error">
                              {severityCodesObjectErrors.levelError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.level}
                            onChange={(e) => onChangeValue(e, "level", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Impact</label>
                            <label className="error">
                              {severityCodesObjectErrors.impactError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.impact}
                            onChange={(e) => onChangeValue(e, "impact", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {severityCodesObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                severityCodesObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    severityCodesObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  severityCodesObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {severityCodesObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                severityCodesObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    severityCodesObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  severityCodesObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnEditSeverity()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Delete Popup column */}
                {show_delete_confirmations === true ? (
                  <div className="deleteModelstyle">
                    <div className="delete_header_modal">Confirmation</div>
                    <div className=" Confirmation">
                      Are you sure you want to delete?
                    </div>
                    <div className="btnDeletewrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnDeleteItem(item_to_delete)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Add Popup column */}
                {open_add_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Add Severity Codes Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Severity Code</label>
                            <label className="error">
                              {severityCodesObjectErrors.severityCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.severityCode}
                            onChange={(e) =>
                              onChangeValue(e, "severityCode", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Severity Code Description
                            </label>
                            <label className="error">
                              {severityCodesObjectErrors.descriptionError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.description}
                            onChange={(e) =>
                              onChangeValue(e, "description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Level</label>
                            <label className="error">
                              {severityCodesObjectErrors.levelError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.level}
                            onChange={(e) => onChangeValue(e, "level", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Impact</label>
                            <label className="error">
                              {severityCodesObjectErrors.impactError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={severityCodesObject.impact}
                            onChange={(e) => onChangeValue(e, "impact", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {severityCodesObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                severityCodesObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    severityCodesObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  severityCodesObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {severityCodesObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                severityCodesObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    severityCodesObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  severityCodesObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="savedraftButton"
                          onClick={() => AddTheItem(true)}
                        >
                          Save as Draft
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => AddTheItem(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
            <LogoutConfirmation />
          </>
        ) : null}
        {loading == true ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SeverityCodes;
