import {createSlice} from '@reduxjs/toolkit';
export const FacilitySlice = createSlice({
  name: 'facility',
  initialState: {
    value: [],
  },
  reducers: {
    populate_Facility: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_Facility} = FacilitySlice.actions;
export default FacilitySlice.reducer;
