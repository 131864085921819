import {createSlice} from '@reduxjs/toolkit';
export const ComplaintSlice = createSlice({
  name: 'complaint',
  initialState: {
    value: [],
  },
  reducers: {
    populate_complaint: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_complaint} = ComplaintSlice.actions;
export default ComplaintSlice.reducer;
