import {createSlice} from '@reduxjs/toolkit';
export const ProductsSlice = createSlice({
  name: 'products',
  initialState: {
    value: [],
  },
  reducers: {
    populate_Products: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_Products} = ProductsSlice.actions;
export default ProductsSlice.reducer;
