import React, { useState, useEffect, useRef } from "react";
import UnCheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Company } from "./Redux/CompanySlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";

function Company() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const { user, isAuthenticated } = useAuth0();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated == false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "companyMaster?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "companyMaster";

  let companyMaster = useSelector((state) => state.callCompany.value);

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [companyObjectErrors, setCompanyObjectErrors] = useState({
    companyCodeError: "",
    nameError: "",
    countryError: "",
    stateError: "",
    cityError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "companyCodeError");
    OnChangeErrorValues("", "nameError");
    OnChangeErrorValues("", "countryError");
    OnChangeErrorValues("", "stateError");
    OnChangeErrorValues("", "cityError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productNameError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          productNameError: error,
        }));
        break;
      case "companyCodeError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          companyCodeError: error,
        }));
        break;

      case "nameError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          nameError: error,
        }));
        break;
      case "countryError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          countryError: error,
        }));
        break;
      case "stateError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          stateError: error,
        }));
        break;
      case "cityError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          cityError: error,
        }));
        break;

      case "effDateFromError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setCompanyObjectErrors((companyObjectErrors) => ({
          ...companyObjectErrors,
          effDateToError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;

  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < companyMasterArray.length; i++) {
  //     if (
  //       companyMasterArray[i].name !==
  //       null
  //     ) {
  //       if (
  //         companyMasterArray[i].name
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(companyMasterArray[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = companyMaster.filter((e1) =>
      e1.name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (companyMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(companyMaster[i]);
        }
      } else {
        for (let i = 0; i < companyMaster.length; i++) {
          tempArray.push(companyMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        companyMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    set_searched_array(temp);
    current_page.current = 1;
    setPaginatedArray(tempArray);
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [companyMasterArray, setCompanyMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [companyMasterObject, setCompanyMasterObject] = useState({
    companyCode: "",
    name: "",
    country: "",
    state: "",
    city: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  //Declaration Of Delete States
  const [companyMasterObjectDelete, setCompanyMasterObjectDelete] = useState({
    companyCode: "",
    name: "",
    country: "",
    state: "",
    city: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });

  const onResetValue = () => {
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      companyCode: "",
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      name: "",
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      country: "",
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      state: "",
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      city: "",
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      effDateFrom: new Date(),
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "companyCode":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          companyCode: e.target.value,
        }));
        break;
      case "name":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          name: e.target.value,
        }));
        break;
      case "country":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          country: e.target.value,
        }));
        break;
      case "state":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          state: e.target.value,
        }));
        break;
      case "city":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          city: e.target.value,
        }));
        break;

      case "effDateFrom":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setCompanyMasterObject((companyMasterObject) => ({
          ...companyMasterObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          companyMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            companyMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(companyMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            companyMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (companyMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(companyMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(companyMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = companyMaster.findIndex((e) => e.companyDbId === _code);
    select_deselect_the_record(companyMaster[idx]);
    set_item_to_delete(companyMaster[idx].companyDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "companyMaster?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "companyMaster?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          companyCode: companyMasterObject.companyCode,
          name: companyMasterObject.name,
          country: companyMasterObject.country,
          state: companyMasterObject.state,
          city: companyMasterObject.city,
          effDateFrom: companyMasterObject.effDateFrom,
          effDateTo:
            status === true
              ? new Date("1900-01-01")
              : companyMasterObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : "",
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : "",
          updatedTimestamp: new Date().toISOString(),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "companyMaster?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = (status) => {
    console.log("Updating" + post_url + "/" + selectedCompanyDbId);
    let deleteData = {
      companyCode: companyMasterObjectDelete.companyCode,
      name: companyMasterObjectDelete.name,
      country: companyMasterObjectDelete.country,
      state: companyMasterObjectDelete.state,
      city: companyMasterObjectDelete.city,
      effDateFrom: GetServerDate(companyMasterObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      companyCode: companyMasterObject.companyCode,
      name: companyMasterObject.name,
      country: companyMasterObject.country,
      state: companyMasterObject.state,
      city: companyMasterObject.city,
      effDateFrom: GetServerDate(companyMasterObject.effDateFrom),
      effDateTo: GetServerDate(companyMasterObject.effDateTo),
      createdBy: isAuthenticated == true ? user.name : "",
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };

    axios
      .patch(post_url + "/" + selectedCompanyDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios.post(post_url, data).then((response) => {
            if (response.status === 201) {
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "companyMaster?size=1000",

                "Patch"
              );
              ShowMEWSPopup("✅Successfully updated the item", "Success");
              set_mews_popup_color("#28A745");
              set_open_edit_modal(false);
            }
          });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedCompanyDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };

  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Company Master") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "companyCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              companyMasterObject.companyCode.length === 0
            ) {
              temp++;
              setCompanyObjectErrors((companyObjectErrors) => ({
                ...companyObjectErrors,
                companyCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "name":
            if (
              clientSideValidations[i].validationType === "required" &&
              companyMasterObject.name.length === 0
            ) {
              temp++;
              setCompanyObjectErrors((companyObjectErrors) => ({
                ...companyObjectErrors,
                nameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "required" &&
              companyMasterObject.effDateFrom.length === 0
            ) {
              temp++;
              setCompanyObjectErrors((companyObjectErrors) => ({
                ...companyObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateTo":
            if (
              clientSideValidations[i].validationType === "required" &&
              companyMasterObject.effDateTo.length === 0
            ) {
              temp++;
              setCompanyObjectErrors((companyObjectErrors) => ({
                ...companyObjectErrors,
                effDateToError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = companyMaster.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        companyMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (companyMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < companyMaster.length; i++) {
          temp_display_array.push(companyMaster[i]);
        }
      } else {
        if (
          companyMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          companyMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < companyMaster.length;
            i++
          ) {
            temp_display_array.push(companyMaster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(companyMaster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [selected_form_id, set_selected_form_id] = useState(3);
  const onChangeFormID = (e, _code) => {
    let temp = [];
    for (let i = 0; i < companyMaster.length; i++) {
      temp.push(companyMaster[i]);
    }
    let updatedArray = [];
    if (e != 0) {
      updatedArray = temp.filter((e1) => e1.productDbId == e);
    } else {
      updatedArray = temp;
    }
    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }

    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setCompanyMasterArray(updatedArray);
    setPaginatedArray(tempArray);
    set_selected_form_id(e);
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_companyMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];

    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_companyMaster.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setCompanyMasterArray(tempNonDraftArray);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);
          dispatch(populate_Company(temp_companyMaster));
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
      });
  };
  const [selectedCompanyDbId, setSelectedCompanyDbId] = useState("");
  //View Modal||Edit Modal
  const OpenModal = (companyDbId, type) => {
    setSelectedCompanyDbId(companyDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(companyDbId);
  };
  const SupplyValuesToModal = (companyDbId) => {
    let dateFrom = companyMaster.find(
      (e) => e.companyDbId == companyDbId
    ).effDateFrom;
    let dateTo = companyMaster.find(
      (e) => e.companyDbId == companyDbId
    ).effDateTo;
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      companyCode: companyMaster.find((e) => e.companyDbId == companyDbId)
        .companyCode,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      companyCode: companyMaster.find((e) => e.companyDbId == companyDbId)
        .companyCode,
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      name: companyMaster.find((e) => e.companyDbId == companyDbId).name,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      name: companyMaster.find((e) => e.companyDbId == companyDbId).name,
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      country: companyMaster.find((e) => e.companyDbId == companyDbId).country,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      country: companyMaster.find((e) => e.companyDbId == companyDbId).country,
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      state: companyMaster.find((e) => e.companyDbId == companyDbId).state,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      state: companyMaster.find((e) => e.companyDbId == companyDbId).state,
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      city: companyMaster.find((e) => e.companyDbId == companyDbId).city,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      city: companyMaster.find((e) => e.companyDbId == companyDbId).city,
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      effDateFrom: dateFrom,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      effDateFrom: dateFrom,
    }));
    setCompanyMasterObject((companyMasterObject) => ({
      ...companyMasterObject,
      effDateTo: dateTo,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      effDateTo: dateTo,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      createdBy: companyMaster.find((e) => e.companyDbId == companyDbId)
        .createdBy,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      updatedBy: companyMaster.find((e) => e.companyDbId == companyDbId)
        .updatedBy,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      createdTimestamp: companyMaster.find((e) => e.companyDbId == companyDbId)
        .createdTimestamp,
    }));
    setCompanyMasterObjectDelete((companyMasterObjectDelete) => ({
      ...companyMasterObjectDelete,
      updatedTimestamp: companyMaster.find((e) => e.companyDbId == companyDbId)
        .updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortFacilitie = [...companyMasterArray];
    if (sortStyle === "Descending") {
      let updatedProduction = SortFacilitie.sort((a, b) => {
        const a_res = action === "Code" ? a.companyCode : a.name;
        const b_res = action === "Code" ? b.companyCode : b.name;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedProduction);
      setSortStyle("Ascending");
      setCompanyMasterArray(updatedProduction);
    } else if (sortStyle === "Ascending") {
      let updatedProduction = SortFacilitie.sort((a, b) => {
        const a_res = action === "Code" ? a.companyCode : a.name;
        const b_res = action === "Code" ? b.companyCode : b.name;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedProduction);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortFacilitie.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    } else {
      for (let i = 0; i < SortFacilitie.length; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortFacilitie.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setCompanyMasterArray(SortFacilitie);
    setPaginatedArray(tempArray);
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="flex flex-row w-full">
              <div className="container-fluid">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                        multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className=" green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                  <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                    <div className="manageHeader">Manage Customers</div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UnCheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UnCheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Customers"
                        value={search_content}
                        onChange={(e) => SearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>

                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "12%" }} />
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "16%" }} />
                        <col span="1" style={{ width: "12%" }} />
                        <col span="1" style={{ width: "12%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Name")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Name
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Country</th>
                          <th>State</th>
                          <th>City</th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.companyDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.companyCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.name}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.country}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.state}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.city}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.effDateFrom
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.companyDbId, "View")
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.companyDbId, "Edit")
                                        }
                                        src={EditIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          ShowDeleteConfirmation(
                                            item.companyDbId
                                          )
                                        }
                                        src={DeleteIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                          : show_drafts === true
                          ? display_draft_array.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("1900-01-01").getTime() ? (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.companyDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.companyCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.name}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.country}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.state}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.city}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.effDateFrom
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.companyDbId, "View")
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.companyDbId, "Edit")
                                        }
                                        src={EditIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          ShowDeleteConfirmation(
                                            item.companyDbId
                                          )
                                        }
                                        src={DeleteIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.companyDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.companyCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.name}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.country}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.state}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {item.city}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.effDateFrom
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.companyDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.companyDbId
                                      )
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.companyDbId, "View")
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.companyDbId, "Edit")
                                        }
                                        src={EditIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          ShowDeleteConfirmation(
                                            item.companyDbId
                                          )
                                        }
                                        src={DeleteIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <div className="addTheFormSplits">
                    <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={
                            current_page.current == 1
                              ? PreviousHiddenIcon
                              : PreviousIcon
                          }
                          onClick={() => onPressPrevious()}
                          className="PreviouesNextIcons"
                          alt=""
                          style={{
                            cursor:
                              current_page.current == 1 ? "auto" : "pointer",
                          }}
                        />
                        <img
                          src={
                            current_page.current == total_pages.current
                              ? NextHiddenIcon
                              : NextIcon
                          }
                          onClick={() => onPressNext()}
                          className="PreviouesNextIcons"
                          style={{
                            cursor:
                              current_page.current == total_pages.current
                                ? "auto"
                                : "pointer",
                          }}
                          alt=""
                        />
                      </div>
                      <p>
                        Page {current_page.current} of{" "}
                        {paginatedArray.length === 0
                          ? current_page.current
                          : total_pages.current}
                      </p>
                    </div>
                    <div className="specebutton">
                      <button
                        className="addBtn"
                        onClick={() => onClickAddItem()}
                      >
                        Add
                      </button>
                      <button className="uploadBtn">Upload</button>
                      <button
                        className="deleteBtn"
                        onClick={() => OnDeleteSelectionClicked()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {/* //View box */}
                {open_view_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      View Company Master Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Code</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={companyMasterObject.companyCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Name</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={companyMasterObject.name}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Country</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={companyMasterObject.country}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">State</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={companyMasterObject.state}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">City</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={companyMasterObject.city}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                companyMasterObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    companyMasterObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  companyMasterObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                companyMasterObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    companyMasterObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  companyMasterObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="editButton"
                          onClick={() => OpenModal(selectedCompanyDbId, "Edit")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Edit Popup column */}
                {open_edit_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Edit Company Master Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Code</label>
                            <label className="error">
                              {companyObjectErrors.companyCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.companyCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Name</label>
                            <label className="error">
                              {companyObjectErrors.nameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.name}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Country</label>
                            <label className="error">
                              {companyObjectErrors.countryError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.country}
                            onChange={(e) => onChangeValue(e, "country", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">State</label>
                            <label className="error">
                              {companyObjectErrors.stateError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.state}
                            onChange={(e) => onChangeValue(e, "state", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">City</label>
                            <label className="error">
                              {companyObjectErrors.cityError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.city}
                            onChange={(e) => onChangeValue(e, "city", null)}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {companyObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                companyMasterObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    companyMasterObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  companyMasterObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {companyObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                companyMasterObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    companyMasterObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  companyMasterObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => UpdateTheItem()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Delete Popup column */}
                {show_delete_confirmations === true ? (
                  <div className="deleteModelstyle">
                    <div className="delete_header_modal">Confirmation</div>
                    <div className=" Confirmation">
                      Are you sure you want to delete?
                    </div>
                    <div className="btnDeletewrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnDeleteItem(item_to_delete)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Add Popup column */}
                {open_add_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Add Company Master Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Code</label>
                            <label className="error">
                              {companyObjectErrors.companyCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.companyCode}
                            onChange={(e) =>
                              onChangeValue(e, "companyCode", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Name</label>
                            <label className="error">
                              {companyObjectErrors.nameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.name}
                            onChange={(e) => onChangeValue(e, "name", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Country</label>
                            <label className="error">
                              {companyObjectErrors.countryError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.country}
                            onChange={(e) => onChangeValue(e, "country", null)}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">State</label>
                            <label className="error">
                              {companyObjectErrors.stateError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.state}
                            onChange={(e) => onChangeValue(e, "state", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">City</label>
                            <label className="error">
                              {companyObjectErrors.cityError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={companyMasterObject.city}
                            onChange={(e) => onChangeValue(e, "city", null)}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {companyObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                companyMasterObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    companyMasterObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  companyMasterObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {companyObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                companyMasterObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    companyMasterObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  companyMasterObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              companyMasterObject(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="savedraftButton"
                          onClick={() => AddTheItem(true)}
                        >
                          Save as Draft
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => AddTheItem(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
            <LogoutConfirmation />
          </>
        ) : null}
        {loading == true ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Company;
