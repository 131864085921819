import { createSlice } from "@reduxjs/toolkit";
export const FailureCodesSlice = createSlice({
  name: "failure_codes",
  initialState: {
    value: [],
  },
  reducers: {
    populate_failure_codes: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_failure_codes } = FailureCodesSlice.actions;
export default FailureCodesSlice.reducer;
