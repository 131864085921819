import { createSlice } from "@reduxjs/toolkit";
export const FormSectionAttributeSlice = createSlice({
  name: "formSectionAttribute",
  initialState: {
    value: [],
  },
  reducers: {
    populate_form_Section_Attribute: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_form_Section_Attribute } =
  FormSectionAttributeSlice.actions;
export default FormSectionAttributeSlice.reducer;
