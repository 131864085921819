import React, { useEffect } from "react";
import { store } from "../src/Componects/Redux/store";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import RoutingComponent from "./Componects/RoutingComponent";
import { onMessage } from "firebase/messaging";
import { messaging } from "./Componects/FirebaseInit";
const App = () => {
  const { isAuthenticated } = useAuth0;
  useEffect(() => {
    onMessage(messaging, (payload) => {
      alert(payload.notification.body);
      console.log("Message received. ", payload["notification"]);
      // ...
    });
  }, []);
  React.useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  }, []);
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      }}
    >
      <Provider store={store}>
        <RoutingComponent />
      </Provider>
    </Auth0Provider>
  );
};
export default App;
