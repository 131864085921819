import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import InspectionParameters from "./InspectionParameters";
import Dashboards from "./Dashboards";
import ProductionProcess from "./ProductionProcess";
import QualityProcess from "./QualityProcess";
import Forms from "./Forms";
import DefectCodes from "./DefectCodes";
import FailureCodes from "./FailureCodes";
import SeverityCodes from "./SeverityCodes";
import RoleHierarchy from "./RoleHierarchy";
import Staff from "./Staff";
import Facility from "./Facility";
import Product from "./Product";
import Equipment from "./Equipment";
import Sensor from "./Sensor";
import InspectionRoster from "./InspectionRoster";
import ComplaintMaster from "./ComplaintMaster";
import Company from "./Company";
import Supplier from "./Supplier";
import Parts from "./Parts";
import Profile from "./Profile";
// import { useIdleTimer } from "react-idle-timer";
import SplashScreen from "./SplashScreen";
import CustomerProduct from "./CustomerProduct";
import BOM from "./BOM";
import Screens from "./Screens";
import TestCertificate from "./TestCertificate";
import TestCertificateValidation from "./TestCertificateValidation";
import EventRules from "./EventRules";
import EventTriggers from "./EventTriggers";
import Notification from "./Notification";
import ReleaseNotes from "./ReleaseNotes";
const RoutingComponent = () => {
  // const { isAuthenticated, logout } = useAuth0();
  // const onIdle = () => {
  //   LogoutFunction();
  // };
  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   timeout: process.env.REACT_APP_ALLOWED_IDLE_TIME,
  // });

  // const LogoutFunction = () => {
  //   console.log("Logging Out");
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   logout({
  //     logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI },
  //   });
  // };
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      alert("Close");
      console.log(e);
    });
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 3000);
  }, []);
  return (
    <Router>
      {showSplashScreen == true ? (
        <SplashScreen />
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Facility" element={<Facility />} />
          <Route path="/Products" element={<Product />} />
          <Route path="/ProductionProcess" element={<ProductionProcess />} />
          <Route path="/QualityProcess" element={<QualityProcess />} />
          <Route path="/Forms" element={<Forms />} />
          <Route path="/DefectCodes" element={<DefectCodes />} />
          <Route path="/FailureCodes" element={<FailureCodes />} />
          <Route path="/SeverityCodes" element={<SeverityCodes />} />
          <Route
            path="/InspectionParameters"
            element={<InspectionParameters />}
          />
          <Route path="/dashboards" element={<Dashboards />} />
          <Route path="/RoleHierarchy" element={<RoleHierarchy />} />
          <Route path="/Staff" element={<Staff />} />
          <Route path="/Equipment" element={<Equipment />} />
          <Route path="/Sensor" element={<Sensor />} />
          <Route path="/Complaint" element={<ComplaintMaster />} />
          <Route path="/Roster" element={<InspectionRoster />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/Supplier" element={<Supplier />} />
          <Route path="/Parts" element={<Parts />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/BOM" element={<BOM />} />
          <Route path="/CustomerProduct" element={<CustomerProduct />} />
          <Route path="/Screens" element={<Screens />} />
          <Route path="/TestCertficate" element={<TestCertificate />} />
          <Route
            path="/TestCertificateValidation"
            element={<TestCertificateValidation />}
          />
          <Route path="/EventRules" element={<EventRules />} />
          <Route path="/EventTriggers" element={<EventTriggers />} />
          <Route path="/Notifications" element={<Notification />} />
          <Route path="/ReleaseNotes" element={<ReleaseNotes />} />
        </Routes>
      )}
    </Router>
  );
};
export default RoutingComponent;
