import {createSlice} from '@reduxjs/toolkit';
export const DefectCodesSlice = createSlice({
  name: 'defect_codes',
  initialState: {
    value: [],
  },
  reducers: {
    populate_defect_codes: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_defect_codes} = DefectCodesSlice.actions;
export default DefectCodesSlice.reducer;
