import React, { useEffect } from "react";
import Header from "./Header";
import LogoIcon from "../Assert/ProfilePicture.png";
import ProfileIcon from "../Assert/UserID.png";
import MessageIcon from "../Assert/JobFunction.png";
import EmailIcon from "../Assert/WorkEmail.png";
import LogoutIcon from "../Assert/Logout.png";
import { populate_Staff } from "./Redux/StaffSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_role_Hierarchy } from "./Redux/RoleHierarchySlice";
import { populate_user } from "./Redux/UserSlice";
import CloseIcon from "../Assert/Close.png";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";

function Profile() {
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let roleHierarchyMaster = useSelector(
    (state) => state.callRoleHierarchy.value
  );
  const showSidebar = useSelector((state) => state.callUser.value);

  const LogoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({
      logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI },
    });
  };
  let staff = useSelector((state) => state.callStaff.value);
  const dispatch = useDispatch();
  const FetchTheStaff = () => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000")
        .then((response) => {
          if (response.status == 200) {
            let tempStaff = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
              }
            }
            console.log("tempStaff" + tempStaff);
            dispatch(populate_Staff(tempStaff));
          }
        });
    }
  };
  const FetchTheRole = () => {
    if (roleHierarchyMaster.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "roleHierarchyMaster?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempRoleHierarchyMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempRoleHierarchyMaster.push(response.data[i]);
              }
            }
            dispatch(populate_role_Hierarchy(tempRoleHierarchyMaster));
          }
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheStaff();
        FetchTheRole();
        FetchTheEventNotificationLog();
        FetchTheEventRule();
      }
    }, 2000);
  }, []);

  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div>
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid bg-[#092E3D]">
              <div className="max-w-[1280px] m-auto  xl:w-[30%] lg:w-[58%] w-full flex flex-col space-y-5 py-5 px-5">
                <div className="flex flex-row items-center justify-center">
                  <img className="w-24 h-24" src={LogoIcon} />
                </div>
                <div className="profileInput">
                  <img className="ProfileIcons" src={ProfileIcon} />
                  <p>
                    {isAuthenticated === true
                      ? staff.findIndex((e) => e.workEmail == user.email) != -1
                        ? staff.find((e) => e.workEmail == user.email).firstName
                        : null
                      : null}{" "}
                    {isAuthenticated === true
                      ? staff.findIndex((e) => e.workEmail == user.email) != -1
                        ? staff.find((e) => e.workEmail == user.email).lastName
                        : null
                      : null}
                  </p>
                </div>
                <div className="profileInput">
                  <img className="ProfileIcons" src={EmailIcon} />
                  <p className="w-full">
                    {isAuthenticated === true
                      ? staff.find((e) => e.workEmail == user.email) != -1
                        ? staff.find((e) => e.workEmail == user.email).workEmail
                        : null
                      : null}
                  </p>
                </div>
                <div className="profileInput">
                  <img className="ProfileIcons" src={MessageIcon} />
                  <p>
                    {isAuthenticated === true
                      ? roleHierarchyMaster.findIndex(
                          (e) =>
                            e.roleDbId ==
                            staff.findIndex((e1) => e1.workEmail === user.email)
                        ) != -1
                        ? roleHierarchyMaster.find(
                            (e) =>
                              e.roleDbId ==
                              staff.find((e1) => e1.workEmail === user.email)
                                .roleDbId
                          ).jobFunction
                        : null
                      : null}
                  </p>
                </div>
                <div
                  className="profileInput cursor-pointer"
                  onClick={() => LogoutFunction()}
                >
                  <img className="ProfileIcons" src={LogoutIcon} />
                  <p>Logout</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
            {/* {showSidebar == 1 ? (
              <div className="px-[0.8%] w-[30%] 2xl:absolute xl:absolute lg:absolute 2xl:flex xl:flex lg:hidden hidden right-0 2xl:text-xl xl:text-lg  text-sm py-[0.8%] bg-white border-[1px] shadow-2xl border-[#DDE1E6] rounded-md overflow-y-auto h-[85vh]">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col justify-end items-end">
                    <img
                      onClick={() => dispatch(populate_user(0))}
                      className="ProfileIcons"
                      src={CloseIcon}
                    />
                  </div>
                  <p>Past 24-Hr Notifications</p>
                  {eventNotificationLog.map((item, index) => (
                    <div
                      className="py-1 px-3 rounded flex flex-col gap-3 bg-[#092E3D] text-white"
                      key={index}
                    >
                      <p>
                        {
                          eventRules.find(
                            (e) => e.event_rule_id == item.event_rule_id
                          ).rule_name
                        }
                      </p>
                      <p>
                        {
                          eventRules.find(
                            (e) => e.event_rule_id == item.event_rule_id
                          ).alert_message
                        }
                      </p>
                      <p className="text-end">
                        {new Date(item.sent_timestamp).getFullYear() +
                          "-" +
                          Number(
                            new Date(item.sent_timestamp).getUTCMonth() + 1
                          ) +
                          "-" +
                          new Date(item.sent_timestamp).getUTCDate() +
                          " " +
                          new Date(item.sent_timestamp).getUTCHours() +
                          ":" +
                          new Date(item.sent_timestamp).getUTCMinutes() +
                          ":" +
                          new Date(item.sent_timestamp).getUTCSeconds()}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      ) : null}
      <LogoutConfirmation />
    </div>
  );
}

export default Profile;
