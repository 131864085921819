import React, { useState, useEffect, useRef } from "react";
import UnCheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import CloseIcon from "../Assert/Close.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_failure_codes } from "./Redux/FailureCodesSlice";
import { populate_defect_codes } from "./Redux/DefectCodesSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function FailureCodes() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON +
            "failureCodesDictionary?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "Defect") {
            setDefectCode(false);
          }
        });
      }
    }, 2000);
  }, []);
  //Get all the facilities
  let defectDictionary = useSelector((state) => state.callDefectCodes.value);
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  const [defectDictionaryArray, setDefectDictionaryArray] = useState([]);
  const FetchTheDefect = (_FailureCodes) => {
    if (defectDictionary.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "defectDictionary")
        .then((response) => {
          if (response.status == 200) {
            let tempDefectDictionary = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempDefectDictionary.push(response.data[i]);
              }
            }
            dispatch(populate_defect_codes(tempDefectDictionary));
            let tempDefect = [];
            for (let i = 0; i < tempDefectDictionary.length; i++) {
              if (
                _FailureCodes.findIndex(
                  (e) => e.defectDbId == tempDefectDictionary[i].defectDbId
                ) != -1
              ) {
                tempDefect.push(tempDefectDictionary[i]);
              }
            }
            setDefectDictionaryArray(tempDefect);
            setSelectedDefectCodes(tempDefect);
          }
        });
    } else {
      let tempDefect = [];
      for (let i = 0; i < defectDictionary.length; i++) {
        if (
          _FailureCodes.findIndex(
            (e) => e.defectDbId == defectDictionary[i].defectDbId
          ) != -1
        ) {
          tempDefect.push(defectDictionary[i]);
        }
      }
      setDefectDictionaryArray(tempDefect);
      setSelectedDefectCodes(tempDefect);
    }
  };

  const [search_defect, setsearch_defect] = useState("");
  const [search_defect_array, setsearch_defect_array] = useState([]);
  const OnChangeSearchDefect = (e) => {
    let content = e.target.value;
    setsearch_defect(content.toLowerCase());
    let temp = defectDictionaryArray.filter((e) =>
      e.description.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setsearch_defect_array(temp);
  };
  //Post Url
  const post_url =
    process.env.REACT_APP_BASE_API_PYTHON + "failureCodesDictionary";

  let failureCodesDictionary = useSelector(
    (state) => state.callFailureCodes.value
  );

  //Get The Client Side Validations
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [failureCodesObjectErrors, setFailureCodesObjectErrors] = useState({
    failureCodeError: "",
    descriptionError: "",
    effDateFromError: "",
    effDateToError: "",
    defectCodeError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "failureCodeError");
    OnChangeErrorValues("", "descriptionError");
    OnChangeErrorValues("", "defectCode");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "failureCodeError":
        setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
          ...failureCodesObjectErrors,
          failureCodeError: val,
        }));
        break;
      case "descriptionError":
        setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
          ...failureCodesObjectErrors,
          descriptionError: val,
        }));
        break;
      case "effDateFromError":
        setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
          ...failureCodesObjectErrors,
          effDateFromError: val,
        }));
        break;
      case "effDateToError":
        setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
          ...failureCodesObjectErrors,
          effDateToError: val,
        }));
        break;
      case "defectCodeError":
        setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
          ...failureCodesObjectErrors,
          defectCodeError: val,
        }));
        break;
    }
  };
  const [show_calender_date_from, set_show_calender_date_form] =
    useState(false);
  const [show_calender_date_to, set_show_calender_date_to] = useState(false);
  ///////////////////////////////////////////////////////////////////
  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);

  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < failureCodesDictionary.length; i++) {
  //     if (
  //       failureCodesDictionary[i].failureCode !==
  //       null
  //     ) {
  //       if (
  //         failureCodesDictionary[i].failureCode
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(failureCodesDictionary[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = failureCodesDictionary.filter((e1) =>
      e1.failureCode.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let temp_Array = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (
        failureCodesDictionary.length > process.env.REACT_APP_ITEMS_PER_PAGE
      ) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          temp_Array.push(failureCodesDictionary[i]);
        }
      } else {
        for (let i = 0; i < failureCodesDictionary.length; i++) {
          temp_Array.push(failureCodesDictionary[i]);
        }
      }
      total_pages.current = Math.ceil(
        failureCodesDictionary.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          temp_Array.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          temp_Array.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(temp_Array);
    set_searched_array(temp);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [failureCodesDictionaryArray, setFailureCodesDictionaryArray] =
    useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  let product_array_ref = useRef([]);
  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [failureCodesObject, setFailureCodesObject] = useState({
    failureCode: "",
    description: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    defectCode: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  //Declaration Of Delete States
  const [failureCodesObjectDelete, setFailureCodesObjectDelete] = useState({
    failureCode: "",
    description: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    defectCode: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  const onResetValue = () => {
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      failureCode: "",
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      description: "",
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      effDateFrom: new Date(),
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      defectCode: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "failureCode":
        setFailureCodesObject((failureCodesObject) => ({
          ...failureCodesObject,
          failureCode: e.target.value,
        }));
        break;
      case "description":
        setFailureCodesObject((failureCodesObject) => ({
          ...failureCodesObject,
          description: e.target.value,
        }));
        break;
      case "effDateFrom":
        set_show_calender_date_form(false);
        setFailureCodesObject((failureCodesObject) => ({
          ...failureCodesObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        set_show_calender_date_to(false);
        setFailureCodesObject((failureCodesObject) => ({
          ...failureCodesObject,
          effDateTo: e.target.value,
        }));
        break;
      case "defectCode":
        setFailureCodesObject((failureCodesObject) => ({
          ...failureCodesObject,
          defectCode: e.target.value,
        }));
        break;
    }
  };
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          failureCodesDictionaryArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            failureCodesDictionaryArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(failureCodesDictionaryArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            failureCodesDictionaryArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (failureCodesDictionaryArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(failureCodesDictionaryArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(failureCodesDictionaryArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === false && search_content.length > 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_show_calender_date_form(false);
    set_show_calender_date_to(false);
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = failureCodesDictionary.findIndex((e) => e.failureDbId === _code);
    select_deselect_the_record(failureCodesDictionary[idx]);
    set_item_to_delete(failureCodesDictionary[idx].failureDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "failureCodesDictionary?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "failureCodesDictionary?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    console.log("Facility:" + failureCodesObject.facilityName);
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          failureCode: failureCodesObject.failureCode,
          description: failureCodesObject.description,
          effDateFrom: failureCodesObject.effDateFrom,
          effDateTo:
            status === true
              ? new Date("1900-01-01")
              : failureCodesObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : "",
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : "",
          updatedTimestamp: new Date().toISOString(),
          defectDbId: Number(failureCodesObject.defectCode),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "failureCodesDictionary?size=1000",
              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditFailure = () => {
    let deleteData = {
      failureCode: failureCodesObjectDelete.failureCode,
      description: failureCodesObjectDelete.description,
      effDateFrom: GetServerDate(failureCodesObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
      defectDbId: Number(failureCodesObject.defectCode),
    };
    let data = {
      failureCode: failureCodesObject.failureCode,
      description: failureCodesObject.description,
      effDateFrom: GetServerDate(failureCodesObject.effDateFrom),
      effDateTo: GetServerDate(failureCodesObject.effDateTo),
      createdBy: isAuthenticated == true ? user.name : "",
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
      defectDbId: Number(failureCodesObject.defectCode),
    };
    axios
      .patch(post_url + "/" + selectedfailureDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON +
                    "failureCodesDictionary?size=1000",
                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedfailureDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Failure Codes Dictionary") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "failureCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              failureCodesObject.failureCode.length === 0
            ) {
              temp++;
              setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
                ...failureCodesObjectErrors,
                failureCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              failureCodesDictionary.findIndex(
                (e) => e.failureCode === failureCodesObject.failureCode
              ) != -1
            ) {
              temp++;
              setFailureCodesObjectErrors((failureCodesObjectErrors) => ({
                ...failureCodesObjectErrors,
                failureCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "defectCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              failureCodesObject.defectCode === 0
            ) {
              temp++;
              setFailureCodesObjectErrors((failureCodeError) => ({
                ...failureCodeError,
                defectCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = failureCodesDictionary.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        failureCodesDictionary.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (
        failureCodesDictionary.length <= process.env.REACT_APP_ITEMS_PER_PAGE
      ) {
        current_page.current = 1;
        for (let i = 0; i < failureCodesDictionary.length; i++) {
          temp_display_array.push(failureCodesDictionary[i]);
        }
      } else {
        if (
          failureCodesDictionary.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          failureCodesDictionary.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < failureCodesDictionary.length;
            i++
          ) {
            temp_display_array.push(failureCodesDictionary[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(failureCodesDictionary[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [selected_form_id, set_selected_form_id] = useState();
  const [paginatedArray, setPaginatedArray] = useState([]);
  const onChangeFormID = (e, _code) => {
    let temp = [];
    for (let i = 0; i < failureCodesDictionary.length; i++) {
      temp.push(failureCodesDictionary[i]);
    }
    let updatedArray = [];
    if (e != 0) {
      updatedArray = temp.filter((e1) => e1.defectDbId == e);
    } else {
      updatedArray = temp;
    }

    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }

    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setFailureCodesDictionaryArray(updatedArray);
    setPaginatedArray(tempArray);
    set_selected_form_id(e);
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_failureCodes = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_failureCodes.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setFailureCodesDictionaryArray(tempNonDraftArray);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_failure_codes(temp_failureCodes));
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheValidation();
          FetchTheDefect(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectedfailureDbId, setSelectedfailureDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (failureDbId, type) => {
    setSelectedfailureDbId(failureDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(failureDbId);
  };
  const SupplyValuesToModal = (failureDbId) => {
    let dateFrom = failureCodesDictionary.find(
      (e) => e.failureDbId == failureDbId
    ).effDateFrom;
    let dateTo = failureCodesDictionary.find(
      (e) => e.failureDbId == failureDbId
    ).effDateTo;

    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      failureCode: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).failureCode,
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      description: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).description,
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      effDateFrom: dateFrom,
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      effDateTo: dateTo,
    }));
    setFailureCodesObject((failureCodesObject) => ({
      ...failureCodesObject,
      defectCode:
        failureCodesDictionary.findIndex(
          (e1) => e1.failureDbId == failureDbId
        ) != -1
          ? failureCodesDictionary.find((e1) => e1.failureDbId == failureDbId)
              .defectDbId
          : null,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      failureCode: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).failureCode,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      description: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).description,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      effDateFrom: dateFrom,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      effDateTo: dateTo,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      defectCode: failureCodesDictionary.find(
        (e1) => e1.failureDbId == failureDbId
      ).defectDbId,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      createdBy: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).createdBy,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      updatedBy: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).updatedBy,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      createdTimestamp: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).createdTimestamp,
    }));
    setFailureCodesObjectDelete((failureCodesObjectDelete) => ({
      ...failureCodesObjectDelete,
      updatedTimestamp: failureCodesDictionary.find(
        (e) => e.failureDbId == failureDbId
      ).updatedTimestamp,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    console.log("Sorting:" + paginatedArray);
    let SortingFailureCode = [...failureCodesDictionaryArray];
    if (sortStyle === "Descending") {
      let UpdatedFailureCode = SortingFailureCode.sort((a, b) => {
        const a_res = action === "Code" ? a.failureCode : a.description;
        const b_res = action === "Code" ? b.failureCode : b.description;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedFailureCode);
      setSortStyle("Ascending");
    }
    if (sortStyle === "Ascending") {
      let UpdatedFailureCode = SortingFailureCode.sort((a, b) => {
        const a_res = action === "Code" ? a.failureCode : a.description;
        const b_res = action === "Code" ? b.failureCode : b.description;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedFailureCode);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingFailureCode.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingFailureCode[i]);
      }
    } else {
      for (let i = 0; i < SortingFailureCode.length; i++) {
        tempArray.push(SortingFailureCode[i]);
      }
    }

    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingFailureCode.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setFailureCodesDictionaryArray(SortingFailureCode);
  };
  const [defectCode, setDefectCode] = useState(false);
  const OnClickDefectcode = () => {
    if (failureCodesDictionary.length == 0) {
      setDefectCode(false);
    } else {
      setDefectCode(!defectCode);
    }
  };
  const [selectedDefectCodes, setSelectedDefectCodes] = useState([]);
  const AddRemoveDefectCodes = (id) => {
    let tempDefectCodes = [...selectedDefectCodes];
    let defectIndex = tempDefectCodes.findIndex((e) => e.defectDbId == id);
    if (defectIndex == -1) {
      tempDefectCodes.push(defectDictionary.find((e) => e.defectDbId == id));
    } else {
      tempDefectCodes.splice(defectIndex, 1);
    }
    console.log(tempDefectCodes);
    setSelectedDefectCodes(tempDefectCodes);
  };

  const OnOkDefectCode = () => {
    let temp = [...failureCodesDictionary];
    let updatedArray = [];
    for (let i = 0; i < selectedDefectCodes.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].defectDbId == selectedDefectCodes[i].defectDbId
          // defectDictionary.find((e) => e.description == selectedDefectCodes[i])
          //   .defectDbId
        ) {
          updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setFailureCodesDictionaryArray(updatedArray);
    setPaginatedArray(tempArray);
    setDefectCode(false);
  };
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="flex flex-row w-full">
              <div className="container-fluid ">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                        multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className="green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                  <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                    <div className="manageHeader">
                      {" "}
                      Manage Failure Codes Dictionary
                    </div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UnCheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UnCheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Failure Codes Dictionary"
                        value={search_content}
                        onChange={(e) => SearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "22%" }} />
                        <col span="1" style={{ width: "22%" }} />
                        <col span="1" style={{ width: "22%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Failure Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Name")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Description
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            {defectCode === true ? (
                              <div className="filterContainer">
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="Defect"
                                          type="text"
                                          placeholder="Search"
                                          value={search_defect}
                                          onChange={(e) =>
                                            OnChangeSearchDefect(e)
                                          }
                                        />
                                        <div
                                          id="Defect"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedDefectCodes(
                                              defectDictionaryArray
                                            )
                                          }
                                        >
                                          <img
                                            id="Defect"
                                            className="Icons"
                                            src={
                                              defectDictionaryArray.length ==
                                              selectedDefectCodes.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Defect">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {search_defect.length == 0
                                          ? defectDictionaryArray.map(
                                              (item, index) => (
                                                <div
                                                  id="Defect"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveDefectCodes(
                                                      item.defectDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Defect"
                                                    className="Icons"
                                                    src={
                                                      selectedDefectCodes.findIndex(
                                                        (e) =>
                                                          e.defectDbId ===
                                                          item.defectDbId
                                                      ) != -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Defect">
                                                    {item.description}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : search_defect.length > 0
                                          ? search_defect_array.map(
                                              (item, index) => (
                                                <div
                                                  id="Defect"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveDefectCodes(
                                                      item.defectDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Defect"
                                                    className="Icons"
                                                    src={
                                                      selectedDefectCodes.findIndex(
                                                        (e) =>
                                                          e.defectDbId ===
                                                          item.defectDbId
                                                      ) != -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Defect">
                                                    {item.description}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="Defect"
                                        className="popupfile_button"
                                        onClick={() => OnOkDefectCode()}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Defect"
                                        className="popupfile_button"
                                        onClick={() =>
                                          setSelectedDefectCodes([])
                                        }
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="IconsSpace">
                              <img className="ArrowIcons opacity-0" alt="" />
                              Defect Code
                              <img
                                id="Defect"
                                className="Icons"
                                onClick={() => OnClickDefectcode()}
                                src={FilterIcon}
                                alt=""
                              />
                              <label className="xl:hidden lg:hidden flex">
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length == 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.failureDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {item.failureCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {defectDictionary.length > 0 &&
                                  defectDictionary.findIndex(
                                    (e) => e.defectDbId == item.defectDbId
                                  ) != -1
                                    ? defectDictionary.find(
                                        (e) => e.defectDbId == item.defectDbId
                                      ).description
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.failureDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.failureDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.failureDbId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.failureDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {item.failureCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {defectDictionary.length > 0 &&
                                  defectDictionary.findIndex(
                                    (e) => e.defectDbId == item.defectDbId
                                  ) != -1
                                    ? defectDictionary.find(
                                        (e) => e.defectDbId == item.defectDbId
                                      ).description
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.failureDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.failureDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.failureDbId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.failureDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {item.failureCode}
                                </td>
                                <td
                                  className="text-left pl-3"
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {defectDictionary.length > 0 &&
                                  defectDictionary.findIndex(
                                    (e) => e.defectDbId == item.defectDbId
                                  ) != -1
                                    ? defectDictionary.find(
                                        (e) => e.defectDbId == item.defectDbId
                                      ).description
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.failureDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.failureDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.failureDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.failureDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.failureDbId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-nceter">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <div className="addTheFormSplits">
                    <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={
                            current_page.current == 1
                              ? PreviousHiddenIcon
                              : PreviousIcon
                          }
                          onClick={() => onPressPrevious()}
                          className="PreviouesNextIcons"
                          alt=""
                          style={{
                            cursor:
                              current_page.current == 1 ? "auto" : "pointer",
                          }}
                        />
                        <img
                          src={
                            current_page.current == total_pages.current
                              ? NextHiddenIcon
                              : NextIcon
                          }
                          onClick={() => onPressNext()}
                          className="PreviouesNextIcons"
                          style={{
                            cursor:
                              current_page.current == total_pages.current
                                ? "auto"
                                : "pointer",
                          }}
                          alt=""
                        />
                      </div>
                      <p>
                        Page {current_page.current} of{" "}
                        {paginatedArray.length === 0
                          ? current_page.current
                          : total_pages.current}
                      </p>
                    </div>
                    <div className="specebutton">
                      <button
                        className="addBtn"
                        onClick={() => onClickAddItem()}
                      >
                        Add
                      </button>
                      <button className="uploadBtn">Upload</button>
                      <button
                        className="deleteBtn"
                        onClick={() => OnDeleteSelectionClicked()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {/* //View box */}
                {open_view_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      View Failure Codes Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Failure Code</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={failureCodesObject.failureCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Failure Code Description
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={failureCodesObject.description}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Defect</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              defectDictionary.find(
                                (e) =>
                                  e.defectDbId == failureCodesObject.defectCode
                              ).description
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                failureCodesObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    failureCodesObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  failureCodesObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                failureCodesObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    failureCodesObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  failureCodesObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="editButton"
                          onClick={() => OpenModal(selectedfailureDbId, "Edit")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Edit Popup column */}
                {open_edit_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Edit Failure Codes Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Failure Code</label>
                            <label className="error">
                              {failureCodesObjectErrors.failureCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={failureCodesObject.failureCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Failure Code Description
                            </label>
                            <label className="error">
                              {failureCodesObjectErrors.descriptionError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={failureCodesObject.description}
                            onChange={(e) =>
                              onChangeValue(e, "description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Defect</label>
                            <label className="error">
                              {failureCodesObjectErrors.defectCodeError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={failureCodesObject.defectCode}
                            onChange={(e) =>
                              onChangeValue(e, "defectCode", null)
                            }
                          >
                            <option value={null}>Select...</option>
                            {defectDictionary.length > 0
                              ? defectDictionary.map((item, index) => (
                                  <option key={index} value={item.defectDbId}>
                                    {item.description}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {failureCodesObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                failureCodesObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    failureCodesObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  failureCodesObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {failureCodesObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                failureCodesObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    failureCodesObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  failureCodesObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnEditFailure()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Delete Popup column */}
                {show_delete_confirmations === true ? (
                  <div className="deleteModelstyle">
                    <div className="delete_header_modal">Confirmation</div>
                    <div className=" Confirmation">
                      Are you sure you want to delete?
                    </div>
                    <div className="btnDeletewrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnDeleteItem(item_to_delete)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Add Popup column */}
                {open_add_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Add Failure Codes Details

                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Failure Code</label>
                            <label className="error">
                              {failureCodesObjectErrors.failureCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={failureCodesObject.failureCode}
                            onChange={(e) =>
                              onChangeValue(e, "failureCode", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Failure Code Description
                            </label>
                            <label className="error">
                              {failureCodesObjectErrors.descriptionError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={failureCodesObject.description}
                            onChange={(e) =>
                              onChangeValue(e, "description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Defect</label>
                            <label className="error">
                              {failureCodesObjectErrors.defectCodeError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={failureCodesObject.defectCode}
                            onChange={(e) =>
                              onChangeValue(e, "defectCode", null)
                            }
                          >
                            <option value={null}>Select...</option>
                            {defectDictionary.length > 0
                              ? defectDictionary.map((item, index) =>
                                  new Date(item.effDateTo).getTime() ===
                                  new Date("9999-12-31").getTime() ? (
                                    <option key={index} value={item.defectDbId}>
                                      {item.description}
                                    </option>
                                  ) : null
                                )
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {failureCodesObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                failureCodesObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    failureCodesObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  failureCodesObject.effDateFrom
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {failureCodesObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                failureCodesObject.effDateTo
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    failureCodesObject.effDateTo
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  failureCodesObject.effDateTo
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="savedraftButton"
                          onClick={() => AddTheItem(true)}
                        >
                          Save as Draft
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => AddTheItem(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
            <LogoutConfirmation />
          </>
        ) : null}
      </div>
      {defectCode == true ? (
        <div className="FilterMobile">
          {defectCode === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Defect"
                      type="text"
                      placeholder="Search"
                      value={search_defect}
                      onChange={(e) => OnChangeSearchDefect(e)}
                    />
                    <div
                      id="Defect"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedDefectCodes(defectDictionaryArray)
                      }
                    >
                      <img
                        id="Defect"
                        className="Icons"
                        src={
                          defectDictionaryArray.length ==
                          selectedDefectCodes.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Defect">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_defect.length === 0
                      ? defectDictionaryArray.map((item, index) => (
                          <div
                            id="Defect"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveDefectCodes(item.defectDbId)
                            }
                          >
                            <img
                              id="Defect"
                              className="Icons"
                              src={
                                selectedDefectCodes.findIndex(
                                  (e) => e.defectDbId == item.defectDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Defect">{item.description}</p>
                          </div>
                        ))
                      : search_defect.length > 0
                      ? search_defect_array.map((item, index) => (
                          <div
                            id="Defect"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveDefectCodes(item.defectDbId)
                            }
                          >
                            <img
                              id="Defect"
                              className="Icons"
                              src={
                                selectedDefectCodes.findIndex(
                                  (e) => e.defectDbId == item.defectDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Defect">{item.description}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Defect"
                    className="popupfile_button"
                    onClick={() => OnOkDefectCode()}
                  >
                    Ok
                  </button>
                  <button
                    id="Defect"
                    className="popupfile_button"
                    onClick={() => setSelectedDefectCodes([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default FailureCodes;
