import {createSlice} from '@reduxjs/toolkit';
export const InspectionParametersSlice = createSlice({
  name: 'inspection_parameters',
  initialState: {
    value: [],
  },
  reducers: {
    populate_inspection_parameters: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_inspection_parameters} = InspectionParametersSlice.actions;
export default InspectionParametersSlice.reducer;
