import React, { useEffect, useState, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Products } from "./Redux/ProductsSlice";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_Facility } from "./Redux/FacilitySlice";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function Product() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheFacility();
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FecthTheValidation();
        FetchTheRecords("Get");
      }
    }, 2000);
  }, []);
  const products = useSelector((state) => state.callProducts.value);

  const { user, isAuthenticated } = useAuth0();
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "productMaster";

  //Get The Facilities
  let facilities = useSelector((state) => state.callFacilities.value);
  const FetchTheFacility = () => {
    if (facilities.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempFacilityMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              tempFacilityMaster.push(response.data[i]);
            }
            dispatch(populate_Facility(tempFacilityMaster));
          }
        });
    }
  };
  //Get The Client Side Validations
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };

  const FecthTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidation = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidation.push(response.data[i]);
          }
          dispatch(populate_client_side_validation(tempClientSideValidation));
        }
      });
  };
  //Refs
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [product_master_array, set_product_master_array] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);
  const dispatch = useDispatch();

  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = products.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        products.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (products.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < products.length; i++) {
          temp_display_array.push(products[i]);
        }
      } else {
        if (
          products.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          products.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < products.length;
            i++
          ) {
            temp_display_array.push(products[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(products[i]);
          }
        }
      }
      set_product_master_array(temp_display_array);
    }
  };

  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");

  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  //Searching
  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const onChangeSearchContent = (e) => {
    setLoading(false);

    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = products.filter((e) =>
      e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (products.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < products.length; i++) {
          tempArray.push(products[i]);
        }
      } else {
        for (let i = 0; i < products.length; i++) {
          tempArray.push(products[i]);
        }
      }
      current_page.current = Math.ceil(
        products.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      current_page.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    set_product_master_array(tempArray);
    set_searched_array(tempFilter);
  };

  const [productObject, setProductObject] = useState({
    productCode: "",
    partNumber: "",
    skuCode: "",
    productName: "",
    description: "",
    brand: "",
    subbrand: "",
    grouping: "",
    subgroup: "",
    category: "",
    subcategory: "",
    cluster: "",
    attribute: "",
    attributeLevel: "",
    attributeValue: "",
    generation: "",
    ver: 0,
    trim: "",
    bomRef: "",
    specsRef: "",
    recipeRef: "",
    uom: "",
    packSize: "",
    netWeight: "",
    mannufactured: "",
    traded: "",
    hsnCode: "",
    listPrice: 0,
    cogs: "",
    packagingMaterialVendor: "",
    createdBy: "",
    updatedBy: "",
    approvedBy: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    approvedTimestamp: new Date(),
    facilityDbId: 0,
    facilityCode: "",
    productDbId: 0,
    facilityName: "",
  });
  const [productObjectDelete, setProductObjectDelete] = useState({
    productCode: "",
    partNumber: "",
    skuCode: "",
    productName: "",
    description: "",
    brand: "",
    subbrand: "",
    grouping: "",
    subgroup: "",
    category: "",
    subcategory: "",
    cluster: "",
    attribute: "",
    attributeLevel: "",
    attributeValue: "",
    generation: "",
    ver: 0,
    trim: "",
    bomRef: "",
    specsRef: "",
    recipeRef: "",
    uom: "",
    packSize: "",
    netWeight: "",
    mannufactured: "",
    traded: "",
    hsnCode: "",
    listPrice: 0,
    cogs: "",
    packagingMaterialVendor: "",
    createdBy: "",
    updatedBy: "",
    approvedBy: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    approvedTimestamp: new Date(),
    facilityDbId: 0,
    facilityCode: "",
    productDbId: 0,
    facilityName: "",
  });
  const [productObjectErrors, setProductObjectErrors] = useState({
    productCodeErrors: "",
    productNameErrors: "",
    descriptionErrors: "",
    verErrors: "",
    facilityNameErrors: "",
    brandErrors: "",
    subbrandErrors: "",
    categoryErrors: "",
    listPriceErrors: "",
    effDateFromErrors: "",
    effDateToErrors: "",
    specsRefErrors: "",
    bomRefErrors: "",
  });
  const onChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productCode":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          productCodeErrors: error,
        }));
        break;
      case "productName":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          productNameErrors: error,
        }));
        break;
      case "description":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          descriptionErrors: error,
        }));
        break;
      case "ver":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          ver: error,
        }));
        break;
      case "facilityName":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          facilityNameErrors: error,
        }));
        break;
      case "brand":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          brandErrors: error,
        }));
        break;
      case "subbrand":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          subbrandErrors: error,
        }));
        break;
      case "category":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          categoryErrors: error,
        }));
        break;
      case "listPrice":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          listPriceErrors: error,
        }));
        break;
      case "effDateFrom":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          effDateFromErrors: error,
        }));
        break;
      case "effDateTo":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          effDateToErrors: error,
        }));
        break;
      case "specsRef":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          specsRefErrors: error,
        }));
        break;
      case "bomRef":
        setProductObjectErrors((productObjectErrors) => ({
          ...productObjectErrors,
          bomRefErrors: error,
        }));
    }
  };
  const OnResetErrors = () => {
    onChangeErrorValues("", "productCodeErrors");
    onChangeErrorValues("", "productNameErrors");
    onChangeErrorValues("", "descriptionErrors");
    onChangeErrorValues("", "verErrors");
    onChangeErrorValues("", "facilityNameErrors");
    onChangeErrorValues("", "brandErrors");
    onChangeErrorValues("", "subbrandErrors");
    onChangeErrorValues("", "categoryErrors");
    onChangeErrorValues("", "listPriceErrors");
    onChangeErrorValues("", "effDateFromErrors");
    onChangeErrorValues("", "effDateToErrors");
    onChangeErrorValues("", "specsRefErrors");
    onChangeErrorValues("", "bomRefErrors");
  };
  const onChangeProductValues = (e, type) => {
    console.log(e.target.value);

    // eslint-disable-next-line default-case
    switch (type) {
      case "productCode":
        setProductObject((productObject) => ({
          ...productObject,
          productCode: e.target.value,
        }));
        break;
      case "productName":
        setProductObject((productObject) => ({
          ...productObject,
          productName: e.target.value,
        }));
        break;
      case "description":
        setProductObject((productObject) => ({
          ...productObject,
          description: e.target.value,
        }));
        break;
      case "ver":
        setProductObject((productObject) => ({
          ...productObject,
          ver: e.target.value,
        }));
        break;
      case "facilityName":
        setProductObject((productObject) => ({
          ...productObject,
          facilityName: e.target.value,
        }));
        break;
      case "brand":
        setProductObject((productObject) => ({
          ...productObject,
          brand: e.target.value,
        }));
        break;
      case "subbrand":
        setProductObject((productObject) => ({
          ...productObject,
          subbrand: e.target.value,
        }));
        break;
      case "category":
        setProductObject((productObject) => ({
          ...productObject,
          category: e.target.value,
        }));
        break;
      case "listPrice":
        setProductObject((productObject) => ({
          ...productObject,
          listPrice: e.target.value,
        }));
        break;
      case "effDateTo":
        setProductObject((productObject) => ({
          ...productObject,
          effDateTo: e.target.value,
        }));
        break;
      case "effDateFrom":
        setProductObject((productObject) => ({
          ...productObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "specsRef":
        setProductObject((productObject) => ({
          ...productObject,
          specsRef: e.target.value,
        }));
        break;
      case "bomRef":
        setProductObject((productObject) => ({
          ...productObject,
          bomRef: e.target.value,
        }));
        break;
    }
  };
  const [selectedProductDbId, setSelectedProductDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (productDbId, type) => {
    setSelectedProductDbId(productDbId);
    switch (type) {
      case "View":
        set_open_view_modal(true);
        break;
      case "Edit":
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(productDbId);
  };
  const SupplyValuesToModal = (productDbId) => {
    let dateFrom = products.find(
      (e) => e.productDbId == productDbId
    ).effDateFrom;
    let dateTo = products.find((e) => e.productDbId == productDbId).effDateTo;
    setProductObject((productObject) => ({
      ...productObject,
      productCode: products.find((e) => e.productDbId == productDbId)
        .productCode,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      productCode: products.find((e) => e.productDbId == productDbId)
        .productCode,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      productName: products.find((e) => e.productDbId == productDbId)
        .productName,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      productName: products.find((e) => e.productDbId == productDbId)
        .productName,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      description: products.find((e) => e.productDbId == productDbId)
        .description,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      description: products.find((e) => e.productDbId == productDbId)
        .description,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      ver: products.find((e) => e.productDbId == productDbId).ver,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      ver: products.find((e) => e.productDbId == productDbId).ver,
    }));
    setProductObject((productObject) => ({
      ...productObject,

      facilityName: products.find((e) => e.productDbId == productDbId)
        .facilityDbId,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      facilityName: products.find((e) => e.productDbId == productDbId)
        .facilityDbId,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      brand: products.find((e) => e.productDbId == productDbId).brand,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      subbrand: products.find((e) => e.productDbId == productDbId).brand,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      brand: products.find((e) => e.productDbId == productDbId).subbrand,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      subbrand: products.find((e) => e.productDbId == productDbId).subbrand,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      category: products.find((e) => e.productDbId == productDbId).category,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      category: products.find((e) => e.productDbId == productDbId).category,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      listPrice: products.find((e) => e.productDbId == productDbId).listPrice,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      listPrice: products.find((e) => e.productDbId == productDbId).listPrice,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      effDateFrom: dateFrom,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      effDateFrom: dateFrom,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      effDateTo: dateTo,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      effDateTo: dateTo,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      specsRef: products.find((e) => e.productDbId == productDbId).specsRef,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      specsRef: products.find((e) => e.productDbId == productDbId).specsRef,
    }));
    setProductObject((productObject) => ({
      ...productObject,
      bomRef: products.find((e) => e.productDbId == productDbId).bomRef,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      bomRef: products.find((e) => e.productDbId == productDbId).bomRef,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      createdBy: products.find((e) => e.productDbId == productDbId).createdBy,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      updatedBy: products.find((e) => e.productDbId == productDbId).updatedBy,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      createdTimestamp: products.find((e) => e.productDbId == productDbId)
        .createdTimestamp,
    }));
    setProductObjectDelete((productObjectDelete) => ({
      ...productObjectDelete,
      updatedTimestamp: products.find((e) => e.productDbId == productDbId)
        .updatedTimestamp,
    }));
  };

  //Get Products

  const [nonDraftArray, setNonDraftArray] = useState([]);

  const FetchTheRecords = (_action) => {
    setLoading(true);
    let temp_product_master = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(process.env.REACT_APP_BASE_API_PYTHON + "productMaster?size=1000")
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅ Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_product_master.push(response.data[i]);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setNonDraftArray(tempNonDraftArray);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);
          dispatch(populate_Products(temp_product_master));
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          set_product_master_array(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        ShowMEWSPopup("❌ " + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
      });
  };
  function isValidHttpUrl(str) {
    if (str != null) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return pattern.test(str);
    } else {
      return true;
    }
  }
  //Add The Products
  const AddTheProducts = (status) => {
    OnResetErrors();
    if (Validation() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          productCode: productObject.productCode,
          productName: productObject.productName,
          description: productObject.description,
          brand: productObject.brand,
          subbrand: productObject.subbrand,
          category: productObject.category,
          ver: productObject.ver,
          specsRef: productObject.specsRef,
          bomRef: productObject.bomRef,
          effDateFrom: productObject.effDateFrom,
          effDateTo:
            status === true ? new Date("1900-01-01") : productObject.effDateTo,
          listPrice: Number(productObject.listPrice),
          facilityDbId: Number(productObject.facilityName),
          createdBy: isAuthenticated == true ? user.name : null,
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : null,
          updatedTimestamp: new Date().toISOString(),
        })
        .then((response) => {
          if (response.status === 201) {
            ShowMEWSPopup("✅ Successfully added the item", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheRecords("Post");
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌ " + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validation = () => {
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Product Master") {
        csv_count++;

        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "productCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              productObject.productCode.length === 0
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "productCode"
              );
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              products.findIndex(
                (e) =>
                  e.productCode.toUpperCase().trim() ===
                  productObject.productCode.toUpperCase().trim()
              ) !== -1
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "productCode"
              );
            }
            break;
          case "productName":
            if (productObject.productName !== null) {
              if (
                clientSideValidations[i].validationType === "required" &&
                productObject.productName.length == 0
              ) {
                temp++;
                onChangeErrorValues(
                  clientSideValidations[i].shortMessage,
                  "productName"
                );
              }
              if (
                clientSideValidations[i].validationType === "min_length" &&
                productObject.productName.length < 3
              ) {
                temp++;
                onChangeErrorValues(
                  clientSideValidations[i].shortMessage,
                  "productName"
                );
              }
              // if (
              //   clientSideValidations[i].validationType === "duplicate" &&
              //   products.findIndex(
              //     (e) =>
              //       e.productName.toUpperCase().trim() ===
              //       productObject.productName.toUpperCase().trim()
              //   ) !== -1
              // ) {
              //   temp++;
              //   onChangeErrorValues(clientSideValidations[i].shortMessage,"productName");
              // }
            }
            break;
          case "description":
            if (
              clientSideValidations[i].validationType === "required" &&
              productObject.description.length == 0
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "description"
              );
            }
            if (
              clientSideValidations[i].validationType === "max_length" &&
              productObject.description.length > 500
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "description"
              );
            }
            break;
          case "listPrice":
            if (
              clientSideValidations[i].validationType === "numeric_float" &&
              isNaN(productObject.listPrice)
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "listPrice"
              );
            }
            if (
              clientSideValidations[i].validationType === "required" &&
              productObject.listPrice === null
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "listPrice"
              );
            }
            if (
              clientSideValidations[i].validationType === "min_value" &&
              productObject.listPrice == 0
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "listPrice"
              );
            }
            if (
              clientSideValidations[i].validationType === "max_value" &&
              productObject.listPrice > 999999.99
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "listPrice"
              );
            }
            break;
          case "specsRef":
            if (
              clientSideValidations[i].validationType === "url" &&
              isValidHttpUrl(productObject.specsRef) === false &&
              productObject.specsRef.length > 0
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "specsRef"
              );
            }
            break;
          case "bomRef":
            if (
              clientSideValidations[i].validationType === "url" &&
              isValidHttpUrl(productObject.bomRef) === false &&
              productObject.bomRef.length > 0
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "bomRef"
              );
            }
            break;
          case "facilityName":
            if (
              clientSideValidations[i].validationType === "required" &&
              productObject.facilityName === ""
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "facilityName"
              );
            }
            break;
          case "effDateTo":
            if (
              clientSideValidations[i].validationType ===
                "greater_than_equalto_date" &&
              productObject.effDateTo < productObject.effDateFrom
            ) {
              temp++;
              onChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "effDateTo"
              );
            }
            break;
        }
      }
    }
    return temp;
  };
  //Get The Facilities
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);
  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    //Reset Product Object
    setProductObject({
      productCode: "",
      partNumber: "",
      skuCode: "",
      productName: "",
      description: "",
      brand: "",
      subbrand: "",
      grouping: "",
      subgroup: "",
      category: "",
      subcategory: "",
      cluster: "",
      attribute: "",
      attributeLevel: "",
      attributeValue: "",
      generation: "",
      ver: 0,
      trim: "",
      bomRef: "",
      specsRef: "",
      recipeRef: "",
      uom: "",
      packSize: "",
      netWeight: "",
      mannufactured: true,
      traded: "",
      hsnCode: "",
      listPrice: 0,
      cogs: "",
      packagingMaterialVendor: "",
      createdBy: "",
      updatedBy: "",
      approvedBy: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
      approvedTimestamp: new Date(),
      facilityDbId: 0,
      facilityCode: "",
      productDbId: 0,
      facilityName: "",
    });
    //Reset Error Object
    OnResetErrors();
  };

  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
      if (temp_multiple_codes > 0) {
        set_mews_popup_color("#28A745");
        ShowMEWSPopup(`You have selected ${multiple_codes.length}`, "Success");
      }
    }
  };
  //Previous/Next Button
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          products.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            products.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(products[i]);
            }
            current_page.current += 1;
            set_product_master_array(temp);
          } else if (
            products.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (products.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(products[i]);
            }
            current_page.current += 1;
            set_product_master_array(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            set_product_master_array(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            set_product_master_array(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            set_product_master_array(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            set_product_master_array(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(products[i]);
        }
        current_page.current -= 1;
        set_product_master_array(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        set_product_master_array(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        set_product_master_array(temp);
      }
    }
  };

  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = products.findIndex((e) => e.productDbId === _code);
    select_deselect_the_record(products[idx].productCode);
    set_item_to_delete(_code);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheRecords(
              process.env.REACT_APP_BASE_API_PYTHON + "productMaster?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      console.log(multiple_codes);
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheRecords(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "productMaster?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditProduct = () => {
    let deleteData = {
      productCode: productObjectDelete.productCode,
      productName: productObjectDelete.productName,
      description: productObjectDelete.description,
      brand: productObjectDelete.brand,
      subbrand: productObjectDelete.subbrand,
      category: productObjectDelete.category,
      ver: productObjectDelete.ver,
      specsRef: productObjectDelete.specsRef,
      bomRef: productObjectDelete.bomRef,
      effDateFrom: GetServerDate(productObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      listPrice: Number(productObjectDelete.listPrice),
      facilityDbId: Number(productObject.facilityName),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      productCode: productObject.productCode,
      productName: productObject.productName,
      description: productObject.description,
      brand: productObject.brand,
      subbrand: productObject.subbrand,
      category: productObject.category,
      ver: productObject.ver,
      specsRef: productObject.specsRef,
      bomRef: productObject.bomRef,
      effDateFrom: GetServerDate(productObject.effDateFrom),
      effDateTo: GetServerDate(productObject.effDateTo),
      listPrice: Number(productObject.listPrice),
      facilityDbId: Number(productObject.facilityName),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    axios
      .patch(post_url + "/" + selectedProductDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(process.env.REACT_APP_BASE_API_PYTHON + "productMaster", data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheRecords(
                  process.env.REACT_APP_BASE_API_PYTHON +
                    "productMaster?size=1000",

                  "Post"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedProductDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const [sortStyle, setSortStyle] = useState("Descending");

  const AscendingDescendingFunction = (action) => {
    let SortingProduct = [...products];
    console.log("Sorting:" + products);
    if (sortStyle === "Descending") {
      let updatedProductMaster = SortingProduct.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.productCode
            : action === "Name"
            ? a.productName
            : action === "EffectiveFrom"
            ? a.effDateFrom
            : action === "EffectiveTo"
            ? a.effDateTo
            : null;
        const b_res =
          action === "Code"
            ? b.productCode
            : action === "Name"
            ? b.productName
            : action === "EffectiveFrom"
            ? b.effDateFrom
            : action === "EffectiveTo"
            ? b.effDateTo
            : null;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      set_product_master_array(updatedProductMaster);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let updatedProductMaster = SortingProduct.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.productCode
            : action === "Name"
            ? a.productName
            : action === "EffectiveFrom"
            ? a.effDateFrom
            : action === "EffectiveTo"
            ? a.effDateTo
            : null;
        const b_res =
          action === "Code"
            ? b.productCode
            : action === "Name"
            ? b.productName
            : action === "EffectiveFrom"
            ? b.effDateFrom
            : action === "EffectiveTo"
            ? b.effDateTo
            : null;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      set_product_master_array(updatedProductMaster);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingProduct.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingProduct[i]);
      }
    } else {
      for (let i = 0; i < SortingProduct.length; i++) {
        tempArray.push(SortingProduct[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingProduct.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    set_product_master_array(SortingProduct);
    set_product_master_array(tempArray);
  };

  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid">
              <div
                className="multipleSelection "
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 1
                      : 1,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {" "}
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader">Manage Products</div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Product"
                      value={search_content}
                      onChange={(e) => onChangeSearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons " src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "10%" }} />
                      <col span="1" style={{ width: "21%" }} />
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "11%" }} />
                      <col span="1" style={{ width: "9%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>

                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Code")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Product Code
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Name")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            <caption className="flex flex-row justify-items-center">
                              Product Name
                            </caption>
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>Version</th>
                        <th>Facility Code</th>
                        <th>Brand</th>
                        <th>Sub-Brand</th>
                        <th>Category</th>
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? product_master_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.productDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.productDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.productCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.productName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.ver}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {/* {
                              facilities.find(
                                (e) => e.facilities == item.facilityDbId
                              ).name
                            } */}
                                {facilities.length > 0 &&
                                facilities.findIndex(
                                  (e) => e.facilities == item.facilities
                                ) != -1
                                  ? facilities.find(
                                      (e) => e.facilities == item.facilities
                                    ).name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.brand}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.subbrand}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.category}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {Number(
                                  new Date(item.effDateTo).getUTCMonth()
                                ) +
                                  1 +
                                  "-" +
                                  new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.productDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.productDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.productDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.productDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.productDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.productCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.productName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.ver}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {facilities.length > 0 &&
                                facilities.findIndex(
                                  (e) => e.facilities == item.facilities
                                ) != -1
                                  ? facilities.find(
                                      (e) => e.facilities == item.facilities
                                    ).name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.brand}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.subbrand}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.category}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {Number(
                                  new Date(item.effDateTo).getUTCMonth()
                                ) +
                                  1 +
                                  "-" +
                                  new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.productDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.productDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.productDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? product_master_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.productDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.productDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.productCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.productName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.ver}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {facilities.length > 0 &&
                                facilities.findIndex(
                                  (e) => e.facilities == item.facilities
                                ) != -1
                                  ? facilities.find(
                                      (e) => e.facilities == item.facilities
                                    ).name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.brand}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.subbrand}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {item.category}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.productDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.productDbId)
                                }
                              >
                                {Number(
                                  new Date(item.effDateTo).getUTCMonth()
                                ) +
                                  1 +
                                  "-" +
                                  new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.productDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.productDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.productDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {product_master_array.length == 0
                        ? "No records found"
                        : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {product_master_array.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">View Product Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product Code</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.productCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product Name</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.productName}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.description}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Version</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.ver}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            facilities.find(
                              (e) =>
                                e.facilityDbId === productObject.facilityName
                            ).name
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Brand</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.brand}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sub Brand</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.subbrand}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Category</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.category}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">List Price</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.listPrice}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              productObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(productObject.effDateFrom).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(productObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(productObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(productObject.effDateTo).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Specifications URL
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.specsRef}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            BOM Reference URL
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productObject.bomRef}
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectedProductDbId, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">Edit Product Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product Code</label>
                          <label className="error">
                            {productObjectErrors.productCode}
                          </label>
                        </div>
                        <input
                          type="text"
                          contentEditable={false}
                          value={productObject.productCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product Name</label>
                          <label className="error">
                            {productObjectErrors.productName}
                          </label>
                        </div>
                        <input
                          type="text"
                          contentEditable={false}
                          value={productObject.productName}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup"> Description</label>
                          <label className="error">
                            {productObjectErrors.description}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.description}
                          onChange={(e) =>
                            onChangeProductValues(e, "description", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Version</label>
                          <label className="error">
                            {productObjectErrors.ver}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.ver}
                          onChange={(e) =>
                            onChangeProductValues(e, "ver", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {productObjectErrors.facilityName}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={productObject.facilityName}
                          onChange={(e) =>
                            onChangeProductValues(e, "facilityName", null)
                          }
                        >
                          <option value={null} className="Poppins">
                            Select...
                          </option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                                new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Brand</label>
                          <label className="error">
                            {productObjectErrors.brand}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.brand}
                          onChange={(e) =>
                            onChangeProductValues(e, "brand", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sub Brand</label>
                          <label className="error">
                            {productObjectErrors.subbrand}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.subbrand}
                          onChange={(e) =>
                            onChangeProductValues(e, "subbrand", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Category</label>
                          <label className="error">
                            {productObjectErrors.category}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.category}
                          onChange={(e) =>
                            onChangeProductValues(e, "category", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">List Price</label>
                          <label className="error">
                            {productObjectErrors.listPrice}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.listPrice}
                          onChange={(e) =>
                            onChangeProductValues(e, "listPrice", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {productObjectErrors.effDateFrom}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              productObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(productObject.effDateFrom).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeProductValues(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {productObjectErrors.effDateTo}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(productObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(productObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(productObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeProductValues(e, "effDateTo", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Specifications URL
                          </label>
                          <label className="error">
                            {productObjectErrors.specsRef}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.specsRef}
                          onChange={(e) =>
                            onChangeProductValues(e, "specsRef", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            BOM Reference URL
                          </label>
                          <label className="error">
                            {productObjectErrors.bomRef}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.bomRef}
                          onChange={(e) =>
                            onChangeProductValues(e, "bomRef", null)
                          }
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEditProduct()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">Add Product Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product Code</label>
                          <label className="error">
                            {productObjectErrors.productCodeErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.productCode}
                          onChange={(e) =>
                            onChangeProductValues(e, "productCode", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product Name</label>
                          <label className="error">
                            {productObjectErrors.productNameErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.productName}
                          onChange={(e) =>
                            onChangeProductValues(e, "productName", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup"> Description</label>
                          <label className="error">
                            {productObjectErrors.descriptionErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.description}
                          onChange={(e) =>
                            onChangeProductValues(e, "description", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Version</label>
                          <label className="error">
                            {productObjectErrors.verErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.ver}
                          onChange={(e) =>
                            onChangeProductValues(e, "ver", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {productObjectErrors.facilityNameErrors}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={productObject.facilityName}
                          onChange={(e) =>
                            onChangeProductValues(e, "facilityName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                                new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Brand</label>
                          <label className="error">
                            {productObjectErrors.brandErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.brand}
                          onChange={(e) =>
                            onChangeProductValues(e, "brand", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sub Brand</label>
                          <label className="error">
                            {productObjectErrors.subbrandErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.subbrand}
                          onChange={(e) =>
                            onChangeProductValues(e, "subbrand", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Category</label>
                          <label className="error">
                            {productObjectErrors.categoryErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.category}
                          onChange={(e) =>
                            onChangeProductValues(e, "category", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">List Price</label>
                          <label className="error">
                            {productObjectErrors.listPriceErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.listPrice}
                          onChange={(e) =>
                            onChangeProductValues(e, "listPrice", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {productObjectErrors.effDateFromErrors}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              productObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(productObject.effDateFrom).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeProductValues(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {productObjectErrors.effDateToErrors}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(productObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(productObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(productObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeProductValues(e, "effDateTo", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Specifications URL
                          </label>
                          <label className="error">
                            {productObjectErrors.specsRefErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.specsRef}
                          onChange={(e) =>
                            onChangeProductValues(e, "specsRef", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            BOM Reference URL
                          </label>
                          <label className="error">
                            {productObjectErrors.bomRefErrors}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productObject.bomRef}
                          onChange={(e) =>
                            onChangeProductValues(e, "bomRef", null)
                          }
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheProducts(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheProducts(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default Product;
