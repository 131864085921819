import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Sensor } from "./Redux/SensorSlice";
import { populate_Equipment } from "./Redux/EquipmentSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function Sensor() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheData(
          process.env.REACT_APP_BASE_API_SENSOR + "sensor?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "EquipmentPopup") {
            setEquipmentPopup(false);
          }
        });
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );

  const FetchtheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  //Get all the facilities
  let equipmentMaster = useSelector((state) => state.callEquipment.value);

  const [equipmentArray, setEquipmentArray] = useState([]);
  const FetchTheEquipment = (_sensor) => {
    if (equipmentMaster.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempEquipmentMaster = [];
            let tempEquipmentDropdown = [];
            for (let i = 0; i < response.data.results.length; i++) {
              if (
                new Date(response.data.results[i].eff_date_to).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempEquipmentMaster.push(response.data.results[i]);
              }

              if (
                tempEquipmentDropdown.findIndex(
                  (e) =>
                    e.main_equipment_name ==
                    response.data.results[i].main_equipment_name
                ) == -1
              ) {
                tempEquipmentDropdown.push(response.data.results[i]);
              }
            }
            dispatch(populate_Equipment(tempEquipmentMaster));
            setSelectedEquipment(tempEquipmentDropdown);
            setEquipmentArray(tempEquipmentDropdown);
            // let tempEquipment = [];
            // for (let i = 0; i < tempEquipmentMaster.length; i++) {
            //   if (
            //     _sensor.findIndex(
            //       (e) => e.equipment_id == tempEquipmentMaster[i].equipment_id
            //     ) != -1
            //   ) {
            //     tempEquipment.push(tempEquipmentMaster[i]);
            //   }
            // }
          }
        });
    } else {
      // let tempEquipment = [];
      // for (let i = 0; i < equipmentMaster.length; i++) {
      //   if (
      //     _sensor.findIndex(
      //       (e) => e.equipment_id == equipmentMaster[i].equipment_id
      //     ) != -1
      //   ) {
      //     tempEquipment.push(equipmentMaster[i]);
      //     console.log(equipmentMaster[i]);
      //   }
      // }
      let tempEquipmentDropdown = [];
      for (let i = 0; i < equipmentMaster.length; i++) {
        if (
          tempEquipmentDropdown.findIndex(
            (e) => e.main_equipment_name == equipmentMaster.main_equipment_name
          ) == -1
        ) {
          tempEquipmentDropdown.push(equipmentMaster[i]);
        }
      }
      setEquipmentArray(tempEquipmentDropdown);
      setSelectedEquipment(tempEquipmentDropdown);
    }
  };
  const [search_equipment, setsearch_equipment] = useState("");
  const [searched_equipment_array, setSearched_equipment_array] = useState([]);
  const OnChangeSearchEquipment = (e) => {
    let content = e.target.value;
    setsearch_equipment(content.toLowerCase());
    let temp = equipmentArray.filter((e) =>
      e.main_equipment_name
        .toLowerCase()
        .trim()
        .match(content.toLowerCase().trim())
    );
    setSearched_equipment_array(temp);
  };

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_SENSOR + "sensor";

  let SensorMaster = useSelector((state) => state.callSensor.value);
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [sensorObjectErrors, setSensorObjectErrors] = useState({
    calibration_dateError: "",
    sensor_nameError: "",
    sensor_codeError: "",
    sensor_categoryError: "",
    sensor_locationError: "",
    upper_thresholdError: "",
    eff_date_fromError: "",
    eff_date_toError: "",
    lower_thresholdError: "",
    measurement_unitError: "",
    sensor_directionError: "",
    equipmentError: "",
    activeError: "",
    serial_numberError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "sensor_nameError");
    OnChangeErrorValues("", "sensor_codeError");
    OnChangeErrorValues("", "sensor_categoryError");
    OnChangeErrorValues("", "sensor_locationError");
    OnChangeErrorValues("", "upper_thresholdError");
    OnChangeErrorValues("", "measurement_unitError");
    OnChangeErrorValues("", "eff_date_fromError");
    OnChangeErrorValues("", "eff_date_toError");
    OnChangeErrorValues("", "calibration_dateError");
    OnChangeErrorValues("", "lower_thresholdError");
    OnChangeErrorValues("", "sensor_directionError");
    OnChangeErrorValues("", "equipmentError");
    OnChangeErrorValues("", "activeError");
    OnChangeErrorValues("", "serial_numberError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "measurement_unitError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          measurement_unitError: val,
        }));
        break;
      case "calibration_dateError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          calibration_dateError: val,
        }));
        break;
      case "sensor_nameError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          sensor_nameError: val,
        }));
        break;
      case "sensor_codeError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          sensor_codeError: val,
        }));
        break;
      case "sensor_categoryError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          sensor_categoryError: val,
        }));
        break;
      case "sensor_locationError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          sensor_locationError: val,
        }));
        break;
      case "upper_thresholdError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          upper_thresholdError: val,
        }));
        break;
      case "eff_date_fromError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          eff_date_fromError: val,
        }));
        break;
      case "eff_date_toError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          eff_date_toError: val,
        }));
        break;
      case "lower_thresholdError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          lower_thresholdError: val,
        }));
        break;
      case "sensor_directionError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          sensor_directionError: val,
        }));
        break;
      case "equipmentError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          equipmentError: val,
        }));
        break;
      case "activeError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          activeError: val,
        }));
        break;
      case "serial_numberError":
        setSensorObjectErrors((sensorObjectErrors) => ({
          ...sensorObjectErrors,
          serial_numberError: val,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < sensorMasterArray.length; i++) {
  //     if (sensorMasterArray[i].sensor_name !== null) {
  //       if (
  //         sensorMasterArray[i].sensor_name
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(sensorMasterArray[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    let content = e.target.value;
    current_page.current = 1;
    set_search_content(content);
    let temp = SensorMaster.filter((e1) =>
      e1.sensor_name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (SensorMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(SensorMaster[i]);
        }
      } else {
        for (let i = 0; i < SensorMaster.length; i++) {
          tempArray.push(SensorMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        SensorMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [sensorMasterArray, setSensorMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [sensorObject, setSensorObject] = useState({
    sensor_name: "",
    sensor_code: "",
    sensor_category: "",
    sensor_direction: "",
    sensor_location: "",
    lower_threshold: "",
    upper_threshold: "",
    measurement_unit: "",
    calibration_date: new Date(),
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
    equipment: 0,
    active: null,
    serial_number: 0,
    created_by: "",
    created_timestamp: new Date(),
    updated_by: "",
    updated_timestamp: new Date(),
  });

  //Declaration Of Delete States
  const [sensorObjectDelete, setSensorObjectDelete] = useState({
    sensor_name: "",
    sensor_code: "",
    sensor_category: "",
    sensor_direction: "",
    sensor_location: "",
    lower_threshold: "",
    upper_threshold: "",
    measurement_unit: "",
    calibration_date: new Date(),
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
    equipment: 0,
    active: null,
    serial_number: 0,
    created_by: "",
    created_timestamp: new Date(),
    updated_by: "",
    updated_timestamp: new Date(),
  });

  const onResetValue = () => {
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_code: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_category: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_direction: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_location: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      lower_threshold: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      upper_threshold: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      eff_date_from: new Date(),
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      eff_date_to: new Date("9999-12-31"),
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_name: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      measurement_unit: "",
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      calibration_date: new Date(),
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      equipment: 0,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      active: null,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      serial_number: 0,
    }));
  };

  const onChangeValue = (e, type, value) => {
    // console.log(value);
    // console.log(new Date(value).getFullYear()+"-"+new Date(value).getMonth()+"-"+new Date(value).getDate()+" "+new Date(value).getHours()+":"+new Date(value).getMinutes()+":"+new Date(value).getSeconds());
    // console.log(value.toISOString());
    // eslint-disable-next-line default-case
    switch (type) {
      case "sensor_name":
        console.log(e.target.value);
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          sensor_name: e.target.value,
        }));
        break;
      case "sensor_code":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          sensor_code: e.target.value,
        }));
        break;
      case "sensor_category":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          sensor_category: e.target.value,
        }));
        break;
      case "sensor_direction":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          sensor_direction: e.target.value,
        }));
        break;
      case "sensor_location":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          sensor_location: e.target.value,
        }));
        break;
      case "lower_threshold":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          lower_threshold: e.target.value,
        }));
        break;
      case "upper_threshold":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          upper_threshold: e.target.value,
        }));
        break;
      case "eff_date_from":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          eff_date_from: e.target.value,
        }));
        break;
      case "eff_date_to":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          eff_date_to: e.target.value,
        }));
        break;
      case "measurement_unit":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          measurement_unit: e.target.value,
        }));
        break;
      case "calibration_date":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          calibration_date: e.target.value,
        }));
        break;
      case "equipment":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          equipment: e.target.value,
        }));
        break;
      case "active":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          active: e.target.value,
        }));
      case "serial_number":
        setSensorObject((sensorObject) => ({
          ...sensorObject,
          serial_number: e.target.value,
        }));
        break;
    }
  };
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          sensorMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            sensorMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(sensorMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            sensorMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (sensorMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(sensorMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(sensorMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };

  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = SensorMaster.findIndex((e) => e === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(SensorMaster[idx]);
    set_item_to_delete(SensorMaster[idx].sensor_id);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log("code" + _code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .delete(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_SENSOR + "sensor?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .delete(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_SENSOR + "sensor?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    console.log(post_url);
    let data = {
      active: sensorObject.active,
      sensor_code: sensorObject.sensor_code,
      sensor_category: sensorObject.sensor_category,
      sensor_direction: sensorObject.sensor_direction,
      sensor_location: sensorObject.sensor_location,
      lower_threshold: Number(sensorObject.lower_threshold),
      upper_threshold: Number(sensorObject.upper_threshold),
      eff_date_from: sensorObject.eff_date_from,
      eff_date_to:
        status === true ? new Date("1900-01-01") : sensorObject.eff_date_to,
      measurement_unit: sensorObject.measurement_unit,
      sensor_name: sensorObject.sensor_name,
      calibration_date: sensorObject.calibration_date,
      serial_number: Number(sensorObject.serial_number),
      equipment_id: sensorObject.equipment,
      created_by: isAuthenticated == true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated == true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_SENSOR + "sensor?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = () => {
    let deleteData = {
      active: sensorObjectDelete.active,
      sensor_code: sensorObjectDelete.sensor_code,
      sensor_category: sensorObjectDelete.sensor_category,
      sensor_direction: sensorObjectDelete.sensor_direction,
      sensor_location: sensorObjectDelete.sensor_location,
      lower_threshold: Number(sensorObjectDelete.lower_threshold),
      upper_threshold: Number(sensorObjectDelete.upper_threshold),
      eff_date_from: GetServerDate(sensorObjectDelete.eff_date_from),
      eff_date_to: OneBack(),
      measurement_unit: sensorObjectDelete.measurement_unit,
      sensor_name: sensorObjectDelete.sensor_name,
      calibration_date: sensorObjectDelete.calibration_date,
      serial_number: Number(sensorObjectDelete.serial_number),
      equipment_id: sensorObjectDelete.equipment,
      updated_by: isAuthenticated == true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    let data = {
      active: sensorObject.active,
      sensor_code: sensorObject.sensor_code,
      sensor_category: sensorObject.sensor_category,
      sensor_direction: sensorObject.sensor_direction,
      sensor_location: sensorObject.sensor_location,
      lower_threshold: Number(sensorObject.lower_threshold),
      upper_threshold: Number(sensorObject.upper_threshold),
      eff_date_from: GetServerDate(sensorObject.eff_date_from),
      eff_date_to: GetServerDate(sensorObject.eff_date_to),
      measurement_unit: sensorObject.measurement_unit,
      sensor_name: sensorObject.sensor_name,
      calibration_date: sensorObject.calibration_date,
      serial_number: Number(sensorObject.serial_number),
      equipment_id: sensorObject.equipment,
      created_by: isAuthenticated == true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated == true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };

    axios
      .put(post_url + "/" + selectedsensor_id, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios.post(post_url, data).then((response) => {
            if (response.status === 201) {
              FetchTheData(
                process.env.REACT_APP_BASE_API_SENSOR + "sensor?size=1000",

                "Patch"
              );
              ShowMEWSPopup("✅Successfully updated the item", "Success");
              set_mews_popup_color("#28A745");
              set_open_edit_modal(false);
            }
          });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedsensor_id);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Sensor Master") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "sensorCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              sensorObject.sensor_code.length === 0
            ) {
              temp++;
              setSensorObjectErrors((sensorObjectErrors) => ({
                ...sensorObjectErrors,
                sensor_codeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "sensorName":
            if (
              clientSideValidations[i].validationType === "required" &&
              sensorObject.sensor_name.length === 0
            ) {
              temp++;
              setSensorObjectErrors((sensorObjectErrors) => ({
                ...sensorObjectErrors,
                sensor_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "serial_number":
            if (
              clientSideValidations[i].validationType === "required" &&
              sensorObject.serial_number.length === 0
            ) {
              temp++;
              setSensorObjectErrors((sensorObjectErrors) => ({
                ...sensorObjectErrors,
                serial_numberError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              isNaN(sensorObject.serial_number)
            ) {
              temp++;
              setSensorObjectErrors((sensorObjectErrors) => ({
                ...sensorObjectErrors,
                serial_numberError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "equipmentId":
            if (
              clientSideValidations[i].validationType === "required" &&
              sensorObject.equipment == 0
            ) {
              temp++;
              setSensorObjectErrors((sensorObjectErrors) => ({
                ...sensorObjectErrors,
                equipmentError: clientSideValidations[i].shortMessage,
              }));
            }
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = SensorMaster.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        SensorMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (SensorMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < SensorMaster.length; i++) {
          temp_display_array.push(SensorMaster[i]);
        }
      } else {
        if (
          SensorMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          SensorMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < SensorMaster.length;
            i++
          ) {
            temp_display_array.push(SensorMaster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(SensorMaster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [paginatedArray, setPaginatedArray] = useState([]);
  const [selected_form_id, set_selected_form_id] = useState();
  // const onChangeFormID = (e, _code) => {
  //   let temp = [];
  //   for (let i = 0; i < SensorMaster.length; i++) {
  //     temp.push(SensorMaster[i]);
  //   }
  //   let updatedArray = [];
  //   if (e != 0) {
  //     updatedArray = temp.filter((e1) => e1.prodProcessDbId == e);
  //   } else {
  //     updatedArray = temp;
  //   }

  //   console.log(updatedArray);
  //   let tempArray = [];
  //   if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
  //     for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
  //       tempArray.push(updatedArray[i]);
  //     }
  //   } else {
  //     for (let i = 0; i < updatedArray.length; i++) {
  //       tempArray.push(updatedArray[i]);
  //     }
  //   }

  //   current_page.current = 1;
  //   total_pages.current = Math.ceil(updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE);
  //   setSensorMasterArray(updatedArray);
  //   setPaginatedArray(tempArray);
  //   set_selected_form_id(e);
  // };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_SensorMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];

    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.results.length; i++) {
            temp_SensorMaster.push(response.data.results[i]);
            if (
              new Date(response.data.results[i].eff_date_to).getTime() ==
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data.results[i]);
              temp_count++;
            }
            setSensorMasterArray(tempNonDraftArray);
            if (
              new Date(response.data.results[i].eff_date_to).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data.results[i]);
            }
          }
          set_draft_array(temp_draft_array);
          dispatch(populate_Sensor(temp_SensorMaster));

          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          FetchtheValidation();
          FetchTheEquipment(tempNonDraftArray);
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectedsensor_id, setSelectedSensor_id] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (sensor_id, type) => {
    setSelectedSensor_id(sensor_id);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(sensor_id);
  };
  const SupplyValuesToModal = (sensor_id) => {
    let dateFrom = SensorMaster.find(
      (e) => e.sensor_id == sensor_id
    ).eff_date_from;
    let dateTo = SensorMaster.find((e) => e.sensor_id == sensor_id).eff_date_to;
    let Calibration = SensorMaster.find(
      (e) => e.sensor_id == sensor_id
    ).calibration_date;
    console.log("SensorId" + sensor_id);
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_code: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_code,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_name: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_name,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_category: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_category,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_direction: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_direction,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      sensor_location: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_location,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      measurement_unit: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .measurement_unit,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      lower_threshold: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .lower_threshold,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      upper_threshold: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .upper_threshold,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      eff_date_from:
        new Date(Calibration).getUTCFullYear() +
        "-" +
        (Number(new Date(Calibration).getUTCMonth()) + 1) +
        "-" +
        new Date(Calibration).getUTCDate(),
    }));

    setSensorObject((sensorObject) => ({
      ...sensorObject,
      eff_date_from: dateFrom,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      eff_date_to: dateTo,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      active: SensorMaster.find((e) => e.sensor_id == sensor_id).active,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      equipment: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .equipment_id,
    }));
    setSensorObject((sensorObject) => ({
      ...sensorObject,
      serial_number: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .serial_number,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      sensor_code: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_code,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      sensor_name: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_name,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      sensor_category: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_category,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      sensor_direction: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_direction,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      sensor_location: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .sensor_location,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      measurement_unit: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .measurement_unit,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      lower_threshold: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .lower_threshold,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      upper_threshold: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .upper_threshold,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      eff_date_from: dateFrom,
    }));

    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      eff_date_from:
        new Date(dateFrom).getUTCFullYear() +
        "-" +
        (Number(new Date(dateFrom).getUTCMonth()) + 1) +
        "-" +
        new Date(dateFrom).getUTCDate(),
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      eff_date_to: dateTo,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      active: SensorMaster.find((e) => e.sensor_id == sensor_id).active,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      equipment: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .equipment_id,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      serial_number: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .serial_number,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      created_by: SensorMaster.find((e) => e.sensor_id == sensor_id).created_by,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      updated_by: SensorMaster.find((e) => e.sensor_id == sensor_id).updated_by,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      created_timestamp: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .created_timestamp,
    }));
    setSensorObjectDelete((sensorObjectDelete) => ({
      ...sensorObjectDelete,
      updated_timestamp: SensorMaster.find((e) => e.sensor_id == sensor_id)
        .updated_timestamp,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortingSensor = [...sensorMasterArray];
    if (sortStyle === "Descending") {
      let UpdatedSensor = SortingSensor.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.sensor_code
            : action === "Category"
            ? a.sensor_category
            : action === "Upper"
            ? a.upper_threshold
            : action === "Lower"
            ? a.lower_threshold
            : null;
        const b_res =
          action === "Code"
            ? b.sensor_code
            : action === "Category"
            ? b.sensor_category
            : action === "Upper"
            ? b.upper_threshold
            : action === "Lower"
            ? b.lower_threshold
            : null;
        // const a_res=action==="Code"?a.sensor_code:a.sensor_category;
        // const b_res=action==="Code"?b.sensor_code:b.sensor_category;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedSensor);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let UpdatedSensor = SortingSensor.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.sensor_code
            : action === "Category"
            ? a.sensor_category
            : action === "Upper"
            ? a.upper_threshold
            : action === "Lower"
            ? a.lower_threshold
            : null;
        const b_res =
          action === "Code"
            ? b.sensor_code
            : action === "Category"
            ? b.sensor_category
            : action === "Upper"
            ? b.upper_threshold
            : action === "Lower"
            ? b.lower_threshold
            : null;

        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedSensor);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingSensor.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingSensor[i]);
      }
    } else {
      for (let i = 0; i < SortingSensor.length; i++) {
        tempArray.push(SortingSensor[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingSensor.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setSensorMasterArray(SortingSensor);
    setPaginatedArray(tempArray);
  };

  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const AddRemoveEquipment = (id) => {
    let tempSelectedEquipment = [...selectedEquipment];
    let equipmentIndex = tempSelectedEquipment.findIndex(
      (e) => e.equipment_id == id
    );
    if (equipmentIndex == -1) {
      tempSelectedEquipment.push(
        equipmentMaster.find((e) => e.equipment_id == id)
      );
    } else {
      tempSelectedEquipment.splice(equipmentIndex, 1);
    }
    setSelectedEquipment(tempSelectedEquipment);
  };
  const [equipmentPopup, setEquipmentPopup] = useState(false);

  const OnClickEquipment = () => {
    if (SensorMaster.length == 0) {
      setEquipmentPopup(false);
    } else {
      setEquipmentPopup(!equipmentPopup);
    }
  };
  const OnOkEquipment = () => {
    let tempSensorMaster = [...SensorMaster];
    let updatedArray = [];
    let equipmentDropDown = [];
    for (let i = 0; i < selectedEquipment.length; i++) {
      for (
        let j = 0;
        j <
        equipmentMaster.filter(
          (e) =>
            e.main_equipment_name == selectedEquipment[i].main_equipment_name
        ).length;
        j++
      ) {
        equipmentDropDown.push(
          equipmentMaster.filter(
            (e) =>
              e.main_equipment_name == selectedEquipment[i].main_equipment_name
          )[j]
        );
      }
    }
    for (let i = 0; i < tempSensorMaster.length; i++) {
      for (let j = 0; j < equipmentDropDown.length; j++) {
        if (
          tempSensorMaster[i].equipment_id == equipmentDropDown[j].equipment_id
          // equipmentMaster.find(
          //   (e) => e.main_equipment_name == selectedEquipment[j]
          // ).equipment_id
        ) {
          updatedArray.push(tempSensorMaster[i]);
        }
      }
    }
    // let selectedEquipmentIds = [];
    // for (let i = 0; i < selectedEquipment.length; i++) {
    //   let filteredIdArray = equipmentMaster.filter(
    //     (e) => e.main_equipment_name == selectedEquipment[i]
    //   );
    //   for (let j = 0; j < filteredIdArray.length; j++) {
    //     selectedEquipmentIds.push(filteredIdArray[j].equipment_id);
    //   }
    // }
    // console.log(selectedEquipmentIds);
    // for (let i = 0; i < selectedEquipmentIds.length; i++) {
    //   let tempFilteredArray = tempSensorMaster.filter(
    //     (e) => e.equipment_id == selectedEquipmentIds[i]
    //   );
    //   for (let j = 0; j < tempFilteredArray.length; j++) {
    //     updatedArray.push(tempFilteredArray[j]);
    //   }
    // }
    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setSensorMasterArray(updatedArray);
    setPaginatedArray(tempArray);
    setEquipmentPopup(false);
  };

  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col w-full ">
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader">Manage Sensor</div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Sensor"
                      value={search_content}
                      onChange={(e) => SearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons " src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Code")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Sensor Code
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>S. No</th>
                        <th>Sensor</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Category")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Category
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          {equipmentPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="flex flex-col gap-2">
                                    <input
                                      id="EquipmentPopup"
                                      type="text"
                                      placeholder="Search"
                                      value={search_equipment}
                                      onChange={(e) =>
                                        OnChangeSearchEquipment(e)
                                      }
                                    />
                                    <div
                                      id="EquipmentPopup"
                                      className="Imageflex"
                                      onClick={() =>
                                        setSelectedEquipment(equipmentArray)
                                      }
                                    >
                                      <img
                                        id="EquipmentPopup"
                                        className="Icons"
                                        src={
                                          equipmentArray.length ==
                                          selectedEquipment.length
                                            ? CheckIcon
                                            : UnCheckIcon
                                        }
                                        alt=""
                                      />
                                      <p id="EquipmentPopup">Select All</p>
                                    </div>
                                  </div>
                                  <div className="popupfile-data">
                                    {search_equipment.length === 0
                                      ? equipmentArray.map((item, index) => (
                                          <div
                                            id="EquipmentPopup"
                                            key={index}
                                            className="Imageflex"
                                            onClick={() =>
                                              AddRemoveEquipment(
                                                item.equipment_id
                                              )
                                            }
                                          >
                                            <img
                                              id="EquipmentPopup"
                                              className="Icons"
                                              src={
                                                selectedEquipment.findIndex(
                                                  (e) =>
                                                    e.equipment_id ==
                                                    item.equipment_id
                                                ) !== -1
                                                  ? CheckIcon
                                                  : UnCheckIcon
                                              }
                                              alt=""
                                            />
                                            <p id="EquipmentPopup">
                                              {item.main_equipment_name}
                                            </p>
                                          </div>
                                        ))
                                      : search_equipment.length > 0
                                      ? searched_equipment_array.map(
                                          (item, index) => (
                                            <div
                                              id="EquipmentPopup"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveEquipment(
                                                  item.equipment_id
                                                )
                                              }
                                            >
                                              <img
                                                id="EquipmentPopup"
                                                className="Icons"
                                                src={
                                                  selectedEquipment.findIndex(
                                                    (e) =>
                                                      e.equipment_id ==
                                                      item.equipment_id
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="EquipmentPopup">
                                                {item.main_equipment_name}
                                              </p>
                                            </div>
                                          )
                                        )
                                      : null}
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="EquipmentPopup"
                                      className="popupfile_button"
                                      onClick={() => OnOkEquipment()}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="EquipmentPopup"
                                      className="popupfile_button"
                                      onClick={() => setSelectedEquipment([])}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <div />
                            Equipment
                            <img
                              id="EquipmentPopup"
                              className="Icons"
                              onClick={() => OnClickEquipment()}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className=" flex"> &nbsp;</label>
                          </div>
                        </th>
                        <th>
                          {/* location */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        </th>
                        <th>Measurement Unit</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Lower")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Lower Limit
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Upper")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Upper Limit
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.sensor_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_code}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.serial_number}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_name}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_category}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {equipmentMaster.length > 0 &&
                                equipmentMaster.findIndex(
                                  (e) => e.equipment_id == item.equipment_id
                                ) != -1
                                  ? equipmentMaster.find(
                                      (e) => e.equipment_id == item.equipment_id
                                    ).main_equipment_name
                                  : null}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_location}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.measurement_unit}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.lower_threshold}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.upper_threshold}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.sensor_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.sensor_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() => ShowDeleteConfirmation(item)}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.sensor_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_code}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.serial_number}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_name}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_category}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {equipmentMaster.length > 0 &&
                                equipmentMaster.findIndex(
                                  (e) => e.equipment_id == item.equipment_id
                                ) != -1
                                  ? equipmentMaster.find(
                                      (e) => e.equipment_id == item.equipment_id
                                    ).main_equipment_name
                                  : null}
                              </td>
                              {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.sensor_id)
                            }
                          >
                            {item.sensor_direction}
                          </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_location}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.measurement_unit}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.lower_threshold}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.upper_threshold}
                              </td>
                              {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.sensor_id)
                            }
                          >
                            {item.active}
                          </td> */}
                              {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.sensor_id)
                            }
                          >
                            {new Date(item.calibration_date).getUTCDate() +
                              "-" +
                              (Number(
                                new Date(item.calibration_date).getUTCMonth()
                              ) +
                                1) +
                              "-" +
                              new Date(item.calibration_date).getUTCFullYear()}
                          </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.sensor_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.sensor_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() => ShowDeleteConfirmation(item)}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.sensor_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_code}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.serial_number}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_category}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {equipmentMaster.length > 0 &&
                                equipmentMaster.findIndex(
                                  (e) => e.equipment_id == item.equipment_id
                                ) != -1
                                  ? equipmentMaster.find(
                                      (e) => e.equipment_id == item.equipment_id
                                    ).main_equipment_name
                                  : null}
                              </td>
                              {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.sensor_id)
                            }
                          >
                            {item.sensor_direction}
                          </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.sensor_location}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.measurement_unit}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.lower_threshold}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {item.upper_threshold}
                              </td>
                              {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.sensor_id)
                            }
                          >
                            {item.active}
                          </td> */}
                              {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.sensor_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.sensor_id)
                            }
                          >
                            {new Date(item.calibration_date).getUTCDate() +
                              "-" +
                              (Number(
                                new Date(item.calibration_date).getUTCMonth()
                              ) +
                                1) +
                              "-" +
                              new Date(item.calibration_date).getUTCFullYear()}
                          </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.sensor_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.sensor_id)
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.sensor_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.sensor_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() => ShowDeleteConfirmation(item)}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">View Sensor Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Serial Number</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.serial_number}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Code</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.sensor_code}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Name</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.sensor_name}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Category</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.sensor_category}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Direction</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.sensor_direction}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Location</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.sensor_location}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Measurement Unit
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.measurement_unit}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Lower Threshold</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.lower_threshold}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Upper Threshold</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={sensorObject.upper_threshold}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Equipment</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          // value={
                          //   equipmentMaster.length > 0
                          //     ? equipmentMaster.findIndex(
                          //         (e) => e.equipment_id == sensorObject.equipment
                          //       ) != -1
                          //       ? equipmentMaster[
                          //           equipmentMaster.findIndex(
                          //             (e) => e.equipment_id == sensorObject.equipment
                          //           )
                          //         ].main_equipment_name
                          //       : null
                          //     : null
                          // }
                          value={
                            equipmentMaster.find(
                              (e) => e.equipment_id === sensorObject.equipment
                            ).main_equipment_name
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Active</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            sensorObject.active === true
                              ? "true"
                              : sensorObject.active === false
                              ? "false"
                              : null
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Calibration Date
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              sensorObject.calibration_date
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  sensorObject.calibration_date
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                sensorObject.calibration_date
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              sensorObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  sensorObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(sensorObject.eff_date_from).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                        </div>
                        <input
                          disabled={true}
                          type="text"
                          value={
                            new Date(
                              sensorObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(sensorObject.eff_date_to).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(sensorObject.eff_date_to).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectedsensor_id, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">Edit Sensor Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Serial Number</label>
                          <label className="error">
                            {sensorObjectErrors.serial_numberError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.serial_number}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Code</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_codeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_code}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_code", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Name</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_nameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_name}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_name", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Category</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_categoryError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_category}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_category", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Direction</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_directionError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_direction}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_direction", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Location</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_locationError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_location}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_location", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Measurement Unit
                          </label>
                          <label className="error">
                            {sensorObjectErrors.measurement_unitError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.measurement_unit}
                          onChange={(e) =>
                            onChangeValue(e, "measurement_unit", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Lower Threshold</label>
                          <label className="error">
                            {sensorObjectErrors.lower_thresholdError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.lower_threshold}
                          onChange={(e) =>
                            onChangeValue(e, "lower_threshold", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Upper Threshold</label>
                          <label className="error">
                            {sensorObjectErrors.upper_thresholdError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.upper_threshold}
                          onChange={(e) =>
                            onChangeValue(e, "upper_threshold", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Equipment</label>
                          <label className="error">
                            {sensorObjectErrors.equipmentError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={sensorObject.equipment}
                          onChange={(e) => onChangeValue(e, "equipment", null)}
                        >
                          <option value={null}>Select...</option>
                          {equipmentMaster.length > 0
                            ? equipmentMaster.map((item, index) =>
                                new Date(item.eff_date_to).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.equipment_id}>
                                    {item.main_equipment_name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Active</label>
                          <label className="error">
                            {sensorObjectErrors.activeError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={sensorObject.active}
                          onChange={(e) => onChangeValue(e, "active", null)}
                        >
                          <option value={null}>Select Active</option>
                          <option value={1}>True</option>
                          <option value={0}>False</option>
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Calibration Date
                          </label>
                          <label className="error">
                            {sensorObjectErrors.calibration_dateError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              sensorObject.calibration_date
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  sensorObject.calibration_date
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                sensorObject.calibration_date
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "calibration_date", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {sensorObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              sensorObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  sensorObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(sensorObject.eff_date_from).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {sensorObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              sensorObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(sensorObject.eff_date_to).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(sensorObject.eff_date_to).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => UpdateTheItem()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">Add Sensor Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Serial Number</label>
                          <label className="error">
                            {sensorObjectErrors.serial_numberError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.serial_number}
                          onChange={(e) =>
                            onChangeValue(e, "serial_number", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Code</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_codeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_code}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_code", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Name</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_nameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_name}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_name", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Sensor Category</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_categoryError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_category}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_category", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Direction</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_directionError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_direction}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_direction", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Location</label>
                          <label className="error">
                            {sensorObjectErrors.sensor_locationError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.sensor_location}
                          onChange={(e) =>
                            onChangeValue(e, "sensor_location", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Measurement Unit
                          </label>
                          <label className="error">
                            {sensorObjectErrors.measurement_unitError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.measurement_unit}
                          onChange={(e) =>
                            onChangeValue(e, "measurement_unit", null)
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Lower Threshold</label>
                          <label className="error">
                            {sensorObjectErrors.lower_thresholdError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.lower_threshold}
                          onChange={(e) =>
                            onChangeValue(e, "lower_threshold", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Upper Threshold</label>
                          <label className="error">
                            {sensorObjectErrors.upper_thresholdError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={sensorObject.upper_threshold}
                          onChange={(e) =>
                            onChangeValue(e, "upper_threshold", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Equipment</label>
                          <label className="error">
                            {sensorObjectErrors.equipmentError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={sensorObject.equipment}
                          onChange={(e) => onChangeValue(e, "equipment", null)}
                        >
                          <option value={null}>Select...</option>
                          {equipmentMaster.map((item, index) =>
                            new Date(item.eff_date_to).getTime() ===
                            new Date("9999-12-31").getTime() ? (
                              <option key={index} value={item.equipment_id}>
                                {item.main_equipment_name}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Active</label>
                          <label className="error">
                            {sensorObjectErrors.activeError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={sensorObject.active}
                          onChange={(e) => onChangeValue(e, "active", null)}
                        >
                          <option value={null}>Select Active</option>
                          <option value={1}>True</option>
                          <option value={0}>False</option>
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Calibration Date
                          </label>
                          <label className="error">
                            {sensorObjectErrors.calibration_dateError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              sensorObject.calibration_date
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  sensorObject.calibration_date
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                sensorObject.calibration_date
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "calibration_date", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {sensorObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              sensorObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  sensorObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(sensorObject.eff_date_from).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {sensorObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              sensorObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(sensorObject.eff_date_to).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(sensorObject.eff_date_to).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}

      {equipmentPopup === true ? (
        <div className="FilterMobile">
          {equipmentPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="flex flex-col gap-2">
                  <input
                    id="EquipmentPopup"
                    type="text"
                    placeholder="Search"
                    value={search_equipment}
                    onChange={(e) => OnChangeSearchEquipment(e)}
                  />
                  <div
                    id="EquipmentPopup"
                    className="Imageflex"
                    onClick={() => setSelectedEquipment(equipmentArray)}
                  >
                    <img
                      id="EquipmentPopup"
                      className="Icons"
                      src={
                        equipmentArray.length === selectedEquipment.length
                          ? CheckIcon
                          : UnCheckIcon
                      }
                      alt=""
                    />
                    <p id="EquipmentPopup">Select All</p>
                  </div>
                </div>
                <div className="popupfile-data">
                  {search_equipment.length === 0
                    ? equipmentArray.map(
                        (item, index) => (
                          <div
                            id="EquipmentPopup"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveEquipment(item.equipment_id)
                            }
                          >
                            <img
                              id="EquipmentPopup"
                              className="Icons"
                              src={
                                selectedEquipment.findIndex(
                                  (e) => e.equipment_id === item.equipment_id
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="EquipmentPopup">
                              {item.main_equipment_name}
                            </p>
                          </div>
                        )
                        // ) : null
                      )
                    : search_equipment.length > 0
                    ? searched_equipment_array.map((item, index) => (
                        <div
                          id="EquipmentPopup"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveEquipment(item)}
                        >
                          <img
                            id="EquipmentPopup"
                            className="Icons"
                            src={
                              selectedEquipment.findIndex(
                                (e) => e.equipment_id == item.equipment_id
                              ) != -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="EquipmentPopup">{item.main_equipment_name}</p>
                        </div>
                      ))
                    : null}
                </div>
                <div className="file_button">
                  <button
                    id="EquipmentPopup"
                    className="popupfile_button"
                    onClick={() => OnOkEquipment()}
                  >
                    Ok
                  </button>
                  <button
                    id="EquipmentPopup"
                    className="popupfile_button"
                    onClick={() => setSelectedEquipment([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}
export default Sensor;
