import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Form } from "./Redux/FormSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_inspection_parameters } from "./Redux/InspectionParametersSlice";
import { populate_Facility } from "./Redux/FacilitySlice";
import { populate_severity_codes } from "./Redux/SeverityCodesSlice";
import { populate_defect_codes } from "./Redux/DefectCodesSlice";
import { populate_failure_codes } from "./Redux/FailureCodesSlice";
import { populate_Products } from "./Redux/ProductsSlice";
import { populate_supplier } from "./Redux/SupplierSlice";
import { populate_parts } from "./Redux/PartsSlice";
import { populate_Company } from "./Redux/CompanySlice";
import { populate_failure_parameter } from "./Redux/FailureParametersSlice";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";

function EventRules() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const severityCodes = useSelector((state) => state.callSeverityCodes.value);
  const failureCodes = useSelector((state) => state.callFailureCodes.value);
  const defectCodes = useSelector((state) => state.callDefectCodes.value);
  const facilityCodes = useSelector((state) => state.callFacilities.value);
  const parts = useSelector((state) => state.callParts.value);
  const supplierCodes = useSelector((state) => state.callSupplier.value);
  const products = useSelector((state) => state.callProducts.value);
  const companies = useSelector((state) => state.callCompany.value);
  const failureParameters = useSelector(
    (state) => state.callFailureParameters.value
  );
  const fieldsValidation = () => {
    if (
      eventRulesObject.rule_name.length > 0 &&
      eventRulesObject.formName.toString().length > 0 &&
      eventRulesObject.event_type.length > 0 &&
      eventRulesObject.alert_message.length > 0 &&
      eventRulesObject.rule_description.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    severityCodes.length == 0
      ? fetchDatas("severityCodesDictionary")
      : console.log("Data Exists");
    defectCodes.length == 0
      ? fetchDatas("defectDictionary")
      : console.log("Data Exists");
    facilityCodes.length == 0
      ? fetchDatas("facilityMaster")
      : console.log("Data Exists");
    failureCodes.length == 0
      ? fetchDatas("failureCodesDictionary")
      : console.log("Data Exists");
    parts.length == 0 ? fetchDatas("partsMaster") : console.log("Data Exists");
    supplierCodes.length == 0
      ? fetchDatas("supplierMaster")
      : console.log("Data Exists");
    products.length == 0
      ? fetchDatas("productMaster")
      : console.log("Data Exists");
    companies.length == 0
      ? fetchDatas("companyMaster")
      : console.log("Data Exists");
    failureParameters.length == 0
      ? fetchDatas("failureParametersMaster")
      : console.log("Data Exists");
  }, []);
  const fetchDatas = (collectionName) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_PYTHON}${collectionName}?size=1000`
      )
      .then((response) => {
        if (collectionName === "failureCodesDictionary") {
          console.log(response);
        }
        if (response.status === 200) {
          switch (collectionName) {
            case "severityCodesDictionary":
              dispatch(
                populate_severity_codes(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "defectDictionary":
              dispatch(
                populate_defect_codes(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "failureCodesDictionary":
              dispatch(
                populate_failure_codes(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "facilityMaster":
              dispatch(
                populate_Facility(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "partsMaster":
              dispatch(
                populate_parts(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "supplierMaster":
              dispatch(
                populate_supplier(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "productMaster":
              dispatch(
                populate_Products(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "companyMaster":
              dispatch(
                populate_Company(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
            case "failureParametersMaster":
              dispatch(
                populate_failure_parameter(
                  response.data.filter(
                    (e) =>
                      new Date(e.effDateTo).getTime() ==
                      new Date("9999-12-31").getTime()
                  )
                )
              );
              break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheEventNotificationLog();
        FetchTheInspectionParameter();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "eventRules?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "FormMaster") {
            setFormModal(false);
          }
        });
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidation = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidation.push(response.data[i]);
          }
          dispatch(populate_client_side_validation(tempClientSideValidation));
        }
      });
  };
  let formMaster = useSelector((state) => state.callForm.value);
  const [formArray, setFormArray] = useState([]);
  const FetchTheForm = (_eventRules) => {
    if (formMaster.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            let tempFormArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                temp.push(response.data[i]);
                if (
                  tempFormArray.findIndex(
                    (e) => e === response.data[i].formName
                  ) == -1
                ) {
                  tempFormArray.push(response.data[i].formName);
                }
              }
            }
            dispatch(populate_Form(temp));
            let tempForm = [];
            for (let i = 0; i < temp.length; i++) {
              if (
                _eventRules.findIndex(
                  (e) => e.form_db_id == temp[i].formDbId
                ) != -1
              ) {
                tempForm.push(temp[i]);
              }
            }
            setFormArray(tempForm);
            setSelectedFormArray(tempForm);
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    } else {
      // let tempFormMaster = [...formMaster];
      // let tempForm = [];
      // for (let i = 0; i < tempFormMaster.length; i++) {
      //   tempForm.push(tempFormMaster[i].formName);
      // }
      let tempForm = [];
      for (let i = 0; i < formMaster.length; i++) {
        if (
          _eventRules.findIndex(
            (e) => e.form_db_id == formMaster[i].formDbId
          ) != -1
        ) {
          tempForm.push(formMaster[i]);
        }
      }
      setSelectedFormArray(tempForm);
      setFormArray(tempForm);
    }
  };
  let inspectionParametersMaster = useSelector(
    (state) => state.callInspectionParameter.value
  );

  const FetchTheInspectionParameter = () => {
    if (inspectionParametersMaster.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "inspectionParametersMaster"
        )
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                temp.push(response.data[i]);
              }
            }
            dispatch(populate_inspection_parameters(temp));
          }
        });
    }
  };
  const [search_form, setSearch_form] = useState("");
  const [search_form_array, setSearch_form_array] = useState([]);
  const OnChangeSearchFormMaster = (e) => {
    let content = e.target.value;
    setSearch_form(content.toLowerCase());
    let temp = formArray.filter((e) =>
      e.formName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setSearch_form_array(temp);
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "eventRules";

  let eventRules = useSelector((state) => state.callEventRules.value);

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [eventRulesObjectErrors, setEventRulesObjectErrors] = useState({
    rule_nameError: "",
    event_typeError: "",
    rule_descriptionError: "",
    formNameError: "",
    alert_messageError: "",
    conditionError: "",
    eff_date_fromError: "",
    eff_date_toError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "rule_nameError");
    OnChangeErrorValues("", "event_typeError");
    OnChangeErrorValues("", "rule_descriptionError");
    OnChangeErrorValues("", "formNameError");
    OnChangeErrorValues("", "alert_messageError");
    OnChangeErrorValues("", "conditionError");
    OnChangeErrorValues("", "eff_date_fromError");
    OnChangeErrorValues("", "eff_date_toError");
  };
  const OnChangeErrorValues = (error, type, formDbId) => {
    // eslint-disable-next-line default-case

    switch (type) {
      case "rule_nameError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          rule_nameError: error,
        }));
        break;

      case "event_typeError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          event_typeError: error,
        }));
        break;
      case "rule_descriptionError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          rule_descriptionError: error,
        }));
        break;
      case "formNameError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          formNameError: error,
        }));
        break;
      case "alert_messageError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          alert_messageError: error,
        }));
        break;
      case "conditionError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          conditionError: error,
        }));
        break;
      case "eff_date_fromError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          eff_date_fromError: error,
        }));
        break;
      case "eff_date_toError":
        setEventRulesObjectErrors((eventRulesObjectErrors) => ({
          ...eventRulesObjectErrors,
          eff_date_toError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = eventRules.filter((e1) =>
      e1.rule_name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].eff_date_to).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (eventRules.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(eventRules[i]);
        }
      } else {
        for (let i = 0; i < eventRules.length; i++) {
          tempArray.push(eventRules[i]);
        }
      }
      total_pages.current = Math.ceil(
        eventRules.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [eventRulesArray, setEventRulesArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [eventRulesObject, setEventRulesObject] = useState({
    rule_name: "",
    event_type: "",
    rule_description: "",
    formName: "",
    alert_message: "",
    condition: "",
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
  });

  const [eventRulesObjectDelete, setEventRulesObjectDelete] = useState({
    rule_name: "",
    event_type: "",
    rule_description: "",
    formName: "",
    alert_message: "",
    condition: "",
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
    created_by: "",
    updated_by: "",
    created_timestamp: new Date(),
    updated_timestamp: new Date(),
  });
  const onResetValue = () => {
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      rule_name: "",
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      event_type: "",
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      rule_description: "",
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      formName: "",
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      alert_message: "",
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      condition: "",
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      eff_date_from: new Date(),
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      eff_date_to: new Date("9999-12-31"),
    }));
  };
  const [parameterCodeArray, setParameterCodeArray] = useState([]);
  const [failureParameterCodeArray, setFailureParameterCodeArray] = useState(
    []
  );
  const onChangeValue = (e, type, value) => {
    switch (type) {
      case "rule_name":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          rule_name: e.target.value,
        }));
        break;
      case "event_type":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          event_type: e.target.value,
        }));
        break;
      case "rule_description":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          rule_description: e.target.value,
        }));
        break;
      case "formName":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          formName: e.target.value,
        }));
        let tempParameterCodeArray = inspectionParametersMaster.filter(
          (e1) => e1.formDbId == e.target.value
        );
        setParameterCodeArray(tempParameterCodeArray);
        let tempFailureParameterCodeArray = failureParameters.filter(
          (e1) => e1.formDbId == e.target.value
        );
        setFailureParameterCodeArray(tempFailureParameterCodeArray);
        console.log(tempFailureParameterCodeArray);
        break;
      case "alert_message":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          alert_message: e.target.value,
        }));
        break;
      case "condition":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          condition: e.target.value,
        }));
        break;
      case "eff_date_from":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          eff_date_from: e.target.value,
        }));
        break;
      case "eff_date_to":
        setEventRulesObject((eventRulesObject) => ({
          ...eventRulesObject,
          eff_date_to: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          eventRulesArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            eventRulesArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(eventRulesArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            eventRulesArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (eventRulesArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(eventRulesArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(eventRulesArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    setSelectedEventArray([
      {
        idx: 0,
        attributeName: "",
        operator: "",
        attributeValue: "",
        andOrValue: "",
      },
    ]);
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);

  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = eventRules.findIndex((e) => e.event_rule_id === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(eventRules[idx]);
    set_item_to_delete(eventRules[idx].event_rule_id);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    console.log("code" + _code);
    console.log("delete_multiple" + multiple_codes);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updated_timestamp: new Date().toISOString(),
          eff_date_to: OneBack(),
          updated_by: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log(response);
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "eventRules?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updated_timestamp: new Date().toISOString(),
            eff_date_to: OneBack(),
            updated_by: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "eventRules?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const returnInAccurateFormat = (value) => {
    if (isNaN(value)) {
      if (value.includes("'")) {
        return value;
      } else {
        return "'" + value + "'";
      }
    } else if (!isNaN(value)) {
      return value;
    }
  };
  const returnTheCondition = () => {
    let tempValidation = 0;
    for (let i = 0; i < selectedEventArray.length; i++) {
      selectedEventArray[i].attributeName.length == 0
        ? (tempValidation += 1)
        : (tempValidation += 0);
      selectedEventArray[i].operator.length == 0
        ? (tempValidation += 1)
        : (tempValidation += 0);
      selectedEventArray[i].attributeValue.length == 0
        ? (tempValidation += 1)
        : (tempValidation += 0);
    }
    if (tempValidation > 0) {
      setEventRulesObjectErrors((eventRulesObjectErrors) => ({
        ...eventRulesObjectErrors,
        conditionError: "Fields are missing in sub forms",
      }));
      return "";
    } else {
      let temp = "";
      for (let i = 0; i < selectedEventArray.length; i++) {
        temp +=
          selectedEventArray[i].attributeName +
          " " +
          selectedEventArray[i].operator +
          " " +
          returnInAccurateFormat(selectedEventArray[i].attributeValue);
        selectedEventArray[i].andOrValue !== undefined
          ? selectedEventArray[i].andOrValue.length > 0
            ? (temp += " " + selectedEventArray[i].andOrValue + " ")
            : (temp += "")
          : (temp += "");
      }
      console.log("Length:" + temp);
      return temp;
    }
  };
  const AddTheItem = (status) => {
    console.log("Call Add");
    let data = {
      eff_date_from:
        new Date(eventRulesObject.eff_date_from).getUTCFullYear() +
        "-" +
        ReturnThePaddedValues(
          new Date(eventRulesObject.eff_date_from).getUTCMonth() + 1
        ) +
        "-" +
        ReturnThePaddedValues(
          new Date(eventRulesObject.eff_date_from).getUTCDate()
        ),
      eff_date_to:
        status === true
          ? new Date("1900-01-01")
          : new Date(eventRulesObject.eff_date_to).getUTCFullYear() +
            "-" +
            ReturnThePaddedValues(
              new Date(eventRulesObject.eff_date_to).getUTCMonth() + 1
            ) +
            "-" +
            ReturnThePaddedValues(
              new Date(eventRulesObject.eff_date_to).getUTCDate()
            ),
      rule_name: eventRulesObject.rule_name,
      event_type: eventRulesObject.event_type,
      alert_message: eventRulesObject.alert_message,
      condition: returnTheCondition(),
      rule_description: eventRulesObject.rule_description,
      form_db_id: Number(eventRulesObject.formName),
      created_by: isAuthenticated === true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
      is_system_defined: true,
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)

        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "eventRules?size=1000",

              "Post"
            );
            set_open_add_modal(false);
            set_open_edit_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditEventRules = () => {
    let deleteData = {
      eff_date_from: GetServerDate(eventRulesObjectDelete.eff_date_from),
      eff_date_to: OneBack(),
      rule_name: eventRulesObjectDelete.rule_name,
      event_type: eventRulesObjectDelete.event_type,
      alert_message: eventRulesObjectDelete.alert_message,
      rule_description: eventRulesObjectDelete.rule_description,
      form_db_id: eventRulesObjectDelete.formName,
      updated_by: isAuthenticated == true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
      condition: returnTheCondition(),
    };
    let data = {
      eff_date_from: GetServerDate(eventRulesObject.eff_date_from),
      eff_date_to: GetServerDate(eventRulesObject.eff_date_to),
      rule_name: eventRulesObject.rule_name,
      event_type: eventRulesObject.event_type,
      alert_message: eventRulesObject.alert_message,
      form_db_id: Number(eventRulesObject.formName),
      created_by: isAuthenticated === true ? user.name : null,
      createdTimestap: new Date().toISOString(),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
      condition: returnTheCondition(),
      rule_description: eventRulesObject.rule_description,
    };
    axios
      .patch(post_url + "/" + selectedEvent_rule_id, deleteData)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // axios
          //   .post(post_url, data)
          //   .then((response) => {
          //     console.log(response);
          //     if (response.status === 201) {
          //       FetchTheData(
          //         process.env.REACT_APP_BASE_API_PYTHON +
          //           "eventRules?size=1000",
          //
          //         "Patch"
          //       );
          //       ShowMEWSPopup("✅Successfully updated the item", "Success");
          //       set_mews_popup_color("#28A745");
          //       set_open_edit_modal(false);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
          AddTheItem(false);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(
          error.message + ":" + post_url + "/" + selectedEvent_rule_id
        );
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Event Rules") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "ruleName":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventRulesObject.rule_name.length == 0
            ) {
              temp++;
              setEventRulesObjectErrors((eventRulesObjectErrors) => ({
                ...eventRulesObjectErrors,
                rule_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              eventRulesArray
                .filter(
                  (e1) =>
                    new Date(e1.effDateTo).getTime() ==
                    new Date("9999-12-31").getTime()
                )
                .findIndex((e) => e.rule_name === eventRulesObject.rule_name) !=
                -1
            ) {
              temp++;
              setEventRulesObjectErrors((eventRulesObjectErrors) => ({
                ...eventRulesObjectErrors,
                rule_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "formDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventRulesObject.formName.length == 0
            ) {
              temp++;
              setEventRulesObjectErrors((eventRulesObjectErrors) => ({
                ...eventRulesObjectErrors,
                formNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "eventType":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventRulesObject.event_type.length == 0
            ) {
              temp++;
              setEventRulesObjectErrors((eventRulesObjectErrors) => ({
                ...eventRulesObjectErrors,
                event_typeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "condition":
            if (
              clientSideValidations[i].validationType === "required" &&
              returnTheCondition().trim().length == 0
            ) {
              temp++;
              setEventRulesObjectErrors((eventRulesObjectErrors) => ({
                ...eventRulesObjectErrors,
                conditionError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = eventRules.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        eventRules.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (eventRules.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < eventRules.length; i++) {
          temp_display_array.push(eventRules[i]);
        }
      } else {
        if (
          eventRules.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          eventRules.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < eventRules.length;
            i++
          ) {
            temp_display_array.push(eventRules[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(eventRules[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);

    let temp_eventRules = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_eventRules.push(response.data[i]);

            if (
              new Date(response.data[i].eff_date_to).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setEventRulesArray(tempNonDraftArray);
            if (
              new Date(response.data[i].eff_date_to).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);
          dispatch(populate_Event_Rules(temp_eventRules));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheForm(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectedEvent_rule_id, setSelectedEvent_rule_id] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (event_rule_id, type) => {
    setSelectedEvent_rule_id(event_rule_id);

    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(event_rule_id);
  };
  let [conditionToDisplay, setConditionToDisplay] = useState([]);
  const SupplyValuesToModal = (event_rule_id) => {
    let dateFrom = eventRules.find(
      (e) => e.event_rule_id == event_rule_id
    ).eff_date_from;
    let dateTo = eventRules.find(
      (e) => e.event_rule_id == event_rule_id
    ).eff_date_to;
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      rule_name: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .rule_name,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      rule_name: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .rule_name,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      event_type: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .event_type,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      event_type: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .event_type,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      rule_description: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .rule_description,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      rule_description: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .rule_description,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      formName: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .formName,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      formName: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .formName,
    }));
    let _selectedFormId = eventRules.find(
      (e) => e.event_rule_id == event_rule_id
    ).form_db_id;
    console.log("Selected Form ID:" + _selectedFormId);
    let filteredInspectionParametersMaster = inspectionParametersMaster.filter(
      (e1) => e1.formDbId == _selectedFormId
    );
    setParameterCodeArray(filteredInspectionParametersMaster);
    let tempSelectedEventRules = [];
    for (
      let i = 0;
      i <
      eventRules
        .find((e) => e.event_rule_id == event_rule_id)
        .condition.trim()
        .split(" ").length /
        4;
      i++
    ) {
      let ca = eventRules
        .find((e) => e.event_rule_id == event_rule_id)
        .condition.trim()
        .split(" ");
      tempSelectedEventRules.push({
        idx: i,
        attributeName: ca[i * 4],
        operator: ca[i * 4 + 1],
        attributeValue: ca[i * 4 + 2],
        andOrValue: ca[i * 4 + 3],
      });
    }
    setSelectedEventArray(tempSelectedEventRules);
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      condition: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .condition,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      alert_message: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .alert_message,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      alert_message: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .alert_message,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      formName: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .form_db_id,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      formName: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .form_db_id,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      eff_date_from: dateFrom,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      eff_date_from: dateFrom,
    }));
    setEventRulesObject((eventRulesObject) => ({
      ...eventRulesObject,
      eff_date_to: dateTo,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      eff_date_to: dateTo,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      created_by: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .created_by,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      updated_by: eventRules.find((e) => e.event_rule_id == event_rule_id)
        .updated_by,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      created_timestamp: eventRules.find(
        (e) => e.event_rule_id == event_rule_id
      ).created_timestamp,
    }));
    setEventRulesObjectDelete((eventRulesObjectDelete) => ({
      ...eventRulesObjectDelete,
      updated_timestamp: eventRules.find(
        (e) => e.event_rule_id == event_rule_id
      ).updated_timestamp,
    }));
  };
  const returnTheCodes = (_attributeName, _attributeValue) => {
    switch (_attributeName) {
      case "assembly_floor":
        return _attributeValue;
        break;
      case "company_db_id":
        return companies.find((e) => e.companyDbId == _attributeValue)
          .companyCode;
        break;
      case "defect_db_id":
        return defectCodes.find((e) => e.defectDbId == _attributeValue)
          .defectCode;
        break;
      case "facility_db_id":
        return facilityCodes.find((e) => e.facilityDbId == _attributeValue)
          .facilityCode;
        break;
      case "failure_db_id":
        return failureCodes.find((e) => e.failureDbId == _attributeValue)
          .failureCode;
        break;
      case "outcome":
        return _attributeValue === 0 ? "Pass" : "Fail";
        break;
      case "parameter_code":
        return _attributeValue;
        break;
      case "parameter_result":
        return _attributeValue === 0 ? "Pass" : "Fail";
        break;
      case "parameter_value":
        return _attributeValue;
        break;
      case "part_db_id":
        return parts.find((e) => e.partDbId == _attributeValue).partCode;
        break;
      case "product_db_id":
        return products.find((e) => e.productDbId == _attributeValue)
          .productCode;
        break;
      case "severity_db_id":
        return severityCodes.find((e) => e.severityDbId == _attributeValue)
          .severityCode;
        break;
      case "supplier_db_id":
        return supplierCodes.find((e) => e.supplierDbId == _attributeValue)
          .supplierCode;
        break;
    }
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortEventRules = [...eventRulesArray];
    if (sortStyle === "Descending") {
      let updatedEventRules = SortEventRules.sort((a, b) => {
        const a_res = action === "Code" ? a.rule_name : a.event_type;
        const b_res = action === "Code" ? b.rule_name : b.event_type;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedEventRules);
      setSortStyle("Ascending");
      setEventRulesArray(updatedEventRules);
    } else if (sortStyle === "Ascending") {
      let updatedEventRules = SortEventRules.sort((a, b) => {
        const a_res = action === "Code" ? a.rule_name : a.event_type;
        const b_res = action === "Code" ? b.rule_name : b.event_type;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedEventRules);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortEventRules.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortEventRules[i]);
      }
    } else {
      for (let i = 0; i < SortEventRules.length; i++) {
        tempArray.push(SortEventRules[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortEventRules.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setEventRulesArray(SortEventRules);
    setPaginatedArray(tempArray);
  };
  const [formModal, setFormModal] = useState(false);

  const OnClickForm = () => {
    // let temp = [];
    // if (selectedFormArray.length == 0) {
    //   for (let i = 0; i < formMaster.length; i++) {
    //     temp.push(formMaster[i].form_db_id);
    //   }
    // } else {
    //   for (let i = 0; i < selectedFormArray.length; i++) {
    //     temp.push(selectedFormArray[i]);
    //   }
    // }
    // setSelectedFormArray(temp);
    if (eventRules.length == 0) {
      setFormModal(false);
    }
    setFormModal(!formModal);
  };
  const [selectedFormArray, setSelectedFormArray] = useState([]);
  const AddRemoveForm = (id) => {
    let tempSelectedFormArray = [...selectedFormArray];
    let FormMasterIndex = tempSelectedFormArray.findIndex(
      (e) => e.formDbId == id
    );
    if (FormMasterIndex == -1) {
      tempSelectedFormArray.push(formMaster.find((e) => e.formDbId == id));
    } else {
      tempSelectedFormArray.splice(FormMasterIndex, 1);
    }
    setSelectedFormArray(tempSelectedFormArray);
  };

  const OnOkFormMaster = () => {
    let temp = [...eventRules];
    let updatedArray = [];
    for (let i = 0; i < selectedFormArray.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].form_db_id === selectedFormArray[i].formDbId
          // formMaster.find((e) => e.formName == selectedFormArray[i]).formDbId
        ) {
          if (
            new Date(temp[j].eff_date_to).getTime() ==
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setEventRulesArray(updatedArray);
    setPaginatedArray(tempArray);
    setFormModal(false);
  };
  let operator = [
    {
      name: ">=",
      value: ">=",
    },
    {
      name: "<=",
      value: "<=",
    },
    {
      name: ">",
      value: ">",
    },
    {
      name: "<",
      value: "<",
    },
    {
      name: "=",
      value: "=",
    },
  ];
  const [selectedEventArray, setSelectedEventArray] = useState([
    {
      idx: 0,
      attributeName: "",
      operator: "",
      attributeValue: "",
      andOrValue: "",
    },
  ]);
  const onClickTheEventRule = (_event) => {
    let temp = [];
    if (selectedEventArray.length > 0) {
      temp = [...selectedEventArray];
    }
    temp.push({
      idx: selectedEventArray.length + 1,
      attributeName: "",
      operator: "",
      attributeValue: "",
      andOrValue: "",
    });

    setSelectedEventArray(temp);
  };

  // const bottomRef = useRef(null);

  // const OnClickORSection = () => {
  //   if (bottomRef.current) {
  //     bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  //   }
  // };
  const RemoveTheEvent = (index) => {
    let temp = [...selectedEventArray];
    temp.splice(index, 1);
    temp[temp.length - 1].andOrValue = "";
    setSelectedEventArray(temp);
  };
  const onChangeEventValue = (e, type, idx) => {
    let temp = [...selectedEventArray];
    switch (type) {
      case "AttributeName":
        temp.find((e) => e.idx == idx).attributeName = e.target.value;
        break;
      case "Operator":
        temp.find((e) => e.idx == idx).operator = e.target.value;
        break;
      case "AttributeValue":
        temp.find((e) => e.idx == idx).attributeValue = e.target.value;
        break;
      case "AndOrValue":
        temp.find((e) => e.idx == idx).andOrValue = e;
        temp.push({
          idx: selectedEventArray.length + 1,
          attributeName: "",
          operator: "",
          attributeValue: "",
          andOrValue: "",
        });
        break;
    }
    setSelectedEventArray(temp);
  };
  let AttributeName = [
    {
      name: "Assembly Floor",
      value: "assembly_floor",
    },
    {
      name: "Company Code",
      value: "company_db_id",
    },
    {
      name: "Defect Code",
      value: "defect_db_id",
    },
    {
      name: "Facility Code",
      value: "facility_db_id",
    },
    // {
    //   name: "Sub Form Parameter Code",
    //   value: "fail_parameter_db_id",
    // },
    {
      name: "Failure Code",
      value: "failure_db_id",
    },
    {
      name: "Final Inspection Outcome",
      value: "outcome",
    },
    {
      name: "Parameter Code",
      value: "parameter_code",
    },
    {
      name: "Parameter Result",
      value: "parameter_result",
    },
    {
      name: "Parameter Value",
      value: "parameter_value",
    },
    {
      name: "Part Code",
      value: "part_db_id",
    },
    {
      name: "Product Code",
      value: "product_db_id",
    },
    {
      name: "Severity Code",
      value: "severity_db_id",
    },
    {
      name: "Supplier Code",
      value: "supplier_db_id",
    },
  ];
  const isFormFilled = (index) => {
    let tempCount = 0;
    if (selectedEventArray[index].attributeName.length == 0) {
      tempCount += 1;
    }
    if (selectedEventArray[index].operator.length == 0) {
      tempCount += 1;
    }
    if (selectedEventArray[index].attributeValue.length == 0) {
      tempCount += 1;
    }
    return tempCount;
  };
  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />
          <div className="flex flex-row">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader"> Manage Event Rules</div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Event Rules"
                      value={search_content}
                      onChange={(e) => onChangeSearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons" src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "18%" }} />
                      <col span="1" style={{ width: "11%" }} />
                      <col span="1" style={{ width: "18%" }} />
                      <col span="1" style={{ width: "25%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Code")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Rule Name
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Name")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Event Type
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          {formModal === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="flex flex-col gap-2">
                                    <input
                                      id="FormMaster"
                                      type="text"
                                      placeholder="Search"
                                      value={search_form}
                                      onChange={(e) =>
                                        OnChangeSearchFormMaster(e)
                                      }
                                    />
                                    <div
                                      id="FormMaster"
                                      className="Imageflex"
                                      onClick={() =>
                                        setSelectedFormArray(formArray)
                                      }
                                    >
                                      <img
                                        id="FormMaster"
                                        className="Icons"
                                        src={
                                          formArray.length ==
                                          selectedFormArray.length
                                            ? CheckIcon
                                            : UnCheckIcon
                                        }
                                        alt=""
                                      />
                                      <p id="FormMaster">Select All</p>
                                    </div>
                                  </div>
                                  <div className="popupfile-data">
                                    {search_form.length === 0
                                      ? formArray.map((item, index) => (
                                          <div
                                            id="FormMaster"
                                            key={index}
                                            className="Imageflex"
                                            onClick={() =>
                                              AddRemoveForm(item.formDbId)
                                            }
                                          >
                                            <img
                                              id="FormMaster"
                                              className="Icons"
                                              src={
                                                selectedFormArray.findIndex(
                                                  (e) =>
                                                    e.formDbId == item.formDbId
                                                ) != -1
                                                  ? CheckIcon
                                                  : UnCheckIcon
                                              }
                                              alt=""
                                            />
                                            <p id="FormMaster">
                                              {item.formName}
                                            </p>
                                          </div>
                                        ))
                                      : search_form.length > 0
                                      ? search_form_array.map((item, index) => (
                                          <div
                                            id="FormMaster"
                                            key={index}
                                            className="Imageflex"
                                            onClick={() =>
                                              AddRemoveForm(item.formDbId)
                                            }
                                          >
                                            <img
                                              id="FormMaster"
                                              className="Icons"
                                              src={
                                                selectedFormArray.findIndex(
                                                  (e) =>
                                                    e.formDbId == item.formDbId
                                                ) !== -1
                                                  ? CheckIcon
                                                  : UnCheckIcon
                                              }
                                              alt=""
                                            />
                                            <p id="FormMaster">
                                              {item.formName}
                                            </p>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="FormMaster"
                                      className="popupfile_button"
                                      onClick={() => OnOkFormMaster()}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="FormMaster"
                                      className="popupfile_button"
                                      onClick={() => setSelectedFormArray([])}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="FormMaster"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Forms
                            <img
                              id="FormMaster"
                              className="Icons"
                              onClick={() => OnClickForm()}
                              src={FilterIcon}
                              alt=""
                            />
                          </div>
                        </th>
                        {/* <th>Condition</th> */}
                        <th>Alert Message</th>
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.event_rule_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.rule_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.event_type}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {formMaster.length > 0 &&
                                formMaster.findIndex(
                                  (e) => e.formDbId == item.form_db_id
                                ) != -1
                                  ? formMaster.find(
                                      (e) => e.formDbId == item.form_db_id
                                    ).formName
                                  : null}
                              </td>

                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.condition}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.alert_message}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_rule_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_rule_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.event_rule_id)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.event_rule_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.rule_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.event_type}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {formMaster.length > 0 &&
                                formMaster.findIndex(
                                  (e) => e.formDbId == item.form_db_id
                                ) != -1
                                  ? formMaster.find(
                                      (e) => e.formDbId == item.form_db_id
                                    ).formName
                                  : null}
                              </td>

                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.condition}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.alert_message}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_rule_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_rule_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.event_rule_id)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.event_rule_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.rule_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.event_type}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {formMaster.length > 0 &&
                                formMaster.findIndex(
                                  (e) => e.formDbId == item.form_db_id
                                ) != -1
                                  ? formMaster.find(
                                      (e) => e.formDbId == item.form_db_id
                                    ).formName
                                  : null}
                              </td>

                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.condition}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {item.alert_message}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_rule_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.event_rule_id)
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_rule_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_rule_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.event_rule_id)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    View Event Rules Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Rule Name</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={eventRulesObject.rule_name}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Event Type</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={eventRulesObject.event_type}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Alert Message</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={eventRulesObject.alert_message}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Rule Description
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={eventRulesObject.rule_description}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Forms</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            formMaster.find(
                              (e) => e.formDbId === eventRulesObject.formName
                            ).formName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              eventRulesObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventRulesObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventRulesObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              eventRulesObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventRulesObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventRulesObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 mt-[2%]">
                      {selectedEventArray.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            pointerEvents:
                              fieldsValidation() === false ? "none" : "auto",
                          }}
                          className="flex flex-col gap-4 border-dotted border-2 p-2  border-dark-default"
                        >
                          <div className="inputGrid">
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Name
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={
                                  AttributeName.find(
                                    (e) => e.value == item.attributeName
                                  ).name
                                }
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">Operator</label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={item.operator}
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Value
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={
                                  returnTheCodes(
                                    item.attributeName,
                                    item.attributeValue
                                  ).includes("'")
                                    ? returnTheCodes(
                                        item.attributeName,
                                        item.attributeValue
                                      ).substring(
                                        1,
                                        returnTheCodes(
                                          item.attributeName,
                                          item.attributeValue
                                        ).length - 1
                                      )
                                    : returnTheCodes(
                                        item.attributeName,
                                        item.attributeValue
                                      )
                                }
                                className="child_input"
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center justify-center gap-5">
                            {item.andOrValue}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectedEvent_rule_id, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Edit Event Rules Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Rule Name</label>
                          <label className="error">
                            {eventRulesObjectErrors.rule_nameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={eventRulesObject.rule_name}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">Event Type</label>
                          <label className="error">
                            {eventRulesObjectErrors.event_typeError}
                          </label>
                        </div>
                        <select
                          value={eventRulesObject.event_type}
                          onChange={(e) => onChangeValue(e, "event_type", null)}
                          className="child_input"
                        >
                          <option value={null}>Select....</option>
                          <option value="inspection">Inspections</option>
                        </select>
                      </div>

                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">Alert Message</label>
                          <label className="error">
                            {eventRulesObjectErrors.alert_messageError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={eventRulesObject.alert_message}
                          onChange={(e) =>
                            onChangeValue(e, "alert_message", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Rule Description
                          </label>
                          <label className="error">
                            {eventRulesObjectErrors.rule_descriptionError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={eventRulesObject.rule_description}
                          onChange={(e) =>
                            onChangeValue(e, "rule_description", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">FormMaster</label>
                          <label className="error">
                            {eventRulesObjectErrors.formNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={eventRulesObject.formName}
                          onChange={(e) => onChangeValue(e, "formName", null)}
                        >
                          <option value={null}>Select....</option>
                          {formMaster.length > 0
                            ? formMaster.map((item, index) =>
                                item.formDbId == 6 || item.formDbId == 7 ? (
                                  <option key={index} value={item.formDbId}>
                                    {item.formName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {eventRulesObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              eventRulesObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventRulesObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventRulesObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {eventRulesObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              eventRulesObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventRulesObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventRulesObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                    <label className="error">
                      {eventRulesObjectErrors.conditionError}
                    </label>
                    <div className="flex flex-col gap-3 mt-[2%]">
                      {selectedEventArray.map((item, index) => (
                        <div
                          style={{
                            pointerEvents:
                              fieldsValidation() === false ? "none" : "auto",
                            opacity: fieldsValidation() === false ? 0.5 : 1,
                          }}
                          className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default"
                        >
                          <div className="inputGrid">
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Name
                                </label>
                                <label className="error"></label>
                              </div>
                              <select
                                className="child_input"
                                value={
                                  selectedEventArray.find(
                                    (e) => e.idx == item.idx
                                  ).attributeName
                                }
                                onChange={(e) =>
                                  onChangeEventValue(
                                    e,
                                    "AttributeName",
                                    item.idx
                                  )
                                }
                              >
                                <option value={null}>Select....</option>
                                {AttributeName.length > 0
                                  ? AttributeName.map((item, index) => (
                                      <option key={index} value={item.value}>
                                        {item.name}
                                      </option>
                                    ))
                                  : null}
                              </select>
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">Operator</label>
                                <label className="error"></label>
                              </div>
                              {item.attributeName != "assembly_floor" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).operator
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(e, "Operator", item.idx)
                                  }
                                  className="child_input"
                                >
                                  <option>Select Operator Type...</option>
                                  <option value="=">=</option>
                                  <option value="!=">!=</option>
                                </select>
                              ) : item ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).operator
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(e, "Operator", item.idx)
                                  }
                                  className="child_input"
                                >
                                  <option>Select Operator Type...</option>
                                  {operator.map((item, index) => (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              ) : null}
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Value
                                </label>
                                <label className="error"></label>
                              </div>
                              {item.attributeName == "parameter_code" ? (
                                <select
                                  value={
                                    item.attributeValue.includes("'")
                                      ? item.attributeValue.substring(
                                          1,
                                          item.attributeValue.length - 1
                                        )
                                      : item.attributeValue
                                    // selectedEventArray.find(
                                    //   (e) => e.idx == item.idx
                                    // ).attributeValue.substring(1,  selectedEventArray.find(
                                    //   (e) => e.idx == item.idx
                                    // ).attributeValue.length-1)
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option>Select Parameter Code...</option>
                                  {parameterCodeArray.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.inspParameterDbId}
                                    >
                                      {item.parameterCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "assembly_floor" ? (
                                <input
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  type="number"
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                />
                              ) : item.attributeName === "parameter_value" ? (
                                <input
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                />
                              ) : item.attributeName === "outcome" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>Select Outcome..</option>
                                  <option value={0}>Pass</option>
                                  <option value={1}>Fail</option>
                                </select>
                              ) : item.attributeName === "parameter_result" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Parameter Result..
                                  </option>
                                  <option value={0}>Pass</option>
                                  <option value={1}>Fail</option>
                                </select>
                              ) : item.attributeName === "severity_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Severity Codes..
                                  </option>
                                  {severityCodes.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.severityDbId}
                                    >
                                      {item.severityCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "failure_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Failure Codes..
                                  </option>
                                  {failureCodes.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.failureDbId}
                                    >
                                      {item.failureCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "facility_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Facility Codes..
                                  </option>
                                  {facilityCodes.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.facilityDbId}
                                    >
                                      {item.facilityCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "defect_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Defect Codes..
                                  </option>
                                  {defectCodes.map((item, index) => (
                                    <option key={index} value={item.defectDbId}>
                                      {item.defectCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "company_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Company Codes..
                                  </option>
                                  {companies.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.companyDbId}
                                    >
                                      {item.companyCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "part_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Part Code..
                                  </option>
                                  {parts.map((item, index) => (
                                    <option key={index} value={item.partDbId}>
                                      {item.partCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "supplier_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Supplier Code..
                                  </option>
                                  {supplierCodes.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.supplierDbId}
                                    >
                                      {item.supplierCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName === "product_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Product Code..
                                  </option>
                                  {products.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.productDbId}
                                    >
                                      {item.productCode}
                                    </option>
                                  ))}
                                </select>
                              ) : item.attributeName ===
                                "fail_parameter_db_id" ? (
                                <select
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).attributeValue
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeValue",
                                      item.idx
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>
                                    Select Sub Form Parameter Code..
                                  </option>
                                  {failureParameterCodeArray.map(
                                    (item, index) => (
                                      <option
                                        key={index}
                                        value={item.failParameterDbId}
                                      >
                                        {item.parameterCode}
                                      </option>
                                    )
                                  )}
                                </select>
                              ) : null}
                            </div>
                          </div>
                          {index == selectedEventArray.length - 1 ? (
                            <div className="flex flex-row items-center justify-center gap-5">
                              {isFormFilled(index) == 0 ? (
                                <button
                                  onClick={() =>
                                    onChangeEventValue(
                                      "and",
                                      "AndOrValue",
                                      item.idx
                                    )
                                  }
                                  className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                >
                                  AND
                                </button>
                              ) : null}
                              {isFormFilled(index) == 0 ? (
                                <button
                                  onClick={() =>
                                    onChangeEventValue(
                                      "or",
                                      "AndOrValue",
                                      item.idx
                                    )
                                  }
                                  className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                >
                                  OR
                                </button>
                              ) : null}
                              {index > 0 ? (
                                <button
                                  onClick={() => RemoveTheEvent(index)}
                                  className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                >
                                  DELETE
                                </button>
                              ) : null}
                            </div>
                          ) : (
                            <div className="flex flex-row items-center justify-center gap-5">
                              {selectedEventArray[index].andOrValue === "or"
                                ? "OR"
                                : selectedEventArray[index].andOrValue === "and"
                                ? "AND"
                                : null}
                              {index > 0 ? (
                                <button
                                  onClick={() => RemoveTheEvent(index)}
                                  className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                >
                                  DELETE
                                </button>
                              ) : null}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEditEventRules()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Add Event Rules Details
                  </div>
                  <div className="inputOverflow">
                    <div className="flex flex-col gap-5">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Rule Name</label>
                            <label className="error">
                              {eventRulesObjectErrors.rule_nameError}
                            </label>
                          </div>
                          <input
                            onChange={(e) =>
                              onChangeValue(e, "rule_name", null)
                            }
                            type="text"
                            value={eventRulesObject.rule_name}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup ">
                          <div className="child_label_flex">
                            <label className="child_popup">Event Type</label>
                            <label className="error">
                              {eventRulesObjectErrors.event_typeError}
                            </label>
                          </div>
                          <select
                            value={eventRulesObject.event_type}
                            onChange={(e) =>
                              onChangeValue(e, "event_type", null)
                            }
                            className="child_input"
                          >
                            <option value={null}>Select....</option>
                            <option value="inspection">Inspections</option>
                          </select>
                        </div>

                        <div className="parent_popup ">
                          <div className="child_label_flex">
                            <label className="child_popup">Alert Message</label>
                            <label className="error">
                              {eventRulesObjectErrors.alert_messageError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={eventRulesObject.alert_message}
                            onChange={(e) =>
                              onChangeValue(e, "alert_message", null)
                            }
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Forms</label>
                            <label className="error">
                              {eventRulesObjectErrors.formNameError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={eventRulesObject.formName}
                            onChange={(e) => onChangeValue(e, "formName", null)}
                          >
                            <option value={null}>Select....</option>
                            {formMaster.length > 0
                              ? formMaster.map((item, index) =>
                                  new Date(item.effDateTo).getTime() ==
                                    new Date("9999-12-31").getTime() &&
                                  (item.formDbId == 6 || item.formDbId == 7) ? (
                                    <option key={index} value={item.formDbId}>
                                      {item.formName}
                                    </option>
                                  ) : null
                                )
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup ">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Rule Description
                            </label>
                            <label className="error">
                              {eventRulesObjectErrors.rule_descriptionError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={eventRulesObject.rule_description}
                            onChange={(e) =>
                              onChangeValue(e, "rule_description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Effective From
                            </label>
                            <label className="error">
                              {eventRulesObjectErrors.eff_date_fromError}
                            </label>
                          </div>
                          <input
                            type="date"
                            value={
                              new Date(
                                eventRulesObject.eff_date_from
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    eventRulesObject.eff_date_from
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  eventRulesObject.eff_date_from
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "eff_date_from", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Effective To</label>
                            <label className="error">
                              {eventRulesObjectErrors.eff_date_toError}
                            </label>
                          </div>
                          <input
                            type="date"
                            value={
                              new Date(
                                eventRulesObject.eff_date_to
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    eventRulesObject.eff_date_to
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  eventRulesObject.eff_date_to
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "eff_date_to", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                      </div>
                      {/* {selectedEventArray.length == 0 ? (
                        <div className="flex flex-row items-center justify-center gap-5">
                          <button
                            onClick={() => onClickTheEventRule("AND")}
                            className="2xl:text-xl xl:text-lg  text-sm flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                          >
                            AND
                          </button>
                          <button
                            onClick={() => onClickTheEventRule("OR")}
                            className="2xl:text-xl xl:text-lg  text-sm flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                          >
                            OR
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center justify-center gap-5">
                          <p>{selectedEventArray[0].event_type}</p>
                        </div>
                      )} */}
                      <label className="error">
                        {eventRulesObjectErrors.conditionError}
                      </label>
                      <div className="flex flex-col gap-3">
                        {selectedEventArray.map((item, index) => (
                          <div
                            style={{
                              pointerEvents:
                                fieldsValidation() === false ? "none" : "auto",
                              opacity: fieldsValidation() === false ? 0.5 : 1,
                            }}
                            className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default"
                          >
                            <div className="inputGrid">
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Attribute Name
                                  </label>
                                  <label className="error"></label>
                                </div>
                                <select
                                  className="child_input"
                                  value={
                                    selectedEventArray.find(
                                      (e) => e.idx == item.idx
                                    ).parameterCode
                                  }
                                  onChange={(e) =>
                                    onChangeEventValue(
                                      e,
                                      "AttributeName",
                                      item.idx
                                    )
                                  }
                                >
                                  <option value={null}>Select....</option>
                                  {AttributeName.length > 0
                                    ? AttributeName.map((item, index) => (
                                        <option key={index} value={item.value}>
                                          {item.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Operator
                                  </label>
                                  <label className="error"></label>
                                </div>
                                {item.attributeName != "assembly_floor" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).opeater
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "Operator",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option>Select Operator Type...</option>
                                    <option value="=">=</option>
                                    <option value="!=">!=</option>
                                  </select>
                                ) : item ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).opeater
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "Operator",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option>Select Operator Type...</option>
                                    {operator.map((item, index) => (
                                      <option value={item.value}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                ) : null}
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Attribute Value
                                  </label>
                                  <label className="error"></label>
                                </div>
                                {item.attributeName == "parameter_code" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option>Select Parameter Code...</option>
                                    {parameterCodeArray.map((item, index) => (
                                      <option value={item.inspParameterDbId}>
                                        {item.parameterCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "assembly_floor" ? (
                                  <input
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    type="number"
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  />
                                ) : item.attributeName === "parameter_value" ? (
                                  <input
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    type="text"
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  />
                                ) : item.attributeName === "outcome" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Outcome..
                                    </option>
                                    <option value={0}>Pass</option>
                                    <option value={1}>Fail</option>
                                  </select>
                                ) : item.attributeName ===
                                  "parameter_result" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Parameter Result..
                                    </option>
                                    <option value={0}>Pass</option>
                                    <option value={1}>Fail</option>
                                  </select>
                                ) : item.attributeName === "severity_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Severity Codes..
                                    </option>
                                    {severityCodes.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.severityDbId}
                                      >
                                        {item.severityCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "failure_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Failure Codes..
                                    </option>
                                    {failureCodes.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.failureDbId}
                                      >
                                        {item.failureCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "facility_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Facility Codes..
                                    </option>
                                    {facilityCodes.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.facilityDbId}
                                      >
                                        {item.facilityCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "defect_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Defect Codes..
                                    </option>
                                    {defectCodes.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.defectDbId}
                                      >
                                        {item.defectCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "company_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Company Codes..
                                    </option>
                                    {companies.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.companyDbId}
                                      >
                                        {item.companyCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "part_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Part Code..
                                    </option>
                                    {parts.map((item, index) => (
                                      <option key={index} value={item.partDbId}>
                                        {item.partCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "supplier_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Supplier Code..
                                    </option>
                                    {supplierCodes.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.supplierDbId}
                                      >
                                        {item.supplierCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName === "product_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Product Code..
                                    </option>
                                    {products.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.productDbId}
                                      >
                                        {item.productCode}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.attributeName ===
                                  "fail_parameter_db_id" ? (
                                  <select
                                    value={
                                      selectedEventArray.find(
                                        (e) => e.idx == item.idx
                                      ).attributeValue
                                    }
                                    onChange={(e) =>
                                      onChangeEventValue(
                                        e,
                                        "AttributeValue",
                                        item.idx
                                      )
                                    }
                                    className="child_input"
                                  >
                                    <option value={null}>
                                      Select Sub Form Parameter Code..
                                    </option>
                                    {failureParameterCodeArray.map(
                                      (item, index) => (
                                        <option
                                          key={index}
                                          value={item.failParameterDbId}
                                        >
                                          {item.parameterCode}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : null}
                              </div>
                            </div>
                            {index == selectedEventArray.length - 1 ? (
                              <div className="flex flex-row items-center justify-center gap-5">
                                {isFormFilled(index) == 0 ? (
                                  <button
                                    onClick={() =>
                                      onChangeEventValue(
                                        "and",
                                        "AndOrValue",
                                        item.idx
                                      )
                                    }
                                    className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                  >
                                    AND
                                  </button>
                                ) : null}
                                {isFormFilled(index) == 0 ? (
                                  <button
                                    onClick={() =>
                                      onChangeEventValue(
                                        "or",
                                        "AndOrValue",
                                        item.idx
                                      )
                                    }
                                    className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                  >
                                    OR
                                  </button>
                                ) : null}
                                {index > 0 ? (
                                  <button
                                    onClick={() => RemoveTheEvent(index)}
                                    className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                  >
                                    DELETE
                                  </button>
                                ) : null}
                              </div>
                            ) : (
                              <div className="flex flex-row items-center justify-center gap-5">
                                {selectedEventArray[index].andOrValue === "or"
                                  ? "OR"
                                  : selectedEventArray[index].andOrValue ===
                                    "and"
                                  ? "AND"
                                  : null}
                                {index > 0 ? (
                                  <button
                                    onClick={() => RemoveTheEvent(index)}
                                    className="flex flex-col py-1 px-2 rounded text-white bg-[#092E3D]"
                                  >
                                    DELETE
                                  </button>
                                ) : null}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}

      {formModal === true ? (
        <div className="FilterMobile">
          {formModal === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="flex flex-col gap-2">
                  <input
                    id="FormMaster"
                    type="text"
                    placeholder="Search"
                    value={search_form}
                    onChange={(e) => OnChangeSearchFormMaster(e)}
                  />
                  <div
                    id="FormMaster"
                    className="Imageflex"
                    onClick={() => setSelectedFormArray(formArray)}
                  >
                    <img
                      id="FormMaster"
                      className="Icons"
                      src={
                        formArray.length == selectedFormArray.length
                          ? CheckIcon
                          : UnCheckIcon
                      }
                      alt=""
                    />
                    <p id="FormMaster">Select All</p>
                  </div>
                </div>
                <div className="popupfile-data">
                  {search_form.length === 0
                    ? formArray.map((item, index) => (
                        <div
                          id="FormMaster"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveForm(item.formDbId)}
                        >
                          <img
                            id="FormMaster"
                            className="Icons"
                            src={
                              selectedFormArray.findIndex(
                                (e) => e.formDbId === item.formDbId
                              ) != -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="FormMaster">{item.formName}</p>
                        </div>
                      ))
                    : search_form.length > 0
                    ? search_form_array.map((item, index) => (
                        <div
                          id="FormMaster"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveForm(item.formDbId)}
                        >
                          <img
                            id="FormMaster"
                            className="Icons"
                            src={
                              selectedFormArray.findIndex(
                                (e) => e.formDbId === item.formDbId
                              ) !== -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="FormMaster">{item.formName}</p>
                        </div>
                      ))
                    : null}
                </div>
                <div className="file_button">
                  <button
                    id="FormMaster"
                    className="popupfile_button"
                    onClick={() => OnOkFormMaster()}
                  >
                    Ok
                  </button>
                  <button
                    id="FormMaster"
                    className="popupfile_button"
                    onClick={() => setSelectedFormArray([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default EventRules;
