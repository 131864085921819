import {createSlice} from '@reduxjs/toolkit';
export const QualityProcessSlice = createSlice({
  name: 'quality_process',
  initialState: {
    value: [],
  },
  reducers: {
    populate_quality_process: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_quality_process} = QualityProcessSlice.actions;
export default QualityProcessSlice.reducer;