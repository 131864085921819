import React from "react";
import Header from "./Header";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
function ReleaseNotes() {

  let releaseNotes = [
    {
      id: 1,
      Header: "Release notes for version 3.8:",
      date: "2024-06-10",
      _subName: [
        {
          name: "Automotive real-time version dashboard released"
        },
        {
          name: "All events and event notification infrastructure/front-end released"
        },
        {
          name: "Ability to define multi-level questions in inspection parameters master for P&C"
        },
      ]
    },
    {
      id: 2,
      Header: "Release notes for version 2.3:",
      date: "2024-04-18",
      _subName: [
        {name:"Sorted inspection parameters display by row and then column"},
        {name:"Sorted inspection shift data by roster date descending"},
        {name:"Fixed the numbering and arrows for page numbers"},
        {name:"All the filters fixed to show only data that is available in the table and not all parent data"},
        {name:"Message Popup has been fixed to show for all selections"},
        {name:"BOM Filter based on Supplier is fixed"},
        {name:"Certificate filter was not working and fixed"},
        {name:"Existing uploaded certificate view was not working and fixed"},
        {name:"Rearranged of columns display across master tables"}
      ]
    }
  ]


  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0)
  }
  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex flex-row w-full">
        <div className="container-fluid flex flex-col gap-3">
        {releaseNotes.map((item, index) =>
          <div className="py-2 px-3 rounded flex flex-col gap-3 bg-[#092E3D] text-white">
              <div key={index} className="flex flex-col gap-3">
                <div className=" flex flex-row items-center justify-between">
                  <div className="xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1rem] sm:text-[0.875rem] xs:text-[0.75rem] font-medium text-white">
                    {item.Header}
                  </div>
                  <div>{item.date}</div>
                </div>
                {item._subName.map((sub, index1) => <li>{sub.name}</li>)}
              </div>
          </div> )}


        </div>
        <div className="flex flex-col">
          <NotificationWeb />
        </div>
      </div>
      <LogoutConfirmation />
    </div>
  );
}

export default ReleaseNotes;
