import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import CloseIcon from "../Assert/Close.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios, { all } from "axios";
import { populate_inspection_parameters } from "./Redux/InspectionParametersSlice";
import { populate_quality_process } from "./Redux/QualityProcessSlice";
import { populate_failure_parameter } from "./Redux/FailureParametersSlice";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { populate_Form } from "./Redux/FormSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import RadioOnIcon from "../Assert/RadioButtonOn.png";
import RadioOffIcon from "../Assert/RadioButtonOff.png";
import PlusIcon from "../Assert/plus.png";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function InspectionParameters() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheForms();
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        window.addEventListener("click", (e) => {
          if (e.target.id != "QualityProcess") {
            setQualityProcess(false);
          }
        });
      }
    }, 2000);
  }, []);
  let qualityProcessMap = useSelector(
    (state) => state.callQualityProcess.value
  );
  let failureParameters = useSelector(
    (state) => state.callFailureParameters.value
  );
  const [qualityProcessArray, setQualityProcessArray] = useState([]);
  const FetchTheQuality = (_InspectionParamter) => {
    if (qualityProcessMap.length === 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "qualityProcessMap?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempQualityProcessMap = [];
            let tempQualityProcessArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempQualityProcessMap.push(response.data[i]);
                if (
                  tempQualityProcessArray.findIndex(
                    (e) => e === response.data[i].processName
                  ) == -1
                ) {
                  tempQualityProcessArray.push(response.data[i].processName);
                }
              }
            }
            dispatch(populate_quality_process(tempQualityProcessMap));
            let tempQuality = [];
            for (let i = 0; i < tempQualityProcessMap.length; i++) {
              if (
                _InspectionParamter.findIndex(
                  (e) =>
                    e.qaProcessDbId == tempQualityProcessMap[i].qaProcessDbId
                ) != -1
              ) {
                tempQuality.push(tempQualityProcessMap[i]);
              }
            }
            setSelectedQualityProccess(tempQuality);
            setQualityProcessArray(tempQuality);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      // let tempQualityProcess = [...qualityProcessMap];
      // let tempQuality = [];
      // for (let i = 0; i < tempQualityProcess.length; i++) {
      //   tempQuality.push(tempQualityProcess[i].processName);
      // }
      let tempQuality = [];
      for (let i = 0; i < qualityProcessMap.length; i++) {
        if (
          _InspectionParamter.findIndex(
            (e) => e.qaProcessDbId == qualityProcessMap[i].qaProcessDbId
          ) != -1
        ) {
          tempQuality.push(qualityProcessMap[i]);
        }
      }
      setSelectedQualityProccess(tempQuality);
      setQualityProcessArray(tempQuality);
    }
  };
  const [searchQuality, setSearchQuality] = useState("");
  const [searchQualityArray, setSearchQualityArray] = useState([]);
  const onChangeSearchQuality = (e) => {
    let content = e.target.value;
    setSearchQuality(content.toLowerCase());
    let temp = qualityProcessArray.filter((e) =>
      e.processName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setSearchQualityArray(temp);
  };
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON + "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  //Get all validation

  const post_url =
    process.env.REACT_APP_BASE_API_PYTHON + "inspectionParametersMaster";

  let formMaster = useSelector((state) => state.callForm.value);
  const FetchTheForms = () => {
    if (formMaster.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "formMaster")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime() &&
                response.data[i].formDbId != 1 &&
                response.data[i].formDbId != 2
              ) {
                temp.push(response.data[i]);
              }
            }

            dispatch(populate_Form(temp));
          }
        });
    }
  };
  let inspectionParametersMaster = useSelector(
    (state) => state.callInspectionParameter.value
  );
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [inspectionParameterObjectErrors, setInspectionParameterObjectErrors] =
    useState({
      parameterCodeError: "",
      parameterQuestionError: "",
      parameterDisplayRowError: "",
      parameterDisplayColError: "",
      parameterValueError: "",
      parameterValueTypeError: "",
      parameterMandatoryError: "",
      toleranceTypeError: "",
      varianceTypeError: "",
      allowedVarianceError: "",
      displayControlError: "",
      controlLengthError: "",
      ctqFlagError: "",
      refLinksUrlError: "",
      effDateFromError: "",
      effDateToError: "",
      formNameError: "",
      qaProcessNameError: "",
      parentInspParameterError: "",
      parameterLevelError: "",
      parentOptionError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "parameterCodeError");
    OnChangeErrorValues("", "parameterQuestionError");
    OnChangeErrorValues("", "parameterDisplayRowError");
    OnChangeErrorValues("", "parameterDisplayColError");
    OnChangeErrorValues("", "parameterValueError");
    OnChangeErrorValues("", "parameterValueTypeError");
    OnChangeErrorValues("", "parameterMandatoryError");
    OnChangeErrorValues("", "toleranceTypeError");
    OnChangeErrorValues("", "varianceTypeError");
    OnChangeErrorValues("", "allowedVarianceError");
    OnChangeErrorValues("", "displayControlError");
    OnChangeErrorValues("", "controlLengthError");
    OnChangeErrorValues("", "ctqFlagError");
    OnChangeErrorValues("", "refLinksUrlError");
    OnChangeErrorValues("", "effDateToEsError");
    OnChangeErrorValues("", "effDateFromrror");
    OnChangeErrorValues("", "qaProcessNameError");
    OnChangeErrorValues("", "formNameError");
    OnChangeErrorValues("", "parentInspParameterError");
    OnChangeErrorValues("", "parameterLevelError");
    OnChangeErrorValues("", "parentOptionError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "qaProcessNameError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            qaProcessNameError: val,
          })
        );
        break;
      case "parameterCodeError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterCodeError: val,
          })
        );
        break;
      case "parameterQuestionError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterQuestionError: val,
          })
        );
        break;
      case "parameterDisplayRowError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterDisplayRowError: val,
          })
        );
        break;
      case "parameterDisplayColError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterDisplayColError: val,
          })
        );
        break;
      case "parameterValueError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterValueError: val,
          })
        );
        break;
      case "parameterValueTypeError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterValueTypeError: val,
          })
        );
        break;
      case "parameterMandatoryError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterMandatoryError: val,
          })
        );
        break;
      case "displayControlError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            displayControlError: val,
          })
        );
        break;
      case "controlLengthError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            controlLengthError: val,
          })
        );
        break;
      case "ctqFlagError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            ctqFlagError: val,
          })
        );
        break;

      case "refLinksUrlError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            refLinksUrlError: val,
          })
        );
        break;
      case "effDateFromError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            effDateFromError: val,
          })
        );
        break;
      case "effDateToError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            effDateToError: val,
          })
        );
        break;
      case "toleranceTypeError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            toleranceTypeError: val,
          })
        );
        break;
      case "varianceTypeError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            varianceTypeError: val,
          })
        );
        break;
      case "allowedVarianceError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            allowedVarianceError: val,
          })
        );
        break;
      case "formNameError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            formNameError: val,
          })
        );
        break;
      case "parentInspParameterError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parentInspParameterError: val,
          })
        );
        break;
      case "parameterLevelError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parameterLevelError: val,
          })
        );
        break;
      case "parentOptionError":
        setInspectionParameterObjectErrors(
          (inspectionParameterObjectErrors) => ({
            ...inspectionParameterObjectErrors,
            parentOptionError: val,
          })
        );
        break;
    }
  };
  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < inspectionParametersMaster.length; i++) {
  //     if (
  //       inspectionParametersMaster[i]
  //         .parameterQuestion !== null
  //     ) {
  //       if (
  //         inspectionParametersMaster[
  //           i
  //         ].parameterQuestion
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(inspectionParametersMaster[i]);
  //       }
  //     }
  //   }

  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    // current_page.current=0;
    setLoading(true);
    let content = e.target.value;
    set_search_content(content);
    let temp = inspectionParametersMaster.filter((e1) =>
      e1.parameterQuestion
        .toLowerCase()
        .trim()
        .match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value == 0) {
      if (
        inspectionParametersMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE
      ) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(inspectionParametersMaster[i]);
        }
      } else {
        for (let i = 0; i < inspectionParametersMaster.length; i++) {
          tempArray.push(inspectionParametersMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        inspectionParametersMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [inspectionParametersMasterArray, setInspectionParametersMasterArray] =
    useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [inspectionParameterObject, setInspectionParameterObject] = useState({
    parameterCode: "",
    parameterQuestion: "",
    parameterDisplayRow: 0,
    parameterDisplayCol: 0,
    parameterValue: "",
    parameterValueType: "",
    parameterMandatory: "",
    displayControl: "",
    controlLength: "",
    ctqFlag: "",
    refLinksUrl: "",
    formName: 0,
    toleranceType: "",
    varianceType: "",
    allowedVariance: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    qaProcessName: 0,
    createdTimestamp: new Date(),
    createdBy: "",
    updatedBy: "",
    updatedTimestamp: new Date(),
    parentInspParameterCode: "",
    parameterLevel: 0,
    parentOption: "",
  });
  //Declaration Of Delete States
  const [inspectionParameterObjectDelete, setInspectionParameterObjectDelete] =
    useState({
      parameterCode: "",
      parameterQuestion: "",
      parameterDisplayRow: 0,
      parameterDisplayCol: 0,
      parameterValue: "",
      parameterValueType: "",
      parameterMandatory: "",
      displayControl: "",
      controlLength: "",
      ctqFlag: "",
      refLinksUrl: "",
      formName: 0,
      toleranceType: "",
      varianceType: "",
      allowedVariance: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      qaProcessName: 0,
      createdTimestamp: new Date(),
      createdBy: "",
      updatedBy: "",
      updatedTimestamp: new Date(),
      parentInspParameterCode: "",
      parameterLevel: 0,
      parentOption: "",
    });
  const onResetValue = () => {
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterCode: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterQuestion: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterDisplayRow: 0,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterDisplayCol: 0,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterValue: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterValueType: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterMandatory: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      displayControl: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      controlLength: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      ctqFlag: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      refLinksUrl: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      effDateFrom: new Date(),
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      formName: 0,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      toleranceType: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      varianceType: "",
    }));

    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      allowedVariance: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      qaProcessName: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parentInspParameterCode: "",
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterLevel: 0,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parentOption: "",
    }));

  };
  const onChangeValue = (e, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "parameterCode":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterCode: e.target.value,
        }));
        break;
      case "formName":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          formName: e.target.value,
        }));
        break;
      case "parameterQuestion":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterQuestion: e.target.value,
        }));
        break;
      case "parameterDisplayRow":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterDisplayRow: e.target.value,
        }));
        break;
      case "parameterDisplayCol":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterDisplayCol: e.target.value,
        }));
        break;
      case "parameterValue":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterValue: e.target.value,
        }));
        break;
      case "parameterValueType":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterValueType: e.target.value,
        }));
        break;
      case "parameterMandatory":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterMandatory: e.target.value,
        }));
        break;
      case "displayControl":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          displayControl: e.target.value,
        }));
        break;
      case "controlLength":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          controlLength: e.target.value,
        }));
        break;
      case "ctqFlag":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          ctqFlag: e.target.value,
        }));
        break;

      case "refLinksUrl":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          refLinksUrl: e.target.value,
        }));
        break;
      case "effDateFrom":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          effDateTo: e.target.value,
        }));
        break;
      case "toleranceType":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          toleranceType: e.target.value,
        }));
        break;
      case "varianceType":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          varianceType: e.target.value,
        }));
        break;
      case "allowedVariance":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          allowedVariance: e.target.value,
        }));
        break;
      case "qaProcessName ":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          qaProcessName: e.target.value,
        }));
        break;

      case "parentInspParameterCode":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parentInspParameterCode: e.target.value,
        }));
        break;
      case "parameterLevel":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parameterLevel: e.target.value,
        }));
        break;
      case "parentOption":
        setInspectionParameterObject((inspectionParameterObject) => ({
          ...inspectionParameterObject,
          parentOption: e.target.value,
        }));
        break;

    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          inspectionParametersMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            inspectionParametersMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(inspectionParametersMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            inspectionParametersMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (inspectionParametersMasterArray.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(inspectionParametersMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (searched_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (draft_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };

  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(inspectionParametersMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === false && search_content.length > 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const handleAddModelClose = () => {
    set_open_add_modal(false);
    setOpen_Sub_Form(false);
    setOpenInspectionSubForm(false)
  };
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    ResetTheFailureValue();
    ResetTheInspectionValue()
    ResetTheNewInspectionValue()
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = inspectionParametersMaster.findIndex(
      (e) => e.inspParameterDbId === _code
    );
    select_deselect_the_record(
      inspectionParametersMaster[idx].inspParameterDbId
    );
    set_item_to_delete(_code);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              "Delete",
              "InspectionParameter"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Delete",
              "FailureParametersMaster"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                `inspectionParametersMaster/formDbId/${selected_form_id}`,
                "Delete",
                "InspectionParameter"
              );
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                "failureParametersMaster",
                "Delete",
                "FailureParametersMaster"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const AddTheItem = (status) => {
    let data = {
      parameterCode: inspectionParameterObject.parameterCode,
      parameterQuestion: inspectionParameterObject.parameterQuestion,
      parameterValue: inspectionParameterObject.parameterValue,
      parameterValueType: inspectionParameterObject.parameterValue,
      isParamMandatory: inspectionParameterObject.parameterMandatory,
      parameterDisplayCol: Number(inspectionParameterObject.parameterDisplayCol),
      parameterDisplayRow: Number(inspectionParameterObject.parameterDisplayRow),
      // reflinksUrl: inspectionParameterObject.refLinksUrl,
      displayControl: inspectionParameterObject.displayControl,
      controlLength: inspectionParameterObject.controlLength,
      parentInspParameter: inspectionParameterObject.parentInspParameterCode,
      parentOption: inspectionParameterObject.parentOption,
      parameterLevel: Number(inspectionParameterObject.parameterLevel),
      // ctqFlag:inspectionParameterObject.ctqFlag,
      effDateFrom: inspectionParameterObject.effDateFrom,
      effDateTo:
        status === true
          ? new Date("1900-01-01")
          : inspectionParameterObject.effDateTo,
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      formDbId: Number(inspectionParameterObject.formName),
      toleranceType: inspectionParameterObject.toleranceType,
      varianceType: inspectionParameterObject.varianceType,
      allowedVariance: inspectionParameterObject.allowedVariance,
      qaProcessDbId: Number(inspectionParameterObject.qaProcessName),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response)
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            if (selected_form_id == 7) {
              AddTheInspectionParametersMaster()
            } else {
              AddTheFailureParametersMaster()
            }
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              "Post",
              "InspectionParameter",
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Post",
              "FailureParametersMaster",
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
          console.log(data)
        });
    }
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditInspection = () => {
    let deleteData = {
      parameterCode: inspectionParameterObjectDelete.parameterCode,
      parameterQuestion: inspectionParameterObjectDelete.parameterQuestion,
      parameterValue: inspectionParameterObjectDelete.parameterValue,
      parameterValueType: inspectionParameterObjectDelete.parameterValueType,
      isParamMandatory: inspectionParameterObjectDelete.parameterMandatory,
      parameterDisplayCol: inspectionParameterObjectDelete.parameterDisplayCol,
      parameterDisplayRow: inspectionParameterObjectDelete.parameterDisplayRow,
      reflinksUrl:
        inspectionParameterObjectDelete.refLinksUrl.length > 0
          ? inspectionParameterObjectDelete.refLinksUrl
          : null,
      displayControl: inspectionParameterObjectDelete.displayControl,
      controlLength: inspectionParameterObjectDelete.controlLength,
      parentInspParameter: inspectionParameterObjectDelete.parentInspParameterCode,
      parentOption: inspectionParameterObjectDelete.parentOption,
      parameterLevel: Number(inspectionParameterObjectDelete.parameterLevel),
      ctqFlag: Number(inspectionParameterObjectDelete.ctqFlag),
      effDateFrom: GetServerDate(inspectionParameterObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      formDbId: Number(inspectionParameterObjectDelete.formName),
      varianceType: inspectionParameterObjectDelete.varianceType,
      allowedVariance: inspectionParameterObjectDelete.allowedVariance,
      qaProcessDbId: Number(inspectionParameterObjectDelete.qaProcessName),
    };
    let data = {
      parameterCode:
        inspectionParameterObject.parameterCode.length > 0
          ? inspectionParameterObject.parameterCode
          : null,
      parameterQuestion:
        inspectionParameterObject.parameterQuestion.length > 0
          ? inspectionParameterObject.parameterQuestion
          : null,
      parameterValue: inspectionParameterObject.parameterValue,

      reflinksUrl:
        inspectionParameterObject.refLinksUrl.length > 0
          ? inspectionParameterObject.refLinksUrl
          : null,
      ctqFlag: Number(inspectionParameterObject.ctqFlag),
      effDateFrom: GetServerDate(inspectionParameterObject.effDateFrom),
      effDateTo: GetServerDate(inspectionParameterObject.effDateTo),
      parameterValueType: inspectionParameterObject.parameterValueType,
      isParamMandatory: inspectionParameterObject.parameterMandatory,
      parameterDisplayCol: inspectionParameterObject.parameterDisplayCol,
      parameterDisplayRow: inspectionParameterObject.parameterDisplayRow,
      parentInspParameter: inspectionParameterObject.parentInspParameterCode,
      parentOption: inspectionParameterObject.parentOption,
      parameterLevel: Number(inspectionParameterObject.parameterLevel),

      displayControl: inspectionParameterObject.displayControl,
      controlLength: inspectionParameterObject.controlLength,
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      formDbId: Number(inspectionParameterObject.formName),
      toleranceType: inspectionParameterObject.toleranceType,
      varianceType: inspectionParameterObject.varianceType,
      allowedVariance: inspectionParameterObject.allowedVariance,
      qaProcessDbId: Number(inspectionParameterObject.qaProcessName),
    };
    axios
      .patch(post_url + "/" + selectedInspDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                if (formMaster.find((e) => e.formDbId == 7)) {
                  UpdateTheInspectionParametersMaster()
                }
                else {
                  UpdateTheFailureParametersMaster();
                }
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
                setOpen_Sub_Form(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedInspDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Inspection Parameters") {
        csv_count++;
        switch (clientSideValidations[i].fieldName) {
          case "parameterCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionParameterObject.parameterCode.length === 0
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  parameterCodeError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "qaProcessCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionParameterObject.qaProcessName == 0
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  qaProcessNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "parameterQuestion":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionParameterObject.parameterQuestion.length === 0
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  parameterQuestionError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "parameterDisplayRow":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionParameterObject.parameterDisplayRow == 0
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  parameterDisplayRowError:
                    clientSideValidations[i].shortMessage,
                })
              );
            }
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(
                Number(inspectionParameterObject.parameterDisplayRow)
              )
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  parameterDisplayRowError:
                    clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "parameterDisplayCol":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionParameterObject.parameterDisplayCol == 0
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  parameterDisplayColError:
                    clientSideValidations[i].shortMessage,
                })
              );
            }
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(
                Number(inspectionParameterObject.parameterDisplayCol)
              )
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  parameterDisplayColError:
                    clientSideValidations[i].shortMessage,
                })
              );
            }
            break;

          // case "effDateTo":
          //   if (
          //     clientSideValidations[i].validationType ===
          //     "greater_than_equalto_date" &&
          //     inspectionParameterObject.effDateTo <
          //     inspectionParameterObject.effDateFrom
          //   ) {
          //     temp++;
          //     setInspectionParameterObjectErrors(
          //       (inspectionParameterObjectErrors) => ({
          //         ...inspectionParameterObjectErrors,
          //         effDateToError: clientSideValidations[i].shortMessage,
          //       })
          //     );
          //   }
          //   break;
          case "allowedVariance":
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(
                Number(inspectionParameterObject.allowedVariance)
              )
            ) {
              temp++;
              setInspectionParameterObjectErrors(
                (inspectionParameterObjectErrors) => ({
                  ...inspectionParameterObjectErrors,
                  allowedVarianceError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          default:
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = inspectionParametersMaster.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        inspectionParametersMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (
        inspectionParametersMaster.length <=
        process.env.REACT_APP_ITEMS_PER_PAGE
      ) {
        current_page.current = 1;
        for (let i = 0; i < inspectionParametersMaster.length; i++) {
          temp_display_array.push(inspectionParametersMaster[i]);
        }
      } else {
        if (
          inspectionParametersMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          inspectionParametersMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < inspectionParametersMaster.length;
            i++
          ) {
            temp_display_array.push(inspectionParametersMaster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(inspectionParametersMaster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [selected_form_id, set_selected_form_id] = useState(6);
  const onChangeFormID = (e) => {
    set_selected_form_id(e.target.value);
    current_page.current = 1;
    FetchTheData(
      process.env.REACT_APP_BASE_API_PYTHON +
      "inspectionParametersMaster/formDbId/" +
      e.target.value,
      "Get",
      "InspectionParameter",
      e.target.value
    );
  };
  useEffect(() => {
    set_selected_form_id(6);
    FetchTheData(
      process.env.REACT_APP_BASE_API_PYTHON +
      "inspectionParametersMaster/formDbId/6",
      "Get",
      "InspectionParameter", 6
    );
    FetchTheData(
      process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
      "Get",
      "FailureParametersMaster", 0
    );
  }, []);

  const FetchTheData = (_fetch_link, _action, _type, _fdi) => {
    if (_type === "InspectionParameter") {
      setLoading(true);
      let temp_inspectionParameter = [];
      let temp_draft_array = [];
      let tempNonDraftArray = [];
      let tempFormDbIdArray = [];
      axios
        .get(_fetch_link)
        .then((response) => {
          if (response.status === 200) {
            if (_action === "Get") {
              ShowMEWSPopup("✅Successfully fetched the records", "Success");
              set_mews_popup_color("#28A745");
            }
            let temp_count = 0;
            for (let i = 0; i < response.data.length; i++) {
              temp_inspectionParameter.push(response.data[i]);
              if (new Date(response.data[i].effDateTo).getTime() == new Date("9999-12-31").getTime()) {
                tempNonDraftArray.push(response.data[i]);
                temp_count++;
              }
              if (response.data[i].parameterLevel == 0 && response.data[i].formDbId == 7 && new Date(response.data[i].effDateTo).getTime() == new Date("9999-12-31").getTime()) {
                tempFormDbIdArray.push(response.data[i]);
              }
              // console.log(tempFormDbIdArray)
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("1900-01-01").getTime()
              ) {
                temp_draft_array.push(response.data[i]);
              }
            }

            if (_fdi != 7) {
              setInspectionParametersMasterArray(tempNonDraftArray)
            } else {
              setInspectionParametersMasterArray(tempFormDbIdArray)
            }
            set_draft_array(temp_draft_array);

            let sortInspectionParameter = temp_inspectionParameter.sort(
              (a, b) => {
                return (
                  a.parameterDisplayCol - b.parameterDisplayCol &&
                  a.parameterDisplayRow - b.parameterDisplayRow
                );
              }
            );
            dispatch(populate_inspection_parameters(sortInspectionParameter));
            if (_fdi != 7) {
              total_records.current = temp_count;
              //Get The Total Pages
              total_pages.current = Math.ceil(
                temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
              );
              console.log(total_pages.current = Math.ceil(
                temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
              ))
              //Pagination Of All Records
              let temp_display_array = [];
              if (
                sortInspectionParameter.length <=
                process.env.REACT_APP_ITEMS_PER_PAGE
              ) {
                current_page.current = 1;
                for (let i = 0; i < sortInspectionParameter.length; i++) {
                  temp_display_array.push(sortInspectionParameter[i]);
                }
              } else {
                if (
                  sortInspectionParameter.length <=
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE
                ) {
                  current_page.current -= 1;
                }
                if (
                  sortInspectionParameter.length <=
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
                ) {
                  for (
                    let i =
                      current_page.current *
                      process.env.REACT_APP_ITEMS_PER_PAGE -
                      process.env.REACT_APP_ITEMS_PER_PAGE;
                    i < sortInspectionParameter.length;
                    i++
                  ) {
                    temp_display_array.push(sortInspectionParameter[i]);
                  }
                } else {
                  for (
                    let i =
                      current_page.current *
                      process.env.REACT_APP_ITEMS_PER_PAGE -
                      process.env.REACT_APP_ITEMS_PER_PAGE;
                    i <
                    current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                    i++
                  ) {
                    temp_display_array.push(sortInspectionParameter[i]);
                  }
                }
              }
              setPaginatedArray(temp_display_array);
              FetchTheQuality(tempNonDraftArray);
              setLoading(false);
            }
            if (_fdi == 7) {
              total_records.current = tempFormDbIdArray;
              //Get The Total Pages
              total_pages.current = Math.ceil(
                tempFormDbIdArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
              );
              console.log(total_pages.current = Math.ceil(
                tempFormDbIdArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
              ))
              //Pagination Of All Records
              let temp_display_array = [];
              if (
                sortInspectionParameter.length <=
                process.env.REACT_APP_ITEMS_PER_PAGE
              ) {
                current_page.current = 1;
                for (let i = 0; i < sortInspectionParameter.length; i++) {
                  temp_display_array.push(sortInspectionParameter[i]);
                }
              } else {
                if (
                  sortInspectionParameter.length <=
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE
                ) {
                  current_page.current -= 1;
                }
                if (
                  sortInspectionParameter.length <=
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
                ) {
                  for (
                    let i =
                      current_page.current *
                      process.env.REACT_APP_ITEMS_PER_PAGE -
                      process.env.REACT_APP_ITEMS_PER_PAGE;
                    i < sortInspectionParameter.length;
                    i++
                  ) {
                    temp_display_array.push(sortInspectionParameter[i]);
                  }
                } else {
                  for (
                    let i =
                      current_page.current *
                      process.env.REACT_APP_ITEMS_PER_PAGE -
                      process.env.REACT_APP_ITEMS_PER_PAGE;
                    i <
                    current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                    i++
                  ) {
                    temp_display_array.push(sortInspectionParameter[i]);
                  }
                }
              }
              setPaginatedArray(temp_display_array);
              setLoading(false);
            }
          } else {
            if (_action === "Get") {
              ShowMEWSPopup("⚠" + response.message, "Warning");
              set_mews_popup_color("#FFC107");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
          setLoading(false);
        });
    }
    if (_type === "FailureParametersMaster") {
      let temp_FailureParametersMaster = [];
      axios
        .get(_fetch_link)
        .then((response) => {
          if (response.status === 200) {
            if (_action === "Get") {
              ShowMEWSPopup("✅Successfully fetched the records", "Success");
              set_mews_popup_color("#28A745");
            }
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                temp_FailureParametersMaster.push(response.data[i]);
              }
            }
            dispatch(populate_failure_parameter(temp_FailureParametersMaster));
          } else {
            if (_action === "Get") {
              ShowMEWSPopup("⚠" + response.message, "Warning");
              set_mews_popup_color("#FFC107");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  // const [pncInspectionArray, setPncInspectionArray] = useState([])
  // const FetchTheData = (_fetch_link, _action, _type,_fdi) => {
  //   if (_type === "InspectionParameter") {
  //     setLoading(true);
  //     let temp_inspectionParameter = [];
  //     let temp_draft_array = [];
  //     let tempNonDraftArray = [];
  //     let tempFormDbIdArray = [];
  //     axios
  //       .get(_fetch_link)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           if (_action === "Get") {
  //             ShowMEWSPopup("✅Successfully fetched the records", "Success");
  //             set_mews_popup_color("#28A745");
  //           }
  //           let temp_count = 0;
  //           for (let i = 0; i < response.data.length; i++) {
  //             if (response.data[i].formDbId != 7) 
  //             {
  //               temp_inspectionParameter.push(response.data[i]);
  //               if (new Date(response.data[i].effDateTo).getTime()==new Date("9999-12-31").getTime())
  //               {
  //                 tempNonDraftArray.push(response.data[i]);
  //                 temp_count++;
  //               }
  //               // setInspectionParametersMasterArray(tempNonDraftArray);
  //             }
  //             else if (response.data[i].formDbId == 7) {
  //               if (new Date(response.data[i].effDateTo).getTime() == new Date("9999-12-31").getTime()) {
  //                 if (response.data[i].parameterLevel == 0) {
  //                   tempFormDbIdArray.push(response.data[i])
  //                 }
  //                 tempNonDraftArray.push(response.data[i]);
  //               }
  //             }

  //             if (
  //               new Date(response.data[i].effDateTo).getTime() ==
  //               new Date("1900-01-01").getTime()
  //             ) {
  //               temp_draft_array.push(response.data[i]);
  //             }
  //           }
  //           setInspectionParametersMasterArray(tempNonDraftArray)
  //           setPncInspectionArray(tempFormDbIdArray)
  //           set_draft_array(temp_draft_array);
  //           let sortInspectionParameter = tempNonDraftArray.sort(
  //             (a, b) => {
  //               return (
  //                 a.parameterDisplayCol - b.parameterDisplayCol &&
  //                 a.parameterDisplayRow - b.parameterDisplayRow
  //               );
  //             }
  //           );
  //           dispatch(populate_inspection_parameters(sortInspectionParameter));
  //           //Get The Total Records
  //           // total_records.current = temp_count;
  //           if (_fdi != 7) {


  //             total_records.current = tempNonDraftArray.length;

  //             //Get The Total Pages
  //             total_pages.current = Math.ceil(
  //               tempNonDraftArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
  //             );
  //             //Pagination Of All Records
  //             let temp_display_array = [];
  //             if (
  //               sortInspectionParameter.length <=
  //               process.env.REACT_APP_ITEMS_PER_PAGE
  //             ) {
  //               current_page.current = 1;
  //               for (let i = 0; i < sortInspectionParameter.length; i++) {
  //                 temp_display_array.push(sortInspectionParameter[i]);
  //               }
  //             } else {
  //               if (
  //                 sortInspectionParameter.length <=
  //                 current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
  //                 process.env.REACT_APP_ITEMS_PER_PAGE
  //               ) {
  //                 current_page.current -= 1;
  //               }
  //               if (
  //                 sortInspectionParameter.length <=
  //                 current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
  //               ) {
  //                 for (
  //                   let i =
  //                     current_page.current *
  //                     process.env.REACT_APP_ITEMS_PER_PAGE -
  //                     process.env.REACT_APP_ITEMS_PER_PAGE;
  //                   i < sortInspectionParameter.length;
  //                   i++
  //                 ) {
  //                   temp_display_array.push(sortInspectionParameter[i]);
  //                 }
  //               } else {
  //                 for (
  //                   let i =
  //                     current_page.current *
  //                     process.env.REACT_APP_ITEMS_PER_PAGE -
  //                     process.env.REACT_APP_ITEMS_PER_PAGE;
  //                   i <
  //                   current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
  //                   i++
  //                 ) {
  //                   temp_display_array.push(sortInspectionParameter[i]);
  //                 }
  //               }
  //             }
  //             setPaginatedArray(temp_display_array);
  //             FetchTheQuality(tempNonDraftArray);
  //             setLoading(false);
  //           }
  //           else if(_fdi == 7) {
  //             alert("id:" + _fdi)
  //             total_records.current = tempFormDbIdArray.length;

  //             //Get The Total Pages
  //             total_pages.current = Math.ceil(
  //               tempFormDbIdArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
  //             );
  //             //Pagination Of All Records
  //             let temp_display_array = [];
  //             if (
  //               tempFormDbIdArray.length <=
  //               process.env.REACT_APP_ITEMS_PER_PAGE
  //             ) {
  //               current_page.current = 1;
  //               for (let i = 0; i < tempFormDbIdArray.length; i++) {
  //                 temp_display_array.push(tempFormDbIdArray[i]);
  //               }
  //             } else {
  //               if (
  //                 tempFormDbIdArray.length <=
  //                 current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
  //                 process.env.REACT_APP_ITEMS_PER_PAGE
  //               ) {
  //                 current_page.current -= 1;
  //               }
  //               if (
  //                 tempFormDbIdArray.length <=
  //                 current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
  //               ) {
  //                 for (
  //                   let i =
  //                     current_page.current *
  //                     process.env.REACT_APP_ITEMS_PER_PAGE -
  //                     process.env.REACT_APP_ITEMS_PER_PAGE;
  //                   i < tempFormDbIdArray.length;
  //                   i++
  //                 ) {
  //                   temp_display_array.push(tempFormDbIdArray[i]);
  //                 }
  //               } else {
  //                 for (
  //                   let i =
  //                     current_page.current *
  //                     process.env.REACT_APP_ITEMS_PER_PAGE -
  //                     process.env.REACT_APP_ITEMS_PER_PAGE;
  //                   i <
  //                   current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
  //                   i++
  //                 ) {
  //                   temp_display_array.push(tempFormDbIdArray[i]);
  //                 }
  //               }
  //             }
  //             setPaginatedArray(temp_display_array);
  //             console.log(temp_display_array)
  //             FetchTheQuality(tempNonDraftArray);
  //             setLoading(false);
  //           }
  //         } else {
  //           if (_action === "Get") {
  //             ShowMEWSPopup("⚠" + response.message, "Warning");
  //             set_mews_popup_color("#FFC107");
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         ShowMEWSPopup("❌" + error.message, "Error");
  //         //return_mews_popup_color("#DC3545");
  //         set_mews_popup_color("#DC3545");
  //         setLoading(false);
  //       });
  //   }
  //   if (_type === "FailureParametersMaster") {
  //     let temp_FailureParametersMaster = [];
  //     axios
  //       .get(_fetch_link)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           if (_action === "Get") {
  //             ShowMEWSPopup("✅Successfully fetched the records", "Success");
  //             set_mews_popup_color("#28A745");
  //           }
  //           for (let i = 0; i < response.data.length; i++) {
  //             if (
  //               new Date(response.data[i].effDateTo).getTime() ==
  //               new Date("9999-12-31").getTime()
  //             ) {
  //               temp_FailureParametersMaster.push(response.data[i]);
  //             }
  //           }
  //           dispatch(populate_failure_parameter(temp_FailureParametersMaster));
  //         } else {
  //           if (_action === "Get") {
  //             ShowMEWSPopup("⚠" + response.message, "Warning");
  //             set_mews_popup_color("#FFC107");
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         ShowMEWSPopup("❌" + error.message, "Error");
  //         //return_mews_popup_color("#DC3545");
  //         set_mews_popup_color("#DC3545");
  //       });
  //   }
  // };
  const [selectedInspDbId, setSelectedInspDbId] = useState(0);
  const [displayViewFailureParameters, setDisplayViewFailureParameters] =
    useState([]);
  const [displayEditFailureParameters, setdisplayEditFailureParameters] =
    useState([]);
  const [displayViewFormDbIdArray, setDisplayViewFormDbIdArray] =
    useState([]);
  const [displayEditFormDbIdArray, setDisplayEditFormDbIdArray] =
    useState([]);
  const [displayParameterLevelArray, setDisplayParameterLevelArray] =
    useState([]);
  //View Modal||Edit Modal
  const OpenModal = (inspParameterDbId, type) => {
    setSelectedInspDbId(inspParameterDbId);
    switch (type) {
      case "View":
        set_open_view_modal(true);
        let tempFailureParameters = failureParameters.filter(
          (e) =>
            e.parameterCode ==
            inspectionParametersMaster.find(
              (e) => e.inspParameterDbId == inspParameterDbId
            ).parameterCode && e.formDbId == selected_form_id
        );
        setDisplayViewFailureParameters(tempFailureParameters);
        let tempFormDbIdArray = inspectionParametersMaster.filter(
          (e) =>
            e.parameterCode == inspectionParametersMaster.find(
              (e) => e.inspParameterDbId == inspParameterDbId
            ).parameterCode && e.parameterLevel != 0 && e.formDbId == 7
        );
        // let tempSortFormDbId = tempFormDbIdArray.sort((a, b) => {
        //   return (
        //     Number(a.parameterLevel) - Number(b.parameterLevel)
        //   )
        // })
        setDisplayViewFormDbIdArray(tempFormDbIdArray)
        console.log(tempFormDbIdArray)
        break;
      case "Edit":
        set_open_view_modal(false);
        set_open_edit_modal(true);
        let tempEditFailureParameters = failureParameters.filter(
          (e) =>
            e.parameterCode ===
            inspectionParametersMaster.find(
              (e) => e.inspParameterDbId == inspParameterDbId
            ).parameterCode && e.formDbId == selected_form_id
        );
        setdisplayEditFailureParameters(tempEditFailureParameters);

        let tempeEditFormDbIdArray = inspectionParametersMaster.filter(
          (e) =>
            e.parameterCode == inspectionParametersMaster.find(
              (e) => e.inspParameterDbId == inspParameterDbId
            ).parameterCode && e.parameterLevel != 0 && e.formDbId == 7.
        );
        // let tempEditSortFormDbId = tempeEditFormDbIdArray.sort((a, b) => {
        //   return (
        //     Number(a.parameterLevel) - Number(b.parameterLevel)
        //   )
        // })
        // for (let i = 0; i < displayEditFormDbIdArray.length; i++) {
        //   console.log("Id:" + displayEditFormDbIdArray[i].formDbId)
        // }
        setDisplayEditFormDbIdArray(tempeEditFormDbIdArray)
        console.log(tempeEditFormDbIdArray)
        let temp = [];
        for (let i = 0; i < tempeEditFormDbIdArray.length; i++) {
          if (temp.findIndex((e) => e==tempeEditFormDbIdArray[i].parameterLevel) == -1) {
            temp.push(tempeEditFormDbIdArray[i].parameterLevel)
          }
        }
        console.log("temp length:"+temp.length)
        setDisplayParameterLevelArray(temp)
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(inspParameterDbId);
  };
  const SupplyValuesToModal = (inspParameterDbId) => {
    let dateFrom = inspectionParametersMaster.find(
      (e) => e.inspParameterDbId == inspParameterDbId
    ).effDateFrom;
    let dateTo = inspectionParametersMaster.find(
      (e) => e.inspParameterDbId == inspParameterDbId
    ).effDateTo;
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterCode: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterCode,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterQuestion: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterQuestion,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterValue: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterValue,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterValueType: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterValueType,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterMandatory: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).isParamMandatory,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterDisplayCol: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterDisplayCol,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterDisplayRow: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterDisplayRow,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      ctqFlag: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).ctqFlag,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      effDateFrom: dateFrom,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      effDateTo: dateTo,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      varianceType: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).varianceType,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      toleranceType: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).toleranceType,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      allowedVariance: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).allowedVariance,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      qaProcessName: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).qaProcessDbId,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      controlLength: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).controlLength,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      displayControl: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).displayControl,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      formName: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).formDbId,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parameterLevel: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).parameterLevel,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parentInspParameterCode: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).parentInspParameter,
    }));
    setInspectionParameterObject((inspectionParameterObject) => ({
      ...inspectionParameterObject,
      parentOption: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).parentOption,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterCode: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterCode,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterQuestion: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterQuestion,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterValue: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterValue,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterValueType: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterValueType,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterMandatory: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).isParamMandatory,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterDisplayCol: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterDisplayCol,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterDisplayRow: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).parameterDisplayRow,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      ctqFlag: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).ctqFlag,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      effDateFrom: dateFrom,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      effDateTo: dateTo,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      varianceType: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).varianceType,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      toleranceType: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).toleranceType,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      allowedVariance: inspectionParametersMaster.find(
        (e) => e.inspParameterDbId == inspParameterDbId
      ).allowedVariance,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      displayControl: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).displayControl,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      controlLength: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).controlLength,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      qaProcessName: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).qaProcessDbId,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      formName: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).formDbId,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parameterLevel: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).parameterLevel,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parentInspParameterCode: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).parentInspParameter,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      parentOption: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).parentOption,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      createdBy: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).createdBy,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      updatedBy: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).updatedBy,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      createdTimestamp: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).createdTimestamp,
    }));
    setInspectionParameterObjectDelete((inspectionParameterObjectDelete) => ({
      ...inspectionParameterObjectDelete,
      updatedTimestamp: inspectionParametersMaster.find(
        (e1) => e1.inspParameterDbId == inspParameterDbId
      ).updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortInspection = [...inspectionParametersMasterArray];
    if (sortStyle === "Descending") {
      let updatedInspectionMaster = SortInspection.sort((a, b) => {
        const a_res = action === "Code" ? a.parameterCode : a.parameterQuestion;
        const b_res = action === "Code" ? b.parameterCode : b.parameterQuestion;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedInspectionMaster);
      setSortStyle("Ascending");
    }
    if (sortStyle === "Ascending") {
      let updatedInspectionMaster = SortInspection.sort((a, b) => {
        const a_res = action === "Code" ? a.parameterCode : a.parameterQuestion;
        const b_res = action === "Code" ? b.parameterCode : b.parameterQuestion;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedInspectionMaster);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortInspection.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortInspection[i]);
      }
    } else {
      for (let i = 0; i < SortInspection.length; i++) {
        tempArray.push(SortInspection[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortInspection.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setInspectionParametersMasterArray(SortInspection);
  };

  const [qualityProcess, setQualityProcess] = useState(false);
  const OnClickQualityProcess = () => {
    if (inspectionParametersMaster.length == 0) {
      setQualityProcess(false);
    } else {
      setQualityProcess(!qualityProcess);
    }
  };
  const OnOkQualityProcess = () => {
    let temp = [...inspectionParametersMaster];
    let updatedArray = [];
    for (let i = 0; i < selectedQualityProcess.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].qaProcessDbId == selectedQualityProcess[i].qaProcessDbId
          // qualityProcessMap.find(
          //   (e) => e.processName == selectedQualityProcess[i]
          // ).qaProcessDbId
        ) {
          if (
            new Date(temp[j].effDateTo).getTime() ==
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setInspectionParametersMasterArray(updatedArray);
    setPaginatedArray(tempArray);
    setQualityProcess(false);
  };
  const [selectedQualityProcess, setSelectedQualityProccess] = useState([]);
  const AddRemoveQuality = (id) => {
    let tempQualityProcess = [...selectedQualityProcess];
    let qualityIndex = tempQualityProcess.findIndex(
      (e) => e.qaProcessDbId == id
    );
    if (qualityIndex == -1) {
      tempQualityProcess.push(
        qualityProcessMap.find((e) => e.qaProcessDbId == id)
      );
    } else {
      tempQualityProcess.splice(qualityIndex, 1);
    }
    setSelectedQualityProccess(tempQualityProcess);
  };
  const OnPressedClearClicked = (qaProcessDbId) => {
    let tempSelectedQuality = [];
    if (selectedQualityProcess.length == 0) {
      for (let i = 0; i < qualityProcessMap.length; i++) {
        tempSelectedQuality.push(qualityProcessMap[i].qaProcessDbId);
      }
    } else {
      tempSelectedQuality.splice(
        tempSelectedQuality.findIndex((e) => e === qaProcessDbId),
        1
      );
      setSelectedQualityProccess(tempSelectedQuality);
    }
    setSelectedQualityProccess(tempSelectedQuality);
  };


  const [addSubInspectionMasterArray, setAddSubInspectionMasterArray] = useState([
    {
      parameterQuestion: "",
      parameterDisplayRow: 0,
      parameterDisplayCol: 0,
      parameterValue: "",
      parameterValueType: "",
      parameterMandatory: "",
      displayControl: "",
      controlLength: "",
      ctqFlag: "",
      refLinksUrl: "",
      formName: 0,
      toleranceType: "",
      varianceType: "",
      allowedVariance: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      qaProcessName: 0,
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
      parentInspParameterCode: "",
      parameterLevel: 1,
      parentOption: "",
    },
  ]);


  const [openInspectionSubForm, setOpenInspectionSubForm] = useState(false);
  const AddTheInspectionParameter = (_plv) => {
    // alert(_plv)
    let temp = [...addSubInspectionMasterArray];
    temp.push({
      idx: addSubInspectionMasterArray.length + 1,
      parameterQuestion: "",
      parameterDisplayRow: 0,
      parameterDisplayCol: 0,
      parameterValue: "",
      parameterValueType: "",
      parameterMandatory: "",
      displayControl: "",
      controlLength: "",
      ctqFlag: "",
      refLinksUrl: "",
      formName: 0,
      toleranceType: "",
      varianceType: "",
      allowedVariance: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      qaProcessName: 0,
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
      parentInspParameterCode: "",
      parameterLevel: _plv,
      parentOption: "",
    });
    setAddSubInspectionMasterArray(temp);
    setOpenInspectionSubForm(true)
  };

  const isSubInspectionFormFilled = (index) => {
    let tempCount = 0;
    if (addSubInspectionMasterArray[index].parameterQuestion.length == 0) {
      tempCount += 1;
    }
    if (
      addSubInspectionMasterArray[index].parameterDisplayRow == 0
    ) {
      tempCount += 1;
    }
    if (
      addSubInspectionMasterArray[index].parameterDisplayCol == 0
    ) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].parameterValue.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].parameterValueType.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].parameterMandatory.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].displayControl.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].controlLength.Length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].ctqFlag.length == 0) {
      tempCount += 1;
    }
    // if (addSubInspectionMasterArray[index].refLinksUrl.length == 0) {
    //   tempCount += 1;
    // }
    // if (addSubInspectionMasterArray[index].formName == 0) {
    //   tempCount += 1;
    // }
    if (addSubInspectionMasterArray[index].toleranceType.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].varianceType.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].allowedVariance.length == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].qaProcessName == 0) {
      tempCount += 1;
    }
    if (addSubInspectionMasterArray[index].parentInspParameterCode.length == 0) {
      tempCount += 1;
    }

    if (addSubInspectionMasterArray[index].parentOption.length <= 0) {
      tempCount += 1;

    }
    console.log(tempCount)

    return tempCount;
  };
  const OnChangeSubInpectionParameter = (e, _action, idx) => {
    let tempParameter = [...addSubInspectionMasterArray]
    for (let i = 0; i < tempParameter.length; i++) {
      console.log(tempParameter[i].parameterLevel)
    }
    switch (_action) {
      case "parameterQuestion":
        tempParameter.find((e) => e.idx == idx).parameterQuestion = e.target.value;
        break;
      case "parameterDisplayRow":
        tempParameter.find((e) => e.idx == idx).parameterDisplayRow = e.target.value;
        break;
      case "parameterDisplayCol":
        tempParameter.find((e) => e.idx == idx).parameterDisplayCol = e.target.value;
        break;
      case "parameterValue":
        tempParameter.find((e) => e.idx == idx).parameterValue = e.target.value;
        break;
      case "parameterValueType":
        tempParameter.find((e) => e.idx == idx).parameterValueType = e.target.value;
        break;
      case "parameterMandatory":
        tempParameter.find((e) => e.idx == idx).parameterMandatory = e.target.value;
        break;
      case "displayControl":
        tempParameter.find((e) => e.idx == idx).displayControl = e.target.value;
        break;
      case "controlLength":
        tempParameter.find((e) => e.idx == idx).controlLength = e.target.value;
        break;
      case "ctqFlag":
        tempParameter.find((e) => e.idx == idx).ctqFlag = e.target.value;
        break;
      case "formName":
        tempParameter.find((e) => e.idx == idx).formName = e.target.value;
        break;
      case "qaProcessName":
        tempParameter.find((e) => e.idx == idx).qaProcessName = e.target.value;
        break;
      case "refLinksUrl":
        tempParameter.find((e) => e.idx == idx).refLinksUrl = e.target.value;
        break;
      case "toleranceType":
        tempParameter.find((e) => e.idx == idx).toleranceType = e.target.value;
        break;
      case "varianceType":
        tempParameter.find((e) => e.idx == idx).varianceType = e.target.value;
        break;
      case "allowedVariance":
        tempParameter.find((e) => e.idx == idx).allowedVariance = e.target.value;
        break;
      case "effDateFrom":
        tempParameter.find((e) => e.idx == idx).effDateFrom = e.target.value;
        break;
      case "effDateTo":
        tempParameter.find((e) => e.idx == idx).effDateTo = e.target.value;
        break;
      case "parentInspParameterCode":
        tempParameter.find((e) => e.idx == idx).parentInspParameterCode = e.target.value;
        break;
      case "parameterLevel":
        if (e.target.value - idx != 1) {
          tempParameter.find((e) => e.idx == idx).parameterLevel = e.target.value;
        }
        break;
      case "parentOption":
        tempParameter.find((e) => e.idx == idx).parentOption = e.target.value;
        break;
    }
    setAddSubInspectionMasterArray(tempParameter)
  }
  const ResetTheInspectionValue = (idx) => {
    let temp = [...addSubInspectionMasterArray];
    temp.find((e) => e.idx == idx).parameterQuestion = "";
    temp.find((e) => e.idx == idx).parameterValue = "";
    temp.find((e) => e.idx == idx).parameterValueType = "";
    temp.find((e) => e.idx == idx).parameterDisplayCol = 0;
    temp.find((e) => e.idx == idx).parameterDisplayRow = 0;
    temp.find((e) => e.idx == idx).displayControl = "";
    temp.find((e) => e.idx == idx).controlLength = "";
    temp.find((e) => e.idx == idx).qaProcessName = "";
    temp.find((e) => e.idx == idx).ctqFlag = "";
    temp.find((e) => e.idx == idx).toleranceType = "";
    temp.find((e) => e.idx == idx).varianceType = "";
    temp.find((e) => e.idx == idx).parentInspParameterCode = "";
    temp.find((e) => e.idx == idx).parentOption = "";
    setAddSubInspectionMasterArray(temp);
  };
  const ResetTheNewInspectionValue = (idx) => {
    let temp = [...displayAddNewSubInspectionMasterArray];
    temp.find((e) => e.idx == idx).parameterQuestion = "";
    temp.find((e) => e.idx == idx).parameterValue = "";
    temp.find((e) => e.idx == idx).parameterValueType = "";
    temp.find((e) => e.idx == idx).parameterDisplayCol = 0;
    temp.find((e) => e.idx == idx).parameterDisplayRow = 0;
    temp.find((e) => e.idx == idx).displayControl = "";
    temp.find((e) => e.idx == idx).controlLength = "";
    temp.find((e) => e.idx == idx).qaProcessName = "";
    temp.find((e) => e.idx == idx).ctqFlag = "";
    temp.find((e) => e.idx == idx).toleranceType = "";
    temp.find((e) => e.idx == idx).varianceType = "";
    temp.find((e) => e.idx == idx).parentInspParameterCode = "";
    temp.find((e) => e.idx == idx).parentOption = "";
    setDisplayAddNewSubInspectionMasterArray(temp);
  };
  const RemoveTheInspectionSubForm = (index) => {
    let temp = [...addSubInspectionMasterArray];
    temp.splice(index, 1);
    setAddSubInspectionMasterArray(temp);
  };
  const [parameterCode, setParameterCode] = useState("")
  const OnClickInspectionSubForm = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    setOpenInspectionSubForm(true);

    if (selected_form_id == 7) {
      let temp = inspectionParametersMaster.filter((e) => e.parameterCode == parameterCode && new Date(e.effDateTo).getTime() == new Date("9999-12-31").getTime())
      temp.sort((a, b) => {
        return (
          b.parameterLevel - a.parameterLevel
        )
      })
      AddTheNewInspectionParameter(temp[0].parameterLevel + 1)

    }
    else {
      setOpenInspectionSubForm(true);
    }

  };
  const AddTheInspectionParametersMaster = (_action) => {
    alert(_action)
    for (let i = 0; i < addSubInspectionMasterArray.length; i++) {
      console.log(addSubInspectionMasterArray[i].parameterQuestion + "-" + addSubInspectionMasterArray[i].parameterLevel)
      let data = {
        formDbId: Number(inspectionParameterObject.formName),
        parameterCode: inspectionParameterObject.parameterCode,
        parameterQuestion: addSubInspectionMasterArray[i].parameterQuestion,
        parameterValue: addSubInspectionMasterArray[i].parameterValue,
        parameterValueType: addSubInspectionMasterArray[i].parameterValueType,
        isParamMandatory: addSubInspectionMasterArray[i].parameterMandatory,
        parameterDisplayCol: Number(addSubInspectionMasterArray[i].parameterDisplayCol),
        parameterDisplayRow: Number(addSubInspectionMasterArray[i].parameterDisplayRow),
        // reflinksUrl: addSubInspectionMasterArray[i].refLinksUrl,
        displayControl: addSubInspectionMasterArray[i].displayControl,
        controlLength: addSubInspectionMasterArray[i].controlLength,
        parentInspParameter: inspectionParameterObject.parameterCode + " | " + Number(addSubInspectionMasterArray[i].parameterLevel),
        parentOption: addSubInspectionMasterArray[i].parentOption,
        parameterLevel: Number(addSubInspectionMasterArray[i].parameterLevel),
        // ctqFlag: addSubInspectionMasterArray[i].ctqFlag,
        varianceType: addSubInspectionMasterArray[i].varianceType,
        allowedVariance: addSubInspectionMasterArray[i].allowedVariance,
        qaProcessDbId: Number(addSubInspectionMasterArray[i].qaProcessName),
        effDateFrom: inspectionParameterObject.effDateFrom,
        effDateTo: inspectionParameterObject.effDateTo,
        createdBy: isAuthenticated == true ? user.name : null,
        createdTimestamp: new Date().toISOString(),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      axios
        .post(
          process.env.REACT_APP_BASE_API_PYTHON + "inspectionParametersMaster",
          data
        )
        .then((response) => {
          console.log(response)
          if (response.status === 201) {
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              selected_form_id,
              "Post",
              "InspectionParameter", 6
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Post",
              "FailureParametersMaster", 0
            );
          }
        })
        .catch((error) => {
          console.log(error.message);
          console.log(data);
        });
    }
  };
  const OnChangeEditSubInspectionParameter = (purpose, e, index) => {
    let tempFunction = [];
    for (let i = 0; i < displayEditFormDbIdArray.length; i++) {
      tempFunction.push(displayEditFormDbIdArray[i]);
      console.log(displayEditFormDbIdArray[i])
    }
    let newObj = {
      inspParameterDbId: tempFunction[index].inspParameterDbId,
      parameterQuestion: tempFunction[index].parameterQuestion,
      parameterValue: tempFunction[index].parameterValue,
      parameterValueType: tempFunction[index].parameterValueType,
      isParamMandatory: tempFunction[index].isParamMandatory,
      parameterDisplayCol: tempFunction[index].parameterDisplayCol,
      parameterDisplayRow: tempFunction[index].parameterDisplayRow,
      displayControl: tempFunction[index].displayControl,
      controlLength: tempFunction[index].controlLength,
      parentInspParameter: tempFunction[index].parentInspParameter,
      parentOption: tempFunction[index].parentOption,
      parameterLevel: tempFunction[index].parameterLevel,
      // ctqFlag: tempFunction[index].ctqFlag,
      effDateFrom: tempFunction[index].effDateFrom,
      effDateTo: tempFunction[index].effDateTo,
      toleranceType: tempFunction[index].toleranceType,
      varianceType: tempFunction[index].varianceType,
      allowedVariance: tempFunction[index].allowedVariance,
      qaProcessDbId: tempFunction[index].qaProcessName,
      updatedBy: tempFunction[index].updatedBy,
      updatedTimestamp: tempFunction[index].updatedTimestamp,
    };
    switch (purpose) {
      // case "parameterCode":
      //   newObj["parameterCode"] = e.target.value;
      //   break;
      // case "formName":
      //   newObj["formDbId"] = e.target.value;
      //   break;
      case "parameterQuestion":
        newObj["parameterQuestion"] = e.target.value;
        break;
      case "parameterDisplayRow":
        newObj["parameterDisplayRow"] = e.target.value;
        break;
      case "parameterDisplayCol":
        newObj["parameterDisplayCol"] = e.target.value;
        break;
      case "parameterValue":
        newObj["parameterValue"] = e.target.value;
        break;
      case "parameterValueType":
        newObj["parameterValueType"] = e.target.value;
        break;
      case "parameterMandatory":
        newObj["isParamMandatory"] = e.target.value;
        break;
      case "displayControl":
        newObj["displayControl"] = e.target.value;
        break;
      case "controlLength":
        newObj["controlLength"] = e.target.value;
        break;
      case "ctqFlag":
        newObj["reflinksUrl"] = e.target.value;
        break;
      case "refLinksUrl":
        newObj["refLinksUrl"] = e.target.value;
        break;
      case "effDateFrom":
        newObj["effDateFrom"] = e.target.value;
        break;
      case "effDateTo":
        newObj["effDateTo"] = e.target.value;
        break;
      case "toleranceType":
        newObj["toleranceType"] = e.target.value;
        break;
      case "varianceType":
        newObj["varianceType"] = e.target.value;
        break;
      case "allowedVariance":
        newObj["allowedVariance"] = e.target.value;
        break;
      case "qaProcessName":
        newObj["qaProcessDbId"] = e.target.value;
        break;
      case "parentInspParameterCode":
        newObj["parentInspParameter"] = e.target.value;
        break;
      case "parameterLevel":
        newObj["parameterLevel"] = e.target.value;
        break;
      case "parentOption":
        newObj["parentOption"] = e.target.value;
        break;
    }
    tempFunction[index] = newObj;
    setDisplayEditFormDbIdArray(tempFunction);
    console.log(tempFunction)
  };
  // if (index != -1) {
  //   temp[idx].parameterDisplayRow = 0;
  //   setParameterDisplayColError("Duplicate Value");
  // }
  // if (e1.target.value - idx != 1) {
  //   setAttributeDisplayRowNumberError("Invliad Format");
  // } else {
  //   setParameterDisplayColError("");
  //   setAttributeDisplayRowNumberError("");
  //   temp[idx].parameterDisplayRow = e1.target.value;
  //   setAdd_Sub_Failure_Parameters_Master(temp);
  // }
  const OnClose=()=>{
    setOpenInspectionSubForm(false)
    set_open_edit_modal(false)
  }
  const UpdateTheInspectionParametersMaster = () => {
    for (let i = 0; i < displayEditFormDbIdArray.length; i++) {
      let data = {
        parameterCode: inspectionParameterObject.parameterCode,
        parameterQuestion: displayEditFormDbIdArray[i].parameterQuestion,
        parameterValue: displayEditFormDbIdArray[i].parameterValue,
        parameterValueType: displayEditFormDbIdArray[i].parameterValueType,
        isParamMandatory: displayEditFormDbIdArray[i].parameterMandatory,
        parameterDisplayCol: Number(displayEditFormDbIdArray[i].parameterDisplayCol),
        parameterDisplayRow: Number(displayEditFormDbIdArray[i].parameterDisplayRow),
        reflinksUrl: displayEditFormDbIdArray[i].refLinksUrl,
        displayControl: displayEditFormDbIdArray[i].displayControl,
        controlLength: displayEditFormDbIdArray[i].controlLength,
        parentInspParameter: displayEditFormDbIdArray[i].parentInspParameterCode,
        parentOption: displayEditFormDbIdArray[i].parentOption,
        parameterLevel: Number(displayEditFormDbIdArray[i].parameterLevel),
        ctqFlag: displayEditFormDbIdArray[i].ctqFlag,
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
        varianceType: displayEditFormDbIdArray[i].varianceType,
        allowedVariance: displayEditFormDbIdArray[i].allowedVariance,
        effDateFrom: GetServerDate(inspectionParameterObject.effDateFrom),
        effDateTo: GetServerDate(inspectionParameterObject.effDateTo),
        formDbId: Number(inspectionParameterObject.formName),
        qaProcessDbId: Number(inspectionParameterObject.qaProcessName),
      };
      axios
        .patch(
          `${process.env.REACT_APP_BASE_API_PYTHON}inspectionParametersMaster/${displayEditFormDbIdArray[i].inspParameterDbId}`,
          data
        )
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            set_open_edit_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              selected_form_id,
              "Patch",
              "InspectionParameter"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Patch",
              "FailureParametersMaster"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
    for (let i = 0; i < displayAddNewSubInspectionMasterArray.length; i++) {
      // alert(GetServerDate(inspectionParameterObject.effDateFrom))
      // alert(GetServerDate(inspectionParameterObject.effDateTo))

      let data = {
        formDbId: Number(inspectionParameterObject.formName),
        parameterCode: inspectionParameterObject.parameterCode,
        parameterQuestion: displayAddNewSubInspectionMasterArray[i].parameterQuestion,
        parameterValue: displayAddNewSubInspectionMasterArray[i].parameterValue,
        parameterValueType: displayAddNewSubInspectionMasterArray[i].parameterValueType,
        isParamMandatory: displayAddNewSubInspectionMasterArray[i].parameterMandatory,
        parameterDisplayCol: Number(displayAddNewSubInspectionMasterArray[i].parameterDisplayCol),
        parameterDisplayRow: Number(displayAddNewSubInspectionMasterArray[i].parameterDisplayRow),
        // reflinksUrl: displayAddNewSubInspectionMasterArray[i].refLinksUrl,
        displayControl: displayAddNewSubInspectionMasterArray[i].displayControl,
        controlLength: displayAddNewSubInspectionMasterArray[i].controlLength,
        parentInspParameter: inspectionParameterObject.parameterCode + " " + displayAddNewSubInspectionMasterArray[i].parameterLevel,
        parentOption: displayAddNewSubInspectionMasterArray[i].parentOption,
        parameterLevel: displayAddNewSubInspectionMasterArray[i].parameterLevel,
        // ctqFlag: displayAddNewSubInspectionMasterArray[i].ctqFlag,
        varianceType: displayAddNewSubInspectionMasterArray[i].varianceType,
        allowedVariance: displayAddNewSubInspectionMasterArray[i].allowedVariance,
        toleranceType: displayAddNewSubInspectionMasterArray[i].toleranceType,
        qaProcessDbId: Number(displayAddNewSubInspectionMasterArray[i].qaProcessName),
        effDateFrom: GetServerDate(inspectionParameterObject.effDateFrom),
        effDateTo: GetServerDate(inspectionParameterObject.effDateTo),
        createdBy: isAuthenticated == true ? user.name : null,
        createdTimestamp: new Date().toISOString(),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      axios
        .post(
          process.env.REACT_APP_BASE_API_PYTHON + "inspectionParametersMaster",
          data
        )
        .then((response) => {
          console.log(response)
          if (response.status === 201) {
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              selected_form_id,
              "Post",
              "InspectionParameter", 6
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Post",
              "FailureParametersMaster", 0
            );
          }
        })
        .catch((error) => {
          console.log(error.message);
          console.log(data);
        });
    }
  };

  const RemoveTheInspectionParameter = (_action, index) => {
    console.log("action" + _action);
    axios
      .patch(process.env.REACT_APP_BASE_API_PYTHON + "inspectionParametersMaster" + "/" + _action, {
        updatedTimestamp: new Date().toISOString(),
        effDateTo: OneBack(),
        updatedBy: isAuthenticated == true ? user.name : "",
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("put:" + response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
    let temp = [...displayEditFormDbIdArray];
    temp.splice(index, 1);
    setDisplayEditFormDbIdArray(temp);
  };
  const [displayAddNewSubInspectionMasterArray, setDisplayAddNewSubInspectionMasterArray] = useState([
    // {
    //   parameterQuestion: "",
    //   parameterDisplayRow: 0,
    //   parameterDisplayCol: 0,
    //   parameterValue: "",
    //   parameterValueType: "",
    //   parameterMandatory: "",
    //   displayControl: "",
    //   controlLength: "",
    //   ctqFlag: "",
    //   refLinksUrl: "",
    //   formName: 0,
    //   toleranceType: "",
    //   varianceType: "",
    //   allowedVariance: "",
    //   effDateFrom: new Date(),
    //   effDateTo: new Date("9999-12-31"),
    //   qaProcessName: 0,
    //   createdBy: "",
    //   updatedBy: "",
    //   createdTimestamp: new Date(),
    //   updatedTimestamp: new Date(),
    //   parentInspParameterCode: "",
    //   parameterLevel: 1,
    //   parentOption: "",
    // },
  ]);
  const AddTheNewInspectionParameter = (_plv) => {
    let temp = [...displayAddNewSubInspectionMasterArray];
    temp.push({
      idx: displayAddNewSubInspectionMasterArray.length + 1,
      parameterQuestion: "",
      parameterDisplayRow: 0,
      parameterDisplayCol: 0,
      parameterValue: "",
      parameterValueType: "",
      parameterMandatory: "",
      displayControl: "",
      controlLength: "",
      ctqFlag: "",
      refLinksUrl: "",
      formName: 0,
      toleranceType: "",
      varianceType: "",
      allowedVariance: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      qaProcessName: 0,
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
      parentInspParameterCode: "",
      parameterLevel: _plv,
      parentOption: "",
    });
    setDisplayAddNewSubInspectionMasterArray(temp);
  };
  const OnChangeNewSubInpectionParameter = (e, _action, idx) => {
    let tempParameter = [...displayAddNewSubInspectionMasterArray]
    switch (_action) {
      case "parameterQuestion":
        tempParameter.find((e) => e.idx == idx).parameterQuestion = e.target.value;
        break;
      case "parameterDisplayRow":
        tempParameter.find((e) => e.idx == idx).parameterDisplayRow = e.target.value;
        break;
      case "parameterDisplayCol":
        tempParameter.find((e) => e.idx == idx).parameterDisplayCol = e.target.value;
        break;
      case "parameterValue":
        tempParameter.find((e) => e.idx == idx).parameterValue = e.target.value;
        break;
      case "parameterValueType":
        tempParameter.find((e) => e.idx == idx).parameterValueType = e.target.value;
        break;
      case "parameterMandatory":
        tempParameter.find((e) => e.idx == idx).parameterMandatory = e.target.value;
        break;
      case "displayControl":
        tempParameter.find((e) => e.idx == idx).displayControl = e.target.value;
        break;
      case "controlLength":
        tempParameter.find((e) => e.idx == idx).controlLength = e.target.value;
        break;
      case "ctqFlag":
        tempParameter.find((e) => e.idx == idx).ctqFlag = e.target.value;
        break;
      case "formName":
        tempParameter.find((e) => e.idx == idx).formName = e.target.value;
        break;
      case "qaProcessName":
        tempParameter.find((e) => e.idx == idx).qaProcessName = e.target.value;
        break;
      case "refLinksUrl":
        tempParameter.find((e) => e.idx == idx).refLinksUrl = e.target.value;
        break;
      case "toleranceType":
        tempParameter.find((e) => e.idx == idx).toleranceType = e.target.value;
        break;
      case "varianceType":
        tempParameter.find((e) => e.idx == idx).varianceType = e.target.value;
        break;
      case "allowedVariance":
        tempParameter.find((e) => e.idx == idx).allowedVariance = e.target.value;
        break;
      case "effDateFrom":
        tempParameter.find((e) => e.idx == idx).effDateFrom = e.target.value;
        break;
      case "effDateTo":
        tempParameter.find((e) => e.idx == idx).effDateTo = e.target.value;
        break;
      case "parentInspParameterCode":
        tempParameter.find((e) => e.idx == idx).parentInspParameterCode = e.target.value;
        break;
      case "parameterLevel":
        if (e.target.value - idx != 1) {
          tempParameter.find((e) => e.idx == idx).parameterLevel = e.target.value;
        }
        break;
      case "parentOption":
        tempParameter.find((e) => e.idx == idx).parentOption = e.target.value;
        break;
    }
    setDisplayAddNewSubInspectionMasterArray(tempParameter)
  }
  const RemoveTheNewInspectionSubForm = (index) => {
    let temp = [...displayAddNewSubInspectionMasterArray];
    temp.splice(index, 1);
    setDisplayAddNewSubInspectionMasterArray(temp);
  };

  const [
    add_Sub_Failure_Parameters_Master,
    setAdd_Sub_Failure_Parameters_Master,
  ] = useState([
    {
      parameterQuestion: "",
      parameterValue: "",
      parameterValueType: "",
      displayControl: "",
      controlLength: "",
      isParamMandatory: "",
      parameterDisplayCol: 0,
      parameterDisplayRow: 0,
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
    },
  ]);

  const [open_Sub_Form, setOpen_Sub_Form] = useState(false);
  const bottomRef = useRef(null);

  const OnClickFormSection = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    setOpen_Sub_Form(true);

  };

  const AddTheFailureParameter = () => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.push({
      idx: add_Sub_Failure_Parameters_Master.length + 1,
      parameterQuestion: "",
      parameterValue: "",
      parameterValueType: "",
      displayControl: "",
      controlLength: "",
      isParamMandatory: "",
      parameterDisplayCol: 0,
      parameterDisplayRow: 0,
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
    });
    setAdd_Sub_Failure_Parameters_Master(temp);
    setOpen_Sub_Form(true)
  };
  const [attributeDisplayRowNumberError, setAttributeDisplayRowNumberError] =
    useState("");
  const [attributeDisplayColNumberError, setAttributeDisplayColNumberError] =
    useState("");
  const OnChangeParameterQuestion = (e, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).parameterQuestion = e.target.value;
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const OnChangeParameterValue = (e, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).parameterValue = e.target.value;
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const OnChangeParameterValueType = (e, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).parameterValueType = e.target.value;
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const OnChangeDisplayControl = (e, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).displayControl = e.target.value;
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const OnChangeControlLength = (e, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).controlLength = e.target.value;
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const OnChangeisParamMandatory = (e, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).isParamMandatory = e.target.value;
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const [parameterDisplayColError, setParameterDisplayColError] = useState("");
  const OnChangeParameterDisplayCol = (e1, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    let index = temp.findIndex(
      (e) =>
        e.parameterDisplayRow == temp[idx].parameterDisplayRow &&
        e.parameterDisplayCol == e1.target.value
    );
    if (index != -1) {
      temp[idx].parameterDisplayCol = 0;
      setParameterDisplayColError("Duplicate Value");
    }
    if (e1.target.value - idx != 1) {
      setAttributeDisplayColNumberError("Invliad Format");
    } else {
      setParameterDisplayColError("");
      setAttributeDisplayColNumberError("");
      temp[idx].parameterDisplayCol = e1.target.value;
      setAdd_Sub_Failure_Parameters_Master(temp);
    }
  };
  const OnChangeParameterDisplayRow = (e1, idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    let index = temp.findIndex(
      (e) =>
        e.parameterDisplayRow == e1.target.value &&
        e.parameterDisplayCol == temp[idx].parameterDisplayCol
    );
    if (index != -1) {
      temp[idx].parameterDisplayRow = 0;
      setParameterDisplayColError("Duplicate Value");
    }
    if (e1.target.value - idx != 1) {
      setAttributeDisplayRowNumberError("Invliad Format");
    } else {
      setParameterDisplayColError("");
      setAttributeDisplayRowNumberError("");
      temp[idx].parameterDisplayRow = e1.target.value;
      setAdd_Sub_Failure_Parameters_Master(temp);
    }
  };
  const AddTheFailureParametersMaster = (_action) => {
    for (let i = 0; i < add_Sub_Failure_Parameters_Master.length; i++) {
      let data = {
        formDbId: Number(inspectionParameterObject.formName),
        parameterCode: inspectionParameterObject.parameterCode,
        parameterQuestion:
          add_Sub_Failure_Parameters_Master[i].parameterQuestion,
        parameterValue: add_Sub_Failure_Parameters_Master[i].parameterValue,
        parameterValueType:
          add_Sub_Failure_Parameters_Master[i].parameterValueType,
        displayControl: add_Sub_Failure_Parameters_Master[i].displayControl,
        controlLength: add_Sub_Failure_Parameters_Master[i].controlLength,
        isParamMandatory: add_Sub_Failure_Parameters_Master[i].isParamMandatory,
        parameterDisplayCol: Number(
          add_Sub_Failure_Parameters_Master[i].parameterDisplayCol
        ),
        parameterDisplayRow: Number(
          add_Sub_Failure_Parameters_Master[i].parameterDisplayRow
        ),
        effDateFrom: inspectionParameterObject.effDateFrom,
        effDateTo: inspectionParameterObject.effDateTo,
        createdBy: isAuthenticated == true ? user.name : null,
        createdTimestamp: new Date().toISOString(),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      axios
        .post(
          process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
          data
        )
        .then((response) => {
          if (response.status === 201) {
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Post",
              "FailureParametersMaster"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              selected_form_id,
              "Post",
              "InspectionParameter"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
  };
  const ResetTheFailureValue = (idx) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.find((e) => e.idx == idx).parameterQuestion = "";
    temp.find((e) => e.idx == idx).parameterValue = "";
    temp.find((e) => e.idx == idx).parameterValueType = "";
    temp.find((e) => e.idx == idx).displayControl = "";
    temp.find((e) => e.idx == idx).controlLength = "";
    temp.find((e) => e.idx == idx).isParamMandatory = "";
    temp.find((e) => e.idx == idx).parameterDisplayCol = "";
    temp.find((e) => e.idx == idx).parameterDisplayRow = "";
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const deleteurl =
    process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster";

  const RemoveTheFailureParameter = (_action, index) => {
    console.log("action" + _action);
    axios
      .patch(deleteurl + "/" + _action, {
        updatedTimestamp: new Date().toISOString(),
        effDateTo: OneBack(),
        updatedBy: isAuthenticated == true ? user.name : "",
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("put:" + response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
    let temp = [...displayEditFailureParameters];
    temp.splice(index, 1);
    setdisplayEditFailureParameters(temp);
  };

  const RemoveTheSubForm = (index) => {
    let temp = [...add_Sub_Failure_Parameters_Master];
    temp.splice(index, 1);
    setAdd_Sub_Failure_Parameters_Master(temp);
  };
  const OnChangeEditModalValues = (purpose, e, index) => {
    let tempFunction = [];
    for (let i = 0; i < displayEditFailureParameters.length; i++) {
      tempFunction.push(displayEditFailureParameters[i]);
    }
    console.log(tempFunction[index]);
    let newObj = {
      // failParameterDbId:tempFunction[index].failParameterDbId,
      approvedBy: tempFunction[index].approvedBy,
      approvedTimestamp: tempFunction[index].approvedTimestamp,
      controlLength: tempFunction[index].controlLength,
      createdBy: tempFunction[index].createdBy,
      createdTimestamp: tempFunction[index].createdTimestamp,
      displayControl: tempFunction[index].displayControl,
      effDateFrom: tempFunction[index].effDateFrom,
      effDateTo: tempFunction[index].effDateTo,
      failParameterDbId: tempFunction[index].failParameterDbId,
      formDbId: tempFunction[index].formDbId,
      isParamMandatory: tempFunction[index].isParamMandatory,
      parameterCode: tempFunction[index].parameterCode,
      parameterDisplayCol: tempFunction[index].parameterDisplayCol,
      parameterDisplayRow: tempFunction[index].parameterDisplayRow,
      parameterQuestion: tempFunction[index].parameterQuestion,
      parameterValue: tempFunction[index].parameterValue,
      parameterValueType: tempFunction[index].parameterValueType,
      updatedBy: tempFunction[index].updatedBy,
      updatedTimestamp: tempFunction[index].updatedTimestamp,
    };
    switch (purpose) {
      case "ParameterQuestion":
        newObj["parameterQuestion"] = e.target.value;
        break;
      case "ParameterValue":
        newObj["parameterValue"] = e.target.value;
        break;
      case "ParameterValueType":
        newObj["parameterValueType"] = e.target.value;
        break;
      case "IsParamMandatory":
        newObj["isParamMandatory"] = e.target.value;
        break;
      case "ControlLength":
        newObj["controlLength"] = e.target.value;
        break;
      case "DisplayControl":
        newObj["displayControl"] = e.target.value;
        break;
      case "ParameterDisplayCol":
        newObj["parameterDisplayCol"] = e.target.value;
        break;
      case "ParameterDisplayRow":
        newObj["parameterDisplayRow"] = e.target.value;
        break;
    }
    tempFunction[index] = newObj;
    setdisplayEditFailureParameters(tempFunction);
  };

  const UpdateTheFailureParametersMaster = (_action) => {
    console.log("action" + _action);
    for (let i = 0; i < displayEditFailureParameters.length; i++) {
      let data = {

        parameterQuestion: displayEditFailureParameters[i].parameterQuestion,
        parameterValue: displayEditFailureParameters[i].parameterValue,
        parameterValueType: displayEditFailureParameters[i].parameterValueType,
        displayControl: displayEditFailureParameters[i].displayControl,
        controlLength: displayEditFailureParameters[i].controlLength,
        isParamMandatory: displayEditFailureParameters[i].isParamMandatory,
        parameterDisplayCol: Number(
          displayEditFailureParameters[i].parameterDisplayCol
        ),
        parameterDisplayRow: Number(
          displayEditFailureParameters[i].parameterDisplayRow
        ),
        effDateFrom: GetServerDate(inspectionParameterObject.effDateFrom),
        effDateTo: GetServerDate(inspectionParameterObject.effDateTo),
        updatedBy: isAuthenticated ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      axios
        .patch(
          `${process.env.REACT_APP_BASE_API_PYTHON}failureParametersMaster/${displayEditFailureParameters[i].failParameterDbId}`,
          data
        )
        .then((response) => {
          if (response.status === 200) {
            set_open_add_modal(false);
            FetchTheData(
              `${process.env.REACT_APP_BASE_API_PYTHON}failureParametersMaster`,
              "Patch",
              "FailureParametersMaster"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              selected_form_id,
              "Patch",
              "InspectionParameter"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
    for (let i = 0; i < displayNewAddFailureParametersMaster.length; i++) {
      let data = {
        formDbId: Number(inspectionParameterObject.formName),
        parameterCode: inspectionParameterObject.parameterCode,
        parameterQuestion:
          displayNewAddFailureParametersMaster[i].parameterQuestion,
        parameterValue: displayNewAddFailureParametersMaster[i].parameterValue,
        parameterValueType:
          displayNewAddFailureParametersMaster[i].parameterValueType,
        displayControl: displayNewAddFailureParametersMaster[i].displayControl,
        controlLength: displayNewAddFailureParametersMaster[i].controlLength,
        isParamMandatory:
          displayNewAddFailureParametersMaster[i].isParamMandatory,
        parameterDisplayCol: Number(
          displayNewAddFailureParametersMaster[i].parameterDisplayCol
        ),
        parameterDisplayRow: Number(
          displayNewAddFailureParametersMaster[i].parameterDisplayRow
        ),
        effDateFrom: GetServerDate(inspectionParameterObject.effDateFrom),
        effDateTo: GetServerDate(inspectionParameterObject.effDateTo),
        createdBy: isAuthenticated == true ? user.name : null,
        createdTimestamp: new Date().toISOString(),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      axios
        .post(
          process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
          data
        )
        .then((response) => {
          if (response.status === 201) {
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "failureParametersMaster",
              "Post",
              "FailureParametersMaster"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              `inspectionParametersMaster/formDbId/${selected_form_id}`,
              selected_form_id,
              "Post",
              "InspectionParameter"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
  };
  const [
    displayNewAddFailureParametersMaster,
    setDisplayNewAddFailureParametersMaster,
  ] = useState([
    {
      parameterQuestion: "",
      parameterValue: "",
      parameterValueType: "",
      displayControl: "",
      controlLength: "",
      isParamMandatory: "",
      parameterDisplayCol: 0,
      parameterDisplayRow: 0,
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
    },
  ]);
  const RemoveTheEditSubForm = (index) => {
    let temp = [...displayNewAddFailureParametersMaster];
    temp.splice(index, 1);
    setDisplayNewAddFailureParametersMaster(temp);
  };
  const EditTheFailureParameter = () => {
    let temp = [...displayNewAddFailureParametersMaster];
    temp.push({
      idx: displayNewAddFailureParametersMaster.length + 1,
      parameterQuestion: "",
      parameterValue: "",
      parameterValueType: "",
      displayControl: "",
      controlLength: "",
      isParamMandatory: "",
      parameterDisplayCol: 0,
      parameterDisplayRow: 0,
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
    });
    setDisplayNewAddFailureParametersMaster(temp);
  };
  const onChangeFormNewPostSectionValue = (e, type, idx) => {
    // eslint-disable-next-line default-case
    let temp = [...displayNewAddFailureParametersMaster];
    switch (type) {
      case "ParameterQuestion":
        temp.find((e) => e.idx == idx).parameterQuestion = e.target.value;
        break;
      case "ParameterValue":
        temp.find((e) => e.idx == idx).parameterValue = e.target.value;
        break;
      case "ParameterValueType":
        temp.find((e) => e.idx == idx).parameterValueType = e.target.value;
        break;
      case "ParameterDisplayCol":
        temp.find((e) => e.idx == idx).parameterDisplayCol = e.target.value;
        break;
      case "ParameterDisplayRow":
        temp.find((e) => e.idx == idx).parameterDisplayRow = e.target.value;
        break;
      case "DisplayControl":
        temp.find((e) => e.idx == idx).displayControl = e.target.value;
        break;
      case "ControlLength":
        temp.find((e) => e.idx == idx).controlLength = e.target.value;
        break;
      case "IsParamMandatory":
        temp.find((e) => e.idx == idx).isParamMandatory = e.target.value;
        break;
    }
    setDisplayNewAddFailureParametersMaster(temp);
  };
  const isFormFilled = (index) => {
    let tempCount = 0;
    if (add_Sub_Failure_Parameters_Master[index].parameterValue.length == 0) {
      tempCount += 1;
    }
    if (
      add_Sub_Failure_Parameters_Master[index].parameterValueType.length == 0
    ) {
      tempCount += 1;
    }
    if (
      add_Sub_Failure_Parameters_Master[index].parameterQuestion.length == 0
    ) {
      tempCount += 1;
    }
    if (add_Sub_Failure_Parameters_Master[index].parameterDisplayCol == 0) {
      tempCount += 1;
    }
    if (add_Sub_Failure_Parameters_Master[index].parameterDisplayRow == 0) {
      tempCount += 1;
    }
    if (add_Sub_Failure_Parameters_Master[index].displayControl.length == 0) {
      tempCount += 1;
    }
    if (add_Sub_Failure_Parameters_Master[index].controlLength == 0) {
      tempCount += 1;
    }
    if (add_Sub_Failure_Parameters_Master[index].isParamMandatory <= 0) {
      tempCount += 1;
    }
    return tempCount;
  };
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="flex flex-row w-full">
              <div className="container-fluid">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                        open_add_modal === true ||
                        open_edit_modal === true ||
                        show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                          multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className="green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit
                  "
                  style={{
                    opacity:
                      open_view_modal === true ||
                        open_add_modal === true ||
                        open_edit_modal === true ||
                        show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:hidden xl:hidden lg:hidden flex">
                    <p>Select Checksheet</p>
                  </div>
                  <div className="w-full flex flex-row items-center gap-5">
                    <div className="2xl:flex xl:flex lg:flex hidden whitespace-nowrap">
                      Select Checksheet
                    </div>
                    <select
                      className="searchInput"
                      value={selected_form_id}
                      onChange={(e) => onChangeFormID(e)}
                    >
                      {formMaster.map((item, index) =>
                        item.formDbId != 1 && item.formDbId != 2 ? (
                          <option
                            className=""
                            key={index}
                            value={item.formDbId}
                          >
                            {item.formName}
                          </option>
                        ) : null
                      )}
                    </select>
                  </div>

                  <div className="flex flex-col grid-cols-1">
                    <div className="manageHeader">

                      Manage Inspection Parameters
                    </div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>

                  <div className="flex flex-row w-full justify-between items-center grid-cols-1">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UncheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Inspection Parameters"
                        value={search_content}
                        onChange={(e) => SearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                        open_add_modal === true ||
                        open_edit_modal === true ||
                        show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "8" }} />
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Name")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Parameter
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            {qualityProcess === true ? (
                              <div className="filterContainer">
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="QualityProcess"
                                          type="text"
                                          placeholder="Search"
                                          value={searchQuality}
                                          onChange={(e) =>
                                            onChangeSearchQuality(e)
                                          }
                                        />
                                        <div
                                          id="QualityProcess"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedQualityProccess(
                                              qualityProcessArray
                                            )
                                          }
                                        >
                                          <img
                                            id="QualityProcess"
                                            className="Icons"
                                            src={
                                              qualityProcessArray.length ==
                                                selectedQualityProcess.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="QualityProcess">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {searchQuality.length == 0
                                          ? qualityProcessArray.map(
                                            (item, index) => (
                                              <div
                                                id="QualityProcess"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveQuality(
                                                    item.qaProcessDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="QualityProcess"
                                                  className="Icons"
                                                  src={
                                                    selectedQualityProcess.findIndex(
                                                      (e) =>
                                                        e.qaProcessDbId ===
                                                        item.qaProcessDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="QualityProcess">
                                                  {item.processName}
                                                </p>
                                              </div>
                                            )
                                          )
                                          : searchQuality.length > 0
                                            ? searchQualityArray.map(
                                              (item, index) => (
                                                <div
                                                  id="QualityProcess"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveQuality(
                                                      item.qaProcessDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="QualityProcess"
                                                    className="Icons"
                                                    src={
                                                      selectedQualityProcess.findIndex(
                                                        (e) =>
                                                          e.qaProcessDbId ===
                                                          item.qaProcessDbId
                                                      ) !== -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="QualityProcess">
                                                    {item.processName}
                                                  </p>
                                                </div>
                                              )
                                            )
                                            : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="QualityProcess"
                                        className="popupfile_button"
                                        onClick={() => OnOkQualityProcess()}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="QualityProcess"
                                        className="popupfile_button"
                                        onClick={() => OnPressedClearClicked()}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="IconsSpace">
                              <img
                                id="QualityProcess"
                                className="Icons opacity-0"
                                onClick={() => OnClickQualityProcess()}
                                alt=""
                              />
                              Quality Process
                              <img
                                id="QualityProcess"
                                className="Icons"
                                onClick={() => OnClickQualityProcess()}
                                src={FilterIcon}
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Value </th>
                          <th>Parameter Row</th>
                          <th>Parameter Col</th>

                          <th>CTQ Flag</th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspParameterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                  (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.parameterCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.parameterQuestion}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {/* {qualityProcessMap.length > 0
                              ? qualityProcessMap.find(
                                  (e) => e.qaProcessDbId === item.qaProcessDbId
                                ).processName
                              : null} */}
                                {qualityProcessMap.length > 0 &&
                                  qualityProcessMap.findIndex(
                                    (e) => e.qaProcessDbId == item.qaProcessDbId
                                  ) != -1
                                  ? qualityProcessMap.find(
                                    (e) =>
                                      e.qaProcessDbId == item.qaProcessDbId
                                  ).processName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.parameterValue}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.parameterDisplayRow}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.parameterDisplayCol}
                              </td>
                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.parameterLevel}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {item.ctqFlag === 1 ? "Yes" : "No"}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspParameterDbId
                                  )
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() => {
                                      setParameterCode(item.parameterCode)
                                      OpenModal(
                                        item.inspParameterDbId,
                                        "View",
                                        item.formSectionAttributeDbId
                                      )
                                    }
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() => {
                                      setParameterCode(item.parameterCode)
                                      OpenModal(
                                        item.inspParameterDbId,
                                        "Edit",
                                        item.formSectionAttributeDbId
                                      )
                                    }
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.inspParameterDbId
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                          : show_drafts === true
                            ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspParameterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {item.parameterCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {item.parameterQuestion}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {/* {qualityProcessMap.length > 0
                              ? qualityProcessMap.find(
                                  (e) => e.qaProcessDbId === item.qaProcessDbId
                                ).processName
                              : null} */}
                                  {qualityProcessMap.length > 0 &&
                                    qualityProcessMap.findIndex(
                                      (e) => e.qaProcessDbId == item.qaProcessDbId
                                    ) != -1
                                    ? qualityProcessMap.find(
                                      (e) =>
                                        e.qaProcessDbId == item.qaProcessDbId
                                    ).processName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {item.parameterValue}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {item.parameterDisplayRow}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {item.parameterDisplayCol}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {item.ctqFlag === 1 ? "Yes" : "No"}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspParameterDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(
                                          item.inspParameterDbId,
                                          "View",
                                          item.formSectionAttributeDbId
                                        )
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(
                                          item.inspParameterDbId,
                                          "Edit",
                                          item.formSectionAttributeDbId
                                        )
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.inspParameterDbId
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                            : search_content.length > 0
                              ? paginatedArray.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspParameterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {item.parameterCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {item.parameterQuestion}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {qualityProcessMap.length > 0 &&
                                      qualityProcessMap.findIndex(
                                        (e) => e.qaProcessDbId == item.qaProcessDbId
                                      ) != -1
                                      ? qualityProcessMap.find(
                                        (e) =>
                                          e.qaProcessDbId == item.qaProcessDbId
                                      ).processName
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {item.parameterValue}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {item.parameterDisplayRow}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {item.parameterDisplayCol}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {item.ctqFlag === 1 ? "Yes" : "No"}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(item.effDateFrom).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(item.effDateFrom).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.inspParameterDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.inspParameterDbId
                                      )
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(
                                            item.inspParameterDbId,
                                            "View",
                                            item.formSectionAttributeDbId
                                          )
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(
                                            item.inspParameterDbId,
                                            "Edit",
                                            item.formSectionAttributeDbId
                                          )
                                        }
                                        src={EditIcon}
                                        alt=""
                                      />
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          ShowDeleteConfirmation(
                                            item.inspParameterDbId
                                          )
                                        }
                                        src={DeleteIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))
                              : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>

                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <div className="addTheFormSplits">
                    <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={
                            current_page.current == 1
                              ? PreviousHiddenIcon
                              : PreviousIcon
                          }
                          onClick={() => onPressPrevious()}
                          className="PreviouesNextIcons"
                          alt=""
                          style={{
                            cursor:
                              current_page.current == 1 ? "auto" : "pointer",
                          }}
                        />
                        <img
                          src={
                            current_page.current == total_pages.current
                              ? NextHiddenIcon
                              : NextIcon
                          }
                          onClick={() => onPressNext()}
                          className="PreviouesNextIcons"
                          style={{
                            cursor:
                              current_page.current == total_pages.current
                                ? "auto"
                                : "pointer",
                          }}
                          alt=""
                        />
                      </div>
                      <p>
                        Page {current_page.current} of{" "}
                        {paginatedArray.length === 0
                          ? current_page.current
                          : total_pages.current}
                      </p>
                    </div>
                    <div className="specebutton">
                      <button
                        className="addBtn"
                        onClick={() => onClickAddItem()}
                      >
                        Add
                      </button>
                      <button className="uploadBtn">Upload</button>
                      <button
                        className="deleteBtn"
                        onClick={() => OnDeleteSelectionClicked()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {/* //View box */}
                {open_view_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      View Inspection Parameter Details
                    </div>
                    <div className="inputOverflow">
                      <div className="flex flex-col gap-5">
                        <div className="additionalHeader">
                          View Inspection Parameter
                        </div>
                        <div className="inputGrid">
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">Param. Code</label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={inspectionParameterObject.parameterCode}
                              className="child_input"
                            />
                          </div>
                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Param. Level
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={inspectionParameterObject.parameterLevel}
                                className="child_input"
                              />
                            </div> : null}
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Question
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.parameterQuestion
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Display Control
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.displayControl ===
                                  "barcode"
                                  ? "Barcode"
                                  : inspectionParameterObject.displayControl ===
                                    "camera"
                                    ? "Camera"
                                    : inspectionParameterObject.displayControl ===
                                      "checkbox"
                                      ? "Checkbox"
                                      : inspectionParameterObject.displayControl ===
                                        "dropdown"
                                        ? "Dropdown"
                                        : inspectionParameterObject.displayControl ===
                                          "formula"
                                          ? "Formula"
                                          : inspectionParameterObject.displayControl ===
                                            "incrementdecrement"
                                            ? "Increment Decrement"
                                            : inspectionParameterObject.displayControl ===
                                              " longnumberbox"
                                              ? "Long Numberbox"
                                              : inspectionParameterObject.displayControl ===
                                                "paragraph"
                                                ? "Paragraph"
                                                : inspectionParameterObject.displayControl ===
                                                  "radiobutton"
                                                  ? "Radio Button"
                                                  : inspectionParameterObject.displayControl ===
                                                    "subheading"
                                                    ? "Subheading"
                                                    : inspectionParameterObject.displayControl ===
                                                      "textbox"
                                                      ? "Textbox"
                                                      : inspectionParameterObject.displayControl === "datetime"
                                                        ? "Datetime" : inspectionParameterObject.displayControl === "date"
                                                          ? "Date" : inspectionParameterObject.displayControl === "time"
                                                            ? "Time" : inspectionParameterObject.displayControl === "elapsedtime"
                                                              ? "Elapsed Time" : null
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Value Type
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.parameterValueType ===
                                  "formula"
                                  ? "Formula"
                                  : inspectionParameterObject.parameterValueType ===
                                    "variable"
                                    ? " Platform Variable"
                                    : inspectionParameterObject.parameterValueType ===
                                      "sql"
                                      ? "SQL"
                                      : inspectionParameterObject.parameterValueType ===
                                        "static"
                                        ? "Static"
                                        : inspectionParameterObject.parameterValueType ===
                                          "input"
                                          ? "User Input"
                                          : null
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Value
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={inspectionParameterObject.parameterValue}
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Is Param. Mandatory?
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.parameterMandatory == "N"
                                  ? "No"
                                  : "Yes"
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Display Row
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.parameterDisplayRow
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Display Col
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.parameterDisplayCol
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">CTQ Flag</label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                inspectionParameterObject.ctqFlag === false
                                  ? "No"
                                  : "Yes"
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Quality Process
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                qualityProcessMap.find(
                                  (e) =>
                                    e.qaProcessDbId ===
                                    inspectionParameterObject.qaProcessName
                                ).processName
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">Forms</label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                formMaster.find(
                                  (e) =>
                                    e.formDbId ===
                                    inspectionParameterObject.formName
                                ).formName
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Eff. Date From
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                new Date(
                                  inspectionParameterObject.effDateFrom
                                ).getUTCFullYear() +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(
                                      inspectionParameterObject.effDateFrom
                                    ).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                ReturnThePaddedValues(
                                  new Date(
                                    inspectionParameterObject.effDateFrom
                                  ).getUTCDate()
                                )
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Eff. Date To
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={
                                new Date(
                                  inspectionParameterObject.effDateTo
                                ).getUTCFullYear() +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(
                                      inspectionParameterObject.effDateTo
                                    ).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                ReturnThePaddedValues(
                                  new Date(
                                    inspectionParameterObject.effDateTo
                                  ).getUTCDate()
                                )
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Tolerance Type
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={inspectionParameterObject.toleranceType}
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Variance Type
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={inspectionParameterObject.varianceType}
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Allowed Variance
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={inspectionParameterObject.allowedVariance}
                              className="child_input"
                            />
                          </div>

                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Control Length
                              </label>
                            </div>
                            <input
                              type="text"
                              disabled={true}
                              value={inspectionParameterObject.controlLength}
                              className="child_input"
                            />
                          </div>
                          {selected_form_id == 7 ?

                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Parent Param. Level
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={inspectionParameterObject.parentInspParameterCode}
                                className="child_input"
                              />
                            </div> : null}
                          {selected_form_id == 7 ?

                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Parent Option
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={inspectionParameterObject.parentOption}
                                className="child_input"
                              />
                            </div> : null}
                        </div>
                      </div>

                      <div className="flex flex-col gap-5">
                        <div className="additionalHeader">View Sub Forms</div>
                        {
                          displayViewFormDbIdArray.map((item, index) =>
                            <div key={index} className="flex flex-col gap-4 border-dark-default border-dotted p-2 border-2">
                              <div className="inputGrid">
                                {selected_form_id == 7 ?
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Param. Level
                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      disabled={true}
                                      value={item.parameterLevel}
                                      className="child_input"
                                    />
                                  </div> : null}
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Question
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterQuestion
                                    }
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Display Control
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.displayControl ===
                                        "barcode"
                                        ? "Barcode"
                                        : item.displayControl ===
                                          "camera"
                                          ? "Camera"
                                          : item.displayControl ===
                                            "checkbox"
                                            ? "Checkbox"
                                            : item.displayControl ===
                                              "dropdown"
                                              ? "Dropdown"
                                              : item.displayControl ===
                                                "formula"
                                                ? "Formula"
                                                : item.displayControl ===
                                                  "incrementdecrement"
                                                  ? "Increment Decrement"
                                                  : item.displayControl ===
                                                    " longnumberbox"
                                                    ? "Long Numberbox"
                                                    : item.displayControl ===
                                                      "paragraph"
                                                      ? "Paragraph"
                                                      : item.displayControl ===
                                                        "radiobutton"
                                                        ? "Radio Button"
                                                        : item.displayControl ===
                                                          "subheading"
                                                          ? "Subheading"
                                                          : item.displayControl ===
                                                            "textbox"
                                                            ? "Textbox"
                                                            : item.displayControl === "datetime"
                                                              ? "Datetime" : item.displayControl === "date"
                                                                ? "Date" : item.displayControl === "time"
                                                                  ? "Time" : item.displayControl === "elapsedtime"
                                                                    ? "Elapsed Time" : null
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Value Type
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterValueType ===
                                        "formula"
                                        ? "Formula"
                                        : item.parameterValueType ===
                                          "variable"
                                          ? " Platform Variable"
                                          : item.parameterValueType ===
                                            "sql"
                                            ? "SQL"
                                            : item.parameterValueType ===
                                              "static"
                                              ? "Static"
                                              : item.parameterValueType ===
                                                "input"
                                                ? "User Input"
                                                : null
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Value
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.parameterValue}
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Is Param. Mandatory?
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterMandatory == "N"
                                        ? "No"
                                        : "Yes"
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Display Row
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterDisplayRow
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Display Col
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterDisplayCol
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">CTQ Flag</label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.ctqFlag === false
                                        ? "No"
                                        : "Yes"
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Quality Process
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      qualityProcessMap.find(
                                        (e) =>
                                          e.qaProcessDbId ===
                                          item.qaProcessDbId
                                      ).processName
                                    }
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date From
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      new Date(
                                        item.effDateFrom
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          item.effDateFrom
                                        ).getUTCDate()
                                      )
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date To
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      new Date(
                                        item.effDateTo
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.effDateTo
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          item.effDateTo
                                        ).getUTCDate()
                                      )
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Tolerance Type
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.toleranceType}
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Variance Type
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.varianceType}
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Allowed Variance
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.allowedVariance}
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Control Length
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.controlLength}
                                    className="child_input"
                                  />
                                </div>
                                {selected_form_id == 7 ?

                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Parent Param. Level
                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      disabled={true}
                                      value={item.parentInspParameter}
                                      className="child_input"
                                    />
                                  </div> : null}
                                {selected_form_id == 7 ?

                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Parent Option
                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      disabled={true}
                                      value={item.parentOption}
                                      className="child_input"
                                    />
                                  </div> : null}
                              </div>
                            </div>

                          )
                        }
                        <div className="flex flex-col gap-5">
                          {displayViewFailureParameters.map((item, index) => (
                            <div
                              key={index}
                              className="flex flex-col gap-4 border-dark-default border-dotted p-2 border-2"
                            >
                              <div className="inputGrid">
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Question
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.parameterQuestion}
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Param. Value
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterValue === "$defect_desc"
                                        ? "Defect Description"
                                        : item.parameterValue ===
                                          "select failure_db_id as id,failure_code as code from failure_codes_dictionary where defect_db_id=$defect_db_id"
                                          ? "Select FailureDbId as Id,Failure Code as code from Failure_Code_Dictionary Where DefectDbId=$ DefectDbId"
                                          : item.parameterValue ===
                                            "select severity_db_id as id,severity_code as code from severity_codes_dictionary"
                                            ? "Select severityDbId as Id,Severity_code as code from Severity_Codes_Dictionary"
                                            : item.parameterValue ===
                                              "Rework | Scrap"
                                              ? "Rework | Scrap"
                                              : null
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Value Type
                                    </label>
                                    <label className="error"></label>
                                  </div>

                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.parameterValueType === "formula"
                                        ? "Formula"
                                        : item.parameterValueType === "variable"
                                          ? " Platform Variable"
                                          : item.parameterValueType === "sql"
                                            ? "SQL"
                                            : item.parameterValueType === "static"
                                              ? "Static"
                                              : item.parameterValueType === "input"
                                                ? "User Input"
                                                : null
                                    }
                                    className="child_input"
                                  />
                                </div>{" "}
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Display Control
                                    </label>
                                    <label className="error"></label>
                                  </div>

                                  <input
                                    type="text"
                                    disabled={true}
                                    value={
                                      item.displayControl === "barcode"
                                        ? "Barcode"
                                        : item.displayControl === "camera"
                                          ? "Camera"
                                          : item.displayControl === "checkbox"
                                            ? "Checkbox"
                                            : item.displayControl === "dropdown"
                                              ? "Dropdown"
                                              : item.displayControl === "formula"
                                                ? "Formula"
                                                : item.displayControl ===
                                                  "incrementdecrement"
                                                  ? "Increment Decrement"
                                                  : item.displayControl ===
                                                    " longnumberbox"
                                                    ? "Long Numberbox"
                                                    : item.displayControl === "paragraph"
                                                      ? "Paragraph"
                                                      : item.displayControl === "radiobutton"
                                                        ? "Radio Button"
                                                        : item.displayControl === "subheading"
                                                          ? "Subheading"
                                                          : item.displayControl === "textbox"
                                                            ? "Textbox"
                                                            : null
                                    }
                                    className="child_input"
                                  />
                                </div>{" "}
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Control Length
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="text"
                                    disabled={true}
                                    value={item.controlLength}
                                    className="child_input"
                                  />
                                </div>{" "}
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Is Param. Mandatory?
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    className="child_input"
                                    disabled={true}
                                    value={
                                      item.isParamMandatory === "N"
                                        ? "No"
                                        : "Yes"
                                    }
                                  />
                                </div>{" "}
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Param. Display Row
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="text"
                                    value={item.parameterDisplayRow}
                                    disabled={true}
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Param. Display Col
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="text"
                                    value={item.parameterDisplayCol}
                                    disabled={true}
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date From
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="date"
                                    value={
                                      new Date(
                                        inspectionParameterObject.effDateFrom
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            inspectionParameterObject.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          inspectionParameterObject.effDateFrom
                                        ).getUTCDate()
                                      )
                                    }
                                    disabled={true}
                                    className="child_input"
                                  ></input>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date To
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <input
                                    type="date"
                                    value={
                                      new Date(
                                        inspectionParameterObject.effDateTo
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            inspectionParameterObject.effDateTo
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          inspectionParameterObject.effDateTo
                                        ).getUTCDate()
                                      )
                                    }
                                    disabled={true}
                                    className="child_input"
                                  ></input>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="editButton"
                          onClick={() => OpenModal(selectedInspDbId, "Edit")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Edit Popup column */}
                {open_edit_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Edit Inspection Parameter Details
                    </div>
                    <div className="inputOverflow">
                      <div className="flex flex-col gap-5">
                        <div className="additionalHeader">
                          Edit the Inspection Parameter
                        </div>
                        <div className="inputGrid">
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">Param. Code</label>
                              <label className="error">
                              
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.parameterCode}
                              onChange={(e) =>
                                onChangeValue(e, "parameterCode", null)
                              }
                              className="child_input"
                            />
                          </div>

                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Param. Level
                                </label>
                                <label className="error">
                                 
                                </label>
                              </div>
                              <input
                                type="text"
                                value={inspectionParameterObject.parameterLevel}
                                onChange={(e) =>
                                  onChangeValue(e, "parameterLevel", null)
                                }
                                className="child_input"
                              />
                            </div>
                            : null}
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Question
                              </label>
                              <label className="error">
                              
                              </label>
                            </div>
                            <input
                              type="text"
                              value={
                                inspectionParameterObject.parameterQuestion
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterQuestion", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Display Control
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.displayControl}
                              onChange={(e) =>
                                onChangeValue(e, "displayControl", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="barcode">Barcode</option>
                              <option value="camera">Camera</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="dropdown">Dropdown</option>
                              <option value="formula">Formula</option>
                              <option value="incrementdecrement">
                                Increment Decrement
                              </option>
                              <option value="longnumberbox">
                                Long Numberbox
                              </option>
                              <option value="paragraph">Paragraph</option>
                              <option value="radiobutton">Radio Button</option>
                              <option value="subheading">Subheading</option>
                              <option value="textbox">Textbox</option>
                              <option value="datetime">Datetime</option>
                              <option value="date">Date</option>
                              <option value="time">Time</option>
                              <option value="elapsedtime">Elapsed Time</option>
                            </select>
                          </div>

                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Param. Value Type
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterValueType
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterValueType", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="formula">Formula</option>
                              <option value="variable">
                                Platform Variable
                              </option>
                              <option value="sql">SQL</option>
                              <option value="static">Static</option>
                              <option value="input">User Input</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Param. Value
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.parameterValue}
                              onChange={(e) =>
                                onChangeValue(e, "parameterValue", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Display Row
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <input
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterDisplayRow
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterDisplayRow", null)
                              }
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Display Col
                              </label>
                              <label className="error">
                              
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterDisplayCol
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterDisplayCol", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                            </select>
                          </div>

                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Is Param. Mandatory?
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterMandatory
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterMandatory", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Quality Process
                              </label>
                              <label className="error">
                              
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.qaProcessName}
                              onChange={(e) =>
                                onChangeValue(e, "qaProcessName ", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              {qualityProcessMap.length > 0
                                ? qualityProcessMap.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.qaProcessDbId}
                                  >
                                    {item.processName}
                                  </option>
                                ))
                                : null}
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">Forms</label>
                              <label className="error">
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.formName}
                              onChange={(e) =>
                                onChangeValue(e, "formName", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              {formMaster.length > 0
                                ? formMaster.map((item, index) =>
                                  item.formDbId != 1 && item.formDbId != 2 ? (
                                    <option key={index} value={item.formDbId}>
                                      {item.formName}
                                    </option>
                                  ) : null
                                )
                                : null}
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">CTQ Flag</label>
                              <label className="error">
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.ctqFlag}
                              onChange={(e) =>
                                onChangeValue(e, "ctqFlag", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Tolerance Type
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.toleranceType}
                              onChange={(e) =>
                                onChangeValue(e, "toleranceType", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Variance Type{" "}
                              </label>
                              <label className="error">
                            
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.varianceType}
                              onChange={(e) =>
                                onChangeValue(e, "varianceType", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="=">=</option>
                              <option value="+">+</option>
                              <option value="-">-</option>
                              <option value="+/-">+/-</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Allowed Variance
                              </label>
                              <label className="error">
                              
                              </label>
                            </div>
                            <input
                              type="text"
                              placeholder="00.00"
                              value={inspectionParameterObject.allowedVariance}
                              onChange={(e) =>
                                onChangeValue(e, "allowedVariance", null)
                              }
                              className="child_input"
                            />
                          </div>

                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Control Length
                              </label>
                              <label className="error">
                               
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.controlLength}
                              onChange={(e) =>
                                onChangeValue(e, "controlLength", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Eff. Date From
                              </label>
                              <label className="error">
                             
                              </label>
                            </div>
                            <input
                              type="date"
                              value={
                                new Date(
                                  inspectionParameterObject.effDateFrom
                                ).getUTCFullYear() +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(
                                      inspectionParameterObject.effDateFrom
                                    ).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                ReturnThePaddedValues(
                                  new Date(
                                    inspectionParameterObject.effDateFrom
                                  ).getUTCDate()
                                )
                              }
                              onChange={(e) =>
                                onChangeValue(e, "effDateFrom", null)
                              }
                              className="child_input"
                            ></input>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Eff. Date To
                              </label>
                              <label className="error">
                              </label>
                            </div>
                            <input
                              type="date"
                              value={
                                new Date(
                                  inspectionParameterObject.effDateTo
                                ).getUTCFullYear() +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(
                                      inspectionParameterObject.effDateTo
                                    ).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                ReturnThePaddedValues(
                                  new Date(
                                    inspectionParameterObject.effDateTo
                                  ).getUTCDate()
                                )
                              }
                              onChange={(e) =>
                                onChangeValue(e, "effDateTo", null)
                              }
                              className="child_input"
                            ></input>
                          </div>
                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Parent Param. Level
                                </label>
                                <label className="error">
                               
                                </label>
                              </div>
                              <input
                                type="text"
                                value={inspectionParameterObject.parentInspParameterCode}
                                onChange={(e) =>
                                  onChangeValue(e, "parentInspParameterCode", null)
                                }
                                className="child_input"
                              />
                            </div>
                            : null}
                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Parent Option
                                </label>
                                <label className="error">
                                 
                                </label>
                              </div>
                              <input
                                type="text"
                                value={inspectionParameterObject.parentOption}
                                onChange={(e) =>
                                  onChangeValue(e, "parentOption", null)
                                }
                                className="child_input"
                              />
                            </div>
                            : null}
                        </div>
                      </div>
                      <div className="flex flex-col gap-5">
                        <div className="additionalHeader">
                          Edit Sub Forms
                        </div>
                        {displayEditFormDbIdArray.map((item, index) =>
                          <div key={index} className="flex flex-col gap-4 border-dark-default border-dotted p-2 border-2">
                            <div className="inputGrid">
                              {selected_form_id == 7 ?
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Level
                                    </label>
                                  </div>
                                  <select className="child_input"
                                    value={item.parameterLevel}
                                    onChange={(e) =>
                                      OnChangeEditSubInspectionParameter(
                                        "parameterLevel",
                                        e,
                                        index
                                      )
                                    }>
                                    {displayParameterLevelArray.length > 0
                                      ? displayParameterLevelArray.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.parameterLevel}
                                        >
                                          {item}
                                        </option>
                                      ))
                                      : null}
                                  </select>

                                </div> : null}
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Param. Question
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={
                                    item.parameterQuestion
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "parameterQuestion",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Display Control
                                  </label>
                                </div>
                                <select
                                  className="child_input"
                                  value={item.displayControl}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "displayControl",
                                      e,
                                      index
                                    )
                                  }
                                >
                                  <option value={null}>Select...</option>
                                  <option value="barcode">Barcode</option>
                                  <option value="camera">Camera</option>
                                  <option value="checkbox">Checkbox</option>
                                  <option value="dropdown">Dropdown</option>
                                  <option value="formula">Formula</option>
                                  <option value="incrementdecrement">
                                    Increment Decrement
                                  </option>
                                  <option value="longnumberbox">
                                    Long Numberbox
                                  </option>
                                  <option value="paragraph">Paragraph</option>
                                  <option value="radiobutton">Radio Button</option>
                                  <option value="subheading">Subheading</option>
                                  <option value="textbox">Textbox</option>
                                  <option value="datetime">Datetime</option>
                                  <option value="date">Date</option>
                                  <option value="time">Time</option>
                                  <option value="elapsedtime">Elapsed Time</option>
                                </select>
                              </div>

                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Param. Value Type
                                  </label>
                                </div>
                                <select
                                  className="child_input"
                                  value={
                                    item.parameterValueType
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "parameterValueType",
                                      e,
                                      index
                                    )
                                  }
                                >
                                  <option value={null}>Select...</option>
                                  <option value="formula">Formula</option>
                                  <option value="variable">
                                    Platform Variable
                                  </option>
                                  <option value="sql">SQL</option>
                                  <option value="static">Static</option>
                                  <option value="input">User Input</option>
                                </select>
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Param. Value
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={item.parameterValue}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "parameterValue",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>

                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Is Param. Mandatory?
                                  </label>
                                </div>
                                <select
                                  className="child_input"
                                  value={
                                    item.isParamMandatory
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "parameterMandatory",
                                      e,
                                      index
                                    )
                                  }
                                >
                                  <option value={null}>Select...</option>
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </select>
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Param. Display Row
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={
                                    item.parameterDisplayRow
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "parameterDisplayRow",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Param. Display Col
                                  </label>
                                </div>

                                <select
                                  className="child_input"
                                  value={item.parameterDisplayCol
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "parameterDisplayCol",
                                      e,
                                      index
                                    )
                                  }
                                >
                                  <option value={null}>Select...</option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                </select>

                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">CTQ Flag</label>
                                </div>
                                <select
                                  className="child_input"
                                  value={item.ctqFlag}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "ctqFlag",
                                      e,
                                      index
                                    )
                                  }
                                >
                                  <option value={null}>Select...</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>

                                {/* <input
                                  type="text"

                                  value={
                                    item.ctqFlag === false
                                      ? "No"
                                      : "Yes"
                                  }
                             
                                  className="child_input"
                                /> */}
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Quality Process
                                  </label>
                                </div>
                                <select
                                  type="text"

                                  value={

                                    item.qaProcessDbId
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "qaProcessName",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>Select...</option>
                                  {qualityProcessMap.length > 0
                                    ? qualityProcessMap.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.qaProcessDbId}
                                      >
                                        {item.processName}
                                      </option>
                                    ))
                                    : null}
                                </select>
                              </div>
                              {/* <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">Forms</label>
                                </div>
                                <select
                                  type="text"

                                  value={

                                    item.formDbId

                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "formName",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                >
                                  <option value={null}>Select...</option>
                                  {formMaster.length > 0
                                    ? formMaster.map((item, index) =>
                                      item.formDbId != 1 && item.formDbId != 2 ? (
                                        <option key={index} value={item.formDbId}>
                                          {item.formName}
                                        </option>
                                      ) : null
                                    )
                                    : null}
                                </select>
                              </div> */}
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Eff. Date From
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={
                                    new Date(
                                      item.effDateFrom
                                    ).getUTCFullYear() +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(
                                          item.effDateFrom
                                        ).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      new Date(
                                        item.effDateFrom
                                      ).getUTCDate()
                                    )
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "effDateFrom",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Eff. Date To
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={
                                    new Date(
                                      item.effDateTo
                                    ).getUTCFullYear() +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(
                                          item.effDateTo
                                        ).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      new Date(
                                        item.effDateTo
                                      ).getUTCDate()
                                    )
                                  }
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "effDateTo",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Tolerance Type
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={item.toleranceType}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "toleranceType",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Variance Type
                                  </label>
                                </div>

                                <select
                                  className="child_input"
                                  value={item.varianceType}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "varianceType",
                                      e,
                                      index
                                    )
                                  }
                                >
                                  <option value={""}>Select...</option>
                                  <option value="=">=</option>
                                  <option value="+">+</option>
                                  <option value="-">-</option>
                                  <option value="+/-">+/-</option>
                                  <option value="%">%</option>
                                </select>

                              </div>
                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Allowed Variance
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={item.allowedVariance}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "allowedVariance",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>

                              <div className="parent_popup">
                                <div className="child_label_flex">
                                  <label className="child_popup">
                                    Control Length
                                  </label>
                                </div>
                                <input
                                  type="text"

                                  value={item.controlLength}
                                  onChange={(e) =>
                                    OnChangeEditSubInspectionParameter(
                                      "controlLength",
                                      e,
                                      index
                                    )
                                  }
                                  className="child_input"
                                />
                              </div>
                              {selected_form_id == 7 ?

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Parent Param. Level
                                    </label>
                                  </div>
                                  <input
                                    type="text"

                                    value={inspectionParameterObject.parameterCode+" | "+index}
                                    onChange={(e) =>
                                      OnChangeEditSubInspectionParameter(
                                        "parentInspParameterCode",
                                        e,
                                        index
                                      )
                                    }
                                    className="child_input"
                                  />
                                </div> : null}
                              {selected_form_id == 7 ?

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Parent Option
                                    </label>
                                  </div>
                                  <input
                                    type="text"

                                    value={item.parentOption}
                                    onChange={(e) =>
                                      OnChangeEditSubInspectionParameter(
                                        "parentOption",
                                        e,
                                        index
                                      )
                                    }
                                    className="child_input"
                                  />
                                </div> : null}
                            </div>
                            <div className="flex flex-wrap flex-row items-center justify-center gap-5 ">
                              <div className="flex flex-row items-center gap-5">
                                <img
                                  onClick={() =>
                                    RemoveTheInspectionParameter(
                                      item.inspParameterDbId,
                                      index
                                    )
                                  }
                                  className="Icons"
                                  src={DeleteIcon}
                                />
                              </div>
                            </div>
                          </div>

                        )
                        }
                        <div className="flex flex-col gap-5">
                          {displayEditFailureParameters.map((item, index) =>
                            new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                              <div className="flex flex-col gap-4 border-2 border-dotted p-2 border-dark-default">
                                <div className="inputGrid">
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Param. Question
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <input
                                      type="text"
                                      value={item.parameterQuestion}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "ParameterQuestion",
                                          e,
                                          index
                                        )
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Param. Value Type
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={item.parameterValueType}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "ParameterValueType",
                                          e,
                                          index
                                        )
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="formula">Formula</option>
                                      <option value="variable">
                                        Platform Variable
                                      </option>
                                      <option value="sql">SQL</option>
                                      <option value="static">Static</option>
                                      <option value="input">User Input</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Param. Value
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    {item.parameterValueType === "variable" ? (
                                      <select
                                        value={item.parameterValue}
                                        onChange={(e) =>
                                          OnChangeEditModalValues(
                                            "ParameterValue",
                                            e,
                                            index
                                          )
                                        }
                                        className="child_input"
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="$defect_desc">
                                          Defect Description
                                        </option>
                                      </select>
                                    ) : (
                                      <input
                                        type="text"
                                        value={item.parameterValue}
                                        onChange={(e) =>
                                          OnChangeEditModalValues(
                                            "ParameterValue",
                                            e,
                                            index
                                          )
                                        }
                                        className="child_input"
                                      />
                                    )}
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Display Control
                                      </label>
                                      <label className="error"></label>
                                    </div>

                                    <select
                                      className="child_input"
                                      value={item.displayControl}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "DisplayControl",
                                          e,
                                          index
                                        )
                                      }
                                    >
                                      <option value={null}>
                                        Select Display Control
                                      </option>
                                      <option value="radiobutton">
                                        Radio Button
                                      </option>
                                      <option value="dropdown">Dropdown</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="subheading">
                                        Subheading
                                      </option>
                                      <option value="paragraph">
                                        Paragraph
                                      </option>
                                      <option value="longnumberbox">
                                        Long Numberbox
                                      </option>
                                      <option value="textbox">Textbox</option>
                                      <option value="barcode">Barcode</option>
                                      <option value="camera">Camera</option>
                                      <option value="formula">Formula</option>
                                      <option value="incrementdecrement">
                                        Increment Decrement
                                      </option>
                                    </select>
                                  </div>{" "}
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Control Length
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <input
                                      type="text"
                                      value={item.controlLength}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "ControlLength",
                                          e,
                                          index
                                        )
                                      }
                                      className="child_input"
                                    />
                                  </div>{" "}
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Is Param. Mandatory?
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={item.isParamMandatory}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "IsParamMandatory",
                                          e,
                                          index
                                        )
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="Y">Y</option>
                                      <option value="N">N</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Param. Display Row
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <input
                                      type="text"
                                      value={item.parameterDisplayRow}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "ParameterDisplayRow",
                                          e,
                                          index
                                        )
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Param. Display Col
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={item.parameterDisplayCol}
                                      onChange={(e) =>
                                        OnChangeEditModalValues(
                                          "ParameterDisplayCol",
                                          e,
                                          index
                                        )
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                    </select>
                                  </div>{" "}
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Eff. Date From
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <input
                                      type="date"
                                      value={
                                        new Date(
                                          inspectionParameterObject.effDateFrom
                                        ).getUTCFullYear() +
                                        "-" +
                                        ReturnThePaddedValues(
                                          Number(
                                            new Date(
                                              inspectionParameterObject.effDateFrom
                                            ).getUTCMonth()
                                          ) + 1
                                        ) +
                                        "-" +
                                        ReturnThePaddedValues(
                                          new Date(
                                            inspectionParameterObject.effDateFrom
                                          ).getUTCDate()
                                        )
                                      }
                                      onChange={(e) =>
                                        onChangeValue(e, "effDateFrom", null)
                                      }
                                      className="child_input"
                                    ></input>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Eff. Date To
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <input
                                      type="date"
                                      value={
                                        new Date(
                                          inspectionParameterObject.effDateTo
                                        ).getUTCFullYear() +
                                        "-" +
                                        ReturnThePaddedValues(
                                          Number(
                                            new Date(
                                              inspectionParameterObject.effDateTo
                                            ).getUTCMonth()
                                          ) + 1
                                        ) +
                                        "-" +
                                        ReturnThePaddedValues(
                                          new Date(
                                            inspectionParameterObject.effDateTo
                                          ).getUTCDate()
                                        )
                                      }
                                      onChange={(e) =>
                                        onChangeValue(e, "effDateTo", null)
                                      }
                                      className="child_input"
                                    ></input>
                                  </div>
                                </div>
                                <div className="flex flex-wrap flex-row items-center justify-center gap-5 ">
                                  <div className="flex flex-row items-center gap-5">
                                    <img
                                      onClick={() =>
                                        RemoveTheFailureParameter(
                                          item.failParameterDbId,
                                          index
                                        )
                                      }
                                      className="Icons"
                                      src={DeleteIcon}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                        {selected_form_id == 7 ?
                          <div className="flex 2xl:flex-row xl:flex-row additionalHeader lg:flex-row flex-col items-center justify-center gap-3">
                            <div className="additionalHeader">
                              Add sub from another level ?
                            </div>
                            <div className="flex flex-row items-center gap-5">
                              <div
                                className="flex flex-row items-center gap-1 cursor-pointer"
                                onClick={() => OnClickInspectionSubForm()}
                              >
                                <>Yes</>
                                <img
                                  className="Icons"
                                  src={
                                    openInspectionSubForm == true
                                      ? RadioOnIcon
                                      : RadioOffIcon
                                  }
                                />
                              </div>
                              <div
                                onClick={() => setOpenInspectionSubForm(false)}
                                className="flex flex-row items-center gap-1 cursor-pointer"
                              >
                                <p>No</p>
                                <img
                                  className="Icons"
                                  src={
                                    openInspectionSubForm == false
                                      ? RadioOnIcon
                                      : RadioOffIcon
                                  }
                                />
                              </div>
                            </div>
                          </div> :
                          <div className="flex 2xl:flex-row xl:flex-row additionalHeader lg:flex-row flex-col items-center justify-center gap-3">
                            <div className="additionalHeader">
                              Do you want to add additional sub form for failed inspection?
                            </div>
                            <div className="flex flex-row items-center gap-5">
                              <div
                                className="flex flex-row items-center gap-1 cursor-pointer"
                                onClick={() => OnClickFormSection()}
                              >
                                Yes
                                <img
                                  className="Icons"
                                  src={
                                    open_Sub_Form == true
                                      ? RadioOnIcon
                                      : RadioOffIcon
                                  }
                                />
                              </div>
                              <div
                                onClick={() => setOpen_Sub_Form(false)}
                                className="flex flex-row items-center gap-1 cursor-pointer"
                              >
                                No
                                <img
                                  className="Icons"
                                  src={
                                    open_Sub_Form == false
                                      ? RadioOnIcon
                                      : RadioOffIcon
                                  }
                                />
                              </div>
                            </div>
                          </div>}
                        {openInspectionSubForm == true ?
                          <div className="flex flex-col gap-5">
                            {displayAddNewSubInspectionMasterArray.map((item, index) =>
                              <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default">
                                <div className="inputGrid">

                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Param. Level
                                      </label>
                                      <label className="error">
                                      </label>
                                    </div>
                                    <input disabled={false}
                                      type="text"
                                      value={
                                        item.parameterLevel
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Param. Question
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterQuestion
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parameterQuestion", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Display Control
                                      </label>
                                      <label className="error">
                                      </label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).displayControl
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "displayControl", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="barcode">Barcode</option>
                                      <option value="camera">Camera</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="dropdown">Dropdown</option>
                                      <option value="formula">Formula</option>
                                      <option value="incrementdecrement">
                                        Increment Decrement
                                      </option>
                                      <option value="longnumberbox">
                                        Long Numberbox
                                      </option>
                                      <option value="paragraph">Paragraph</option>
                                      <option value="radiobutton">Radio Button</option>
                                      <option value="subheading">Subheading</option>
                                      <option value="textbox">Textbox</option>
                                      <option value="datetime">Datetime</option>
                                      <option value="date">Date</option>
                                      <option value="time">Time</option>
                                      <option value="elapsedtime">Elapsed Time</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Param. Value Type
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <select
                                      className="child_input"

                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterValueType
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parameterValueType", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="formula">Formula</option>
                                      <option value="variable">
                                        Platform Variable
                                      </option>
                                      <option value="sql">SQL</option>
                                      <option value="static">Static</option>
                                      <option value="input">User Input</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Param. Value
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterValue
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parameterValue", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>


                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Param. Display Row
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      className="child_input"

                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterDisplayRow
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parameterDisplayRow", item.idx)
                                      }
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Param. Display Col
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <select
                                      className="child_input"

                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterDisplayCol
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parameterDisplayCol", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">

                                        Is Param. Mandatory?
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <select
                                      className="child_input"

                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterMandatory
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parameterMandatory", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Quality Process
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).qaProcessName
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "qaProcessName", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      {qualityProcessMap.length > 0
                                        ? qualityProcessMap.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.qaProcessDbId}
                                          >
                                            {item.processName}
                                          </option>
                                        ))
                                        : null}
                                    </select>
                                  </div>
                                  {/* <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">Forms</label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <select
                                    className="child_input"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).formName
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "formName", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    {formMaster.length > 0
                                      ? formMaster.map((item, index) =>
                                        item.formDbId != 1 && item.formDbId != 2 ? (
                                          <option key={index} value={item.formDbId}>
                                            {item.formName}
                                          </option>
                                        ) : null
                                      )
                                      : null}
                                  </select>
                                </div> */}
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">CTQ Flag</label>
                                      <label className="error">
                                      </label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).ctqFlag
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "ctqFlag", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Tolerance Type
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).toleranceType
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "toleranceType", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Variance Type{" "}
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <select
                                      className="child_input"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).varianceType
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "varianceType", item.idx)
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="=">=</option>
                                      <option value="+">+</option>
                                      <option value="-">-</option>
                                      <option value="+/-">+/-</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Allowed Variance
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="00.00"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).allowedVariance
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "allowedVariance", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>

                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Control Length
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).controlLength
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "controlLength", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  {/*<div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date From
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="date"
                                    value={
                                      new Date(
                                        inspectionParameterObject.effDateFrom
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            inspectionParameterObject.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          inspectionParameterObject.effDateFrom
                                        ).getUTCDate()
                                      )
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "effDateFrom", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date To
                                    </label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <input
                                    type="date"
                                    value={
                                      new Date(
                                        inspectionParameterObject.effDateTo
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            inspectionParameterObject.effDateTo
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          inspectionParameterObject.effDateTo
                                        ).getUTCDate()
                                      )
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "effDateTo", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div> */}
                                  {/* {inspectionParameterObject.formName == 7 ? */}


                                  {/* :null} */}
                                  {selected_form_id==7?
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Parent Param. Level
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input disabled={false}
                                      type="text"
                                      value={
                                        inspectionParameterObject.parameterCode | item.parameterLevel + 1
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parentInspParameterCode", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>:null}
                                  {selected_form_id==7?

                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        Parent Option
                                      </label>
                                      <label className="error">

                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      value={
                                        displayAddNewSubInspectionMasterArray.find((e) => e.idx == item.idx).parentOption
                                      }
                                      onChange={(e) =>
                                        OnChangeNewSubInpectionParameter(e, "parentOption", item.idx)
                                      }
                                      className="child_input"
                                    />
                                  </div>:null}


                                </div>
                                <div className="flex  flex-row items-center justify-center gap-5">
                                  {/* {isSubInspectionFormFilled(index) == 0 ? ( */}
                                  <img
                                    onClick={() => AddTheNewInspectionParameter(item.parameterLevel + 1)}
                                    className="Icons"
                                    src={PlusIcon}
                                  />
                                  {/* ) : null} */}
                                  <img
                                    onClick={() => RemoveTheNewInspectionSubForm(index)}
                                    className="Icons"
                                    src={DeleteIcon}
                                  />
                                </div>
                              </div>
                            )}
                          </div> : null}

                        {open_Sub_Form == true ? (
                          <div className="flex flex-col gap-5">
                            {displayNewAddFailureParametersMaster.map(
                              (item, index) => (
                                <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default">
                                  <div className="inputGrid">
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          Param. Question
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <input
                                        type="text"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).parameterQuestion
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "ParameterQuestion",
                                            item.idx
                                          )
                                        }
                                        className="child_input"
                                      />
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Param. Value Type
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <select
                                        className="child_input"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).parameterValueType
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "ParameterValueType",
                                            item.idx
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="formula">Formula</option>
                                        <option value="variable">
                                          Platform Variable
                                        </option>
                                        <option value="sql">SQL</option>
                                        <option value="static">Static</option>
                                        <option value="input">
                                          User Input
                                        </option>
                                      </select>
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Param. Value
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      {item.parameterValueType ===
                                        "variable" ? (
                                        <select
                                          value={
                                            displayNewAddFailureParametersMaster.find(
                                              (e) => e.idx == item.idx
                                            ).parameterValue
                                          }
                                          onChange={(e) =>
                                            onChangeFormNewPostSectionValue(
                                              e,
                                              "ParameterValue",
                                              item.idx
                                            )
                                          }
                                          className="child_input"
                                        >
                                          <option value={null}>
                                            Select...
                                          </option>
                                          <option value="$defect_desc">
                                            Defect Description
                                          </option>
                                        </select>
                                      ) : (
                                        <input
                                          type="text"
                                          value={
                                            displayNewAddFailureParametersMaster.find(
                                              (e) => e.idx == item.idx
                                            ).parameterValue
                                          }
                                          onChange={(e) =>
                                            onChangeFormNewPostSectionValue(
                                              e,
                                              "ParameterValue",
                                              item.idx
                                            )
                                          }
                                          className="child_input"
                                        />
                                      )}
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Display Control
                                        </label>
                                        <label className="error"></label>
                                      </div>

                                      <select
                                        className="child_input"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).displayControl
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "DisplayControl",
                                            item.idx
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="barcode">Barcode</option>
                                        <option value="camera">Camera</option>
                                        <option value="checkbox">
                                          Checkbox
                                        </option>
                                        <option value="dropdown">
                                          Dropdown
                                        </option>
                                        <option value="formula">Formula</option>
                                        <option value="incrementdecrement">
                                          Increment Decrement
                                        </option>
                                        <option value="longnumberbox">
                                          Long Numberbox
                                        </option>
                                        <option value="paragraph">
                                          Paragraph
                                        </option>
                                        <option value="radiobutton">
                                          Radio Button
                                        </option>
                                        <option value="subheading">
                                          Subheading
                                        </option>
                                        <option value="textbox">Textbox</option>
                                      </select>
                                    </div>{" "}
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Control Length
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <input
                                        type="text"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).controlLength
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "ControlLength",
                                            item.idx
                                          )
                                        }
                                        className="child_input"
                                      />
                                    </div>{" "}
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Is Param. Mandatory?
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <select
                                        className="child_input"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).isParamMandatory
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "IsParamMandatory",
                                            item.idx
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                      </select>
                                    </div>{" "}
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Param. Display Row
                                        </label>
                                        <label className="error">
                                          {parameterDisplayColError}
                                        </label>
                                      </div>
                                      <input
                                        type="text"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).parameterDisplayRow
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "ParameterDisplayRow",
                                            item.idx
                                          )
                                        }
                                        className="child_input"
                                      />
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">

                                          Param. Display Col
                                        </label>
                                        <label className="error">
                                          {parameterDisplayColError}
                                        </label>
                                      </div>

                                      <select
                                        className="child_input"
                                        value={
                                          displayNewAddFailureParametersMaster.find(
                                            (e) => e.idx == item.idx
                                          ).parameterDisplayCol
                                        }
                                        onChange={(e) =>
                                          onChangeFormNewPostSectionValue(
                                            e,
                                            "ParameterDisplayCol",
                                            item.idx
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                      </select>
                                    </div>{" "}
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          Eff. Date From
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <input
                                        type="date"
                                        value={
                                          new Date(
                                            inspectionParameterObject.effDateFrom
                                          ).getUTCFullYear() +
                                          "-" +
                                          ReturnThePaddedValues(
                                            Number(
                                              new Date(
                                                inspectionParameterObject.effDateFrom
                                              ).getUTCMonth()
                                            ) + 1
                                          ) +
                                          "-" +
                                          ReturnThePaddedValues(
                                            new Date(
                                              inspectionParameterObject.effDateFrom
                                            ).getUTCDate()
                                          )
                                        }
                                        onChange={(e) =>
                                          onChangeValue(e, "effDateFrom", null)
                                        }
                                        className="child_input"
                                      ></input>
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          Eff. Date To
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <input
                                        type="date"
                                        value={
                                          new Date(
                                            inspectionParameterObject.effDateTo
                                          ).getUTCFullYear() +
                                          "-" +
                                          ReturnThePaddedValues(
                                            Number(
                                              new Date(
                                                inspectionParameterObject.effDateTo
                                              ).getUTCMonth()
                                            ) + 1
                                          ) +
                                          "-" +
                                          ReturnThePaddedValues(
                                            new Date(
                                              inspectionParameterObject.effDateTo
                                            ).getUTCDate()
                                          )
                                        }
                                        onChange={(e) =>
                                          onChangeValue(e, "effDateTo", null)
                                        }
                                        className="child_input"
                                      ></input>
                                    </div>
                                  </div>
                                  <div className="flex flex-wrap flex-row items-center justify-center">
                                    <div className="flex flex-row gap-5">
                                      <img
                                        onClick={() =>
                                          EditTheFailureParameter()
                                        }
                                        className="Icons"
                                        src={PlusIcon}
                                      />

                                      <img
                                        onClick={() =>
                                          RemoveTheEditSubForm(index)
                                        }
                                        className="Icons"
                                        src={DeleteIcon}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => OnClose()}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => OnClose()}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnEditInspection()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Delete Popup column */}
                {show_delete_confirmations === true ? (
                  <div className="deleteModelstyle">
                    <div className="delete_header_modal">Confirmation</div>
                    <div className=" Confirmation">
                      Are you sure you want to delete?
                    </div>
                    <div className="btnDeletewrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnDeleteItem(item_to_delete)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Add Popup column */}
                {open_add_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Add Inspection Parameter Details
                    </div>
                    <div className="inputOverflow">
                      <div className="flex flex-col gap-5">
                        <div className="additionalHeader">
                          Add the Inspection Parameter
                        </div>
                        <div className="inputGrid">
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">Param. Code</label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterCodeError
                                }
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.parameterCode}
                              onChange={(e) =>
                                onChangeValue(e, "parameterCode", null)
                              }
                              className="child_input"
                            />
                          </div>
                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Param. Level
                                </label>
                                <label className="error">
                                  {
                                    inspectionParameterObjectErrors.parameterLevelError
                                  }
                                </label>
                              </div>
                              <input disabled={true}
                                type="text"
                                value={inspectionParameterObject.parameterLevel}
                                // onChange={(e) =>
                                //   onChangeValue(e, "parameterLevel", null)
                                // }
                                className="child_input"
                              />
                            </div>
                            : null}
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Question
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterQuestionError
                                }
                              </label>
                            </div>
                            <input
                              type="text"
                              value={
                                inspectionParameterObject.parameterQuestion
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterQuestion")
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Display Control
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.displayControlError
                                }
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.displayControl}
                              onChange={(e) =>
                                onChangeValue(e, "displayControl", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="barcode">Barcode</option>
                              <option value="camera">Camera</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="dropdown">Dropdown</option>
                              <option value="formula">Formula</option>
                              <option value="incrementdecrement">
                                Increment Decrement
                              </option>
                              <option value="longnumberbox">
                                Long Numberbox
                              </option>
                              <option value="paragraph">Paragraph</option>
                              <option value="radiobutton">Radio Button</option>
                              <option value="subheading">Subheading</option>
                              <option value="textbox">Textbox</option>
                              <option value="datetime">Datetime</option>
                              <option value="date">Date</option>
                              <option value="time">Time</option>
                              <option value="elapsedtime">Elapsed Time</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Param. Value Type
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterValueTypeError
                                }
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterValueType
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterValueType")
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="formula">Formula</option>
                              <option value="variable">
                                Platform Variable
                              </option>
                              <option value="sql">SQL</option>
                              <option value="static">Static</option>
                              <option value="input">User Input</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Param. Value
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterValueError
                                }
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.parameterValue}
                              onChange={(e) =>
                                onChangeValue(e, "parameterValue")
                              }
                              className="child_input"
                            />
                          </div>


                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Display Row
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterDisplayRowError
                                }
                              </label>
                            </div>
                            <input
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterDisplayRow
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterDisplayRow", null)
                              }
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Param. Display Col
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterDisplayColError
                                }
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterDisplayCol
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterDisplayCol", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">

                                Is Param. Mandatory?
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.parameterMandatoryError
                                }
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={
                                inspectionParameterObject.parameterMandatory
                              }
                              onChange={(e) =>
                                onChangeValue(e, "parameterMandatory", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Quality Process
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.qaProcessNameError
                                }
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.qaProcessName}
                              onChange={(e) =>
                                onChangeValue(e, "qaProcessName ", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              {qualityProcessMap.length > 0
                                ? qualityProcessMap.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.qaProcessDbId}
                                  >
                                    {item.processName}
                                  </option>
                                ))
                                : null}
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">Forms</label>
                              <label className="error">
                                {inspectionParameterObjectErrors.formNameError}
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.formName}
                              onChange={(e) =>
                                onChangeValue(e, "formName", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              {formMaster.length > 0
                                ? formMaster.map((item, index) =>
                                  item.formDbId != 1 && item.formDbId != 2 ? (
                                    <option key={index} value={item.formDbId}>
                                      {item.formName}
                                    </option>
                                  ) : null
                                )
                                : null}
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">CTQ Flag</label>
                              <label className="error">
                                {inspectionParameterObjectErrors.ctqFlagError}
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.ctqFlag}
                              onChange={(e) =>
                                onChangeValue(e, "ctqFlag", null)
                              }
                            >
                              <option value={null}>Select...</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Tolerance Type
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.toleranceTypeError
                                }
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.toleranceType}
                              onChange={(e) =>
                                onChangeValue(e, "toleranceType", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Variance Type{" "}
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.varianceTypeError
                                }
                              </label>
                            </div>
                            <select
                              className="child_input"
                              value={inspectionParameterObject.varianceType}
                              onChange={(e) =>
                                onChangeValue(e, "varianceType", null)
                              }
                            >
                              <option value={""}>Select...</option>
                              <option value="=">=</option>
                              <option value="+">+</option>
                              <option value="-">-</option>
                              <option value="+/-">+/-</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Allowed Variance
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.allowedVarianceError
                                }
                              </label>
                            </div>
                            <input
                              type="text"
                              placeholder="00.00"
                              value={inspectionParameterObject.allowedVariance}
                              onChange={(e) =>
                                onChangeValue(e, "allowedVariance", null)
                              }
                              className="child_input"
                            />
                          </div>

                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Control Length
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.controlLengthError
                                }
                              </label>
                            </div>
                            <input
                              type="text"
                              value={inspectionParameterObject.controlLength}
                              onChange={(e) =>
                                onChangeValue(e, "controlLength", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Eff. Date From
                              </label>
                              <label className="error">
                                {
                                  inspectionParameterObjectErrors.effDateFromError
                                }
                              </label>
                            </div>
                            <input
                              type="date"
                              value={
                                new Date(
                                  inspectionParameterObject.effDateFrom
                                ).getUTCFullYear() +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(
                                      inspectionParameterObject.effDateFrom
                                    ).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                ReturnThePaddedValues(
                                  new Date(
                                    inspectionParameterObject.effDateFrom
                                  ).getUTCDate()
                                )
                              }
                              onChange={(e) =>
                                onChangeValue(e, "effDateFrom", null)
                              }
                              className="child_input"
                            />
                          </div>
                          <div className="parent_popup">
                            <div className="child_label_flex">
                              <label className="child_popup">
                                Eff. Date To
                              </label>
                              <label className="error">
                                {inspectionParameterObjectErrors.effDateToError}
                              </label>
                            </div>
                            <input
                              type="date"
                              value={
                                new Date(
                                  inspectionParameterObject.effDateTo
                                ).getUTCFullYear() +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(
                                      inspectionParameterObject.effDateTo
                                    ).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                ReturnThePaddedValues(
                                  new Date(
                                    inspectionParameterObject.effDateTo
                                  ).getUTCDate()
                                )
                              }
                              onChange={(e) =>
                                onChangeValue(e, "effDateTo", null)
                              }
                              className="child_input"
                            />
                          </div>

                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Parent Param. Level
                                </label>
                                <label className="error">
                                  {
                                    inspectionParameterObjectErrors.parentInspParameterError
                                  }
                                </label>
                              </div>
                              <input
                                type="text"
                                value={inspectionParameterObject.parentInspParameterCode}
                                onChange={(e) =>
                                  onChangeValue(e, "parentInspParameterCode", null)
                                }
                                className="child_input"
                              />
                            </div> : null}
                          {selected_form_id == 7 ?
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Parent Option
                                </label>
                                <label className="error">
                                  {
                                    inspectionParameterObjectErrors.parentOptionError
                                  }
                                </label>
                              </div>
                              <input
                                type="text"
                                value={inspectionParameterObject.parentOption}
                                onChange={(e) =>
                                  onChangeValue(e, "parentOption", null)
                                }
                                className="child_input"
                              />
                            </div> : null}

                        </div>
                      </div>
                      {selected_form_id == 7 ?
                        <div className="flex 2xl:flex-row xl:flex-row additionalHeader lg:flex-row flex-col items-center justify-center gap-3">
                          <div className="additionalHeader">
                            Add sub from another level ?
                          </div>
                          <div className="flex flex-row items-center gap-5">
                            <div
                              className="flex flex-row items-center gap-1 cursor-pointer"
                              onClick={() => OnClickInspectionSubForm()}
                            >
                              <>Yes</>
                              <img
                                className="Icons"
                                src={
                                  openInspectionSubForm === true
                                    ? RadioOnIcon
                                    : RadioOffIcon
                                }
                              />
                            </div>
                            <div
                              onClick={() => setOpenInspectionSubForm(false)}
                              className="flex flex-row items-center gap-1 cursor-pointer"
                            >
                              <p>No</p>
                              <img
                                className="Icons"
                                src={
                                  openInspectionSubForm === false
                                    ? RadioOnIcon
                                    : RadioOffIcon
                                }
                              />
                            </div>
                          </div>
                        </div> :
                        <div className="flex 2xl:flex-row xl:flex-row additionalHeader lg:flex-row flex-col items-center justify-center gap-3">
                          <div className="additionalHeader">
                            Do you want to add sub form for failed inspection?
                          </div>
                          <div className="flex flex-row items-center gap-5">
                            <div
                              className="flex flex-row items-center gap-1 cursor-pointer"
                              onClick={() => OnClickFormSection()}
                            >
                              Yes
                              <img
                                className="Icons"
                                src={
                                  open_Sub_Form == true
                                    ? RadioOnIcon
                                    : RadioOffIcon
                                }
                              />
                            </div>
                            <div
                              onClick={() => setOpen_Sub_Form(false)}
                              className="flex flex-row items-center gap-1 cursor-pointer"
                            >
                              No
                              <img
                                className="Icons"
                                src={
                                  open_Sub_Form === false
                                    ? RadioOnIcon
                                    : RadioOffIcon
                                }
                              />
                            </div>
                          </div>
                        </div>}
                      {openInspectionSubForm == true ?
                        <div className="flex flex-col gap-5">
                          {addSubInspectionMasterArray.map((item, index) =>
                            <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default ">
                              <div className="inputGrid">
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Level
                                    </label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={
                                      index + 1
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Question
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterQuestion
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parameterQuestion", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Display Control
                                    </label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <select
                                    className="child_input"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).displayControl
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "displayControl", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="barcode">Barcode</option>
                                    <option value="camera">Camera</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="dropdown">Dropdown</option>
                                    <option value="formula">Formula</option>
                                    <option value="incrementdecrement">
                                      Increment Decrement
                                    </option>
                                    <option value="longnumberbox">
                                      Long Numberbox
                                    </option>
                                    <option value="paragraph">Paragraph</option>
                                    <option value="radiobutton">Radio Button</option>
                                    <option value="subheading">Subheading</option>
                                    <option value="textbox">Textbox</option>
                                    <option value="datetime">Datetime</option>
                                    <option value="date">Date</option>
                                    <option value="time">Time</option>
                                    <option value="elapsedtime">Elapsed Time</option>
                                  </select>
                                </div>


                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Param. Value Type
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <select
                                    className="child_input"

                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterValueType
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parameterValueType", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="formula">Formula</option>
                                    <option value="variable">
                                      Platform Variable
                                    </option>
                                    <option value="sql">SQL</option>
                                    <option value="static">Static</option>
                                    <option value="input">User Input</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Param. Value
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterValue
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parameterValue", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Display Row
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    className="child_input"

                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterDisplayRow
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parameterDisplayRow", item.idx)
                                    }
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Param. Display Col
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <select
                                    className="child_input"

                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterDisplayCol
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parameterDisplayCol", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">

                                      Is Param. Mandatory?
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <select
                                    className="child_input"

                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parameterMandatory
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parameterMandatory", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Quality Process
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <select
                                    className="child_input"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).qaProcessName
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "qaProcessName", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    {qualityProcessMap.length > 0
                                      ? qualityProcessMap.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.qaProcessDbId}
                                        >
                                          {item.processName}
                                        </option>
                                      ))
                                      : null}
                                  </select>
                                </div>
                                {/* <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">Forms</label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <select
                                    className="child_input"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).formName
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "formName", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    {formMaster.length > 0
                                      ? formMaster.map((item, index) =>
                                        item.formDbId != 1 && item.formDbId != 2 ? (
                                          <option key={index} value={item.formDbId}>
                                            {item.formName}
                                          </option>
                                        ) : null
                                      )
                                      : null}
                                  </select>
                                </div> */}
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">CTQ Flag</label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <select
                                    className="child_input"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).ctqFlag
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "ctqFlag", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Tolerance Type
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).toleranceType
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "toleranceType", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Variance Type{" "}
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <select
                                    className="child_input"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).varianceType
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "varianceType", item.idx)
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="=">=</option>
                                    <option value="+">+</option>
                                    <option value="-">-</option>
                                    <option value="+/-">+/-</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Allowed Variance
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    placeholder="00.00"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).allowedVariance
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "allowedVariance", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Control Length
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).controlLength
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "controlLength", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>
                                {/*<div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date From
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="date"
                                    value={
                                      new Date(
                                        inspectionParameterObject.effDateFrom
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            inspectionParameterObject.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          inspectionParameterObject.effDateFrom
                                        ).getUTCDate()
                                      )
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "effDateFrom", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Eff. Date To
                                    </label>
                                    <label className="error">
                                    </label>
                                  </div>
                                  <input
                                    type="date"
                                    value={
                                      new Date(
                                        inspectionParameterObject.effDateTo
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            inspectionParameterObject.effDateTo
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          inspectionParameterObject.effDateTo
                                        ).getUTCDate()
                                      )
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "effDateTo", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div> */}


                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Parent Param. Level
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={inspectionParameterObject.parameterCode + " | " + (index + 1)
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parentInspParameterCode", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      Parent Option
                                    </label>
                                    <label className="error">

                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={
                                      addSubInspectionMasterArray.find((e) => e.idx == item.idx).parentOption
                                    }
                                    onChange={(e) =>
                                      OnChangeSubInpectionParameter(e, "parentOption", item.idx)
                                    }
                                    className="child_input"
                                  />
                                </div>


                              </div>
                              <div className="flex  flex-row items-center justify-center gap-5">
                                {/* {isSubInspectionFormFilled(index) == 0 ? ( */}
                                <img
                                  onClick={() => AddTheInspectionParameter(index + 1)}
                                  className="Icons"
                                  src={PlusIcon}
                                />
                                {/* ) : null} */}
                                <img
                                  onClick={() => RemoveTheInspectionSubForm(index)}
                                  className="Icons"
                                  src={DeleteIcon}
                                />
                              </div>
                            </div>
                          )}
                        </div> : null}


                   
                   
                          {open_Sub_Form === true ? (
                            <div className="flex flex-col gap-5" ref={bottomRef}>
                              {add_Sub_Failure_Parameters_Master.map(
                                (item, index) => (
                                  <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default">
                                    <div className="inputGrid">
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">
                                            Param. Question
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        <input
                                          type="text"
                                          value={
                                            add_Sub_Failure_Parameters_Master.find(
                                              (e) => e.idx == item.idx
                                            ).parameterQuestion
                                          }
                                          onChange={(e) =>
                                            OnChangeParameterQuestion(e, item.idx)
                                          }
                                          className="child_input"
                                        />
                                      </div>
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Param. Value Type
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        <select
                                          className="child_input"
                                          value={
                                            add_Sub_Failure_Parameters_Master.find(
                                              (e) => e.idx == item.idx
                                            ).parameterValueType
                                          }
                                          onChange={(e) =>
                                            OnChangeParameterValueType(e, item.idx)
                                          }
                                        >
                                          <option value={null}>Select...</option>
                                          <option value="formula">Formula</option>
                                          <option value="variable">
                                            Platform Variable
                                          </option>
                                          <option value="sql">SQL</option>
                                          <option value="static">Static</option>
                                          <option value="input">User Input</option>
                                        </select>
                                      </div>
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Param. Value
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        {item.parameterValueType === "variable" ? (
                                          <select
                                            value={
                                              add_Sub_Failure_Parameters_Master.find(
                                                (e) => e.idx == item.idx
                                              ).parameterValue
                                            }
                                            onChange={(e) =>
                                              OnChangeParameterValue(e, item.idx)
                                            }
                                            className="child_input"
                                          >
                                            <option value={null}>Select...</option>
                                            <option value="$defect_desc">
                                              Defect Description
                                            </option>
                                          </select>
                                        ) : (
                                          <input
                                            type="text"
                                            value={
                                              add_Sub_Failure_Parameters_Master.find(
                                                (e) => e.idx == item.idx
                                              ).parameterValue
                                            }
                                            onChange={(e) =>
                                              OnChangeParameterValue(e, item.idx)
                                            }
                                            className="child_input"
                                          />
                                        )}
                                      </div>
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Display Control
                                          </label>
                                          <label className="error"></label>
                                        </div>

                                        <select
                                          className="child_input"
                                          value={
                                            add_Sub_Failure_Parameters_Master.find(
                                              (e) => e.idx == item.idx
                                            ).displayControl
                                          }
                                          onChange={(e) =>
                                            OnChangeDisplayControl(e, item.idx)
                                          }
                                        >
                                          <option value={null}>Select...</option>
                                          <option value="barcode">Barcode</option>
                                          <option value="camera">Camera</option>
                                          <option value="checkbox">Checkbox</option>
                                          <option value="dropdown">Dropdown</option>
                                          <option value="formula">Formula</option>
                                          <option value="incrementdecrement">
                                            Increment Decrement
                                          </option>
                                          <option value="longnumberbox">
                                            Long Numberbox
                                          </option>
                                          <option value="paragraph">
                                            Paragraph
                                          </option>
                                          <option value="radiobutton">
                                            Radio Button
                                          </option>
                                          <option value="subheading">
                                            Subheading
                                          </option>
                                          <option value="textbox">Textbox</option>
                                          <option value="datetime">Datetime</option>
                                          <option value="date">Date</option>
                                          <option value="time">Time</option>
                                          <option value="elapsedtime">Elapsed Time</option>
                                        </select>
                                      </div>{" "}
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Control Length
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        <input
                                          type="text"
                                          value={
                                            add_Sub_Failure_Parameters_Master.find(
                                              (e) => e.idx == item.idx
                                            ).controlLength
                                          }
                                          onChange={(e) =>
                                            OnChangeControlLength(e, item.idx)
                                          }
                                          className="child_input"
                                        />
                                      </div>{" "}
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Is Param. Mandatory?
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        <select
                                          className="child_input"
                                          value={
                                            add_Sub_Failure_Parameters_Master.find(
                                              (e) => e.idx == item.idx
                                            ).isParamMandatory
                                          }
                                          onChange={(e) =>
                                            OnChangeisParamMandatory(e, item.idx)
                                          }
                                        >
                                          <option value={null}>Select...</option>
                                          <option value="Y">Y</option>
                                          <option value="N">N</option>
                                        </select>
                                      </div>{" "}
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Param. Display Row
                                          </label>
                                          <label className="error">
                                            {parameterDisplayColError}
                                            {attributeDisplayRowNumberError}
                                          </label>
                                        </div>
                                        <input
                                          type="text"
                                          value={item.parameterDisplayRow}
                                          onChange={(e) =>
                                            OnChangeParameterDisplayRow(e, index)
                                          }
                                          className="child_input"
                                        />
                                      </div>
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">

                                            Param. Display Col
                                          </label>
                                          <label className="error">
                                            {parameterDisplayColError}
                                            {attributeDisplayColNumberError}
                                          </label>
                                        </div>

                                        <select
                                          className="child_input"
                                          value={item.parameterDisplayCol}
                                          onChange={(e) =>
                                            OnChangeParameterDisplayCol(e, index)
                                          }
                                        >
                                          <option value={null}>Select...</option>
                                          <option value={1}>1</option>
                                          <option value={2}>2</option>
                                          <option value={3}>3</option>
                                          <option value={4}>4</option>
                                        </select>
                                      </div>{" "}
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">
                                            Eff. Date From
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        <input
                                          type="date"
                                          value={
                                            new Date(
                                              inspectionParameterObject.effDateFrom
                                            ).getUTCFullYear() +
                                            "-" +
                                            ReturnThePaddedValues(
                                              Number(
                                                new Date(
                                                  inspectionParameterObject.effDateFrom
                                                ).getUTCMonth()
                                              ) + 1
                                            ) +
                                            "-" +
                                            ReturnThePaddedValues(
                                              new Date(
                                                inspectionParameterObject.effDateFrom
                                              ).getUTCDate()
                                            )
                                          }
                                          onChange={(e) =>
                                            onChangeValue(e, "effDateFrom", null)
                                          }
                                          className="child_input"
                                        ></input>
                                      </div>
                                      <div className="parent_popup">
                                        <div className="child_label_flex">
                                          <label className="child_popup">
                                            Eff. Date To
                                          </label>
                                          <label className="error"></label>
                                        </div>
                                        <input
                                          type="date"
                                          value={
                                            new Date(
                                              inspectionParameterObject.effDateTo
                                            ).getUTCFullYear() +
                                            "-" +
                                            ReturnThePaddedValues(
                                              Number(
                                                new Date(
                                                  inspectionParameterObject.effDateTo
                                                ).getUTCMonth()
                                              ) + 1
                                            ) +
                                            "-" +
                                            ReturnThePaddedValues(
                                              new Date(
                                                inspectionParameterObject.effDateTo
                                              ).getUTCDate()
                                            )
                                          }
                                          onChange={(e) =>
                                            onChangeValue(e, "effDateTo", null)
                                          }
                                          className="child_input"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="flex flex-wrap flex-row items-center justify-center">
                                      <div className="flex flex-row gap-5">
                                        {isFormFilled(index) == 0 ? (
                                          <img
                                            onClick={() => AddTheFailureParameter()}
                                            className="Icons"
                                            src={PlusIcon}
                                          />
                                        ) : null}
                                        <img
                                          onClick={() => RemoveTheSubForm(index)}
                                          className="Icons"
                                          src={DeleteIcon}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => handleAddModelClose()}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => handleAddModelClose()}
                        >
                          Cancel
                        </button>
                        <button
                          className="savedraftButton"
                          onClick={() => AddTheItem(true)}
                        >
                          Save as Draft
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => AddTheItem(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
            <LogoutConfirmation />
          </>
        ) : null}
      </div>
      {qualityProcess == true ? (
        <div className="FilterMobile">
          {qualityProcess === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="QualityProcess"
                      type="text"
                      placeholder="Search"
                      value={searchQuality}
                      onChange={(e) => onChangeSearchQuality(e)}
                    />
                    <div
                      id="QualityProcess"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedQualityProccess(qualityProcessArray)
                      }
                    >
                      <img
                        id="QualityProcess"
                        className="Icons"
                        src={
                          qualityProcessArray.length ==
                            selectedQualityProcess.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="QualityProcess">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {searchQuality.length == 0
                      ? qualityProcessArray.map((item, index) => (
                        <div
                          id="QualityProcess"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveQuality(item.qaProcessDbId)}
                        >
                          <img
                            id="QualityProcess"
                            className="Icons"
                            src={
                              selectedQualityProcess.findIndex(
                                (e) => e.qaProcessDbId === item.qaProcessDbId
                              ) !== -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="QualityProcess">{item.processName}</p>
                        </div>
                      ))
                      : searchQuality.length > 0
                        ? searchQualityArray.map((item, index) => (
                          <div
                            id="QualityProcess"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveQuality(item.qaProcessDbId)}
                          >
                            <img
                              id="QualityProcess"
                              className="Icons"
                              src={
                                selectedQualityProcess.findIndex(
                                  (e) => e.qaProcessDbId === item.qaProcessDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="QualityProcess">{item.processName}</p>
                          </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="QualityProcess"
                    className="popupfile_button"
                    onClick={() => OnOkQualityProcess()}
                  >
                    Ok
                  </button>
                  <button
                    id="QualityProcess"
                    className="popupfile_button"
                    onClick={() => OnPressedClearClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default InspectionParameters;
