import React from "react";
import SelfIcon from "../Assert/StrateraLogo.png";
function SplashScreen() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <img className="w-[250px] h-[250px] object-contain" src={SelfIcon} />
    </div>
  );
}

export default SplashScreen;
