import React, { useState, useEffect, useRef } from "react";
import UnCheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import CloseIcon from "../Assert/Close.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Equipment } from "./Redux/EquipmentSlice";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";

function Equipment() {
  const [loading, setLoading] = useState(true);

  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheValidation();
        FetchTheData(
          process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  //Get all the facilities
  let EquipmentMaster = useSelector((state) => state.callEquipment.value);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_SENSOR + "equipment";

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [equipmentMasterObjectErrors, setEquipmentMasterObjectErrors] =
    useState({
      equipment_codeError: "",
      main_equipment_nameError: "",
      sub_assembly_nameError: "",
      sub_equipment_nameError: "",
      activeError: "",
      main_assembly_lineError: "",
      eff_date_fromError: "",
      eff_date_toError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "equipment_codeError");
    OnChangeErrorValues("", "main_equipment_nameError");
    OnChangeErrorValues("", "sub_assembly_nameError");
    OnChangeErrorValues("", "sub_equipment_nameError");
    OnChangeErrorValues("", "activeError");
    OnChangeErrorValues("", "eff_date_fromError");
    OnChangeErrorValues("", "eff_date_toError");
    OnChangeErrorValues("", "main_assembly_lineError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "equipment_codeError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          equipment_codeError: val,
        }));
        break;
      case "main_equipment_nameError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          main_equipment_nameError: val,
        }));
        break;
      case "sub_assembly_nameError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          sub_assembly_nameError: val,
        }));
        break;
      case "sub_equipment_nameError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          sub_equipment_nameError: val,
        }));
        break;
      case "active":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          activeError: val,
        }));
        break;
      case "eff_date_fromError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          eff_date_fromError: val,
        }));
        break;
      case "eff_date_toError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          eff_date_toError: val,
        }));
        break;
      case "main_assembly_lineError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          main_assembly_lineError: val,
        }));
    }
  };

  //Searching
  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);

  const onChangeSearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = EquipmentMaster.filter((e1) =>
      e1.main_equipment_name
        .toLowerCase()
        .trim()
        .match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (EquipmentMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(EquipmentMaster[i]);
        }
      } else {
        for (let i = 0; i < EquipmentMaster.length; i++) {
          tempArray.push(EquipmentMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        EquipmentMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };
  ///////

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [equipmentMasterArray, setEquipmentMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [equipmentMasterObject, setEquipmentMasterObject] = useState({
    equipment_code: "",
    equipment_id: "",
    main_equipment_name: "",
    sub_assembly_name: "",
    sub_equipment_name: "",
    active: null,
    main_assembly_line: "",
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
  });
  //Declaration Of States
  const [equipmentMasterObjectDelete, setEquipmentMasterObjectDelete] =
    useState({
      equipment_code: "",
      equipment_id: "",
      main_equipment_name: "",
      sub_assembly_name: "",
      sub_equipment_name: "",
      active: null,
      main_assembly_line: "",
      eff_date_from: new Date(),
      eff_date_to: new Date("9999-12-31"),
    });

  const onResetValue = () => {
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      equipment_code: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_equipment_name: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_assembly_name: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_equipment_name: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      active: null,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_from: new Date(),
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_to: new Date("9999-12-31"),
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_assembly_line: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "equipment_code":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          equipment_code: e.target.value,
        }));
        break;
      case "main_equipment_name":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          main_equipment_name: e.target.value,
        }));
        break;
      case "sub_assembly_name":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          sub_assembly_name: e.target.value,
        }));
        break;
      case "sub_equipment_name":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          sub_equipment_name: e.target.value,
        }));
        break;
      case "main_assembly_line":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          main_assembly_line: e.target.value,
        }));
        break;
      case "active":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          active: e.target.value,
        }));
        break;
      case "eff_date_from":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          eff_date_from: e.target.value,
        }));
        break;
      case "eff_date_to":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          eff_date_to: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          equipmentMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            equipmentMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(equipmentMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            equipmentMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (equipmentMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(equipmentMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(equipmentMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };

  const [open_view_modal, set_open_view_modal] = useState(false);
  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = EquipmentMaster.findIndex((e) => e.equipment_id === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(EquipmentMaster[idx]);
    set_item_to_delete(EquipmentMaster[idx].equipment_id);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .delete(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .delete(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      // active: Number(equipmentMasterObject.active),
      equipment_code: equipmentMasterObject.equipment_code,
      main_equipment_name: equipmentMasterObject.main_equipment_name,
      sub_equipment_name: equipmentMasterObject.sub_equipment_name,
      eff_date_from: equipmentMasterObject.eff_date_from,
      eff_date_to:
        status === true
          ? new Date("1900-01-01")
          : equipmentMasterObject.eff_date_to,
      main_assembly_line: equipmentMasterObject.main_assembly_line,
      created_by: isAuthenticated == true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated == true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else if (Validations() == 0) {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",

              "Post"
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = () => {
    let deleteData = {
      // active: Number(equipmentMasterObjectDelete.active),
      equipment_code: equipmentMasterObjectDelete.equipment_code,
      main_equipment_name: equipmentMasterObjectDelete.main_equipment_name,
      sub_equipment_name: equipmentMasterObjectDelete.sub_equipment_name,
      eff_date_from: GetServerDate(equipmentMasterObjectDelete.eff_date_from),
      eff_date_to: OneBack(),
      main_assembly_line: equipmentMasterObjectDelete.main_assembly_line,
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    let data = {
      // active: Number(equipmentMasterObject.active),
      equipment_code: equipmentMasterObject.equipment_code,
      main_equipment_name: equipmentMasterObject.main_equipment_name,
      sub_equipment_name: equipmentMasterObject.sub_equipment_name,
      eff_date_from: GetServerDate(equipmentMasterObject.eff_date_from),
      eff_date_to: GetServerDate(equipmentMasterObject.eff_date_to),
      main_assembly_line: equipmentMasterObject.main_assembly_line,
      created_by: isAuthenticated === true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    axios
      .put(post_url + "/" + selectEquipment_id, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios.post(post_url, data).then((response) => {
            if (response.status === 201) {
              FetchTheData(
                process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",

                "Patch"
              );
              ShowMEWSPopup("✅Successfully updated the item", "Success");
              set_mews_popup_color("#28A745");
              set_open_edit_modal(false);
            }
          });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectEquipment_id);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Equipment") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "equipmentCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.equipment_code.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              equipmentMasterArray.findIndex(
                (e) => e.equipment_code === equipmentMasterObject.equipment_code
              ) != -1
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          case "mainAssemblyLine":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.main_assembly_line.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "mainEquipmentName":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.main_equipment_name.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              equipmentMasterArray.findIndex(
                (e) =>
                  e.main_equipment_name ===
                    equipmentMasterObject.main_equipment_name &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.sub_assembly_name ===
                    equipmentMasterObject.sub_assembly_name
              ) != -1
            ) {
              console.log("DuplicateCombo:");
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
          case "subEquipmentName":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.sub_equipment_name.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              equipmentMasterArray.findIndex(
                (e) =>
                  e.main_equipment_name ===
                    equipmentMasterObject.main_equipment_name &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name
              ) != -1
            ) {
              console.log("DuplicateCombo:");

              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
          case "subAssemblyName":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.sub_assembly_name.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_assembly_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              equipmentMasterArray.findIndex(
                (e) =>
                  e.main_equipment_name ===
                    equipmentMasterObject.main_equipment_name &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.sub_assembly_name ===
                    equipmentMasterObject.sub_assembly_name
              ) != -1
            ) {
              console.log("DuplicateCombo:");
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_assembly_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "mainEquipmentName,subEquipmentName,subAssemblyName":
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              equipmentMasterArray.findIndex(
                (e) =>
                  e.main_equipment_name ===
                    equipmentMasterObject.main_equipment_name &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.sub_assembly_name ===
                    equipmentMasterObject.sub_assembly_name
              ) != -1
            ) {
              console.log("DuplicateCombo:");
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_assembly_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = EquipmentMaster.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        EquipmentMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (EquipmentMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < EquipmentMaster.length; i++) {
          temp_display_array.push(EquipmentMaster[i]);
        }
      } else {
        if (
          EquipmentMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          EquipmentMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < EquipmentMaster.length;
            i++
          ) {
            temp_display_array.push(EquipmentMaster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(EquipmentMaster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_EquipmentMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.results.length; i++) {
            temp_EquipmentMaster.push(response.data.results[i]);
            if (
              new Date(response.data.results[i].eff_date_to).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data.results[i]);
              temp_count++;
            }
            setEquipmentMasterArray(tempNonDraftArray);
            if (
              new Date(response.data.results[i].eff_date_to).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data.results[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_Equipment(temp_EquipmentMaster));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectEquipment_id, setSelectEquipment_id] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (equipment_id, type) => {
    console.log(selectEquipment_id);
    setSelectEquipment_id(equipment_id);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(equipment_id);
  };
  const SupplyValuesToModal = (equipment_id) => {
    // console.log(
    //   EquipmentMaster.find((e) => e.equipment_id == equipment_id).eff_date_from
    // );
    let dateFrom = EquipmentMaster.find(
      (e) => e.equipment_id == equipment_id
    ).eff_date_from;
    let dateTo = EquipmentMaster.find(
      (e) => e.equipment_id == equipment_id
    ).eff_date_to;
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      equipment_code: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).equipment_code,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_equipment_name,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_assembly_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_assembly_name,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_equipment_name,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      active: EquipmentMaster.find((e) => e.equipment_id == equipment_id)
        .active,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_assembly_line: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_assembly_line,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_from: dateFrom,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_to: dateTo,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      equipment_code: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).equipment_code,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      main_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_equipment_name,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      sub_assembly_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_assembly_name,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      sub_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_equipment_name,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      active: EquipmentMaster.find((e) => e.equipment_id == equipment_id)
        .active,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      main_assembly_line: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_assembly_line,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      eff_date_from: dateFrom,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      eff_date_to: dateTo,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    console.log("Sorting:" + paginatedArray);
    let SortingEquipment = [...equipmentMasterArray];
    if (sortStyle === "Descending") {
      let UpdatedEquipment = SortingEquipment.sort((a, b) => {
        const a_res =
          action === "Code" ? a.equipment_code : a.main_equipment_name;
        const b_res =
          action === "Code" ? b.equipment_code : b.main_equipment_name;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedEquipment);
      setSortStyle("Ascending");
    }
    if (sortStyle === "Ascending") {
      let UpdatedEquipment = SortingEquipment.sort((a, b) => {
        const a_res =
          action === "Code" ? a.equipment_code : a.main_equipment_name;
        const b_res =
          action === "Code" ? b.equipment_code : b.main_equipment_name;

        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedEquipment);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingEquipment.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingEquipment[i]);
      }
    } else {
      for (let i = 0; i < SortingEquipment.length; i++) {
        tempArray.push(SortingEquipment[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingEquipment.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setEquipmentMasterArray(SortingEquipment);
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="flex flex-row w-full">
              <div className="container-fluid">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                        multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className="green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                  <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                    <div className="manageHeader"> Manage Equipment</div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UnCheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UnCheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Equipment"
                        value={search_content}
                        onChange={(e) => onChangeSearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>

                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Equipment Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Main Equipment Name
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Main Assembly Line</th>
                          <th>Sub Equipment Name</th>
                          {/* <th>Active</th> */}
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.equipment_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.equipment_code}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.main_equipment_name}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.main_assembly_line}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.sub_equipment_name}
                                </td>
                                {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.equipment_id)
                            }
                          >
                            {item.active === 1 ? "Yes" : "No"}
                          </td> */}

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.eff_date_from).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(
                                          item.eff_date_from
                                        ).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(
                                      item.eff_date_from
                                    ).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {new Date(item.eff_date_to).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.eff_date_to).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.eff_date_to).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.equipment_id, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.equipment_id, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.equipment_id
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.equipment_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.equipment_code}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.main_equipment_name}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.main_assembly_line}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.sub_equipment_name}
                                </td>

                                {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.equipment_id)
                            }
                          >
                            {item.active === true
                              ? "Yes"
                              : item.active === false
                              ? "No"
                              : null}
                          </td> */}

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.eff_date_from).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(
                                          item.eff_date_from
                                        ).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(
                                      item.eff_date_from
                                    ).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {new Date(item.eff_date_to).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.eff_date_to).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.eff_date_to).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.equipment_id, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.equipment_id, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.equipment_id
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.equipment_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.equipment_code}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.main_equipment_name}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.main_assembly_line}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {item.sub_equipment_name}
                                </td>

                                {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.equipment_id)
                            }
                          >
                            {item.active === true
                              ? "Yes"
                              : item.active === false
                              ? "No"
                              : null}
                          </td> */}

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.eff_date_from).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(
                                          item.eff_date_from
                                        ).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(
                                      item.eff_date_from
                                    ).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.equipment_id
                                    )
                                  }
                                >
                                  {new Date(item.eff_date_to).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.eff_date_to).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.eff_date_to).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.equipment_id, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.equipment_id, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(
                                          item.equipment_id
                                        )
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <div className="addTheFormSplits">
                    <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={
                            current_page.current == 1
                              ? PreviousHiddenIcon
                              : PreviousIcon
                          }
                          onClick={() => onPressPrevious()}
                          className="PreviouesNextIcons"
                          alt=""
                          style={{
                            cursor:
                              current_page.current == 1 ? "auto" : "pointer",
                          }}
                        />
                        <img
                          src={
                            current_page.current == total_pages.current
                              ? NextHiddenIcon
                              : NextIcon
                          }
                          onClick={() => onPressNext()}
                          className="PreviouesNextIcons"
                          style={{
                            cursor:
                              current_page.current == total_pages.current
                                ? "auto"
                                : "pointer",
                          }}
                          alt=""
                        />
                      </div>
                      <p>
                        Page {current_page.current} of{" "}
                        {paginatedArray.length === 0
                          ? current_page.current
                          : total_pages.current}
                      </p>
                    </div>
                    <div className="specebutton">
                      <button
                        className="addBtn"
                        onClick={() => onClickAddItem()}
                      >
                        Add
                      </button>
                      <button className="uploadBtn">Upload</button>
                      <button
                        className="deleteBtn"
                        onClick={() => OnDeleteSelectionClicked()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {/* //View box */}
                {open_view_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      View Equipment Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Equipment Code
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={equipmentMasterObject.equipment_code}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Equipment Name
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={equipmentMasterObject.sub_equipment_name}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Main Assembly Line
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={equipmentMasterObject.main_assembly_line}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Active</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              equipmentMasterObject.active === false
                                ? "No"
                                : "Yes"
                            }
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Sub Equipment Name
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={equipmentMasterObject.sub_equipment_name}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                equipmentMasterObject.eff_date_from
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    equipmentMasterObject.eff_date_from
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  equipmentMasterObject.eff_date_from
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                equipmentMasterObject.eff_date_to
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    equipmentMasterObject.eff_date_to
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  equipmentMasterObject.eff_date_to
                                ).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="editButton"
                          onClick={() => OpenModal(selectEquipment_id, "Edit")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Edit Popup column */}
                {open_edit_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Edit Equipment Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Equipment Code
                            </label>
                            <label className="error">
                              {equipmentMasterObjectErrors.equipment_codeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.equipment_code}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup ">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Equipment Name
                            </label>
                            <label className="error">
                              {
                                equipmentMasterObjectErrors.main_equipment_nameError
                              }
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.main_equipment_name}
                            onChange={(e) =>
                              onChangeValue(e, "main_equipment_name", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup ">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Main Assembly Line
                            </label>
                            <label className="error">
                              {
                                equipmentMasterObjectErrors.main_assembly_lineError
                              }
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.main_assembly_line}
                            onChange={(e) =>
                              onChangeValue(e, "main_assembly_line", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Active</label>
                            <label className="error">
                              {equipmentMasterObjectErrors.activeError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={equipmentMasterObject.active}
                            onChange={(e) => onChangeValue(e, "active", null)}
                          >
                            <option value={null}>Select Active</option>
                            <option value="Yes">True</option>
                            <option value="No">False</option>
                          </select>
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Sub Equipment Name
                            </label>
                            <label className="error">
                              {
                                equipmentMasterObjectErrors.sub_equipment_nameError
                              }
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.sub_equipment_name}
                            onChange={(e) =>
                              onChangeValue(e, "sub_equipment_name", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {equipmentMasterObjectErrors.eff_date_fromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                equipmentMasterObject.eff_date_from
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    equipmentMasterObject.eff_date_from
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  equipmentMasterObject.eff_date_from
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "eff_date_from", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {equipmentMasterObjectErrors.eff_date_toError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                equipmentMasterObject.eff_date_to
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    equipmentMasterObject.eff_date_to
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  equipmentMasterObject.eff_date_to
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "eff_date_to", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => UpdateTheItem()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Delete Popup column */}
                {show_delete_confirmations === true ? (
                  <div className="deleteModelstyle">
                    <div className="delete_header_modal">Confirmation</div>
                    <div className=" Confirmation">
                      Are you sure you want to delete?
                    </div>
                    <div className="btnDeletewrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnDeleteItem(item_to_delete)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Add Popup column */}
                {open_add_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">
                      Add Equipment Details
                    </div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Equipment Code
                            </label>
                            <label className="error">
                              {equipmentMasterObjectErrors.equipment_codeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.equipment_code}
                            onChange={(e) =>
                              onChangeValue(e, "equipment_code", null)
                            }
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Equipment Name
                            </label>
                            <label className="error">
                              {
                                equipmentMasterObjectErrors.main_equipment_nameError
                              }
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.main_equipment_name}
                            onChange={(e) =>
                              onChangeValue(e, "main_equipment_name", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup ">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Main Assembly Line
                            </label>
                            <label className="error">
                              {
                                equipmentMasterObjectErrors.main_assembly_lineError
                              }
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.main_assembly_line}
                            onChange={(e) =>
                              onChangeValue(e, "main_assembly_line", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Active</label>
                            <label className="error">
                              {equipmentMasterObjectErrors.activeError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={equipmentMasterObject.active}
                            onChange={(e) => onChangeValue(e, "active", null)}
                          >
                            <option value={null}>Select Active</option>
                            <option value="Yes">True</option>
                            <option value="No">False</option>
                          </select>
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Sub Equipment Name
                            </label>
                            <label className="error">
                              {
                                equipmentMasterObjectErrors.sub_equipment_nameError
                              }
                            </label>
                          </div>
                          <input
                            type="text"
                            value={equipmentMasterObject.sub_equipment_name}
                            onChange={(e) =>
                              onChangeValue(e, "sub_equipment_name", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {equipmentMasterObjectErrors.eff_date_fromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                equipmentMasterObject.eff_date_from
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    equipmentMasterObject.eff_date_from
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  equipmentMasterObject.eff_date_from
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "eff_date_from", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {equipmentMasterObjectErrors.eff_date_toError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                equipmentMasterObject.eff_date_to
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    equipmentMasterObject.eff_date_to
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(
                                  equipmentMasterObject.eff_date_to
                                ).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "eff_date_to", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="savedraftButton"
                          onClick={() => AddTheItem(true)}
                        >
                          Save as Draft
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => AddTheItem(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
            <LogoutConfirmation />
          </>
        ) : null}
        {loading == true ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Equipment;
