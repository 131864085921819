import {createSlice} from '@reduxjs/toolkit';
export const RoleHierarchySlice = createSlice({
  name: 'role_Hierarchy',
  initialState: {
    value: [],
  },
  reducers: {
    populate_role_Hierarchy: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_role_Hierarchy} = RoleHierarchySlice.actions;
export default RoleHierarchySlice.reducer;