import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_production_process } from "./Redux/ProductionProcessSlice";
import { populate_Products } from "./Redux/ProductsSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_Facility } from "./Redux/FacilitySlice";
import { useNavigate } from "react-router-dom";
import NotificationWeb from "./NotificationWeb";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function ProductionProcess() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheFacility();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON +
            "productionProcessMap?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "Product") {
            setProductPopup(false);
          }
        });
      }
    }, 2000);
  }, []);
  let product = useSelector((state) => state.callProducts.value);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const [productArray, setProductArray] = useState([]);
  const FetchTheProduct = (_ProductionProcessMap) => {
    if (product.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "productMaster")
        .then((response) => {
          if (response.status == 200) {
            let tempProductMaster = [];
            let tempProductArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempProductMaster.push(response.data[i]);
                if (
                  tempProductArray.findIndex(
                    (e) => e === response.data[i].productName
                  ) == -1
                ) {
                  tempProductArray.push(response.data[i].productName);
                }
              }
            }
            dispatch(populate_Products(tempProductMaster));
            let tempProduct = [];
            for (let i = 0; i < tempProductMaster.length; i++) {
              if (
                _ProductionProcessMap.findIndex(
                  (e) => e.productDbId == tempProductMaster[i].productDbId
                ) != -1
              ) {
                tempProduct.push(product[i]);
              }
            }
            setSelectedProducts(tempProduct);
            setProductArray(tempProduct);
          }
        });
    } else {
      // let tempProductMaster = [...product];
      // let tempProduct = [];
      // for (let i = 0; i < tempProductMaster.length; i++) {
      //   tempProduct.push(tempProductMaster[i].productName);
      // }
      let tempProduct = [];
      for (let i = 0; i < product.length; i++) {
        if (
          _ProductionProcessMap.findIndex(
            (e) => e.productDbId == product[i].productDbId
          ) != -1
        ) {
          tempProduct.push(product[i]);
        }
      }
      setProductArray(tempProduct);
      setSelectedProducts(tempProduct);
    }
  };
  const [search_product, setsearch_product] = useState("");
  const [search_product_array, setsearch_product_array] = useState([]);
  const OnChangeSearchProduct = (e) => {
    let content = e.target.value;
    setsearch_product(content.toLowerCase());
    let temp = productArray.filter((e) =>
      e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setsearch_product_array(temp);
  };
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  const FetchTheFacility = () => {
    if (facilities.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempFacilityMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              tempFacilityMaster.push(response.data[i]);
            }
            dispatch(populate_Facility(tempFacilityMaster));
          }
        });
    }
  };

  //Get all the facilities
  let facilities = useSelector((state) => state.callFacilities.value);
  //Post Url
  const post_url =
    process.env.REACT_APP_BASE_API_PYTHON + "productionProcessMap";

  let productionProcessMap = useSelector(
    (state) => state.callProductionProcess.value
  );
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [productionProcessObjectErrors, setProductionProcessObjectErrors] =
    useState({
      prodProcessCodeError: "",
      processNameError: "",
      processTypeError: "",
      processStageSeqError: "",
      processRefUrlError: "",
      processDescError: "",
      effDateFromError: "",
      effDateToError: "",
      facilityNameError: "",
      productNameError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "prodProcessCodeError");
    OnChangeErrorValues("", "processNameError");
    OnChangeErrorValues("", "processTypeError");
    OnChangeErrorValues("", "processStageSeqError");
    OnChangeErrorValues("", "processRefUrlError");
    OnChangeErrorValues("", "processDescError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
    OnChangeErrorValues("", "productNameError");
    OnChangeErrorValues("", "facilityNameError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productNameError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          productNameError: error,
        }));
        break;
      case "prodProcessCodeError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          prodProcessCodeError: error,
        }));
        break;

      case "processNameError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          processNameError: error,
        }));
        break;
      case "processTypeError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          processTypeError: error,
        }));
        break;
      case "processStageSeqError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          processStageSeqError: error,
        }));
        break;
      case "processRefUrlError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          processRefUrlError: error,
        }));
        break;
      case "processDescError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          processDescError: error,
        }));
        break;
      case "effDateFromError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          effDateToError: error,
        }));
        break;

      case "facilityNameError":
        setProductionProcessObjectErrors((productionProcessObjectErrors) => ({
          ...productionProcessObjectErrors,
          facilityNameError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;

  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < productionProcessMapArray.length; i++) {
  //     if (
  //       productionProcessMapArray[i].processName !==
  //       null
  //     ) {
  //       if (
  //         productionProcessMapArray[i].processName
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(productionProcessMapArray[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);

    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = productionProcessMap.filter((e) =>
      e.processName.toLowerCase().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (productionProcessMap.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(productionProcessMap[i]);
        }
      } else {
        for (let i = 0; i < productionProcessMap.length; i++) {
          tempArray.push(productionProcessMap[i]);
        }
      }
      total_pages.current = Math.ceil(
        productionProcessMap.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    set_searched_array(tempFilter);
    current_page.current = 1;
    setPaginatedArray(tempArray);
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [productionProcessMapArray, setProductionProcessMapArray] = useState(
    []
  );
  const [show_drafts, set_show_drafts] = useState(false);

  let product_array_ref = useRef([]);
  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [productionProcessObject, setProductionProcessObject] = useState({
    prodProcessCode: "",
    processName: "",
    processType: "",
    processStageSeq: "",
    processRefUrl: "",
    processDesc: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    facilityName: "",
    productName: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  //Declaration Of Delete States
  const [productionProcessObjectDelete, setProductionProcessObjectDelete] =
    useState({
      prodProcessCode: "",
      processName: "",
      processType: "",
      processStageSeq: "",
      processRefUrl: "",
      processDesc: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      facilityName: "",
      productName: "",
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
    });

  const onResetValue = () => {
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      prodProcessCode: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processName: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processType: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processStageSeq: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processRefUrl: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processDesc: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      effDateFrom: new Date(),
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      productName: "",
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      facilityName: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "prodProcessCode":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          prodProcessCode: e.target.value,
        }));
        break;
      case "processName":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          processName: e.target.value,
        }));
        break;
      case "processType":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          processType: e.target.value,
        }));
        break;
      case "processStageSeq":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          processStageSeq: e.target.value,
        }));
        break;
      case "processRefUrl":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          processRefUrl: e.target.value,
        }));
        break;
      case "processDesc":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          processDesc: e.target.value,
        }));
        break;
      case "effDateFrom":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          effDateTo: e.target.value,
        }));
        break;
      case "facilityName":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          facilityName: e.target.value,
        }));
        break;
      case "productName":
        setProductionProcessObject((productionProcessObject) => ({
          ...productionProcessObject,
          productName: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          productionProcessMapArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            productionProcessMapArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(productionProcessMapArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            productionProcessMapArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (productionProcessMapArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(productionProcessMapArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(productionProcessMapArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  const [link_to_update, set_link_to_update] = useState("");
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = productionProcessMap.findIndex(
      (e) => e.prodProcessDbId === _code
    );
    select_deselect_the_record(productionProcessMap[idx].prodProcessDbId);
    set_item_to_delete(_code);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "productionProcessMap?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "productionProcessMap?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };

  const AddTheItem = (status) => {
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          prodProcessCode: productionProcessObject.prodProcessCode,
          processName: productionProcessObject.processName,
          processType: productionProcessObject.processType,
          processStageSeq: productionProcessObject.processStageSeq,
          processRefUrl: productionProcessObject.processRefUrl,
          processDesc: productionProcessObject.processDesc,
          effDateFrom: productionProcessObject.effDateFrom,
          effDateTo:
            status === true
              ? new Date("1900-01-01")
              : productionProcessObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : null,
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : null,
          updatedTimestamp: new Date().toISOString(),
          facilityDbId: Number(productionProcessObject.facilityName),
          productDbId: Number(productionProcessObject.productName),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "productionProcessMap?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEdiProduction = () => {
    let deleteData = {
      prodProcessCode: productionProcessObjectDelete.prodProcessCode,
      processName: productionProcessObjectDelete.processName,
      processType: productionProcessObjectDelete.processType,
      processStageSeq: productionProcessObjectDelete.processStageSeq,
      processRefUrl: productionProcessObjectDelete.processRefUrl,
      processDesc: productionProcessObjectDelete.processDesc,
      effDateFrom: GetServerDate(productionProcessObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      facilityDbId: Number(productionProcessObjectDelete.facilityName),
      productDbId: Number(productionProcessObjectDelete.productName),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      prodProcessCode: productionProcessObject.prodProcessCode,
      processName: productionProcessObject.processName,
      processType: productionProcessObject.processType,
      processStageSeq: productionProcessObject.processStageSeq,
      processRefUrl: productionProcessObject.processRefUrl,
      processDesc: productionProcessObject.processDesc,
      effDateFrom: GetServerDate(productionProcessObject.effDateFrom),
      effDateTo: GetServerDate(productionProcessObject.effDateTo),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      facilityDbId: Number(productionProcessObject.facilityName),
      productDbId: Number(productionProcessObject.productName),
    };
    axios
      .patch(post_url + "/" + selectedprodDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON +
                    "productionProcessMap?size=1000",
                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedprodDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  function isValidHttpUrl(str) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return pattern.test(str);
  }
  function isFloat(n) {
    return Number(n) === Number(n) || Number(n) % 1 !== 0;
  }
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Production Process Map") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "prodProcessCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.prodProcessCode.length === 0
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  prodProcessCodeError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "processStageSeq":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.processStageSeq.length === 0
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  processStageSeqError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "processName":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.processName.length == 0
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  processNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            if (
              clientSideValidations[i].validationType === "min_length" &&
              productionProcessObject.processName.length > 0 &&
              productionProcessObject.processName.length < 3
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  processNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "processDesc":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.processDesc.length == 0
            ) {
              temp++;
              OnChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "processDescError"
              );
            }
            break;
          case "processType":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.processType.length == 0
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  processTypeError: clientSideValidations[i].shortMessage,
                })
              );
            }
            if (
              clientSideValidations[i].validationType === "min_length" &&
              productionProcessObject.processType.length > 1
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  processTypeError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "facilityName":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.facilityName.length == 0
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  facilityNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;

          case "processRefUrl":
            if (productionProcessObject.processRefUrl != null) {
              if (
                clientSideValidations[i].validationType === "url" &&
                productionProcessObject.processRefUrl.length > 0 &&
                isValidHttpUrl(productionProcessObject.processRefUrl) === false
              ) {
                temp++;
                setProductionProcessObjectErrors(
                  (productionProcessObjectErrors) => ({
                    ...productionProcessObjectErrors,
                    processRefUrlError: clientSideValidations[i].shortMessage,
                  })
                );
              }
            }
            break;
          case "effDateTo":
            if (
              clientSideValidations[i].validationType ===
                "greater_than_equalto_date" &&
              productionProcessObject.effDateTo <
                productionProcessObject.effDateFrom
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  effDateToError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "productName":
            if (
              clientSideValidations[i].validationType === "required" &&
              productionProcessObject.productName.length == 0
            ) {
              temp++;
              setProductionProcessObjectErrors(
                (productionProcessObjectErrors) => ({
                  ...productionProcessObjectErrors,
                  productNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = productionProcessMap.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        productionProcessMap.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (productionProcessMap.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < productionProcessMap.length; i++) {
          temp_display_array.push(productionProcessMap[i]);
        }
      } else {
        if (
          productionProcessMap.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          productionProcessMap.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < productionProcessMap.length;
            i++
          ) {
            temp_display_array.push(productionProcessMap[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(productionProcessMap[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_productionProcess = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_productionProcess.push(response.data[i]);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);
          setProductionProcessMapArray(tempNonDraftArray);
          dispatch(populate_production_process(temp_productionProcess));
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheProduct(tempNonDraftArray);
          FetchTheValidation();
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectedprodDbId, setSelectedprodProcessDbId] = useState("");
  //View Modal||Edit Modal
  const OpenModal = (prodProcessDbId, type) => {
    setSelectedprodProcessDbId(prodProcessDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(prodProcessDbId);
  };
  const SupplyValuesToModal = (prodProcessDbId) => {
    let dateFrom = productionProcessMap.find(
      (e) => e.prodProcessDbId == prodProcessDbId
    ).effDateFrom;
    let dateTo = productionProcessMap.find(
      (e) => e.prodProcessDbId == prodProcessDbId
    ).effDateTo;
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      prodProcessCode: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).prodProcessCode,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processName: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processName,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processType: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processType,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processStageSeq: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processStageSeq,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processRefUrl: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processRefUrl,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      processDesc: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processDesc,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      effDateFrom: dateFrom,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      effDateTo: dateTo,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      facilityName: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).facilityDbId,
    }));
    setProductionProcessObject((productionProcessObject) => ({
      ...productionProcessObject,
      productName: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).productDbId,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      prodProcessCode: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).prodProcessCode,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      processName: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processName,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      processType: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processType,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      processStageSeq: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processStageSeq,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      processRefUrl: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processRefUrl,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      processDesc: productionProcessMap.find(
        (e) => e.prodProcessDbId == prodProcessDbId
      ).processDesc,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      effDateFrom: dateFrom,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      effDateTo: dateTo,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      facilityName: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).facilityDbId,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      productName: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).productDbId,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      createdBy: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).createdBy,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      updatedBy: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).updatedBy,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      createdTimestamp: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).createdTimestamp,
    }));
    setProductionProcessObjectDelete((productionProcessObjectDelete) => ({
      ...productionProcessObjectDelete,
      updatedTimestamp: productionProcessMap.find(
        (e1) => e1.prodProcessDbId == prodProcessDbId
      ).updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortFacilitie = [...productionProcessMapArray];
    if (sortStyle === "Descending") {
      let updatedProduction = SortFacilitie.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.prodProcessCode
            : action === "ProcessName"
            ? a.processName
            : null;
        const b_res =
          action === "Code"
            ? b.prodProcessCode
            : action === "ProcessName"
            ? b.processName
            : null;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedProduction);
      setSortStyle("Ascending");
      console.log("Sorting:" + updatedProduction);
      setProductionProcessMapArray(updatedProduction);
    } else if (sortStyle === "Ascending") {
      let updatedProduction = SortFacilitie.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.prodProcessCode
            : action === "ProcessName"
            ? a.processName
            : null;
        const b_res =
          action === "Code"
            ? b.prodProcessCode
            : action === "ProcessName"
            ? b.processName
            : null;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedProduction);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortFacilitie.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    } else {
      for (let i = 0; i < SortFacilitie.length; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortFacilitie.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setProductionProcessMapArray(SortFacilitie);
    setPaginatedArray(tempArray);
  };
  const [productPopup, setProductPopup] = useState(false);

  const OnClickProduct = () => {
    if (productionProcessMap.length == 0) {
      setProductPopup(false);
    } else {
      setProductPopup(!productPopup);
    }
  };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const AddRemoveProduct = (id) => {
    let tempSelectedProduct = [...selectedProducts];
    let productIndex = tempSelectedProduct.findIndex(
      (e) => e.productDbId == id
    );
    if (productIndex == -1) {
      tempSelectedProduct.push(product.find((e) => e.productDbId == id));
    } else {
      tempSelectedProduct.splice(productIndex, 1);
    }
    setSelectedProducts(tempSelectedProduct);
  };
  const OnPressedClearClicked = (productDbId) => {
    let tempSelectedProduct = [];
    if (selectedProducts.length == 0) {
      for (let i = 0; i < product.length; i++) {
        tempSelectedProduct.push(product[i].productDbId);
      }
    } else {
      tempSelectedProduct.splice(
        tempSelectedProduct.findIndex((e) => e === productDbId),
        1
      );
      setSelectedProducts(tempSelectedProduct);
    }
    setSelectedProducts(tempSelectedProduct);
  };
  const OnOkProduct = () => {
    let temp = [...productionProcessMap];
    let updatedArray = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].productDbId == selectedProducts[i].productDbId
          // product.find((e) => e.productName == selectedProducts[i]).productDbId
        ) {
          if (
            new Date(temp[j].effDateTo).getTime() ==
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setProductionProcessMapArray(updatedArray);
    setPaginatedArray(tempArray);
    //  set_selected_form_id(e.target.value)
    setProductPopup(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="grid-cols-1 h-0"></div>
                <div className="flex flex-col  grid-cols-1">
                  <div className="manageHeader">
                    {" "}
                    Manage Production Process Sequence
                  </div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center  grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Production Process"
                      value={search_content}
                      onChange={(e) => SearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons " src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "14%" }} />
                      <col span="1" style={{ width: "24%" }} />
                      <col span="1" style={{ width: "18%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col
                        span="1"
                        style={{
                          width: "8%",
                        }}
                      />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Code")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Prod. Process Code
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("ProcessName")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Production Process Name
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>

                        <th>
                          {productPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Product"
                                        type="text"
                                        placeholder="Search"
                                        value={search_product}
                                        onChange={(e) =>
                                          OnChangeSearchProduct(e)
                                        }
                                      />
                                      <div
                                        id="Product"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedProducts(productArray)
                                        }
                                      >
                                        <img
                                          id="Product"
                                          className="Icons"
                                          src={
                                            productArray.length ==
                                            selectedProducts.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Product">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_product.length === 0
                                        ? productArray.map((item, index) => (
                                            <div
                                              id="Product"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveProduct(
                                                  item.productDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="Product"
                                                className="Icons"
                                                src={
                                                  selectedProducts.findIndex(
                                                    (e) =>
                                                      e.productDbId ==
                                                      item.productDbId
                                                  ) !== -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="Product">
                                                {item.productName}
                                              </p>
                                            </div>
                                          ))
                                        : search_product.length > 0
                                        ? search_product_array.map(
                                            (item, index) => (
                                              <div
                                                id="Product"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveProduct(
                                                    item.productDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Product"
                                                  className="Icons"
                                                  src={
                                                    selectedProducts.findIndex(
                                                      (e) =>
                                                        e.productDbId ==
                                                        item.productDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Product">
                                                  {item.productName}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Product"
                                      className="popupfile_button"
                                      onClick={() => OnOkProduct()}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Product"
                                      className="popupfile_button"
                                      onClick={() => OnPressedClearClicked()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="Product"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Product
                            <img
                              id="Product"
                              className="Icons"
                              onClick={() => OnClickProduct()}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>Process Type </th>
                        <th>Prod. Stage Seq</th>
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.prodProcessDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.prodProcessCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {/* {
                              product.find(
                                (e) => e.productDbId === item.productDbId
                              ).productName
                            } */}
                                {product.length > 0 &&
                                product.findIndex(
                                  (e) => e.productDbId == item.productDbId
                                ) != -1
                                  ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processType}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processStageSeq}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.prodProcessDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.prodProcessDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.prodProcessDbId
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.prodProcessDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.prodProcessCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {product.length > 0 &&
                                product.findIndex(
                                  (e) => e.productDbId == item.productDbId
                                ) != -1
                                  ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processType}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processStageSeq}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.prodProcessDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.prodProcessDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.prodProcessDbId
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.prodProcessDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.prodProcessCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {product.length > 0 &&
                                product.findIndex(
                                  (e) => e.productDbId == item.productDbId
                                ) != -1
                                  ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processType}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {item.processStageSeq}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.prodProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.prodProcessDbId
                                  )
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.prodProcessDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.prodProcessDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.prodProcessDbId
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    View Production Process Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Code
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productionProcessObject.prodProcessCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Name
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productionProcessObject.processName}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            product.find(
                              (e) =>
                                e.productDbId ===
                                productionProcessObject.productName
                            ).productName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Type
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productionProcessObject.processType}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Process Stage Sequence
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productionProcessObject.processStageSeq}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Reference URL</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productionProcessObject.processRefUrl}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            facilities.find(
                              (e) =>
                                e.facilityDbId ===
                                productionProcessObject.facilityName
                            ).name
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={productionProcessObject.processDesc}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              productionProcessObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productionProcessObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                productionProcessObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              productionProcessObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productionProcessObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                productionProcessObject.effDateTo
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectedprodDbId, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Edit Production Process Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Code
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.prodProcessCodeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.prodProcessCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Name
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.processNameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processName}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                          <label className="error">
                            {productionProcessObjectErrors.productNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={productionProcessObject.productName}
                          onChange={(e) =>
                            onChangeValue(e, "productName", null)
                          }
                        >
                          <option value={null}>Select....</option>
                          {product.length > 0
                            ? product.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.productDbId}>
                                    {item.productName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Type
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.processTypeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          maxLength={1}
                          value={productionProcessObject.processType}
                          onChange={(e) =>
                            onChangeValue(e, "processType", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Process Stage Sequence
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.processStageSeqError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processStageSeq}
                          onChange={(e) =>
                            onChangeValue(e, "processStageSeq", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Reference URL</label>
                          <label className="error">
                            {productionProcessObjectErrors.processRefUrlError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processRefUrl}
                          onChange={(e) =>
                            onChangeValue(e, "processRefUrl", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                          <label className="error">
                            {productionProcessObjectErrors.processDescError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processDesc}
                          onChange={(e) =>
                            onChangeValue(e, "processDesc", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {productionProcessObjectErrors.facilityName}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={productionProcessObject.facilityName}
                          onChange={(e) =>
                            onChangeValue(e, "facilityName", null)
                          }
                        >
                          <option value={null} className="Poppins">
                            Select....
                          </option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                                new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {productionProcessObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              productionProcessObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productionProcessObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                productionProcessObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {productionProcessObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              productionProcessObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productionProcessObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                productionProcessObject.effDateTo
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        ></input>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEdiProduction()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Add Production Process Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Code
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.prodProcessCodeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.prodProcessCode}
                          onChange={(e) =>
                            onChangeValue(e, "prodProcessCode", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Name
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.processNameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processName}
                          onChange={(e) =>
                            onChangeValue(e, "processName", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                          <label className="error">
                            {productionProcessObjectErrors.productNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={productionProcessObject.productName}
                          onChange={(e) =>
                            onChangeValue(e, "productName", null)
                          }
                        >
                          <option value={null} className="Poppins">
                            Select...
                          </option>
                          {product.length > 0
                            ? product.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.productDbId}>
                                    {item.productName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process Type
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.processTypeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          maxLength={1}
                          value={productionProcessObject.processType}
                          onChange={(e) =>
                            onChangeValue(e, "processType", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Process Stage Sequence
                          </label>
                          <label className="error">
                            {productionProcessObjectErrors.processStageSeqError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processStageSeq}
                          onChange={(e) =>
                            onChangeValue(e, "processStageSeq", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Reference URL</label>
                          <label className="error">
                            {productionProcessObjectErrors.processRefUrlError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processRefUrl}
                          onChange={(e) =>
                            onChangeValue(e, "processRefUrl", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                          <label className="error">
                            {productionProcessObjectErrors.processDescError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={productionProcessObject.processDesc}
                          onChange={(e) =>
                            onChangeValue(e, "processDesc", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {productionProcessObjectErrors.facilityNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={productionProcessObject.facilityName}
                          onChange={(e) =>
                            onChangeValue(e, "facilityName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                                new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {productionProcessObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              productionProcessObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productionProcessObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                productionProcessObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {productionProcessObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              productionProcessObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  productionProcessObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                productionProcessObject.effDateTo
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            productionProcessObject(e, "effDateTo", null)
                          }
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}

      {productPopup == true ? (
        <div className="FilterMobile">
          {productPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Product"
                      type="text"
                      placeholder="Search"
                      value={search_product}
                      onChange={(e) => OnChangeSearchProduct(e)}
                    />
                    <div
                      id="Product"
                      className="Imageflex"
                      onClick={() => setSelectedProducts(productArray)}
                    >
                      <img
                        id="Product"
                        className="Icons"
                        src={
                          productArray.length == selectedProducts.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Product">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_product.length === 0
                      ? productArray.map((item, index) => (
                          <div
                            id="Product"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveProduct(item.productDbId)}
                          >
                            <img
                              id="Product"
                              className="Icons"
                              src={
                                selectedProducts.findIndex(
                                  (e) => e.productDbId === item.productDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Product">{item.productName}</p>
                          </div>
                        ))
                      : search_product.length > 0
                      ? search_product_array.map((item, index) => (
                          <div
                            id="Product"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveProduct(item.productDbId)}
                          >
                            <img
                              id="Product"
                              className="Icons"
                              src={
                                selectedProducts.findIndex(
                                  (e) => e.productDbId === item.productDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Product">{item.productName}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Product"
                    className="popupfile_button"
                    onClick={() => OnOkProduct()}
                  >
                    Ok
                  </button>
                  <button
                    id="Product"
                    className="popupfile_button"
                    onClick={() => OnPressedClearClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default ProductionProcess;
