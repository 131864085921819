import {createSlice} from '@reduxjs/toolkit';
export const ClientSideValidationSlice = createSlice({
  name: 'client_side_validation',
  initialState: {
    value: [],
  },
  reducers: {
    populate_client_side_validation: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_client_side_validation} = ClientSideValidationSlice.actions;
export default ClientSideValidationSlice.reducer;
