import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import axios from "axios";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";

const Dashboards = () => {
  const { state } = useLocation();
  const { dashboard_link } = state;
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
        FetchTheEventNotificationLog();
        FetchTheEventRule();
      } else {
      }
    }, 2000);
  }, []);
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full">
        {isAuthenticated == true ? (
          <div>
            <Header />
            <div className="flex flex-row w-full">
              <div className="w-screen h-[85vh] flex flex-col self-center justify-center bg-[#F9FAFE] ">
                <iframe
                  height="100%"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                  }}
                  width="100%"
                  src={dashboard_link}
                ></iframe>
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <LogoutConfirmation />
    </div>
  );
};
export default Dashboards;
