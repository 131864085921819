import { createSlice } from "@reduxjs/toolkit";
export const EventRulesSlice = createSlice({
  name: "eventRules",
  initialState: {
    value: [],
  },
  reducers: {
    populate_Event_Rules: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_Event_Rules } = EventRulesSlice.actions;
export default EventRulesSlice.reducer;
