import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Event_Triggers } from "./Redux/EventTriggersSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_Staff } from "./Redux/StaffSlice";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function EventTriggers() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheEventNotificationLog();
        FetchTheStaff();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "eventTriggers?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "eventRules") {
            setEventRulesPop(false);
          }
        });
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidation = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidation.push(response.data[i]);
          }
          dispatch(populate_client_side_validation(tempClientSideValidation));
        }
      });
  };
  let eventRules = useSelector((state) => state.callEventRules.value);
  const [eventRulesArray, setEventRulesArray] = useState([]);
  const FetchTheRules = (_eventTriggers) => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules?size=1000")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            let tempEventRulesArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].eff_date_to).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                temp.push(response.data[i]);
                if (
                  tempEventRulesArray.findIndex(
                    (e) => e === response.data[i].rule_name
                  ) == -1
                ) {
                  tempEventRulesArray.push(response.data[i].rule_name);
                }
              }
            }
            dispatch(populate_Event_Rules(temp));
            let tempEvents = [];
            for (let i = 0; i < temp.length; i++) {
              if (
                _eventTriggers.findIndex(
                  (e) => e.event_rule_id == temp[i].event_rule_id
                ) != -1
              ) {
                tempEvents.push(temp[i]);
              }
            }
            setEventRulesArray(tempEvents);
            setSelectedEventRulesArray(tempEvents);
          }
        });
    } else {
      // let tempeventRules = [...eventRules];
      // let tempEvents = [];
      // for (let i = 0; i < tempeventRules.length; i++) {
      //   tempEvents.push(tempeventRules[i].rule_name);
      // }
      let tempEvents = [];
      for (let i = 0; i < eventRules.length; i++) {
        if (
          _eventTriggers.findIndex(
            (e) => e.event_rule_id == eventRules[i].event_rule_id
          ) != -1
        ) {
          tempEvents.push(eventRules[i]);
        }
      }
      setSelectedEventRulesArray(tempEvents);
      setEventRulesArray(tempEvents);
    }
  };
  let staff = useSelector((state) => state.callStaff.value);
  const [staffArray, setStaffArray] = useState([]);
  const FetchTheStaff = () => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff")
        .then((response) => {
          if (response.status === 200) {
            let tempStaff = [];
            let tempStaffArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
                console.log("tempStaff" + response.data[i]);
                if (
                  tempStaffArray.findIndex(
                    (e) => e === response.data[i].staffDbId
                  ) == -1
                ) {
                  tempStaffArray.push(
                    response.data[i].firstName + "" + response.data[i].lastName
                  );
                }
                console.log(tempStaffArray);
              }
              setStaffArray(tempStaffArray);
            }
            dispatch(populate_Staff(tempStaff));
          }
        });
    } else {
      let tempStaff = [...staff];
      let tempFirst = [];
      for (let i = 0; i < tempStaff; i++) {
        tempFirst.push(tempStaff[i].firstName + "" + tempStaff[i].lastName);
      }
      setStaffArray(tempFirst);
    }
  };
  const [search_eventRule, setSearch_eventRule] = useState("");
  const [search_eventRuleArray, setSearch_eventRuleArray] = useState([]);
  const OnChangeSearchEventRules = (e) => {
    let content = e.target.value;
    setSearch_eventRule(content.toLowerCase());
    let temp = eventRulesArray.filter((e) =>
      e.rule_name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setSearch_eventRuleArray(temp);
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "eventTriggers";

  let eventTriggers = useSelector((state) => state.callEventTriggers.value);

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [eventTriggersObjectErrors, setEventTriggersObjectErrors] = useState({
    trigger_nameError: "",
    action_typeError: "",
    eventRuleError: "",
    staffError: "",
    eff_date_fromError: "",
    eff_date_toError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "trigger_nameError");
    OnChangeErrorValues("", "action_typeError");
    OnChangeErrorValues("", "eventRuleError");
    OnChangeErrorValues("", "staffError");
    OnChangeErrorValues("", "eff_date_fromError");
    OnChangeErrorValues("", "eff_date_toError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "trigger_nameError":
        setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
          ...eventTriggersObjectErrors,
          trigger_nameError: error,
        }));
        break;

      case "action_typeError":
        setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
          ...eventTriggersObjectErrors,
          action_typeError: error,
        }));
        break;
      case "eventRuleError":
        setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
          ...eventTriggersObjectErrors,
          eventRuleError: error,
        }));
        break;
      case "staffError":
        setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
          ...eventTriggersObjectErrors,
          staffError: error,
        }));
        break;
      case "eff_date_fromError":
        setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
          ...eventTriggersObjectErrors,
          eff_date_fromError: error,
        }));
        break;
      case "eff_date_toError":
        setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
          ...eventTriggersObjectErrors,
          eff_date_toError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    setLoading(true);

    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = eventTriggers.filter((e1) =>
      e1.trigger_name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].eff_date_to).getTime() ===
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (eventTriggers.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(eventTriggers[i]);
        }
      } else {
        for (let i = 0; i < eventTriggers.length; i++) {
          tempArray.push(eventTriggers[i]);
        }
      }
      total_pages.current = Math.ceil(
        eventTriggers.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [eventTriggersArray, setEventTriggersArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [eventTriggerObject, setEventTriggerObject] = useState({
    trigger_name: "",
    action_type: "",
    eventRule: "",
    staffName: "",
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
    created_by: "",
    updated_by: "",
    created_timestamp: new Date(),
    updated_timestamp: new Date(),
  });

  const [eventTriggerObjectDelete, setEventTriggerObjectDelete] = useState({
    trigger_name: "",
    action_type: "",
    eventRule: "",
    staffName: "",
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
    created_by: "",
    updated_by: "",
    created_timestamp: new Date(),
    updated_timestamp: new Date(),
  });
  const onResetValue = () => {
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      trigger_name: "",
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      action_type: "",
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      eventRule: "",
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      staffName: "",
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      eff_date_from: new Date(),
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      eff_date_to: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "trigger_name":
        setEventTriggerObject((eventTriggerObject) => ({
          ...eventTriggerObject,
          trigger_name: e.target.value,
        }));
        break;
      case "action_type":
        setEventTriggerObject((eventTriggerObject) => ({
          ...eventTriggerObject,
          action_type: e.target.value,
        }));
        break;
      case "eventRule":
        setEventTriggerObject((eventTriggerObject) => ({
          ...eventTriggerObject,
          eventRule: e.target.value,
        }));
        break;
      case "staffName":
        setEventTriggerObject((eventTriggerObject) => ({
          ...eventTriggerObject,
          staffName: e.target.value,
        }));
        break;
      case "eff_date_from":
        setEventTriggerObject((eventTriggerObject) => ({
          ...eventTriggerObject,
          eff_date_from: e.target.value,
        }));
        break;
      case "eff_date_to":
        setEventTriggerObject((eventTriggerObject) => ({
          ...eventTriggerObject,
          eff_date_to: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          eventTriggersArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            eventTriggersArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(eventTriggersArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            eventTriggersArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (eventTriggersArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(eventTriggersArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(eventTriggersArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);

  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = eventTriggers.findIndex((e) => e.event_trigger_id === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(eventTriggers[idx]);
    set_item_to_delete(eventTriggers[idx].event_trigger_id);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    console.log("code" + _code);
    console.log("delete_multiple" + multiple_codes);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updated_timestamp: new Date().toISOString(),
          eff_date_to: OneBack(),
          updated_by: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "eventTriggers?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updated_timestamp: new Date().toISOString(),
            eff_date_to: OneBack(),
            updated_by: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "eventTriggers?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      eff_date_from: eventTriggerObject.eff_date_from,
      eff_date_to:
        status === true
          ? new Date("1900-01-01")
          : eventTriggerObject.eff_date_to,
      trigger_name: eventTriggerObject.trigger_name,
      action_type: eventTriggerObject.action_type,
      event_rule_id: Number(eventTriggerObject.eventRule),
      staff_db_id: Number(eventTriggerObject.staffName),
      created_by: isAuthenticated === true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "eventTriggers?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditEventTriggers = () => {
    console.log("Updating" + post_url + "/" + selectedEvent_trigger_id);
    let deleteData = {
      eff_date_from: GetServerDate(eventTriggerObjectDelete.eff_date_from),
      eff_date_to: OneBack(),
      trigger_name: eventTriggerObjectDelete.trigger_name,
      action_type: eventTriggerObjectDelete.action_type,
      event_rule_id: Number(eventTriggerObjectDelete.eventRule),
      staff_db_id: Number(eventTriggerObjectDelete.staffName),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    let data = {
      eff_date_from: GetServerDate(eventTriggerObject.eff_date_from),
      eff_date_to: GetServerDate(eventTriggerObject.eff_date_to),
      trigger_name: eventTriggerObject.trigger_name,
      action_type: eventTriggerObject.action_type,
      event_rule_id: Number(eventTriggerObject.eventRule),
      staff_db_id: Number(eventTriggerObject.staffName),
      created_by: isAuthenticated === true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    axios
      .patch(post_url + "/" + selectedEvent_trigger_id, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON +
                    "eventTriggers?size=1000",

                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(
          error.message + ":" + post_url + "/" + selectedEvent_trigger_id
        );
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Event Triggers") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "triggerName":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventTriggerObject.trigger_name.length == 0
            ) {
              temp++;
              setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
                ...eventTriggersObjectErrors,
                trigger_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            // if (
            //   clientSideValidations[i].validationType === "duplicate" &&
            //   eventTriggersArray.findIndex(
            //     (e) => e.trigger_name === eventTriggerObject.trigger_name
            //   ) != -1
            // ) {
            //   temp++;
            //   setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
            //     ...eventTriggersObjectErrors,
            //     trigger_nameError: clientSideValidations[i].shortMessage,
            //   }));
            // }
            break;
          case "staffDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventTriggerObject.staffName == 0
            ) {
              temp++;
              setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
                ...eventTriggersObjectErrors,
                staffError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "actionType":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventTriggerObject.action_type.length == 0
            ) {
              temp++;
              setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
                ...eventTriggersObjectErrors,
                action_typeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "eventRuleId":
            if (
              clientSideValidations[i].validationType === "required" &&
              eventTriggerObject.eventRule == 0
            ) {
              temp++;
              setEventTriggersObjectErrors((eventTriggersObjectErrors) => ({
                ...eventTriggersObjectErrors,
                eventRuleError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = eventTriggers.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        eventTriggers.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (eventTriggers.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < eventTriggers.length; i++) {
          temp_display_array.push(eventTriggers[i]);
        }
      } else {
        if (
          eventTriggers.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          eventTriggers.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < eventTriggers.length;
            i++
          ) {
            temp_display_array.push(eventTriggers[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(eventTriggers[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_eventTriggers = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];

    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_eventTriggers.push(response.data[i]);

            if (
              new Date(response.data[i].eff_date_to).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setEventTriggersArray(tempNonDraftArray);

            if (
              new Date(response.data[i].eff_date_to).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_Event_Triggers(temp_eventTriggers));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheRules(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectedEvent_trigger_id, setselectedEvent_trigger_id] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (event_trigger_id, type) => {
    setselectedEvent_trigger_id(event_trigger_id);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(event_trigger_id);
  };
  const SupplyValuesToModal = (event_trigger_id) => {
    let dateFrom = eventTriggers.find(
      (e) => e.event_trigger_id == event_trigger_id
    ).eff_date_from;
    let dateTo = eventTriggers.find(
      (e) => e.event_trigger_id == event_trigger_id
    ).eff_date_to;
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      trigger_name: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).trigger_name,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      trigger_name: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).trigger_name,
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      action_type: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).action_type,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      action_type: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).action_type,
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      eventRule: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).event_rule_id,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      eventRule: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).event_rule_id,
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      staffName: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).staff_db_id,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      staffName: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).staff_db_id,
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      eff_date_from: dateFrom,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      eff_date_from: dateFrom,
    }));
    setEventTriggerObject((eventTriggerObject) => ({
      ...eventTriggerObject,
      eff_date_to: dateTo,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      eff_date_to: dateTo,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      created_by: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).created_by,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      updated_by: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).updated_by,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      created_timestamp: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).created_timestamp,
    }));
    setEventTriggerObjectDelete((eventTriggerObjectDelete) => ({
      ...eventTriggerObjectDelete,
      updated_timestamp: eventTriggers.find(
        (e) => e.event_trigger_id == event_trigger_id
      ).updated_timestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SorteventTriggers = [...eventTriggersArray];
    if (sortStyle === "Descending") {
      let updatedeventTriggers = SorteventTriggers.sort((a, b) => {
        const a_res = action === "Code" ? a.trigger_name : a.action_type;
        const b_res = action === "Code" ? b.trigger_name : b.action_type;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedeventTriggers);
      setSortStyle("Ascending");
      setEventTriggersArray(updatedeventTriggers);
    } else if (sortStyle === "Ascending") {
      let updatedeventTriggers = SorteventTriggers.sort((a, b) => {
        const a_res = action === "Code" ? a.trigger_name : a.action_type;
        const b_res = action === "Code" ? b.trigger_name : b.action_type;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedeventTriggers);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SorteventTriggers.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SorteventTriggers[i]);
      }
    } else {
      for (let i = 0; i < SorteventTriggers.length; i++) {
        tempArray.push(SorteventTriggers[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SorteventTriggers.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setEventTriggersArray(SorteventTriggers);
    setPaginatedArray(tempArray);
  };
  const [eventRulesPop, setEventRulesPop] = useState(false);

  const OnClickeventRules = () => {
    // let temp = [];
    // if (selectedeventRulesArray.length == 0) {
    //   for (let i = 0; i < eventRules.length; i++) {
    //     temp.push(eventRules[i].form_db_id);
    //   }
    // } else {
    //   for (let i = 0; i < selectedeventRulesArray.length; i++) {
    //     temp.push(selectedeventRulesArray[i]);
    //   }
    // }
    // setSelectedEventRulesArray(temp);
    if (eventTriggers.length > 0) {
      setEventRulesPop(false);
    }
    setEventRulesPop(!eventRulesPop);
  };
  const [selectedeventRulesArray, setSelectedEventRulesArray] = useState([]);
  const AddRemoveEventRules = (id) => {
    let tempselectedeventRulesArray = [...selectedeventRulesArray];
    let eventRulesIndex = tempselectedeventRulesArray.findIndex(
      (e) => e.event_rule_id == id
    );
    if (eventRulesIndex == -1) {
      tempselectedeventRulesArray.push(
        eventRules.find((e) => e.event_rule_id == id)
      );
    } else {
      tempselectedeventRulesArray.splice(eventRulesIndex, 1);
    }
    setSelectedEventRulesArray(tempselectedeventRulesArray);
  };
  const OnPressedClearClicked = (form_db_id) => {
    let tempselectedeventRulesArray = [];
    if (selectedeventRulesArray.length == 0) {
      for (let i = 0; i < eventRules.length; i++) {
        tempselectedeventRulesArray.push(eventRules[i].form_db_id);
      }
    } else {
      tempselectedeventRulesArray.splice(
        tempselectedeventRulesArray.findIndex((e) => e === form_db_id),
        1
      );
      setSelectedEventRulesArray(tempselectedeventRulesArray);
    }
    setSelectedEventRulesArray(tempselectedeventRulesArray);
  };

  const OnOkEventRules = () => {
    let temp = [...eventTriggers];
    let updatedArray = [];
    for (let i = 0; i < selectedeventRulesArray.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].event_rule_id === selectedeventRulesArray[i].event_rule_id
          // eventRules.find((e) => e.rule_name == selectedeventRulesArray[i])
          //   .event_rule_id
        ) {
          if (
            new Date(temp[j].eff_date_to).getTime() ==
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setEventTriggersArray(updatedArray);
    setPaginatedArray(tempArray);
    setEventRulesPop(false);
  };

  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />
          <div className="flex flex-row">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader">
                    {" "}
                    Manage Event Notifications
                  </div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Event Notifications"
                      value={search_content}
                      onChange={(e) => onChangeSearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons" src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "17%" }} />
                      <col span="1" style={{ width: "15%" }} />
                      <col span="1" style={{ width: "27%" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Code")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Notification Name
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Name")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Notification Type
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          {eventRulesPop === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="flex flex-col gap-2">
                                    <input
                                      id="eventRules"
                                      type="text"
                                      placeholder="Search"
                                      value={search_eventRule}
                                      onChange={(e) =>
                                        OnChangeSearchEventRules(e)
                                      }
                                    />
                                    <div
                                      id="eventRules"
                                      className="Imageflex"
                                      onClick={() =>
                                        setSelectedEventRulesArray(
                                          eventRulesArray
                                        )
                                      }
                                    >
                                      <img
                                        id="eventRules"
                                        className="Icons"
                                        src={
                                          eventRulesArray.length ==
                                          selectedeventRulesArray.length
                                            ? CheckIcon
                                            : UnCheckIcon
                                        }
                                        alt=""
                                      />
                                      <p id="eventRules">Select All</p>
                                    </div>
                                  </div>
                                  <div className="popupfile-data">
                                    {search_eventRule.length === 0
                                      ? eventRulesArray.map((item, index) => (
                                          <div
                                            id="eventRules"
                                            key={index}
                                            className="Imageflex"
                                            onClick={() =>
                                              AddRemoveEventRules(
                                                item.event_rule_id
                                              )
                                            }
                                          >
                                            <img
                                              id="eventRules"
                                              className="Icons"
                                              src={
                                                selectedeventRulesArray.findIndex(
                                                  (e) =>
                                                    e.event_rule_id ==
                                                    item.event_rule_id
                                                ) != -1
                                                  ? CheckIcon
                                                  : UnCheckIcon
                                              }
                                              alt=""
                                            />
                                            <p id="eventRules">
                                              {item.rule_name}
                                            </p>
                                          </div>
                                        ))
                                      : search_eventRule.length > 0
                                      ? search_eventRuleArray.map(
                                          (item, index) => (
                                            <div
                                              id="eventRules"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveEventRules(
                                                  item.event_rule_id
                                                )
                                              }
                                            >
                                              <img
                                                id="eventRules"
                                                className="Icons"
                                                src={
                                                  selectedeventRulesArray.findIndex(
                                                    (e) =>
                                                      e.event_rule_id ==
                                                      item.event_rule_id
                                                  ) !== -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="eventRules">
                                                {item.rule_name}
                                              </p>
                                            </div>
                                          )
                                        )
                                      : null}
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="eventRules"
                                      className="popupfile_button"
                                      onClick={() => OnOkEventRules()}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="eventRules"
                                      className="popupfile_button"
                                      onClick={() => OnPressedClearClicked()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="eventRules"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Event Rule
                            <img
                              id="eventRules"
                              className="Icons"
                              onClick={() => OnClickeventRules()}
                              src={FilterIcon}
                              alt=""
                            />
                          </div>
                        </th>
                        <th>Staff</th>
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.event_trigger_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {item.trigger_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {item.action_type}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {eventRules.length > 0 &&
                                eventRules.findIndex(
                                  (e) => e.event_rule_id == item.event_rule_id
                                ) != -1
                                  ? eventRules.find(
                                      (e) =>
                                        e.event_rule_id == item.event_rule_id
                                    ).rule_name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {staff.length > 0 &&
                                staff.findIndex(
                                  (e) => e.staffDbId == item.staff_db_id
                                ) != -1
                                  ? staff.find(
                                      (e) => e.staffDbId == item.staff_db_id
                                    ).firstName +
                                    " " +
                                    staff.find(
                                      (e) => e.staffDbId == item.staff_db_id
                                    ).lastName
                                  : null}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_trigger_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_trigger_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.event_trigger_id
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.event_trigger_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {item.trigger_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {item.action_type}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {eventRules.length > 0 &&
                                eventRules.findIndex(
                                  (e) => e.event_rule_id == item.event_rule_id
                                ) != -1
                                  ? eventRules.find(
                                      (e) =>
                                        e.event_rule_id == item.event_rule_id
                                    ).rule_name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {staff.length > 0 &&
                                staff.findIndex(
                                  (e) => e.staffDbId == item.staff_db_id
                                ) != -1
                                  ? staff.find(
                                      (e) => e.staffDbId == item.staff_db_id
                                    ).firstName +
                                    " " +
                                    staff.find(
                                      (e) => e.staffDbId == item.staff_db_id
                                    ).lastName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_trigger_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_trigger_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.event_trigger_id
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.event_trigger_id
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {item.trigger_name}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {item.action_type}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {eventRules.length > 0 &&
                                eventRules.findIndex(
                                  (e) => e.event_rule_id == item.event_rule_id
                                ) != -1
                                  ? eventRules.find(
                                      (e) =>
                                        e.event_rule_id == item.event_rule_id
                                    ).rule_name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {staff.length > 0 &&
                                staff.findIndex(
                                  (e) => e.staffDbId == item.staff_db_id
                                ) != -1
                                  ? staff.find(
                                      (e) => e.staffDbId == item.staff_db_id
                                    ).firstName +
                                    " " +
                                    staff.find(
                                      (e) => e.staffDbId == item.staff_db_id
                                    ).lastName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.eff_date_from).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.eff_date_from).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.eff_date_from).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.event_trigger_id
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.event_trigger_id
                                  )
                                }
                              >
                                {new Date(item.eff_date_to).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.eff_date_to).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.eff_date_to).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_trigger_id, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.event_trigger_id, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(
                                        item.event_trigger_id
                                      )
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    View Event Notifications Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Notification Name
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={eventTriggerObject.trigger_name}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Notification Type
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={eventTriggerObject.action_type}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Event Rule</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            eventRules.find(
                              (e) =>
                                e.event_rule_id === eventTriggerObject.eventRule
                            ).rule_name
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Staff</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            staff.findIndex(
                              (e) =>
                                e.staffDbId === eventTriggerObject.staffName
                            ) != -1
                              ? staff.find(
                                  (e) =>
                                    e.staffDbId === eventTriggerObject.staffName
                                ).firstName +
                                " " +
                                staff.find(
                                  (e) =>
                                    e.staffDbId === eventTriggerObject.staffName
                                ).lastName
                              : null
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              eventTriggerObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventTriggerObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventTriggerObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              eventTriggerObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventTriggerObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventTriggerObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() =>
                          OpenModal(selectedEvent_trigger_id, "Edit")
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Edit Event Notifications Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Notification Name
                          </label>
                          <label className="error">
                            {eventTriggersObjectErrors.trigger_nameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={eventTriggerObject.trigger_name}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Notification Type
                          </label>
                          <label className="error">
                            {eventTriggersObjectErrors.action_typeError}
                          </label>
                        </div>
                        <select
                          type="text"
                          value={eventTriggerObject.action_type}
                          onChange={(e) =>
                            onChangeValue(e, "action_type", null)
                          }
                          className="child_input"
                        >
                          <option>Select...</option>
                          <option value="app">App</option>
                          <option value="email">Email</option>
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Event Rule</label>
                          <label className="error">
                            {eventTriggersObjectErrors.eventRuleError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={eventTriggerObject.eventRule}
                          onChange={(e) => onChangeValue(e, "eventRule", null)}
                        >
                          <option value={null}>Select....</option>
                          {eventRules.length > 0
                            ? eventRules.map((item, index) => (
                                <option key={index} value={item.event_rule_id}>
                                  {item.rule_name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Staff</label>
                          <label className="error">
                            {eventTriggersObjectErrors.staffError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={eventTriggerObject.staffName}
                          onChange={(e) => onChangeValue(e, "staffName", null)}
                        >
                          <option value={null}>Select....</option>
                          {staff.length > 0
                            ? staff.map((item, index) => (
                                <option key={index} value={item.staffDbId}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {eventTriggersObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              eventTriggerObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventTriggerObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventTriggerObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {eventTriggersObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              eventTriggerObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventTriggerObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventTriggerObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEditEventTriggers()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Add Event Notifications Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Notification Name
                          </label>
                          <label className="error">
                            {eventTriggersObjectErrors.trigger_nameError}
                          </label>
                        </div>
                        <input
                          onChange={(e) =>
                            onChangeValue(e, "trigger_name", null)
                          }
                          type="text"
                          value={eventTriggerObject.trigger_name}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Notification Type
                          </label>
                          <label className="error">
                            {eventTriggersObjectErrors.action_typeError}
                          </label>
                        </div>
                        <select
                          type="text"
                          value={eventTriggerObject.action_type}
                          onChange={(e) =>
                            onChangeValue(e, "action_type", null)
                          }
                          className="child_input"
                        >
                          <option>Select...</option>
                          <option value="app">App</option>
                          <option value="email">Email</option>
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Event Rule</label>
                          <label className="error">
                            {eventTriggersObjectErrors.eventRuleError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={eventTriggerObject.eventRule}
                          onChange={(e) => onChangeValue(e, "eventRule", null)}
                        >
                          <option value={null}>Select....</option>
                          {eventRules.length > 0
                            ? eventRules.map((item, index) => (
                                <option key={index} value={item.event_rule_id}>
                                  {item.rule_name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Staff</label>
                          <label className="error">
                            {eventTriggersObjectErrors.staffError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={eventTriggerObject.staffName}
                          onChange={(e) => onChangeValue(e, "staffName", null)}
                        >
                          <option value={null}>Select....</option>
                          {staff.length > 0
                            ? staff.map((item, index) => (
                                <option key={index} value={item.staffDbId}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {eventTriggersObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              eventTriggerObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventTriggerObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventTriggerObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {eventTriggersObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              eventTriggerObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  eventTriggerObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                eventTriggerObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}

      {eventRulesPop === true ? (
        <div className="FilterMobile">
          {eventRulesPop === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="flex flex-col gap-2">
                  <input
                    id="eventRules"
                    type="text"
                    placeholder="Search"
                    value={search_eventRule}
                    onChange={(e) => OnChangeSearchEventRules(e)}
                  />
                  <div
                    id="eventRules"
                    className="Imageflex"
                    onClick={() => setSelectedEventRulesArray(eventRulesArray)}
                  >
                    <img
                      id="eventRules"
                      className="Icons"
                      src={
                        eventRulesArray.length == selectedeventRulesArray.length
                          ? CheckIcon
                          : UnCheckIcon
                      }
                      alt=""
                    />
                    <p id="eventRules">Select All</p>
                  </div>
                </div>
                <div className="popupfile-data">
                  {search_eventRule.length === 0
                    ? eventRulesArray.map((item, index) => (
                        <div
                          id="eventRules"
                          key={index}
                          className="Imageflex"
                          onClick={() =>
                            AddRemoveEventRules(item.event_rule_id)
                          }
                        >
                          <img
                            id="eventRules"
                            className="Icons"
                            src={
                              selectedeventRulesArray.findIndex(
                                (e) => e.event_rule_id === item.event_rule_id
                              ) != -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="eventRules">{item.rule_name}</p>
                        </div>
                      ))
                    : search_eventRule.length > 0
                    ? search_eventRuleArray.map((item, index) => (
                        <div
                          id="eventRules"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveEventRules(item)}
                        >
                          <img
                            id="eventRules"
                            className="Icons"
                            src={
                              selectedeventRulesArray.findIndex(
                                (e) => e.event_rule_id === item.event_rule_id
                              ) !== -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="eventRules">{item.rule_name}</p>
                        </div>
                      ))
                    : null}
                </div>
                <div className="file_button">
                  <button
                    id="eventRules"
                    className="popupfile_button"
                    onClick={() => OnOkEventRules()}
                  >
                    Ok
                  </button>
                  <button
                    id="eventRules"
                    className="popupfile_button"
                    onClick={() => OnPressedClearClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default EventTriggers;
