import { configureStore } from "@reduxjs/toolkit";
import ProductsReducer from "./ProductsSlice";
import FacilityReducer from "./FacilitySlice";
import ClientSideValidationReducer from "./ClientSideValidationSlice";
import UserReducer from "./UserSlice";
import ScreenReducer from "./ScreenSlice";
import FormReducer from "./FormSlice";
import InspectionParametersReducer from "./InspectionParametersSlice";
import ProductionProcessReducer from "./ProductionProcessSlice";
import QualityProcessReducer from "./QualityProcessSlice";
import RoleHierarchyReducer from "./RoleHierarchySlice";
import DefectCodesReducer from "./DefectCodesSlice";
import FailureCodesReducer from "./FailureCodesSlice";
import SeverityCodesReducer from "./SeverityCodesSlice";
import CompanyReducer from "./CompanySlice";
import StaffReducer from "./StaffSlice";
import EquipmentReducer from "./EquipmentSlice";
import SensorReducer from "./SensorSlice";
import ComplaintReducer from "./ComplaintSlice";
import InspectionRosterReducer from "./InspectionRosterSlice";
import SupplierReducer from "./SupplierSlice";
import PartsReducer from "./PartsSlice";
import BomReducer from "./BomSlice";
import CustomerProductReducer from "./CustomerProductSlice";
import FailureParametersReducer from "./FailureParametersSlice";
import FormSectionAttributeReducer from "./FormSectionAttributeSlice";
import EventRulesReducer from "./EventRulesSlice";
import EventTriggersReducer from "./EventTriggersSlice";
import EventNotificationLogReducer from "./EventNotificationLogSlice";
export const store = configureStore({
  reducer: {
    callProducts: ProductsReducer,
    callFacilities: FacilityReducer,
    callClientSideValidation: ClientSideValidationReducer,
    callUser: UserReducer,
    callScreen: ScreenReducer,
    callForm: FormReducer,
    callInspectionParameter: InspectionParametersReducer,
    callProductionProcess: ProductionProcessReducer,
    callQualityProcess: QualityProcessReducer,
    callRoleHierarchy: RoleHierarchyReducer,
    callDefectCodes: DefectCodesReducer,
    callFailureCodes: FailureCodesReducer,
    callSeverityCodes: SeverityCodesReducer,
    callCompany: CompanyReducer,
    callStaff: StaffReducer,
    callEquipment: EquipmentReducer,
    callSensor: SensorReducer,
    callComplaint: ComplaintReducer,
    callInspectionRoster: InspectionRosterReducer,
    callSupplier: SupplierReducer,
    callParts: PartsReducer,
    callBom: BomReducer,
    callCustomerProduct: CustomerProductReducer,
    callFailureParameters: FailureParametersReducer,
    callFormSectionAttribute: FormSectionAttributeReducer,
    callEventRules: EventRulesReducer,
    callEventTriggers: EventTriggersReducer,
    callEventNotificationLog: EventNotificationLogReducer,
  },
});
