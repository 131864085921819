import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_user } from "../Componects/Redux/UserSlice";

function LogoutConfirmation() {
  const { user, logout, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const LogoutUser = useSelector((state) => state.callUser.value);
  const LogoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({
      logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI },
    });
  };

  return (
    <>
      {LogoutUser == 5 ? (
        <div className="w-screen h-screen absolute flex flex-col items-center justify-center">
          <div className="w-screen h-screen absolute bg-black opacity-[0.7] " />
          <div className="logoutModal">
            <div className="delete_header_modal">Confirmation</div>
            <div className="Confirmation">
              Are you sure you want to logout ?
            </div>
            <div className="btnDeletewrap">
              <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                <button
                  onClick={() => dispatch(populate_user(4))}
                  className="cancelButton "
                >
                  Cancel
                </button>
              </div>
              <div className="col-span-1 w-full flex flex-row items-center 2xl:justify-end xl:justify-end lg:justify-end justify-between gap-3">
                <button
                  onClick={() => dispatch(populate_user(4))}
                  className="cancelButton 2xl:hidden xl:hidden lg:hidden flex w-[25%]"
                >
                  Cancel
                </button>
                <button
                  className="saveButton "
                  onClick={() => LogoutFunction()}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default LogoutConfirmation;
