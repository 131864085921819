import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Products } from "./Redux/ProductsSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { populate_Company } from "./Redux/CompanySlice";
import { populate_quality_process } from "./Redux/QualityProcessSlice";
import { populate_Form } from "./Redux/FormSlice";
import { populate_Staff } from "./Redux/StaffSlice";
import { populate_Facility } from "./Redux/FacilitySlice";
import { populate_inspection_roster } from "./Redux/InspectionRosterSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";

function InspectionRoster() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "inspectionRoster?size=1000",
          "Get"
        );
        FetchCompany();
        FetchClientSideValidation();
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchFacility();
      }
    }, 2000);
  }, []);

  //Get the Facilities
  let facilities = useSelector((state) => state.callFacilities.value);
  const FetchFacility = () => {
    if (facilities.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempFacilityArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempFacilityArray.push(response.data[i]);
              }
            }
            dispatch(populate_Facility(tempFacilityArray));
          }
        });
    }
  };
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchClientSideValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
        "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  const [productArray, setProductArray] = useState([]);
  let product = useSelector((state) => state.callProducts.value);
  const FetchTheProduct = (_InspectionRoster) => {
    if (product.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "productMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempProductMaster = [];
            let tempProductArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempProductMaster.push(response.data[i]);
                if (
                  tempProductArray.findIndex(
                    (e) => e === response.data[i].productName
                  ) == -1
                ) {
                  tempProductArray.push(response.data[i].productName);
                }
              }
            }
            dispatch(populate_Products(tempProductMaster));
            let tempProduct = [];
            for (let i = 0; i < tempProductMaster.length; i++) {
              if (
                _InspectionRoster.findIndex(
                  (e) => e.productDbId == tempProductMaster[i].productDbId
                ) != -1
              ) {
                tempProduct.push(product[i]);
              }
            }
            setSelectedProducts(tempProduct);
            setProductArray(tempProduct);
          }
        });
    } else {
      // let tempProductMaster = [...product];
      // let tempProduct = [];
      // for (let i = 0; i < tempProductMaster.length; i++) {
      //   tempProduct.push(tempProductMaster[i].productName);
      // }
      let tempProduct = [];
      for (let i = 0; i < product.length; i++) {
        if (
          _InspectionRoster.findIndex(
            (e) => e.productDbId == product[i].productDbId
          ) != -1
        ) {
          tempProduct.push(product[i]);
        }
      }
      setProductArray(tempProduct);
      setSelectedProducts(tempProduct);
    }
  };
  const [search_quality, setsearch_quality] = useState("");
  const [search_quality_array, setsearch_quality_array] = useState([]);
  const [search_form, setsearch_form] = useState("");
  const [search_form_array, setsearch_form_array] = useState([]);
  const [search_product, setsearch_product] = useState("");
  const [search_product_array, setsearch_product_array] = useState([]);
  const [search_inspectionstaff, setsearch_inspectionstaff] = useState("");
  const [search_inspectionstaff_array, setsearch_inspectionstaff_array] =
    useState([]);
  const [search_reviwerstaff, setsearch_reviwerstaff] = useState("");
  const [search_reviwerstaff_array, setsearch_reviwerstaff_array] = useState(
    []
  );

  const OnChangeSearchFilter = (_option, e) => {
    switch (_option) {
      case "Product":
        let content = e.target.value;
        setsearch_product(content.toLowerCase());
        let temp = productArray.filter((e) =>
          e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
        );
        setsearch_product_array(temp);
        break;
      case "QualityProcess":
        let contentQualityProcess = e.target.value;
        setsearch_quality(contentQualityProcess.toLowerCase());
        let tempQualityProcess = qualityProcessArray.filter((e) =>
          e.processName
            .toLowerCase()
            .trim()
            .match(contentQualityProcess.toLowerCase().trim())
        );
        setsearch_quality_array(tempQualityProcess);
        break;
      case "FormMaster":
        let contentForm = e.target.value;
        setsearch_form(contentForm.toLowerCase());
        let tempForm = formMasterArray.filter((e) =>
          e.formName
            .toLowerCase()
            .trim()
            .match(contentForm.toLowerCase().trim())
        );
        setsearch_form_array(tempForm);
        break;
      case "InspectionStaff":
        let contentInspectionStaff = e.target.value;
        setsearch_inspectionstaff(contentInspectionStaff.toLowerCase());
        let tempInspectionStaff = inspection_Staff_Array.filter((e) =>
          e.firstName
            .toLowerCase()
            .trim()
            .match(contentInspectionStaff.toLowerCase().trim())
        );
        setsearch_inspectionstaff_array(tempInspectionStaff);
        break;
      case "ReviewerStaff":
        let ReviewerStaff = e.target.value;
        setsearch_reviwerstaff(ReviewerStaff.toLowerCase());
        let tempReviewerStaff = reviewer_Staff_Array.filter((e) =>
          e.firstName
            .toLowerCase()
            .trim()
            .match(ReviewerStaff.toLowerCase().trim())
        );
        setsearch_reviwerstaff_array(tempReviewerStaff);
        break;
    }
  };

  let companyMaster = useSelector((state) => state.callCompany.value);
  const FetchCompany = () => {
    if (companyMaster.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "companyMaster?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempCompanyMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempCompanyMaster.push(response.data[i]);
              }
            }
            dispatch(populate_Company(tempCompanyMaster));
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };
  const [qualityProcessArray, setQualityProcessArray] = useState([]);
  let qualityProcessMap = useSelector(
    (state) => state.callQualityProcess.value
  );
  const FetchQualityProcess = (_InspectionRoster) => {
    if (qualityProcessMap.length === 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "qualityProcessMap?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempQualityProcessMap = [];
            let tempQualityProcessArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempQualityProcessMap.push(response.data[i]);
                if (
                  tempQualityProcessArray.findIndex(
                    (e) => e === response.data[i].processName
                  ) == -1
                ) {
                  tempQualityProcessArray.push(response.data[i].processName);
                }
              }
            }
            dispatch(populate_quality_process(tempQualityProcessMap));
            let tempQuality = [];
            for (let i = 0; i < tempQualityProcessMap.length; i++) {
              if (
                _InspectionRoster.findIndex(
                  (e) =>
                    e.qaProcessDbId == tempQualityProcessMap[i].qaProcessDbId
                ) != -1
              ) {
                tempQuality.push(tempQualityProcessMap[i]);
              }
            }
            setSelectedQualityProccess(tempQuality);
            setQualityProcessArray(tempQuality);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      // let tempQualityProcess = [...qualityProcessMap];
      // let tempQuality = [];
      // for (let i = 0; i < tempQualityProcess.length; i++) {
      //   tempQuality.push(tempQualityProcess[i].processName);
      // }
      let tempQuality = [];
      for (let i = 0; i < qualityProcessMap.length; i++) {
        if (
          _InspectionRoster.findIndex(
            (e) => e.qaProcessDbId == qualityProcessMap[i].qaProcessDbId
          ) != -1
        ) {
          tempQuality.push(qualityProcessMap[i]);
        }
      }
      setSelectedQualityProccess(tempQuality);
      setQualityProcessArray(tempQuality);
    }
  };
  let formMaster = useSelector((state) => state.callForm.value);
  const [formMasterArray, setFormMasterArray] = useState([]);
  const FetchFormMaster = (_InspectionRoster) => {
    if (formMaster.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempformMaster = [];
            let tempFormMasterArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempformMaster.push(response.data[i]);
                if (
                  tempFormMasterArray.findIndex(
                    (e) => e === response.data[i].formName
                  ) == -1
                ) {
                  tempFormMasterArray.push(response.data[i].formName);
                }
              }
            }
            dispatch(populate_Form(tempformMaster));
            let tempForm = [];
            for (let i = 0; i < tempformMaster.length; i++) {
              if (
                _InspectionRoster.findIndex(
                  (e) => e.formDbId == tempformMaster[i].formDbId
                ) != -1
              ) {
                tempForm.push(tempformMaster[i]);
              }
            }
            setSelectedForm(tempForm);
            setFormMasterArray(tempForm);
          }
        });
    } else {
      // let tempformMaster = [...formMaster];
      // let tempForm = [];
      // for (let i = 0; i < tempformMaster.length; i++) {
      //   tempForm.push(tempformMaster[i].formName);
      // }
      let tempForm = [];
      for (let i = 0; i < formMaster.length; i++) {
        if (
          _InspectionRoster.findIndex(
            (e) => e.formDbId == formMaster[i].formDbId
          ) != -1
        ) {
          tempForm.push(formMaster[i]);
        }
      }
      setSelectedForm(tempForm);
      setFormMasterArray(tempForm);
    }
  };
  let staff = useSelector((state) => state.callStaff.value);
  const [inspection_Staff_Array, setInspection_Staff_Array] = useState([]);
  const [reviewer_Staff_Array, setReviewer_Staff_Array] = useState([]);
  const FetchStaff = (_InspectionRoster) => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff")
        .then((response) => {
          if (response.status == 200) {
            let tempStaff = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
              }
            }

            dispatch(populate_Staff(tempStaff));
            let tempInspectionStaff = [];
            for (let i = 0; i < tempStaff.length; i++) {
              if (
                _InspectionRoster.findIndex(
                  (e) => e.inspectionStaffDbId == tempStaff[i].staffDbId
                ) != -1
              ) {
                tempInspectionStaff.push(tempStaff[i]);
              }
            }
            setSelectedInspectionStaff(tempInspectionStaff);
            setInspection_Staff_Array(tempInspectionStaff);
            let tempReviewerStaff = [];
            for (let i = 0; i < tempStaff.length; i++) {
              if (
                _InspectionRoster.findIndex(
                  (e) => e.reviewerStaffDbId == tempStaff[i].staffDbId
                ) != -1
              ) {
                tempReviewerStaff.push(tempStaff[i]);
              }
            }
            setReviewer_Staff_Array(tempReviewerStaff);
            setSelectedReviewerStaff(tempReviewerStaff);
          }
        });
    } else {
      // let tempStaff = [...staff];
      let tempInspectionStaff = [];
      for (let i = 0; i < staff.length; i++) {
        if (
          _InspectionRoster.findIndex(
            (e) => e.inspectionStaffDbId == staff[i].staffDbId
          ) != -1
        ) {
          tempInspectionStaff.push(staff[i]);
        }
      }
      setInspection_Staff_Array(tempInspectionStaff);
      setSelectedInspectionStaff(tempInspectionStaff);
      let tempReviewerStaff = [];
      for (let i = 0; i < staff.length; i++) {
        if (
          _InspectionRoster.findIndex(
            (e) => e.reviewerStaffDbId == staff[i].staffDbId
          ) != -1
        ) {
          tempReviewerStaff.push(staff[i]);
        }
      }
      setReviewer_Staff_Array(tempReviewerStaff);
      setSelectedReviewerStaff(tempReviewerStaff);
    }
  };

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "inspectionRoster";

  let inspectionRoster = useSelector(
    (state) => state.callInspectionRoster.value
  );
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [inspectionRosterObjectErrors, setInspectionRosterObjectErrors] =
    useState({
      shiftError: "",
      inspectionStaffError: "",
      companyMasterError: "",
      qualityProcessName: "",
      reviewStaffError: "",
      formMasterNameError: "",
      rosterDateError: "",
      facilityNameError: "",
      productNameError: "",
      inspectionStatusError: "",
      reviewStatusError: "",
      complaintNameError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "shiftError");
    OnChangeErrorValues("", "inspectionStaffError");
    OnChangeErrorValues("", "companyMasterError");
    OnChangeErrorValues("", "qualityProcessName");
    OnChangeErrorValues("", "reviewStaffError");
    OnChangeErrorValues("", "formMasterNameError");
    OnChangeErrorValues("", "rosterDateError");
    OnChangeErrorValues("", "productNameError");
    OnChangeErrorValues("", "facilityNameError");
    OnChangeErrorValues("", "inspectionStatusError");
    OnChangeErrorValues("", "reviewStatusError");
    OnChangeErrorValues("", "complaintNameError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productNameError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          productNameError: error,
        }));
        break;
      case "shiftError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          shiftError: error,
        }));
        break;
      case "inspectionStaffError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          inspectionStaffError: error,
        }));
        break;
      case "companyMasterError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          companyMasterError: error,
        }));
        break;
      case "qualityProcessName":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          qualityProcessName: error,
        }));
        break;
      case "reviewStaffError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          reviewStaffError: error,
        }));
        break;
      case "formMasterNameError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          formMasterNameError: error,
        }));
        break;
      case "rosterDateError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          rosterDateError: error,
        }));
        break;
      case "facilityNameError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          facilityNameError: error,
        }));
        break;
      case "inspectionStatusError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          inspectionStatusError: error,
        }));
        break;
      case "reviewStatusError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          reviewStatusError: error,
        }));
        break;
      case "complaintNameError":
        setInspectionRosterObjectErrors((inspectionRosterObjectErrors) => ({
          ...inspectionRosterObjectErrors,
          complaintNameError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const SearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = [];
    let filterProductArray = product.filter((e) =>
      e.productName.toLowerCase().match(content.toLowerCase().trim())
    );
    for (let i = 0; i < filterProductArray.length; i++) {
      let tempArray = inspectionRoster.filter(
        (e1) => e1.productDbId == filterProductArray[i].productDbId
      );
      for (let j = 0; j < tempArray.length; j++) {
        temp.push(tempArray[j]);
      }
    }
    // let temp = inspectionRoster.filter((e1) =>
    //   e1.rosterDate.toLowerCase().trim().match(content.toLowerCase().trim())
    // );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (inspectionRoster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(inspectionRoster[i]);
        }
      } else {
        for (let i = 0; i < inspectionRoster.length; i++) {
          tempArray.push(inspectionRoster[i]);
        }
      }
      total_pages.current = Math.ceil(
        inspectionRoster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    set_searched_array(temp);
    current_page.current = 1;
    setPaginatedArray(tempArray);
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [inspectionRosterArray, setInspectionRosterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [inspectionRosterObject, setInspectionRosterObject] = useState({
    shift: "",
    companyMaster: 0,
    qualityProcessName: 0,
    inspectionStaff: 0,
    reviewStaff: 0,
    formMasterName: 0,
    rosterDate: new Date(),
    facilityName: 0,
    productName: 0,
    inspectionStatus: 0,
    reviewStatus: 0,
    complaintName: 0,
  });

  const onResetValue = () => {
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      shift: "",
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      companyMaster: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      qualityProcessName: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      reviewStaff: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      inspectionStaff: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      formMasterName: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      rosterDate: new Date(),
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      productName: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      facilityName: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      inspectionStatus: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      reviewStatus: 0,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      complaintName: 0,
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "shift":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          shift: e.target.value,
        }));
        break;
      case "companyMaster":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          companyMaster: e.target.value,
        }));
        break;
      case "qualityProcessName":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          qualityProcessName: e.target.value,
        }));
        break;
      case "reviewStaff":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          reviewStaff: e.target.value,
        }));
        break;
      case "inspectionStaff":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          inspectionStaff: e.target.value,
        }));
        break;
      case "formMasterName":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          formMasterName: e.target.value,
        }));
        break;
      case "rosterDate":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          rosterDate: e.target.value,
        }));
        break;
      case "productName":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          productName: e.target.value,
        }));
        break;
      case "facilityName":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          facilityName: e.target.value,
        }));
        break;

      case "inspectionStatus":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          inspectionStatus: e.target.value,
        }));
        break;
      case "reviewStatus":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          reviewStatus: e.target.value,
        }));
        break;
      case "complaintName":
        setInspectionRosterObject((inspectionRosterObject) => ({
          ...inspectionRosterObject,
          complaintName: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          inspectionRosterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            inspectionRosterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(inspectionRosterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            inspectionRosterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (inspectionRosterArray.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(inspectionRosterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (searched_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (draft_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(inspectionRosterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);
  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
    // set_rosterDate(new Date());
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = inspectionRoster.findIndex((e) => e === _code);
    select_deselect_the_record(inspectionRoster[idx]);
    set_item_to_delete(inspectionRoster[idx].inspectionRosterDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          createdTimestamp: new Date(),
          updatedTimestamp: new Date(),
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "inspectionRoster?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                "inspectionRoster?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };

  const AddTheItem = (status) => {
    let data = {
      rosterDate: inspectionRosterObject.rosterDate,
      shift: inspectionRosterObject.shift,
      inspectionStatus: 0,
      complaintDbId: Number(inspectionRosterObject.complaintName),
      facilityDbId: Number(inspectionRosterObject.facilityName),
      productDbId: Number(inspectionRosterObject.productName),
      formDbId: Number(inspectionRosterObject.formMasterName),
      companyDbId: Number(inspectionRosterObject.companyMaster),
      qaProcessDbId: Number(inspectionRosterObject.qualityProcessName),
      inspectionStaffDbId: Number(inspectionRosterObject.inspectionStaff),
      reviewerStaffDbId: Number(inspectionRosterObject.reviewStaff),
      reviewStatus: 0,
      createdBy: isAuthenticated == true ? user.name : "",
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    console.log("Facility:" + inspectionRosterObject.facilityName);
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "inspectionRoster?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = () => {
    let data = {
      rosterDate: GetServerDate(inspectionRosterObject.rosterDate),
      shift: inspectionRosterObject.shift,
      complaintDbId: Number(inspectionRosterObject.complaintName),
      facilityDbId: Number(inspectionRosterObject.facilityName),
      productDbId: Number(inspectionRosterObject.productName),
      formDbId: Number(inspectionRosterObject.formMasterName),
      companyDbId: Number(inspectionRosterObject.companyMaster),
      qaProcessDbId: Number(inspectionRosterObject.qualityProcessName),
      inspectionStaffDbId: Number(inspectionRosterObject.inspectionStaff),
      reviewerStaffDbId: Number(inspectionRosterObject.reviewStaff),
      reviewStatus: Number(inspectionRosterObject.reviewStatus),
      inspectionStatus: Number(inspectionRosterObject.inspectionStatus),

    };
    if (Validations() > 0) {
      console.log(Validations());
      return;
    } else {
      console.log("Updating No Errors");
      axios
        .patch(post_url + "/" + selectedRosterDbId, data)
        .then((response) => {
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully updated the item", "Success");
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "inspectionRoster?size=1000",
              "Patch"
            );
            set_open_edit_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(
            error.message + ":" + post_url + "/" + selectedRosterDbId
          );
          ShowMEWSPopup("❌" + error.message, "Error");
          console.log(error);
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };

  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Inspection Roster") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "rosterDate":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.rosterDate.length == 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  rosterDateError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "formCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.formMasterName === 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  formMasterNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "facilityCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.facilityName === 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  facilityNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "productCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.productName === 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  productNameError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "companyCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.companyMaster === 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  companyMasterError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "inspectionStaffCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.inspectionStaff === 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  inspectionStaffError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "reviewerStaffCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.reviewStaff === 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  reviewStaffError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
          case "shifit":
            if (
              clientSideValidations[i].validationType === "required" &&
              inspectionRosterObject.shift.length == 0
            ) {
              temp++;
              setInspectionRosterObjectErrors(
                (inspectionRosterObjectErrors) => ({
                  ...inspectionRosterObjectErrors,
                  shiftError: clientSideValidations[i].shortMessage,
                })
              );
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = inspectionRoster.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        inspectionRoster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (inspectionRoster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < inspectionRoster.length; i++) {
          temp_display_array.push(inspectionRoster[i]);
        }
      } else {
        if (
          inspectionRoster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          inspectionRoster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < inspectionRoster.length;
            i++
          ) {
            temp_display_array.push(inspectionRoster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(inspectionRoster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [selected_form_id, set_selected_form_id] = useState(3);
  const onChangeFormID = (e, _code) => {
    let temp = [];
    for (let i = 0; i < inspectionRoster.length; i++) {
      temp.push(inspectionRoster[i]);
    }
    let updatedArray = [];
    if (e != 0) {
      updatedArray = temp.filter((e1) => e1.productDbId == e);
    } else {
      updatedArray = temp;
    }
    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setInspectionRosterArray(updatedArray);
    setPaginatedArray(tempArray);
    set_selected_form_id(e);
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_InspectionRoster = [];
    let temp_draft_array = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]) {
              temp_InspectionRoster.push(response.data[i]);
              temp_count++;
            }
            if (response.data[i]) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);
          let sortArray = temp_InspectionRoster.sort((a, b) => {
            return (
              new Date(b.rosterDate).getTime() -
              new Date(a.rosterDate).getTime()
            );
          });
          dispatch(populate_inspection_roster(sortArray));
          setInspectionRosterArray(sortArray);
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (sortArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
            current_page.current = 1;
            for (let i = 0; i < sortArray.length; i++) {
              temp_display_array.push(sortArray[i]);
            }
          } else {
            if (
              sortArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              sortArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < sortArray.length;
                i++
              ) {
                temp_display_array.push(sortArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(sortArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheProduct(sortArray);
          FetchQualityProcess(sortArray);
          FetchFormMaster(sortArray);
          FetchStaff(sortArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectedRosterDbId, setselectedRosterDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (inspectionRosterDbId, type) => {
    setselectedRosterDbId(inspectionRosterDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(inspectionRosterDbId);
  };
  const SupplyValuesToModal = (inspectionRosterDbId) => {
    let RosterData = inspectionRoster.find(
      (e) => e.inspectionRosterDbId == inspectionRosterDbId
    ).rosterDate;
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      shift: inspectionRoster.find(
        (e) => e.inspectionRosterDbId == inspectionRosterDbId
      ).shift,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      qualityProcessName: inspectionRoster.find(
        (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
      ).qaProcessDbId,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      productName:
        inspectionRoster.find(
          (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
        ) != -1
          ? inspectionRoster.find(
            (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
          ).productDbId
          : null,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      companyMaster: inspectionRoster.find(
        (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
      ).companyDbId,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      formMasterName: inspectionRoster.find(
        (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
      ).formDbId,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      inspectionStaff: inspectionRoster.find(
        (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
      ).inspectionStaffDbId,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      reviewStaff: inspectionRoster.find(
        (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
      ).reviewerStaffDbId,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      rosterDate: RosterData,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      inspectionStatus: inspectionRoster.find(
        (e) => e.inspectionRosterDbId == inspectionRosterDbId
      ).inspectionStatus,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      reviewStatus: inspectionRoster.find(
        (e) => e.inspectionRosterDbId == inspectionRosterDbId
      ).reviewStatus,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      facilityName: inspectionRoster.find(
        (e1) => e1.inspectionRosterDbId == inspectionRosterDbId
      ).facilityDbId,
    }));
    setInspectionRosterObject((inspectionRosterObject) => ({
      ...inspectionRosterObject,
      complaintName: inspectionRoster.find(
        (e) => e.inspectionRosterDbId == inspectionRosterDbId
      ).complaintDbId,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortInspectionRoster = [...inspectionRosterArray];
    if (sortStyle === "Descending") {
      let updatedInspectionRoster = SortInspectionRoster.sort((a, b) => {
        const a_res =
          action === "Shift"
            ? a.shift
            : action === "Date"
              ? a.rosterDate
              : null;
        const b_res =
          action === "Shift"
            ? b.shift
            : action === "Date"
              ? b.rosterDate
              : null;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedInspectionRoster);
      setSortStyle("Ascending");
      setInspectionRosterArray(updatedInspectionRoster);
    } else if (sortStyle === "Ascending") {
      let updatedInspectionRoster = SortInspectionRoster.sort((a, b) => {
        const a_res =
          action === "Shift"
            ? a.shift
            : action === "Date"
              ? a.rosterDate
              : null;
        const b_res =
          action === "Shift"
            ? b.shift
            : action === "Date"
              ? b.rosterDate
              : null;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedInspectionRoster);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortInspectionRoster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortInspectionRoster[i]);
      }
    } else {
      for (let i = 0; i < SortInspectionRoster.length; i++) {
        tempArray.push(SortInspectionRoster[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortInspectionRoster.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setInspectionRosterArray(SortInspectionRoster);
    setPaginatedArray(tempArray);
  };

  const [productPopup, setProductPopup] = useState(false);
  const [qualityProcessPopup, setQualityProcessPopup] = useState(false);
  const [formMasterPopup, setFormMasterPopup] = useState(false);
  const [reviewerStaffPopup, setReviewerStaffPopup] = useState(false);
  const [inspectionStaffPopup, setInspectionStaffPopup] = useState(false);
  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.id != "Product") {
        setProductPopup(false);
      }
      if (e.target.id != "QualityProcess") {
        setQualityProcessPopup(false);
      }
      if (e.target.id != "Form") {
        setFormMasterPopup(false);
      }
      if (e.target.id != "reviewerStaffPopup") {
        setReviewerStaffPopup(false);
      }
      if (e.target.id != "InspectionStaff") {
        setInspectionStaffPopup(false);
      }
    });
  }, []);
  const OnClickFilter = (purpose) => {
    switch (purpose) {
      case "Product":
        // let tempProduct = [];
        // if (selectedProducts.length == 0) {
        //   for (let i = 0; i < product.length; i++) {
        //     tempProduct.push(product[i].productDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedProducts.length; i++) {
        //     tempProduct.push(selectedProducts[i]);
        //   }
        // }
        // setSelectedProducts(tempProduct);
        if (inspectionRoster.length == 0) {
          setProductPopup(false);
        } else {
          setProductPopup(!productPopup);
        }
        break;
      case "QualityProcess":
        // let tempQualityProcess = [];
        // if (selectedQualityProcess.length == 0) {
        //   for (let i = 0; i < qualityProcessMap.length; i++) {
        //     tempQualityProcess.push(qualityProcessMap[i].qaProcessDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedQualityProcess.length; i++) {
        //     tempQualityProcess.push(selectedQualityProcess[i]);
        //   }
        // }
        // setSelectedQualityProccess(tempQualityProcess);
        if (inspectionRoster.length == 0) {
          setQualityProcessPopup(false);
        } else {
          setQualityProcessPopup(!qualityProcessPopup);
        }
        break;
      case "FormMaster":
        // let tempForm = [];
        // if (selectedForm.length == 0) {
        //   for (let i = 0; i < formMaster.length; i++) {
        //     tempForm.push(formMaster[i].formDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedForm.length; i++) {
        //     tempForm.push(selectedForm[i]);
        //   }
        // }
        // setSelectedForm(tempForm);
        if (inspectionRoster.length == 0) {
          setFormMasterPopup(false);
        } else {
          setFormMasterPopup(!formMasterPopup);
        }
        break;
      case "InspectionStaff":
        // let tempInspectionStaff = [];
        // if (selectedInspectionStaff.length == 0) {
        //   for (let i = 0; i < staff.length; i++) {
        //     tempInspectionStaff.push(staff[i].staffDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedInspectionStaff.length; i++) {
        //     tempInspectionStaff.push(selectedInspectionStaff[i]);
        //   }
        // }
        // setSelectedInspectionStaff(tempInspectionStaff);
        if (inspectionRoster.length == 0) {
          setInspectionStaffPopup(false);
        } else {
          setInspectionStaffPopup(!inspectionStaffPopup);
        }
        break;
      case "ReviewerStaff":
        // let tempReviewerStaff = [];
        // if (selectedReviewerStaff.length == 0) {
        //   for (let i = 0; i < staff.length; i++) {
        //     tempReviewerStaff.push(staff[i].staffDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedReviewerStaff.length; i++) {
        //     tempReviewerStaff.push(selectedReviewerStaff[i]);
        //   }
        // }
        // setSelectedReviewerStaff(tempReviewerStaff);
        if (inspectionRoster.length == 0) {
          setReviewerStaffPopup(false);
        } else {
          setReviewerStaffPopup(!reviewerStaffPopup);
        }
        break;
    }
  };
  const OnOkAllFilter = (purpose) => {
    switch (purpose) {
      case "Product":
        let tempProduct = [...inspectionRoster];
        let updatedProductArray = [];
        for (let i = 0; i < selectedProducts.length; i++) {
          for (let j = 0; j < tempProduct.length; j++) {
            if (
              tempProduct[j].productDbId === selectedProducts[i].productDbId
              // product.find((e) => e.productName === selectedProducts[i])
              //   .productDbId
            ) {
              updatedProductArray.push(tempProduct[j]);
            }
          }
        }
        let tempProductArray = [];
        if (updatedProductArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempProductArray.push(updatedProductArray[i]);
          }
        } else {
          for (let i = 0; i < updatedProductArray.length; i++) {
            tempProductArray.push(updatedProductArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedProductArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setInspectionRosterArray(updatedProductArray);
        setPaginatedArray(tempProductArray);
        setProductPopup(false);
        break;
      case "QualityProcess":
        let tempQuality = [...inspectionRoster];
        let updatedQualityProcessArray = [];
        for (let i = 0; i < selectedQualityProcess.length; i++) {
          for (let j = 0; j < tempQuality.length; j++) {
            if (
              tempQuality[j].qaProcessDbId ==
              selectedQualityProcess[i].qaProcessDbId
              // qualityProcessMap.find(
              //   (e) => e.processName === selectedQualityProcess[i]
              // ).qaProcessDbId
            ) {
              updatedQualityProcessArray.push(tempQuality[j]);
            }
          }
        }
        let tempQualityProcessArray = [];
        if (
          updatedQualityProcessArray.length >
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempQualityProcessArray.push(updatedQualityProcessArray[i]);
          }
        } else {
          for (let i = 0; i < updatedQualityProcessArray.length; i++) {
            tempQualityProcessArray.push(updatedQualityProcessArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedQualityProcessArray.length /
          process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setInspectionRosterArray(updatedQualityProcessArray);
        setPaginatedArray(tempQualityProcessArray);
        setQualityProcessPopup(false);
        break;
      case "FormMaster":
        let tempForm = [...inspectionRoster];
        let updatedFormArray = [];
        for (let i = 0; i < selectedForm.length; i++) {
          for (let j = 0; j < tempForm.length; j++) {
            if (
              tempForm[j].formDbId == selectedForm[i].formDbId
              // formMaster.find((e) => e.formName === selectedForm[i]).formDbId
            ) {
              updatedFormArray.push(tempForm[j]);
            }
          }
        }
        let tempFormArray = [];
        if (updatedFormArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempFormArray.push(updatedFormArray[i]);
          }
        } else {
          for (let i = 0; i < updatedFormArray.length; i++) {
            tempFormArray.push(updatedFormArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedFormArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setInspectionRosterArray(updatedFormArray);
        setPaginatedArray(tempFormArray);
        setFormMasterPopup(false);
        break;
      case "InspectionStaff":
        let tempInspection = [...inspectionRoster];
        let updatedArray = [];
        for (let i = 0; i < selectedInspectionStaff.length; i++) {
          for (let j = 0; j < tempInspection.length; j++) {
            if (
              tempInspection[j].inspectionStaffDbId ==
              selectedInspectionStaff[i].staffDbId
            ) {
              updatedArray.push(tempInspection[j]);
            }
            // if (
            //   tempInspection[j].inspectionStaffDbId ==
            //   staff.find((e) => e.firstName === selectedInspectionStaff[i])
            //     .staffDbId
            // ) {
            //   updatedArray.push(tempInspection[j]);
            // }
          }
        }
        let tempInspectionArray = [];
        if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempInspectionArray.push(updatedArray[i]);
          }
        } else {
          for (let i = 0; i < updatedArray.length; i++) {
            tempInspectionArray.push(updatedArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setInspectionRosterArray(updatedArray);
        setPaginatedArray(tempInspectionArray);
        setInspectionStaffPopup(false);
        break;
      case "ReviewerStaff":
        let tempReviewer = [...inspectionRoster];
        let updatedReviewerArray = [];
        for (let i = 0; i < selectedReviewerStaff.length; i++) {
          for (let j = 0; j < tempReviewer.length; j++) {
            if (
              tempReviewer[j].reviewerStaffDbId ==
              selectedReviewerStaff[i].staffDbId
            ) {
              updatedReviewerArray.push(tempReviewer[j]);
            }
            // if (
            //   tempReviewer[j].reviewerStaffDbId ===
            //   staff.find(
            //     (e) =>
            //       e.firstName + "" + e.lastName === selectedReviewerStaff[i]
            //   ).staffDbId
            // ) {
            //   updatedReviewerArray.push(tempReviewer[j]);
            // }
          }
        }
        let tempArray = [];
        if (
          updatedReviewerArray.length > process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempArray.push(updatedReviewerArray[i]);
          }
        } else {
          for (let i = 0; i < updatedReviewerArray.length; i++) {
            tempArray.push(updatedReviewerArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedReviewerArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setInspectionRosterArray(updatedReviewerArray);
        setPaginatedArray(tempArray);
        setReviewerStaffPopup(false);
        break;
    }
  };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const AddRemoveProducts = (id) => {
    let tempSelectedProduct = [...selectedProducts];
    let productIndex = tempSelectedProduct.findIndex(
      (e) => e.productDbId == id
    );
    if (productIndex == -1) {
      tempSelectedProduct.push(product.find((e) => e.productDbId == id));
    } else {
      tempSelectedProduct.splice(productIndex, 1);
    }
    setSelectedProducts(tempSelectedProduct);
  };
  const [selectedQualityProcess, setSelectedQualityProccess] = useState([]);
  const AddRemoveQualityProcess = (id) => {
    let tempQualityProcess = [...selectedQualityProcess];
    let qualityIndex = tempQualityProcess.findIndex(
      (e) => e.qaProcessDbId == id
    );
    if (qualityIndex == -1) {
      tempQualityProcess.push(
        qualityProcessMap.find((e) => e.qaProcessDbId == id)
      );
    } else {
      tempQualityProcess.splice(qualityIndex, 1);
    }
    setSelectedQualityProccess(tempQualityProcess);
  };

  const [selectedForm, setSelectedForm] = useState([]);
  const AddRemoveSelectedForm = (id) => {
    let tempselectedForm = [...selectedForm];
    let tempForm = tempselectedForm.findIndex((e) => e.formDbId == id);
    if (tempForm == -1) {
      tempselectedForm.push(formMaster.find((e) => e.formDbId == id));
    } else {
      tempselectedForm.splice(tempForm, 1);
    }
    setSelectedForm(tempselectedForm);
  };

  const [selectedReviewerStaff, setSelectedReviewerStaff] = useState([]);
  const AddRemoveReviewerStaff = (id) => {
    let tempReviewerStaff = [...selectedReviewerStaff];
    let reviewerStaff = tempReviewerStaff.findIndex((e) => e.staffDbId == id);
    if (reviewerStaff == -1) {
      tempReviewerStaff.push(staff.find((e) => e.staffDbId == id));
    } else {
      tempReviewerStaff.splice(reviewerStaff, 1);
    }
    setSelectedReviewerStaff(tempReviewerStaff);
  };

  const [selectedInspectionStaff, setSelectedInspectionStaff] = useState([]);
  const AddRemoveInspection = (id) => {
    let tempInspectionStaff = [...selectedInspectionStaff];
    let inspectionStaff = tempInspectionStaff.findIndex(
      (e) => e.staffDbId == id
    );
    if (inspectionStaff == -1) {
      tempInspectionStaff.push(staff.find((e) => e.staffDbId == id));
    } else {
      tempInspectionStaff.splice(inspectionStaff, 1);
    }
    setSelectedInspectionStaff(tempInspectionStaff);
  };

  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col w-full ">
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                        multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader"> Manage Inspection Roster</div>
                </div>
                {/* <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div> */}
                <div className="flex flex-row w-full justify-end items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  {/* <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div> */}
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Inspection Roster"
                      value={search_content}
                      onChange={(e) => SearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons " src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "10" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Shift")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Shift
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>Roster Date</th>
                        <th>
                          {qualityProcessPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="QualityProcess"
                                        type="text"
                                        placeholder="Search"
                                        value={search_quality}
                                        onChange={(e) =>
                                          OnChangeSearchFilter(
                                            "QualityProcess",
                                            e
                                          )
                                        }
                                      />
                                      <div
                                        id="QualityProcess"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedQualityProccess(
                                            qualityProcessArray
                                          )
                                        }
                                      >
                                        <img
                                          id="QualityProcess"
                                          className="Icons"
                                          src={
                                            qualityProcessArray.length ==
                                              selectedQualityProcess.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="QualityProcess">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_quality.length == 0
                                        ? qualityProcessArray.map(
                                          (item, index) => (
                                            <div
                                              id="QualityProcess"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveQualityProcess(
                                                  item.qaProcessDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="QualityProcess"
                                                className="Icons"
                                                src={
                                                  selectedQualityProcess.findIndex(
                                                    (e) =>
                                                      e.qaProcessDbId ===
                                                      item.qaProcessDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="QualityProcess">
                                                {item.processName}
                                              </p>
                                            </div>
                                          )
                                        )
                                        : search_quality.length > 0
                                          ? search_quality_array.map(
                                            (item, index) => (
                                              <div
                                                id="QualityProcess"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveQualityProcess(
                                                    item.qaProcessDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="QualityProcess"
                                                  className="Icons"
                                                  src={
                                                    selectedQualityProcess.findIndex(
                                                      (e) =>
                                                        e.qaProcessDbId ===
                                                        item.qaProcessDbId
                                                    ) != -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="QualityProcess">
                                                  {item.processName}
                                                </p>
                                              </div>
                                            )
                                          )
                                          : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="QualityProcess"
                                      className="popupfile_button"
                                      onClick={() =>
                                        OnOkAllFilter("QualityProcess")
                                      }
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="QualityProcess"
                                      className="popupfile_button"
                                      onClick={() =>
                                        setSelectedQualityProccess([])
                                      }
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="QualityProcess"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Quality Process
                            <img
                              id="QualityProcess"
                              className="Icons"
                              onClick={() => OnClickFilter("QualityProcess")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>
                          {productPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Product"
                                        type="text"
                                        placeholder="Search"
                                        value={search_product}
                                        onChange={(e) =>
                                          OnChangeSearchFilter("Product", e)
                                        }
                                      />
                                      <div
                                        id="Product"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedProducts(productArray)
                                        }
                                      >
                                        <img
                                          id="Product"
                                          className="Icons"
                                          src={
                                            productArray.length ==
                                              selectedProducts.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Product">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_product.length === 0
                                        ? productArray.map((item, index) => (
                                          <div
                                            id="Product"
                                            key={index}
                                            className="Imageflex"
                                            onClick={() =>
                                              AddRemoveProducts(
                                                item.productDbId
                                              )
                                            }
                                          >
                                            <img
                                              id="Product"
                                              className="Icons"
                                              src={
                                                selectedProducts.findIndex(
                                                  (e) =>
                                                    e.productDbId ===
                                                    item.productDbId
                                                ) !== -1
                                                  ? CheckIcon
                                                  : UnCheckIcon
                                              }
                                              alt=""
                                            />
                                            <p id="Product">
                                              {item.productName}
                                            </p>
                                          </div>
                                        ))
                                        : search_product.length > 0
                                          ? search_product_array.map(
                                            (item, index) => (
                                              <div
                                                id="Product"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveProducts(
                                                    item.productDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Product"
                                                  className="Icons"
                                                  src={
                                                    selectedProducts.findIndex(
                                                      (e) =>
                                                        e.productDbId ===
                                                        item.productDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Product">
                                                  {item.productName}
                                                </p>
                                              </div>
                                            )
                                          )
                                          : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Product"
                                      className="popupfile_button"
                                      onClick={() => OnOkAllFilter("Product")}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Product"
                                      className="popupfile_button"
                                      onClick={() => setSelectedProducts([])}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="Product"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Product
                            <img
                              id="Product"
                              className="Icons"
                              onClick={() => OnClickFilter("Product")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>
                          {inspectionStaffPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="InspectionStaff"
                                        type="text"
                                        placeholder="Search"
                                        value={search_inspectionstaff}
                                        onChange={(e) =>
                                          OnChangeSearchFilter(
                                            "InspectionStaff",
                                            e
                                          )
                                        }
                                      />
                                      <div
                                        id="InspectionStaff"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedInspectionStaff(
                                            inspection_Staff_Array
                                          )
                                        }
                                      >
                                        <img
                                          id="InspectionStaff"
                                          className="Icons"
                                          src={
                                            inspection_Staff_Array.length ==
                                              selectedInspectionStaff.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="InspectionStaff">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_inspectionstaff.length == 0
                                        ? inspection_Staff_Array.map(
                                          (item, index) => (
                                            <div
                                              id="InspectionStaff"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveInspection(
                                                  item.staffDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="InspectionStaff"
                                                className="Icons"
                                                src={
                                                  selectedInspectionStaff.findIndex(
                                                    (e) =>
                                                      e.staffDbId ===
                                                      item.staffDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="InspectionStaff">
                                                {" "}
                                                {item.firstName +
                                                  " " +
                                                  item.lastName}
                                              </p>
                                            </div>
                                          )
                                        )
                                        : search_inspectionstaff.length > 0
                                          ? search_inspectionstaff_array.map(
                                            (item, index) => (
                                              <div
                                                id="InspectionStaff"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveInspection(
                                                    item.staffDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="InspectionStaff"
                                                  className="Icons"
                                                  src={
                                                    selectedInspectionStaff.findIndex(
                                                      (e) =>
                                                        e.staffDbId ==
                                                        item.staffDbId
                                                    ) != -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="InspectionStaff">
                                                  {item.firstName +
                                                    " " +
                                                    item.lastName}
                                                </p>
                                              </div>
                                            )
                                          )
                                          : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="InspectionStaff"
                                      className="popupfile_button"
                                      onClick={() =>
                                        OnOkAllFilter("InspectionStaff")
                                      }
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="InspectionStaff"
                                      className="popupfile_button"
                                      onClick={() =>
                                        setSelectedInspectionStaff([])
                                      }
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="InspectionStaff"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Inspection Staff
                            <img
                              id="InspectionStaff"
                              className="Icons"
                              onClick={() => OnClickFilter("InspectionStaff")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>
                          {reviewerStaffPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="reviewerStaffPopup"
                                        type="text"
                                        placeholder="Search"
                                        value={search_reviwerstaff}
                                        onChange={(e) =>
                                          OnChangeSearchFilter(
                                            "ReviewerStaff",
                                            e
                                          )
                                        }
                                      />
                                      <div
                                        id="reviewerStaffPopup"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedReviewerStaff(
                                            reviewer_Staff_Array
                                          )
                                        }
                                      >
                                        <img
                                          id="reviewerStaffPopup"
                                          className="Icons"
                                          src={
                                            reviewer_Staff_Array.length ==
                                              selectedReviewerStaff.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="reviewerStaffPopup">
                                          Select All
                                        </p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_reviwerstaff.length == 0
                                        ? reviewer_Staff_Array.map(
                                          (item, index) => (
                                            <div
                                              id="reviewerStaffPopup"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveReviewerStaff(
                                                  item.staffDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="reviewerStaffPopup"
                                                className="Icons"
                                                src={
                                                  selectedReviewerStaff.findIndex(
                                                    (e) =>
                                                      e.staffDbId ===
                                                      item.staffDbId
                                                  ) !== -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="reviewerStaffPopup">
                                                {item.firstName +
                                                  " " +
                                                  item.lastName}
                                              </p>
                                            </div>
                                          )
                                        )
                                        : search_reviwerstaff.length > 0
                                          ? search_reviwerstaff_array.map(
                                            (item, index) => (
                                              <div
                                                id="reviewerStaffPopup"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveReviewerStaff(
                                                    item.staffDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="reviewerStaffPopup"
                                                  className="Icons"
                                                  src={
                                                    selectedReviewerStaff.findIndex(
                                                      (e) =>
                                                        e.staffDbId ===
                                                        item.staffDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="reviewerStaffPopup">
                                                  {item.firstName +
                                                    " " +
                                                    item.lastName}
                                                </p>
                                              </div>
                                            )
                                          )
                                          : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="reviewerStaffPopup"
                                      className="popupfile_button"
                                      onClick={() =>
                                        OnOkAllFilter("ReviewerStaff")
                                      }
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="reviewerStaffPopup"
                                      className="popupfile_button"
                                      onClick={() =>
                                        setSelectedReviewerStaff([])
                                      }
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="reviewerStaffPopup"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Review Staff
                            <img
                              id="reviewerStaffPopup"
                              className="Icons"
                              onClick={() => OnClickFilter("ReviewerStaff")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>Company</th>
                        <th>
                          {formMasterPopup === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Form"
                                        type="text"
                                        placeholder="Search"
                                        value={search_form}
                                        onChange={(e) =>
                                          OnChangeSearchFilter("FormMaster", e)
                                        }
                                      />
                                      <div
                                        id="Form"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedForm(formMasterArray)
                                        }
                                      >
                                        <img
                                          id="Form"
                                          className="Icons"
                                          src={
                                            formMasterArray.length ==
                                              selectedForm.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Form">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_form.length === 0
                                        ? formMasterArray.map((item, index) => (
                                          <div
                                            id="Form"
                                            key={index}
                                            className="Imageflex"
                                            onClick={() =>
                                              AddRemoveSelectedForm(
                                                item.formDbId
                                              )
                                            }
                                          >
                                            <img
                                              id="Form"
                                              className="Icons"
                                              src={
                                                selectedForm.findIndex(
                                                  (e) =>
                                                    e.formDbId ===
                                                    item.formDbId
                                                ) !== -1
                                                  ? CheckIcon
                                                  : UnCheckIcon
                                              }
                                              alt=""
                                            />
                                            <p id="Form">{item.formName}</p>
                                          </div>
                                        ))
                                        : search_form.length > 0
                                          ? search_form_array.map(
                                            (item, index) => (
                                              <div
                                                id="Form"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveSelectedForm(
                                                    item.formDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Form"
                                                  className="Icons"
                                                  src={
                                                    selectedForm.findIndex(
                                                      (e) =>
                                                        e.formDbId ===
                                                        item.formDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Form">{item.formName}</p>
                                              </div>
                                            )
                                          )
                                          : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Form"
                                      className="popupfile_button"
                                      onClick={() =>
                                        OnOkAllFilter("FormMaster")
                                      }
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Form"
                                      className="popupfile_button"
                                      onClick={() => setSelectedForm([])}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img id="Form" className="Icons opacity-0" alt="" />
                            Form
                            <img
                              id="Form"
                              className="Icons"
                              onClick={() => OnClickFilter("FormMaster")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length == 0
                        ? paginatedArray.map((item, index) => (
                          <tr
                            style={{
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.inspectionRosterDbId
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            key={index}
                          >
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {index +
                                1 +
                                process.env.REACT_APP_ITEMS_PER_PAGE *
                                (current_page.current - 1)}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {item.shift}
                            </td>

                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {ReturnThePaddedValues(
                                new Date(item.rosterDate).getUTCDate()
                              ) +
                                "-" +
                                ReturnThePaddedValues(
                                  Number(
                                    new Date(item.rosterDate).getUTCMonth()
                                  ) + 1
                                ) +
                                "-" +
                                new Date(item.rosterDate).getUTCFullYear()}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {qualityProcessMap.length > 0
                                ? qualityProcessMap.find(
                                  (e) =>
                                    e.qaProcessDbId === item.qaProcessDbId
                                ).processName
                                : null}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {product.findIndex(
                                (e) => e.productDbId === item.productDbId
                              ) != -1
                                ? product.find(
                                  (e) => e.productDbId === item.productDbId
                                ).productName
                                : null}
                              {/* {product.length > 0
                            ? product.findIndex(
                                (e) => e.productDbId == item.productDbId
                              ) != -1
                              ? product[
                                  product.findIndex(
                                    (e) => e.productDbId == item.productDbId
                                  )
                                ].productName
                              : null
                            : null} */}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {staff.length > 0 &&
                                staff.findIndex(
                                  (e) => e.staffDbId == item.inspectionStaffDbId
                                ) != -1
                                ? staff.find(
                                  (e) =>
                                    e.staffDbId == item.inspectionStaffDbId
                                ).firstName +
                                " " +
                                staff.find(
                                  (e) =>
                                    e.staffDbId == item.inspectionStaffDbId
                                ).lastName
                                : null}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {staff.length > 0 &&
                                staff.findIndex(
                                  (e) => e.staffDbId == item.reviewerStaffDbId
                                ) != -1
                                ? staff.find(
                                  (e) =>
                                    e.staffDbId == item.reviewerStaffDbId
                                ).firstName +
                                " " +
                                staff.find(
                                  (e) =>
                                    e.staffDbId == item.reviewerStaffDbId
                                ).lastName
                                : null}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {companyMaster.length > 0 &&
                                companyMaster.findIndex(
                                  (e) => e.companyDbId == item.companyDbId
                                ) != -1
                                ? companyMaster.find(
                                  (e) => e.companyDbId == item.companyDbId
                                ).name
                                : null}
                            </td>
                            <td
                              style={{
                                borderColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#FFFFFF"
                                    : "#DAE0E4",
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              onClick={() =>
                                select_deselect_the_record(
                                  item.inspectionRosterDbId
                                )
                              }
                            >
                              {formMaster.length > 0
                                ? formMaster.find(
                                  (e) => e.formDbId === item.formDbId
                                ).formName.length > 25
                                  ? formMaster
                                    .find(
                                      (e) => e.formDbId === item.formDbId
                                    )
                                    .formName.substring(0, 25) + "...."
                                  : formMaster.find(
                                    (e) => e.formDbId === item.formDbId
                                  ).formName
                                : null}
                            </td>

                            <td>
                              <div className="anotherIconsSpace">
                                <img
                                  className="Icons"
                                  onClick={() =>
                                    OpenModal(
                                      item.inspectionRosterDbId,
                                      "View"
                                    )
                                  }
                                  src={ViewIcon}
                                  alt=""
                                />
                                <img
                                  className="Icons"
                                  onClick={() =>
                                    OpenModal(
                                      item.inspectionRosterDbId,
                                      "Edit"
                                    )
                                  }
                                  src={EditIcon}
                                  alt=""
                                />
                                <img
                                  className="Icons"
                                  onClick={() => ShowDeleteConfirmation(item)}
                                  src={DeleteIcon}
                                  alt=""
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                        : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.inspectionRosterDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                  (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {item.shift}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.rosterDate).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.rosterDate).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.rosterDate).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {qualityProcessMap.length > 0
                                  ? qualityProcessMap.find(
                                    (e) =>
                                      e.qaProcessDbId === item.qaProcessDbId
                                  ).processName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {product.findIndex(
                                  (e) => e.productDbId === item.productDbId
                                ) != -1
                                  ? product.find(
                                    (e) => e.productDbId === item.productDbId
                                  ).productName
                                  : null}
                                {/* {product.length > 0
                            ? product.findIndex(
                                (e) => e.productDbId == item.productDbId
                              ) != -1
                              ? product[
                                  product.findIndex(
                                    (e) => e.productDbId == item.productDbId
                                  )
                                ].productName
                              : null
                            : null} */}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {staff.length > 0 &&
                                  staff.findIndex(
                                    (e) => e.staffDbId == item.inspectionStaffDbId
                                  ) != -1
                                  ? staff.find(
                                    (e) =>
                                      e.staffDbId == item.inspectionStaffDbId
                                  ).firstName +
                                  " " +
                                  staff.find(
                                    (e) =>
                                      e.staffDbId == item.inspectionStaffDbId
                                  ).lastName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {staff.length > 0 &&
                                  staff.findIndex(
                                    (e) => e.staffDbId == item.reviewerStaffDbId
                                  ) != -1
                                  ? staff.find(
                                    (e) =>
                                      e.staffDbId == item.reviewerStaffDbId
                                  ).firstName +
                                  " " +
                                  staff.find(
                                    (e) =>
                                      e.staffDbId == item.reviewerStaffDbId
                                  ).lastName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {companyMaster.length > 0 &&
                                  companyMaster.findIndex(
                                    (e) => e.companyDbId == item.companyDbId
                                  ) != -1
                                  ? companyMaster.find(
                                    (e) => e.companyDbId == item.companyDbId
                                  ).name
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.inspectionRosterDbId
                                  )
                                }
                              >
                                {formMaster.length > 0
                                  ? formMaster.find(
                                    (e) => e.formDbId === item.formDbId
                                  ).formName.length > 25
                                    ? formMaster
                                      .find(
                                        (e) => e.formDbId === item.formDbId
                                      )
                                      .formName.substring(0, 25) + "...."
                                    : formMaster.find(
                                      (e) => e.formDbId === item.formDbId
                                    ).formName
                                  : null}
                              </td>

                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(
                                        item.inspectionRosterDbId,
                                        "View"
                                      )
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(
                                        item.inspectionRosterDbId,
                                        "Edit"
                                      )
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() => ShowDeleteConfirmation(item)}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                          : search_content.length > 0
                            ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.inspectionRosterDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {item.shift}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.rosterDate).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.rosterDate).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.rosterDate).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {qualityProcessMap.length > 0
                                    ? qualityProcessMap.find(
                                      (e) =>
                                        e.qaProcessDbId === item.qaProcessDbId
                                    ).processName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {product.findIndex(
                                    (e) => e.productDbId === item.productDbId
                                  ) != -1
                                    ? product.find(
                                      (e) => e.productDbId === item.productDbId
                                    ).productName
                                    : null}
                                  {/* {product.length > 0
                            ? product.findIndex(
                                (e) => e.productDbId == item.productDbId
                              ) != -1
                              ? product[
                                  product.findIndex(
                                    (e) => e.productDbId == item.productDbId
                                  )
                                ].productName
                              : null
                            : null} */}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {staff.length > 0 &&
                                    staff.findIndex(
                                      (e) => e.staffDbId == item.inspectionStaffDbId
                                    ) != -1
                                    ? staff.find(
                                      (e) =>
                                        e.staffDbId == item.inspectionStaffDbId
                                    ).firstName +
                                    " " +
                                    staff.find(
                                      (e) =>
                                        e.staffDbId == item.inspectionStaffDbId
                                    ).lastName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {staff.length > 0 &&
                                    staff.findIndex(
                                      (e) => e.staffDbId == item.reviewerStaffDbId
                                    ) != -1
                                    ? staff.find(
                                      (e) =>
                                        e.staffDbId == item.reviewerStaffDbId
                                    ).firstName +
                                    " " +
                                    staff.find(
                                      (e) =>
                                        e.staffDbId == item.reviewerStaffDbId
                                    ).lastName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {companyMaster.length > 0 &&
                                    companyMaster.findIndex(
                                      (e) => e.companyDbId == item.companyDbId
                                    ) != -1
                                    ? companyMaster.find(
                                      (e) => e.companyDbId == item.companyDbId
                                    ).name
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.inspectionRosterDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.inspectionRosterDbId
                                    )
                                  }
                                >
                                  {formMaster.length > 0
                                    ? formMaster.find(
                                      (e) => e.formDbId === item.formDbId
                                    ).formName.length > 25
                                      ? formMaster
                                        .find(
                                          (e) => e.formDbId === item.formDbId
                                        )
                                        .formName.substring(0, 25) + "...."
                                      : formMaster.find(
                                        (e) => e.formDbId === item.formDbId
                                      ).formName
                                    : null}
                                </td>

                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(
                                          item.inspectionRosterDbId,
                                          "View"
                                        )
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(
                                          item.inspectionRosterDbId,
                                          "Edit"
                                        )
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() => ShowDeleteConfirmation(item)}
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                            : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    View Inspection Roster Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Shift</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={inspectionRosterObject.shift}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            product.find(
                              (e) =>
                                e.productDbId ===
                                inspectionRosterObject.productName
                            ).productName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Company Master</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            companyMaster.find(
                              (e) =>
                                e.companyDbId ===
                                inspectionRosterObject.companyMaster
                            ).name
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Quality Process</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            qualityProcessMap.find(
                              (e) =>
                                e.qaProcessDbId ===
                                inspectionRosterObject.qualityProcessName
                            ).processName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Inspection Staff
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            staff.find(
                              (e) =>
                                e.staffDbId ==
                                inspectionRosterObject.inspectionStaff
                            ).firstName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Review Staff</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            staff.find(
                              (e) =>
                                e.staffDbId ==
                                inspectionRosterObject.reviewStaff
                            ).lastName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            facilities.find(
                              (e) =>
                                e.facilityDbId ===
                                inspectionRosterObject.facilityName
                            ).name
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Form Master</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            formMaster.find(
                              (e) =>
                                e.formDbId ===
                                inspectionRosterObject.formMasterName
                            ).formName
                          }
                          className="child_input"
                        />
                      </div>
                      {/* <div className="parent_popup">
                  <div className="child_label_flex">
                    <label className="child_popup">Complaint Master</label>
                  </div>
                  <input
                    type="text"
                    disabled={true}
                    value={
                      complaintMaster.findIndex(
                        (e) =>
                          e.complaintDbId ==
                          inspectionRosterObject.complaintName
                      ) != -1
                        ? complaintMaster[
                            complaintMaster.findIndex(
                              (e) =>
                                e.complaintDbId ==
                                inspectionRosterObject.complaintName
                            )
                          ].customerName
                        : null
                    }
                    className="child_input"
                  />
                </div> */}
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Inspection Status
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={inspectionRosterObject.inspectionStatus}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Review Status</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={inspectionRosterObject.reviewStatus}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Roster Date</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              inspectionRosterObject.rosterDate
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  inspectionRosterObject.rosterDate
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                inspectionRosterObject.rosterDate
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectedRosterDbId, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Edit Inspection Roster Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Shift</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.shiftError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={inspectionRosterObject.shift}
                          onChange={(e) => onChangeValue(e, "shift", null)}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.productNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.productName}
                          onChange={(e) =>
                            onChangeValue(e, "productName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {product.length > 0
                            ? product.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.productDbId}>
                                  {item.productName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Quality Process</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.qualityProcessName}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.qualityProcessName}
                          onChange={(e) =>
                            onChangeValue(e, "qualityProcessName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {qualityProcessMap.length > 0
                            ? qualityProcessMap.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option
                                  key={index}
                                  value={item.qaProcessDbId}
                                >
                                  {item.processName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Company Master</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.companyMasterError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.companyMaster}
                          onChange={(e) =>
                            onChangeValue(e, "companyMaster", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {companyMaster.length > 0
                            ? companyMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.companyDbId}>
                                  {item.name}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Form Master</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.formMasterNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.formMasterName}
                          onChange={(e) =>
                            onChangeValue(e, "formMasterName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {formMaster.length > 0
                            ? formMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.formDbId}>
                                  {item.formName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Inspection Staff
                          </label>
                          <label className="error">
                            {inspectionRosterObjectErrors.inspectionStaffError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.inspectionStaff}
                          onChange={(e) =>
                            onChangeValue(e, "inspectionStaff", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {staff.length > 0
                            ? staff.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.staffDbId}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Review Staff</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.reviewStaffError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.reviewStaff}
                          onChange={(e) =>
                            onChangeValue(e, "reviewStaff", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {staff.length > 0
                            ? staff.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.staffDbId}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {inspectionRosterObjectErrors.facilityNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.facilityName}
                          onChange={(e) =>
                            onChangeValue(e, "facilityName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                              new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option
                                  key={facilityIndex}
                                  value={facility.facilityDbId}
                                >
                                  {facility.name}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Inspection Status
                          </label>
                          <label className="error">
                            {inspectionRosterObjectErrors.inspectionStatusError}
                          </label>
                        </div>
                        <input disabled={true}
                          type="text"
                          value={inspectionRosterObject.inspectionStatus}
                          onChange={(e) =>
                            onChangeValue(e, "inspectionStatus", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Review Status</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.reviewStatusError}
                          </label>
                        </div>
                        <input disabled={true}

                          type="text"
                          value={inspectionRosterObject.reviewStatus}
                          onChange={(e) =>
                            onChangeValue(e, "reviewStatus", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Roster Date</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.rosterDateError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              inspectionRosterObject.rosterDate
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  inspectionRosterObject.rosterDate
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                inspectionRosterObject.rosterDate
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "rosterDate", null)}
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => UpdateTheItem()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Add Inspection Roster Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Shift</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.shiftError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={inspectionRosterObject.shift}
                          onChange={(e) => onChangeValue(e, "shift", null)}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.productNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.productName}
                          onChange={(e) =>
                            onChangeValue(e, "productName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {product.length > 0
                            ? product.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.productDbId}>
                                  {item.productName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Quality Process</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.qualityProcessName}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.qualityProcessName}
                          onChange={(e) =>
                            onChangeValue(e, "qualityProcessName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {qualityProcessMap.length > 0
                            ? qualityProcessMap.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option
                                  key={index}
                                  value={item.qaProcessDbId}
                                >
                                  {item.processName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Company Master</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.companyMasterError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.companyMaster}
                          onChange={(e) =>
                            onChangeValue(e, "companyMaster", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {companyMaster.length > 0
                            ? companyMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.companyDbId}>
                                  {item.name}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Form Master</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.formMasterNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.formMasterName}
                          onChange={(e) =>
                            onChangeValue(e, "formMasterName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {formMaster.length > 0
                            ? formMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.formDbId}>
                                  {item.formName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Inspection Staff
                          </label>
                          <label className="error">
                            {inspectionRosterObjectErrors.inspectionStaffError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.inspectionStaff}
                          onChange={(e) =>
                            onChangeValue(e, "inspectionStaff", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {staff.length > 0
                            ? staff.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.staffDbId}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Review Staff</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.reviewStaffError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.reviewStaff}
                          onChange={(e) =>
                            onChangeValue(e, "reviewStaff", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {staff.length > 0
                            ? staff.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.staffDbId}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {inspectionRosterObjectErrors.facilityNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={inspectionRosterObject.facilityName}
                          onChange={(e) =>
                            onChangeValue(e, "facilityName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                              new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                <option
                                  key={facilityIndex}
                                  value={facility.facilityDbId}
                                >
                                  {facility.name}
                                </option>
                              ) : null
                            )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Inspection Status
                          </label>
                          <label className="error">
                            {inspectionRosterObjectErrors.inspectionStatusError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={inspectionRosterObject.inspectionStatus}
                          onChange={(e) =>
                            onChangeValue(e, "inspectionStatus", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Review Status</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.reviewStatusError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={inspectionRosterObject.reviewStatus}
                          onChange={(e) =>
                            onChangeValue(e, "reviewStatus", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Roster Date</label>
                          <label className="error">
                            {inspectionRosterObjectErrors.rosterDateError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              inspectionRosterObject.rosterDate
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  inspectionRosterObject.rosterDate
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                inspectionRosterObject.rosterDate
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "rosterDate", null)}
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      {/* <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button> */}
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}

      {qualityProcessPopup ||
        productPopup ||
        formMasterPopup ||
        reviewerStaffPopup ||
        inspectionStaffPopup == true ? (
        <div className="FilterMobile">
          {qualityProcessPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="QualityProcess"
                      type="text"
                      placeholder="Search"
                      value={search_quality}
                      onChange={(e) =>
                        OnChangeSearchFilter("QualityProcess", e)
                      }
                    />
                    <div
                      id="QualityProcess"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedQualityProccess(qualityProcessArray)
                      }
                    >
                      <img
                        id="QualityProcess"
                        className="Icons"
                        src={
                          qualityProcessArray.length ==
                            selectedQualityProcess.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="QualityProcess">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_quality.length == 0
                      ? qualityProcessArray.map((item, index) => (
                        <div
                          id="QualityProcess"
                          key={index}
                          className="Imageflex"
                          onClick={() =>
                            AddRemoveQualityProcess(item.qaProcessDbId)
                          }
                        >
                          <img
                            id="QualityProcess"
                            className="Icons"
                            src={
                              selectedQualityProcess.findIndex(
                                (e) => e.qaProcessDbId === item.qaProcessDbId
                              ) != -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="QualityProcess">{item.processName}</p>
                        </div>
                      ))
                      : search_quality.length > 0
                        ? search_quality_array.map((item, index) => (
                          <div
                            id="QualityProcess"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveQualityProcess(item.qaProcessDbId)
                            }
                          >
                            <img
                              id="QualityProcess"
                              className="Icons"
                              src={
                                selectedQualityProcess.findIndex(
                                  (e) => e.qaProcessDbId === item.qaProcessDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="QualityProcess">{item.processName}</p>
                          </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="QualityProcess"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("QualityProcess")}
                  >
                    Ok
                  </button>
                  <button
                    id="QualityProcess"
                    className="popupfile_button"
                    onClick={() => setSelectedQualityProccess([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {productPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Product"
                      type="text"
                      placeholder="Search"
                      value={search_product}
                      onChange={(e) => OnChangeSearchFilter("Product", e)}
                    />
                    <div
                      id="Product"
                      className="Imageflex"
                      onClick={() => setSelectedProducts(productArray)}
                    >
                      <img
                        id="Product"
                        className="Icons"
                        src={
                          productArray.length == selectedProducts.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Product">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_product.length === 0
                      ? productArray.map((item, index) => (
                        <div
                          id="Product"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveProducts(item.productDbId)}
                        >
                          <img
                            id="Product"
                            className="Icons"
                            src={
                              selectedProducts.findIndex(
                                (e) => e.productDbId === item.productDbId
                              ) !== -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="Product">{item.productName}</p>
                        </div>
                      ))
                      : search_product.length > 0
                        ? search_product_array.map((item, index) => (
                          <div
                            id="Product"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveProducts(item.productDbId)}
                          >
                            <img
                              id="Product"
                              className="Icons"
                              src={
                                selectedProducts.findIndex(
                                  (e) => e.productDbId === item.productDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Product">{item.productName}</p>
                          </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Product"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("Product")}
                  >
                    Ok
                  </button>
                  <button
                    id="Product"
                    className="popupfile_button"
                    onClick={() => setSelectedProducts([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {inspectionStaffPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="InspectionStaff"
                      type="text"
                      placeholder="Search"
                      value={search_inspectionstaff}
                      onChange={(e) =>
                        OnChangeSearchFilter("InspectionStaff", e)
                      }
                    />
                    <div
                      id="InspectionStaff"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedInspectionStaff(inspection_Staff_Array)
                      }
                    >
                      <img
                        id="InspectionStaff"
                        className="Icons"
                        src={
                          inspection_Staff_Array.length ==
                            selectedInspectionStaff.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="InspectionStaff">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_inspectionstaff.length === 0
                      ? inspection_Staff_Array.map((item, index) => (
                        <div
                          id="InspectionStaff"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveInspection(item.staffDbId)}
                        >
                          <img
                            id="InspectionStaff"
                            className="Icons"
                            src={
                              selectedInspectionStaff.findIndex(
                                (e) => e.staffDbId === item.staffDbId
                              ) !== -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="reviewerStaffPopup">
                            {item.firstName + " " + item.lastName}
                          </p>
                        </div>
                      ))
                      : search_inspectionstaff.length > 0
                        ? search_inspectionstaff_array.map((item, index) => (
                          <div
                            id="InspectionStaff"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveInspection(item.staffDbId)}
                          >
                            <img
                              id="InspectionStaff"
                              className="Icons"
                              src={
                                selectedInspectionStaff.findIndex(
                                  (e) => e.staffDbId === item.staffDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="InspectionStaff">
                              {item.firstName + " " + item.lastName}
                            </p>
                          </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="InspectionStaff"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("InspectionStaff")}
                  >
                    Ok
                  </button>
                  <button
                    id="InspectionStaff"
                    className="popupfile_button"
                    onClick={() => setSelectedInspectionStaff([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {reviewerStaffPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="reviewerStaffPopup"
                      type="text"
                      placeholder="Search"
                      value={search_reviwerstaff}
                      onChange={(e) => OnChangeSearchFilter("ReviewerStaff", e)}
                    />
                    <div
                      id="reviewerStaffPopup"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedReviewerStaff(reviewer_Staff_Array)
                      }
                    >
                      <img
                        id="reviewerStaffPopup"
                        className="Icons"
                        src={
                          reviewer_Staff_Array.length ==
                            selectedReviewerStaff.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="reviewerStaffPopup">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_reviwerstaff.length == 0
                      ? reviewer_Staff_Array.map((item, index) => (
                        <div
                          id="reviewerStaffPopup"
                          key={index}
                          className="Imageflex"
                          onClick={() =>
                            AddRemoveReviewerStaff(item.staffDbId)
                          }
                        >
                          <img
                            id="reviewerStaffPopup"
                            className="Icons"
                            src={
                              selectedReviewerStaff.findIndex(
                                (e) => e.staffDbId === item.staffDbId
                              ) !== -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="reviewerStaffPopup">
                            {item.firstName + " " + item.lastName}
                          </p>
                        </div>
                      ))
                      : search_reviwerstaff.length > 0
                        ? search_reviwerstaff_array.map((item, index) => (
                          <div
                            id="reviewerStaffPopup"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveReviewerStaff(item.staffDbId)
                            }
                          >
                            <img
                              id="reviewerStaffPopup"
                              className="Icons"
                              src={
                                selectedReviewerStaff.findIndex(
                                  (e) => e.staffDbId === item.staffDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="reviewerStaffPopup">
                              {item.firstName + " " + item.lastName}
                            </p>
                          </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="reviewerStaffPopup"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("ReviewerStaff")}
                  >
                    Ok
                  </button>
                  <button
                    id="reviewerStaffPopup"
                    className="popupfile_button"
                    onClick={() => setSelectedReviewerStaff([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {formMasterPopup === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Form"
                      type="text"
                      placeholder="Search"
                      value={search_form}
                      onChange={(e) => OnChangeSearchFilter("FormMaster", e)}
                    />
                    <div
                      id="Form"
                      className="Imageflex"
                      onClick={() => setSelectedForm(formMasterArray)}
                    >
                      <img
                        id="Form"
                        className="Icons"
                        src={
                          formMasterArray.length == selectedForm.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Form">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_form.length === 0
                      ? formMasterArray.map((item, index) => (
                        <div
                          id="Form"
                          key={index}
                          className="Imageflex"
                          onClick={() => AddRemoveSelectedForm(item.formDbId)}
                        >
                          <img
                            id="Form"
                            className="Icons"
                            src={
                              selectedForm.findIndex(
                                (e) => e.formDbId === item.formDbId
                              ) != -1
                                ? CheckIcon
                                : UnCheckIcon
                            }
                            alt=""
                          />
                          <p id="Form">{item.formName}</p>
                        </div>
                      ))
                      : search_form.length > 0
                        ? search_form_array.map((item, index) => (
                          <div
                            id="Form"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveSelectedForm(item.formDbId)}
                          >
                            <img
                              id="Form"
                              className="Icons"
                              src={
                                selectedForm.findIndex(
                                  (e) => e.formDbId === item.formDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Form">{item.formName}</p>
                          </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Form"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("FormMaster")}
                  >
                    Ok
                  </button>
                  <button
                    id="Form"
                    className="popupfile_button"
                    onClick={() => setSelectedForm([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default InspectionRoster;
