import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_quality_process } from "./Redux/QualityProcessSlice";
import { populate_production_process } from "./Redux/ProductionProcessSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_Facility } from "./Redux/FacilitySlice";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function QualityProcess() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "qualityProcessMap?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "Production") {
            setProduction(false);
          }
        });
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchtheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };

  const FetchTheFacility = () => {
    if (facilities.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempFacilityMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              tempFacilityMaster.push(response.data[i]);
            }
            dispatch(populate_Facility(tempFacilityMaster));
          }
        });
    }
  };
  const [productionProcessArray, setProductionProcessArray] = useState([]);
  const FetchTheProduction = (_QualityProcessMap) => {
    if (productionProcess.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "productionProcessMap")
        .then((response) => {
          if (response.status == 200) {
            let tempProductionProcess = [];
            let tempProductionProcessArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempProductionProcess.push(response.data[i]);
                if (
                  tempProductionProcessArray.findIndex(
                    (e) => e === response.data[i].processName
                  ) == -1
                ) {
                  tempProductionProcessArray.push(response.data[i].processName);
                }
              }
            }
            dispatch(populate_production_process(tempProductionProcess));
            let tempProduction = [];
            for (let i = 0; i < tempProductionProcess.length; i++) {
              if (
                _QualityProcessMap.findIndex(
                  (e) =>
                    e.prodProcessDbId ==
                    tempProductionProcess[i].prodProcessDbId
                ) != -1
              ) {
                tempProduction.push(tempProductionProcess[i]);
              }
            }
            setSelectedProduction(tempProduction);
            setProductionProcessArray(tempProduction);
          }
        });
    } else {
      // let tempProductionProcess = [...productionProcess];
      // let tempProduction = [];
      // for (let i = 0; i < tempProductionProcess.length; i++) {
      //   tempProduction.push(tempProductionProcess[i].processName);
      // }
      let tempProduction = [];
      for (let i = 0; i < productionProcess.length; i++) {
        if (
          _QualityProcessMap.findIndex(
            (e) => e.prodProcessDbId == productionProcess[i].prodProcessDbId
          ) != -1
        ) {
          tempProduction.push(productionProcess[i]);
        }
      }
      setProductionProcessArray(tempProduction);
      setSelectedProduction(tempProduction);
    }
  };
  const [search_production, setsearch_production] = useState("");
  const [search_production_array, setsearch_production_array] = useState([]);
  const OnChangeSearchProduction = (e) => {
    let content = e.target.value;
    setsearch_production(content.toLowerCase().trim());
    let temp = productionProcessArray.filter((e) =>
      e.processName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setsearch_production_array(temp);
  };

  //Get all the facilities
  let facilities = useSelector((state) => state.callFacilities.value);
  let productionProcess = useSelector(
    (state) => state.callProductionProcess.value
  );

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "qualityProcessMap";

  let qualityProcessMap = useSelector(
    (state) => state.callQualityProcess.value
  );
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [qualityProcessObjectErrors, setQualityProcessObjectErrors] = useState({
    qaProcessCodeError: "",
    processNameError: "",
    processTypeError: "",
    processStageSeqError: "",
    processRefUrlError: "",
    processDescError: "",
    effDateFromError: "",
    effDateToError: "",
    facilityNameError: "",
    productionProcessNameError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "qaProcessCodeError");
    OnChangeErrorValues("", "processNameError");
    OnChangeErrorValues("", "processTypeError");
    OnChangeErrorValues("", "processStageSeqError");
    OnChangeErrorValues("", "processRefUrlError");
    OnChangeErrorValues("", "processDescError");
    OnChangeErrorValues("", "productionProcessNameError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
    OnChangeErrorValues("", "facilityNameError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productionProcessNameError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          productionProcessNameError: val,
        }));
        break;
      case "qaProcessCodeError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          qaProcessCodeError: val,
        }));
        break;
      case "processNameError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          processNameError: val,
        }));
        break;
      case "processTypeError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          processTypeError: val,
        }));
        break;
      case "processStageSeqError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          processStageSeqError: val,
        }));
        break;
      case "processRefUrlError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          processRefUrlError: val,
        }));
        break;
      case "processDescError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          processDescError: val,
        }));
        break;
      case "effDateFromError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          effDateFromError: val,
        }));
        break;
      case "effDateToError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          effDateToError: val,
        }));
        break;
      case "facilityNameError":
        setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
          ...qualityProcessObjectErrors,
          facilityNameError: val,
        }));
        break;
    }
  };
  const [show_calender_date_from, set_show_calender_date_form] =
    useState(false);
  const [show_calender_date_to, set_show_calender_date_to] = useState(false);
  ///////////////////////////////////////////////////////////////////
  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < qualityProcessMap.length; i++) {
  //     if (qualityProcessMap[i].processName !== null) {
  //       if (
  //         qualityProcessMap[i].processName
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(qualityProcessMap[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    let content = e.target.value;
    current_page.current = 1;
    set_search_content(content);
    let temp = qualityProcessMap.filter((e1) =>
      e1.processName.toLowerCase().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (qualityProcessMap.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(qualityProcessMap[i]);
        }
      } else {
        for (let i = 0; i < qualityProcessMap.length; i++) {
          tempArray.push(qualityProcessMap[i]);
        }
      }
      total_pages.current = Math.ceil(
        qualityProcessMap.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
    // console.log("Data  set:"+temp.length)
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [qualityProcessMapArray, setQualityProcessMapArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  let product_array_ref = useRef([]);
  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [qualityProcessObject, setQualityProcessObject] = useState({
    productionProcessName: "",
    qaProcessCode: "",
    processName: "",
    processType: "",
    processStageSeq: "",
    processRefUrl: "",
    processDesc: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    facilityName: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  const [qualityProcessObjectDelete, setQualityProcessObjectDelete] = useState({
    productionProcessName: "",
    qaProcessCode: "",
    processName: "",
    processType: "",
    processStageSeq: "",
    processRefUrl: "",
    processDesc: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    facilityName: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });

  const onResetValue = () => {
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      qaProcessCode: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processName: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processType: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processStageSeq: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processRefUrl: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processDesc: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      effDateFrom: new Date(),
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      productionProcessName: "",
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      facilityName: 0,
    }));
  };

  const onChangeValue = (e, type, value) => {
    // console.log(value);
    // console.log(new Date(value).getFullYear()+"-"+new Date(value).getMonth()+"-"+new Date(value).getDate()+" "+new Date(value).getHours()+":"+new Date(value).getMinutes()+":"+new Date(value).getSeconds());
    // console.log(value.toISOString());
    // eslint-disable-next-line default-case
    switch (type) {
      case "productionProcessName":
        console.log(e.target.value);
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          productionProcessName: e.target.value,
        }));
        break;
      case "qaProcessCode":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          qaProcessCode: e.target.value,
        }));
        break;
      case "processName":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          processName: e.target.value,
        }));
        break;
      case "processType":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          processType: e.target.value[0],
        }));
        break;
      case "processStageSeq":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          processStageSeq: e.target.value,
        }));
        break;
      case "processRefUrl":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          processRefUrl: e.target.value,
        }));
        break;
      case "processDesc":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          processDesc: e.target.value,
        }));
        break;
      case "effDateFrom":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          effDateTo: e.target.value,
        }));
        break;
      case "facilityName":
        setQualityProcessObject((qualityProcessObject) => ({
          ...qualityProcessObject,
          facilityName: e.target.value,
        }));
        break;
    }
  };
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          qualityProcessMapArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            qualityProcessMapArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(qualityProcessMapArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            qualityProcessMapArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (qualityProcessMapArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(qualityProcessMapArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            set_display_draft_array(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            set_display_draft_array(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(qualityProcessMapArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        set_display_draft_array(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  const [link_to_update, set_link_to_update] = useState("");
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_show_calender_date_form(false);
    set_show_calender_date_to(false);
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = qualityProcessMap.findIndex((e) => e.qaProcessDbId === _code);
    select_deselect_the_record(qualityProcessMap[idx]);
    set_item_to_delete(qualityProcessMap[idx].qaProcessDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "qualityProcessMap?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updaupdatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "qualityProcessMap?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const AddTheItem = (status) => {
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          qaProcessCode: qualityProcessObject.qaProcessCode,
          processName: qualityProcessObject.processName,
          processType: qualityProcessObject.processType,
          processStageSeq: qualityProcessObject.processStageSeq,
          processRefUrl: qualityProcessObject.processRefUrl,
          processDesc: qualityProcessObject.processDesc,
          effDateFrom: qualityProcessObject.effDateFrom,
          effDateTo:
            status === true
              ? new Date("1900-01-01")
              : qualityProcessObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : null,
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : null,
          updatedTimestamp: new Date().toISOString(),
          facilityDbId: Number(qualityProcessObject.facilityName),
          prodProcessDbId: Number(qualityProcessObject.productionProcessName),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "qualityProcessMap?size=1000",
              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditQuality = () => {
    let deleteData = {
      qaProcessCode: qualityProcessObjectDelete.qaProcessCode,
      prqaProcessCode: qualityProcessObjectDelete.qaProcessCode,
      processName: qualityProcessObjectDelete.processName,
      processType: qualityProcessObjectDelete.processType,
      processStageSeq: qualityProcessObjectDelete.processStageSeq,
      processRefUrl: qualityProcessObjectDelete.processRefUrl,
      processDesc: qualityProcessObjectDelete.processDesc,
      effDateFrom: GetServerDate(qualityProcessObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      facilityDbId: Number(qualityProcessObjectDelete.facilityName),
      prodProcessDbId: Number(qualityProcessObjectDelete.productionProcessName),
    };
    let data = {
      qaProcessCode: qualityProcessObject.qaProcessCode,
      processName: qualityProcessObject.processName,
      processType: qualityProcessObject.processType,
      processStageSeq: qualityProcessObject.processStageSeq,
      processRefUrl: qualityProcessObject.processRefUrl,
      processDesc: qualityProcessObject.processDesc,
      effDateFrom: GetServerDate(qualityProcessObject.effDateFrom),
      effDateTo: GetServerDate(qualityProcessObject.effDateTo),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      facilityDbId: Number(qualityProcessObject.facilityName),
      prodProcessDbId: Number(qualityProcessObject.productionProcessName),
    };
    axios
      .patch(post_url + "/" + selectedqaDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON +
                    "qualityProcessMap?size=1000",

                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectedqaDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  function isValidHttpUrl(str) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return pattern.test(str);
  }
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Quality Process Map") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "qaProcessCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.qaProcessCode.length === 0
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                qaProcessCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              qualityProcessMapArray.findIndex(
                (e) => e.qaProcessCode === qualityProcessObject.qaProcessCode
              ) != -1
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                qaProcessCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "processName":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.processName.length == 0
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                processNameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "min_length" &&
              qualityProcessObject.processName.length > 0 &&
              qualityProcessObject.processName.length < 3
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                processNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "processDesc":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.processDesc.length == 0
            ) {
              temp++;
              OnChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "processDescError"
              );
            }
            break;
          case "processType":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.processType.length == 0
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                processTypeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "min_length" &&
              qualityProcessObject.processType.length > 1
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                processTypeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "processStageSeq":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.processStageSeq.length === 0
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                processStageSeqError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "facilityName":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.facilityName.length == 0
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                facilityNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          case "processRefUrl":
            if (qualityProcessObject.processRefUrl != null) {
              if (
                clientSideValidations[i].validationType === "url" &&
                qualityProcessObject.processRefUrl.length > 0 &&
                isValidHttpUrl(qualityProcessObject.processRefUrl) === false
              ) {
                temp++;
                setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                  ...qualityProcessObjectErrors,
                  processRefUrlError: clientSideValidations[i].shortMessage,
                }));
              }
            }
            break;
          case "effDateTo":
            if (
              clientSideValidations[i].validationType ===
                "greater_than_equalto_date" &&
              qualityProcessObject.effDateTo < qualityProcessObject.effDateFrom
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                effDateToError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "prodProcessCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              qualityProcessObject.productionProcessName == 0
            ) {
              temp++;
              setQualityProcessObjectErrors((qualityProcessObjectErrors) => ({
                ...qualityProcessObjectErrors,
                productionProcessNameError:
                  clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = qualityProcessMap.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        qualityProcessMap.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (qualityProcessMap.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < qualityProcessMap.length; i++) {
          temp_display_array.push(qualityProcessMap[i]);
        }
      } else {
        if (
          qualityProcessMap.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          qualityProcessMap.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < qualityProcessMap.length;
            i++
          ) {
            temp_display_array.push(qualityProcessMap[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(qualityProcessMap[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [paginatedArray, setPaginatedArray] = useState([]);
  const [selected_form_id, set_selected_form_id] = useState();
  const onChangeFormID = (e, _code) => {
    let temp = [];
    for (let i = 0; i < qualityProcessMap.length; i++) {
      temp.push(qualityProcessMap[i]);
    }
    let updatedArray = [];
    if (e != 0) {
      updatedArray = temp.filter((e1) => e1.prodProcessDbId == e);
    } else {
      updatedArray = temp;
    }

    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }

    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setQualityProcessMapArray(updatedArray);
    setPaginatedArray(tempArray);
    set_selected_form_id(e);
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_qualityProcessMap = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_qualityProcessMap.push(response.data[i]);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setQualityProcessMapArray(tempNonDraftArray);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_quality_process(temp_qualityProcessMap));
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchtheValidation();
          FetchTheFacility();
          FetchTheProduction(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectedqaDbId, setSelectedqaProcessDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (qaProcessDbId, type) => {
    setSelectedqaProcessDbId(qaProcessDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(qaProcessDbId);
  };
  const SupplyValuesToModal = (qaProcessDbId) => {
    let dateFrom = qualityProcessMap.find(
      (e) => e.qaProcessDbId == qaProcessDbId
    ).effDateFrom;
    let dateTo = qualityProcessMap.find(
      (e) => e.qaProcessDbId == qaProcessDbId
    ).effDateTo;
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      qaProcessCode: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).qaProcessCode,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      qaProcessCode: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).qaProcessCode,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processName: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processName,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      processName: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processName,
    }));

    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processType: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processType,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      processType: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processType,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processStageSeq: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processStageSeq,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      processStageSeq: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processStageSeq,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processRefUrl: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processRefUrl,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      processRefUrl: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processRefUrl,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      processDesc: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processDesc,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      processDesc: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).processDesc,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      effDateFrom: dateFrom,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      effDateFrom: dateFrom,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      effDateTo: dateTo,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      effDateTo: dateTo,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      facilityName: qualityProcessMap.find(
        (e1) => e1.qaProcessDbId == qaProcessDbId
      ).facilityDbId,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      facilityName: qualityProcessMap.find(
        (e1) => e1.qaProcessDbId == qaProcessDbId
      ).facilityDbId,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      createdBy: qualityProcessMap.find((e) => e.qaProcessDbId == qaProcessDbId)
        .createdBy,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      updatedBy: qualityProcessMap.find((e) => e.qaProcessDbId == qaProcessDbId)
        .updatedBy,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      createdTimestamp: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).createdTimestamp,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      updatedTimestamp: qualityProcessMap.find(
        (e) => e.qaProcessDbId == qaProcessDbId
      ).updatedTimestamp,
    }));
    setQualityProcessObject((qualityProcessObject) => ({
      ...qualityProcessObject,
      productionProcessName: qualityProcessMap.find(
        (e1) => e1.qaProcessDbId == qaProcessDbId
      ).prodProcessDbId,
    }));
    setQualityProcessObjectDelete((qualityProcessObjectDelete) => ({
      ...qualityProcessObjectDelete,
      productionProcessName: qualityProcessMap.find(
        (e1) => e1.qaProcessDbId == qaProcessDbId
      ).prodProcessDbId,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    console.log("Sorting:" + paginatedArray);
    let SortQualityProcess = [...qualityProcessMapArray];
    if (sortStyle === "Descending") {
      let updatedQualityProcess = SortQualityProcess.sort((a, b) => {
        const a_res = action === "Code" ? a.qaProcessCode : a.processName;
        const b_res = action === "Code" ? b.qaProcessCode : b.processName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedQualityProcess);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let updatedQualityProcess = SortQualityProcess.sort((a, b) => {
        const a_res = action === "Code" ? a.qaProcessCode : a.processName;
        const b_res = action === "Code" ? b.qaProcessCode : b.processName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedQualityProcess);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortQualityProcess.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortQualityProcess[i]);
      }
    } else {
      for (let i = 0; i < SortQualityProcess.length; i++) {
        tempArray.push(SortQualityProcess[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortQualityProcess.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setQualityProcessMapArray(SortQualityProcess);
    setPaginatedArray(tempArray);
  };
  const [production, setProduction] = useState(false);

  const OnClickProduction = () => {
    // let temp = [];
    // if (selectedProduction.length == 0) {
    //   for (let i = 0; i < productionProcess.length; i++) {
    //     temp.push(productionProcess[i].prodProcessDbId);
    //   }
    // } else {
    //   for (let i = 0; i < selectedProduction.length; i++) {
    //     temp.push(selectedProduction[i]);
    //   }
    // }
    // setSelectedProduction(temp);
    if (qualityProcessMap.length == 0) {
      setProduction(false);
    } else {
      setProduction(!production);
    }
  };
  const [selectedProduction, setSelectedProduction] = useState([]);
  const AddRemoveProduction = (id) => {
    let tempSelectedProduction = [...selectedProduction];
    let productIndex = tempSelectedProduction.findIndex(
      (e) => e.prodProcessDbId == id
    );
    if (productIndex == -1) {
      tempSelectedProduction.push(
        productionProcess.find((e) => e.prodProcessDbId == id)
      );
    } else {
      tempSelectedProduction.splice(productIndex, 1);
    }
    setSelectedProduction(tempSelectedProduction);
  };
  const OnPressedClearClicked = (prodProcessDbId) => {
    let tempSelectedProduction = [];
    if (selectedProduction.length == 0) {
      for (let i = 0; i < productionProcess.length; i++) {
        tempSelectedProduction.push(productionProcess[i].prodProcessDbId);
      }
    } else {
      tempSelectedProduction.splice(
        tempSelectedProduction.findIndex((e) => e === prodProcessDbId),
        1
      );
      setSelectedProduction(tempSelectedProduction);
    }
    setSelectedProduction(tempSelectedProduction);
  };
  const OnOkProduction = () => {
    let temp = [];
    for (let i = 0; i < qualityProcessMap.length; i++) {
      temp.push(qualityProcessMap[i]);
    }
    let updatedArray = [];
    for (let i = 0; i < selectedProduction.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].prodProcessDbId === selectedProduction[i].prodProcessDbId
          // productionProcess.find((e) => e.processName == selectedProduction[i])
          //   .prodProcessDbId
        ) {
          if (
            new Date(temp[j].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setQualityProcessMapArray(updatedArray);
    setPaginatedArray(tempArray);
    setProduction(false);
  };

  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col w-full ">
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader">
                    {" "}
                    Manage Quality Process Sequence
                  </div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search Quality Process"
                      value={search_content}
                      onChange={(e) => SearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons " src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "13%" }} />
                      <col span="1" style={{ width: "20%" }} />
                      <col span="1" style={{ width: "20%" }} />
                      <col span="1" style={{ width: "9%" }} />
                      <col span="1" style={{ width: "10" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Code")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Qua. Process Code
                            <img
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          <div className="IconsSpace">
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Name")
                              }
                              className="ArrowIcons"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                            Quality Process Name
                            <img
                              onClick={() =>
                                AscendingDescendingFunction("Name")
                              }
                              className="ArrowIcons opacity-0"
                              src={
                                sortStyle === "Descending" ? ArrowDown : ArrowUp
                              }
                              alt=""
                            />
                          </div>
                        </th>
                        <th>
                          {production === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Production"
                                        type="text"
                                        placeholder="Search"
                                        value={search_production}
                                        onChange={(e) =>
                                          OnChangeSearchProduction(e)
                                        }
                                      />
                                      <div
                                        id="Production"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedProduction(
                                            productionProcessArray
                                          )
                                        }
                                      >
                                        <img
                                          id="Production"
                                          className="Icons"
                                          src={
                                            productionProcessArray.length ==
                                            selectedProduction.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Production">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_production.length === 0
                                        ? productionProcessArray.map(
                                            (item, index) => (
                                              <div
                                                id="Production"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveProduction(
                                                    item.prodProcessDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Production"
                                                  className="Icons"
                                                  src={
                                                    selectedProduction.findIndex(
                                                      (e) =>
                                                        e.prodProcessDbId ==
                                                        item.prodProcessDbId
                                                    ) != -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Production">
                                                  {item.processName}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : search_production.length > 0
                                        ? search_production_array.map(
                                            (item, index) => (
                                              <div
                                                id="Production"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveProduction(
                                                    item.prodProcessDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Production"
                                                  className="Icons"
                                                  src={
                                                    selectedProduction.findIndex(
                                                      (e) =>
                                                        e.prodProcessDbId ===
                                                        item.prodProcessDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Production">
                                                  {item.processName}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Production"
                                      className="popupfile_button"
                                      onClick={() => OnOkProduction()}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Production"
                                      className="popupfile_button"
                                      onClick={() => OnPressedClearClicked()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="Production"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Production
                            <img
                              id="Production"
                              className="Icons"
                              onClick={() => OnClickProduction()}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>Process Type </th>
                        <th>Qua. Stage Seq</th>
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {/* (((current_page.current-1)*process.env.REACT_APP_ITEMS_PER_PAGE))+(index+1) */}
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.qaProcessDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.qaProcessCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {productionProcess.length > 0 &&
                                productionProcess.findIndex(
                                  (e) =>
                                    e.prodProcessDbId == item.prodProcessDbId
                                ) != -1
                                  ? productionProcess.find(
                                      (e) =>
                                        e.prodProcessDbId ==
                                        item.prodProcessDbId
                                    ).processName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processType}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processStageSeq}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.qaProcessDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.qaProcessDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.qaProcessDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.qaProcessDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.qaProcessCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {productionProcess.length > 0 &&
                                productionProcess.findIndex(
                                  (e) =>
                                    e.prodProcessDbId == item.prodProcessDbId
                                ) != -1
                                  ? productionProcess.find(
                                      (e) =>
                                        e.prodProcessDbId ==
                                        item.prodProcessDbId
                                    ).processName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processType}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processStageSeq}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.qaProcessDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.qaProcessDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.qaProcessDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.qaProcessDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.qaProcessCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processName}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {productionProcess.length > 0 &&
                                productionProcess.findIndex(
                                  (e) =>
                                    e.prodProcessDbId == item.prodProcessDbId
                                ) != -1
                                  ? productionProcess.find(
                                      (e) =>
                                        e.prodProcessDbId ==
                                        item.prodProcessDbId
                                    ).processName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processType}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {item.processStageSeq}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.qaProcessDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.qaProcessDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.qaProcessDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.qaProcessDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.qaProcessDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    View Quality Process Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Code
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={qualityProcessObject.qaProcessCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Name
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={qualityProcessObject.processName}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Type
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={qualityProcessObject.processType}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Process Stage Sequence
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={qualityProcessObject.processStageSeq}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Reference URL</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={qualityProcessObject.processRefUrl}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            facilities.find(
                              (e) =>
                                e.facilityDbId ===
                                qualityProcessObject.facilityName
                            ).name
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={qualityProcessObject.processDesc}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            productionProcess.find(
                              (e) =>
                                e.prodProcessDbId ===
                                qualityProcessObject.productionProcessName
                            ).processName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              qualityProcessObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  qualityProcessObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                qualityProcessObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              qualityProcessObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  qualityProcessObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                qualityProcessObject.effDateTo
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectedqaDbId, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Edit Quality Process Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Code
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.qaProcessCodeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.qaProcessCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Name
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.processNameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          onChange={(e) =>
                            onChangeValue(e, "processName", null)
                          }
                          value={qualityProcessObject.processName}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Type
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.processTypeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          maxLength={1}
                          value={qualityProcessObject.processType}
                          onChange={(e) =>
                            onChangeValue(e, "processType", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Process Stage Sequence
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.processStageSeqError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processStageSeq}
                          onChange={(e) =>
                            onChangeValue(e, "processStageSeq", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Reference URL</label>
                          <label className="error">
                            {qualityProcessObjectErrors.processRefUrlError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processRefUrl}
                          onChange={(e) =>
                            onChangeValue(e, "processRefUrl", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                          <label className="error">
                            {qualityProcessObjectErrors.processDescError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processDesc}
                          onChange={(e) =>
                            onChangeValue(e, "processDesc", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {qualityProcessObjectErrors.facilityName}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={qualityProcessObject.facilityName}
                          onChange={(e) =>
                            onChangeValue(e, "facilityName", null)
                          }
                        >
                          <option value={null} className="Poppins">
                            Select...
                          </option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                                new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.facilityName}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={qualityProcessObject.productionProcessName}
                          onChange={(e) =>
                            onChangeValue(e, "productionProcessName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {productionProcess.length > 0
                            ? productionProcess.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={index}
                                    value={item.prodProcessDbId}
                                  >
                                    {item.processName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {qualityProcessObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              qualityProcessObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  qualityProcessObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                qualityProcessObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {qualityProcessObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              qualityProcessObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  qualityProcessObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                qualityProcessObject.effDateTo
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEditQuality()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    Add Quality Process Details
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Code
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.qaProcessCodeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.qaProcessCode}
                          onChange={(e) =>
                            onChangeValue(e, "qaProcessCode", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Name
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.processNameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processName}
                          onChange={(e) =>
                            onChangeValue(e, "processName", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Process Type
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.processTypeError}
                          </label>
                        </div>
                        <input
                          type="text"
                          maxLength={1}
                          value={qualityProcessObject.processType}
                          onChange={(e) =>
                            onChangeValue(e, "processType", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Process Stage Sequence
                          </label>
                          <label className="error">
                            {qualityProcessObjectErrors.processStageSeqError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processStageSeq}
                          onChange={(e) =>
                            onChangeValue(e, "processStageSeq", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Reference URL</label>
                          <label className="error">
                            {qualityProcessObjectErrors.processRefUrlError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processRefUrl}
                          onChange={(e) =>
                            onChangeValue(e, "processRefUrl", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                          <label className="error">
                            {qualityProcessObjectErrors.processDescError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={qualityProcessObject.processDesc}
                          onChange={(e) =>
                            onChangeValue(e, "processDesc", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Facility Code </label>
                          <label className="error">
                            {qualityProcessObjectErrors.facilityNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={qualityProcessObject.facilityName}
                          onChange={(e) =>
                            onChangeValue(e, "facilityName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {facilities.length > 0
                            ? facilities.map((facility, facilityIndex) =>
                                new Date(facility.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Production Process
                          </label>
                          <label className="error">
                            {
                              qualityProcessObjectErrors.productionProcessNameError
                            }
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={qualityProcessObject.productionProcessName}
                          onChange={(e) =>
                            onChangeValue(e, "productionProcessName", null)
                          }
                        >
                          <option value={null}>Select...</option>
                          {productionProcess.length > 0
                            ? productionProcess.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option
                                    key={index}
                                    value={item.prodProcessDbId}
                                  >
                                    {item.processName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                          <label className="error">
                            {qualityProcessObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              qualityProcessObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  qualityProcessObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                qualityProcessObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                          <label className="error">
                            {qualityProcessObjectErrors.effDateToError}
                          </label>
                        </div>
                        <input
                          type="date"
                          value={
                            new Date(
                              qualityProcessObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  qualityProcessObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                qualityProcessObject.effDateTo
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}

      {production == true ? (
        <div className="FilterMobile">
          {production === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Production"
                      type="text"
                      placeholder="Search"
                      value={search_production}
                      onChange={(e) => OnChangeSearchProduction(e)}
                    />
                    <div
                      id="Production"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedProduction(productionProcessArray)
                      }
                    >
                      <img
                        id="Production"
                        className="Icons"
                        src={
                          productionProcessArray.length ==
                          selectedProduction.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Production">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_production.length === 0
                      ? productionProcessArray.map((item, index) => (
                          <div
                            id="Production"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveProduction(item.prodProcessDbId)
                            }
                          >
                            <img
                              id="Production"
                              className="Icons"
                              src={
                                selectedProduction.findIndex(
                                  (e) =>
                                    e.prodProcessDbId == item.prodProcessDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Production">{item.processName}</p>
                          </div>
                        ))
                      : search_production.length > 0
                      ? search_production_array.map((item, index) => (
                          <div
                            id="Production"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveProduction(item.prodProcessDbId)
                            }
                          >
                            <img
                              id="Production"
                              className="Icons"
                              src={
                                selectedProduction.findIndex(
                                  (e) =>
                                    e.prodProcessDbId === item.prodProcessDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Production">{item.processName}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Production"
                    className="popupfile_button"
                    onClick={() => OnOkProduction()}
                  >
                    Ok
                  </button>
                  <button
                    id="Production"
                    className="popupfile_button"
                    onClick={() => OnPressedClearClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}
export default QualityProcess;
