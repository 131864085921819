import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_supplier } from "./Redux/SupplierSlice";
import { populate_parts } from "./Redux/PartsSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_Products } from "./Redux/ProductsSlice";
import { populate_Bom } from "./Redux/BomSlice";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";

function BOM() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated == false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "bom?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "Supplier") {
            setSupplierPop(false);
          }
          if (e.target.id != "Parts") {
            setPartsPop(false);
          }
          if (e.target.id != "Product") {
            setProductPop(false);
          }
        });
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  let product = useSelector((state) => state.callProducts.value);
  const [productArray, setProductArray] = useState([]);
  const FetchTheProduct = (_bomMaster) => {
    if (product.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "productMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempProductMaster = [];
            let tempProductArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempProductMaster.push(response.data[i]);
                if (
                  tempProductArray.findIndex(
                    (e) => e === response.data[i].productName
                  ) == -1
                ) {
                  tempProductArray.push(response.data[i].productName);
                }
              }
            }
            dispatch(populate_Products(tempProductMaster));
            let tempProduct = [];
            for (let i = 0; i < tempProductMaster.length; i++) {
              if (
                _bomMaster.findIndex(
                  (e) => e.productDbId == tempProductMaster[i].productDbId
                ) != -1
              ) {
                tempProduct.push(tempProductMaster[i]);
              }
            }
            setSelectedProducts(tempProduct);
            setProductArray(tempProduct);
          }
        });
    } else {
      // let tempProductMaster = [...product];
      // let tempProduct = [];
      // for (let i = 0; i < tempProductMaster.length; i++) {
      //   tempProduct.push(tempProductMaster[i].productName);
      // }
      // setProductArray(tempProduct);
      // setSelectedProducts(tempProduct);
      let tempProduct = [];
      for (let i = 0; i < product.length; i++) {
        if (
          _bomMaster.findIndex(
            (e) => e.productDbId == product[i].productDbId
          ) != -1
        ) {
          tempProduct.push(product[i]);
        }
      }
      setSelectedProducts(tempProduct);
      setProductArray(tempProduct);
    }
  };
  const [supplierArray, setSupplierArray] = useState([]);
  let supplierMaster = useSelector((state) => state.callSupplier.value);
  const FetchTheSupplier = (_bomMaster) => {
    if (supplierMaster.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "supplierMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempSupplierMaster = [];
            let tempSupplierArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempSupplierMaster.push(response.data[i]);
                if (
                  tempSupplierArray.findIndex(
                    (e) => e === response.data[i].supplierName
                  ) == -1
                ) {
                  tempSupplierArray.push(response.data[i].supplierName);
                }
              }
            }
            dispatch(populate_supplier(tempSupplierMaster));
            let tempSuppliers = [];
            for (let i = 0; i < tempSupplierMaster.length; i++) {
              if (
                _bomMaster.findIndex(
                  (e) => e.supplierDbId == tempSupplierMaster[i].supplierDbId
                ) != -1
              ) {
                tempSuppliers.push(tempSupplierMaster[i]);
              }
            }
            setSelectedSupplier(tempSuppliers);
            setSupplierArray(tempSuppliers);
          }
        });
    } else {
      // let tempSupplierMaster = [...supplierMaster];
      // let tempSupplier = [];
      // for (let i = 0; i < tempSupplierMaster.length; i++) {
      //   tempSupplier.push(tempSupplierMaster[i].supplierName);
      // }
      // setSelectedSupplier(tempSupplier);
      // setSupplierArray(tempSupplier);
      let tempSuppliers = [];
      for (let i = 0; i < supplierMaster.length; i++) {
        if (
          _bomMaster.findIndex(
            (e) => e.supplierDbId == supplierMaster[i].supplierDbId
          ) != -1
        ) {
          tempSuppliers.push(supplierMaster[i]);
        }
      }
      setSelectedSupplier(tempSuppliers);
      setSupplierArray(tempSuppliers);
    }
  };
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "bom";

  let bomMaster = useSelector((state) => state.callBom.value);
  let partsMaster = useSelector((state) => state.callParts.value);
  const [partsArray, setPartsArray] = useState([]);
  const FetchTheParts = (_bomMaster) => {
    if (partsMaster.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "partsMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempPartsMaster = [];
            let tempPartsArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempPartsMaster.push(response.data[i]);
                if (
                  tempPartsArray.findIndex(
                    (e) => e === response.data[i].partName
                  ) == -1
                ) {
                  tempPartsArray.push(response.data[i].partName);
                }
              }
            }
            dispatch(populate_parts(tempPartsMaster));
            let tempParts = [];
            for (let i = 0; i < tempPartsMaster.length; i++) {
              if (
                _bomMaster.findIndex(
                  (e) => e.partDbId == tempPartsMaster[i].partDbId
                ) != -1
              ) {
                tempParts.push(tempPartsMaster[i]);
              }
            }
            setSelectedParts(tempParts);
            setPartsArray(tempParts);
          }
        });
    }
    // else {
    //   let tempPartsMaster = [...partsMaster];
    //   let tempParts = [];
    //   for (let i = 0; i < tempPartsMaster.length; i++) {
    //     tempParts.push(tempPartsMaster[i].partName);
    //   }
    //   setSelectedParts(tempParts);
    //   setPartsArray(tempParts);
    // }
    else {
      let tempParts = [];
      console.log("Bom Master:" + _bomMaster.length);
      for (let i = 0; i < partsMaster.length; i++) {
        if (
          _bomMaster.findIndex((e) => e.partDbId == partsMaster[i].partDbId) !=
          -1
        ) {
          tempParts.push(partsMaster[i]);
        }
      }
      setSelectedParts(tempParts);
      setPartsArray(tempParts);
    }
  };
  //Post Url

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [bomObjectErrors, setBomObjectErrors] = useState({
    partNameError: "",
    productNameError: "",
    supplierNameError: "",
    quantityRequiredError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "partNameError");
    OnChangeErrorValues("", "productNameError");
    OnChangeErrorValues("", "supplierNameError");
    OnChangeErrorValues("", "quantityRequiredError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "partNameError":
        setBomObjectErrors((bomObjectErrors) => ({
          ...bomObjectErrors,
          partNameError: error,
        }));
        break;
      case "productNameError":
        setBomObjectErrors((bomObjectErrors) => ({
          ...bomObjectErrors,
          productNameError: error,
        }));
        break;
      case "supplierNameError":
        setBomObjectErrors((bomObjectErrors) => ({
          ...bomObjectErrors,
          supplierNameError: error,
        }));
        break;
      case "quantityRequiredError":
        setBomObjectErrors((bomObjectErrors) => ({
          ...bomObjectErrors,
          quantityRequiredError: error,
        }));
        break;

      case "effDateFromError":
        setBomObjectErrors((bomObjectErrors) => ({
          ...bomObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setBomObjectErrors((bomObjectErrors) => ({
          ...bomObjectErrors,
          effDateToError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = [];
    let filterProductArray = product.filter((e) =>
      e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    for (let i = 0; i < filterProductArray.length; i++) {
      let tempArray = bomMaster.filter(
        (e1) => e1.productDbId == filterProductArray[i].productDbId
      );
      for (let j = 0; j < tempArray.length; j++) {
        temp.push(tempArray[j]);
      }
    }
    setLoading(false);
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (bomMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(bomMaster[i]);
        }
      } else {
        for (let i = 0; i < bomMaster.length; i++) {
          tempArray.push(bomMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        bomMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [bomArray, setBomArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [bomObject, setBomObject] = useState({
    productName: "",
    partName: "",
    supplierName: "",
    quantityRequired: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });

  const [bomObjectDelete, setBomObjectDelete] = useState({
    productName: "",
    partName: "",
    supplierName: "",
    quantityRequired: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  const onResetValue = () => {
    setBomObject((bomObject) => ({
      ...bomObject,
      productName: "",
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      partName: "",
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      supplierName: "",
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      quantityRequired: "",
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      effDateFrom: new Date(),
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const [filterSupplier, setFilterSupplier] = useState([]);
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case

    switch (type) {
      case "productName":
        setBomObject((bomObject) => ({
          ...bomObject,
          productName: e.target.value,
        }));
        break;
      case "partName":
        setBomObject((bomObject) => ({
          ...bomObject,
          partName: e.target.value,
        }));
        if (e.target.value != null) {
          let temp = [partsMaster.find((e1) => e1.partDbId == e.target.value)];
          setFilterSupplier(temp);
          // alert(temp);
        }
        break;
      case "supplierName":
        setBomObject((bomObject) => ({
          ...bomObject,
          supplierName: e.target.value,
        }));
        break;
      case "quantityRequired":
        setBomObject((bomObject) => ({
          ...bomObject,
          quantityRequired: e.target.value,
        }));
        break;
      case "effDateFrom":
        setBomObject((bomObject) => ({
          ...bomObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setBomObject((bomObject) => ({
          ...bomObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          bomArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            bomArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(bomArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            bomArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (bomArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(bomArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(bomArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);

  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = bomMaster.findIndex((e) => e.bomDbId === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(bomMaster[idx]);
    set_item_to_delete(bomMaster[idx].bomDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    console.log("code" + _code);
    console.log("delete_multiple" + multiple_codes);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "bom?size=1000",
              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "bom?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      effDateFrom: bomObject.effDateFrom,
      effDateTo: status === true ? new Date("1900-01-01") : bomObject.effDateTo,
      qtyRequired: Number(bomObject.quantityRequired),
      productDbId: Number(bomObject.productName),
      partDbId: Number(bomObject.partName),
      supplierDbId: Number(bomObject.supplierName),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };

    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "bom?size=1000",
              "Post"
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditBom = () => {
    console.log("Updating" + post_url + "/" + selectBomDbId);
    let deleteData = {
      effDateFrom: GetServerDate(bomObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      qtyRequired: Number(bomObjectDelete.quantityRequired),
      productDbId: Number(bomObjectDelete.productName),
      partDbId: Number(bomObjectDelete.partName),
      supplierDbId: Number(bomObjectDelete.supplierName),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      effDateFrom: GetServerDate(bomObject.effDateFrom),
      effDateTo: GetServerDate(bomObject.effDateTo),
      productDbId: Number(bomObject.productName),
      partDbId: Number(bomObject.partName),
      qtyRequired: Number(bomObject.quantityRequired),
      supplierDbId: Number(bomObject.supplierName),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    axios
      .patch(post_url + "/" + selectBomDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON + "bom?size=1000",
                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectBomDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "BOM") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "supplierDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              bomObject.supplierName == 0
            ) {
              temp++;
              setBomObjectErrors((bomObjectErrors) => ({
                ...bomObjectErrors,
                supplierNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "partDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              bomObject.partName == 0
            ) {
              temp++;
              setBomObjectErrors((bomObjectErrors) => ({
                ...bomObjectErrors,
                partNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "productDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              bomObject.productName == 0
            ) {
              temp++;
              setBomObjectErrors((bomObjectErrors) => ({
                ...bomObjectErrors,
                partNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = bomMaster.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        bomMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (bomMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < bomMaster.length; i++) {
          temp_display_array.push(bomMaster[i]);
        }
      } else {
        if (
          bomMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          bomMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < bomMaster.length;
            i++
          ) {
            temp_display_array.push(bomMaster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(bomMaster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_BomMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_BomMaster.push(response.data[i]);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setBomArray(tempNonDraftArray);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_Bom(temp_BomMaster));

          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheSupplier(tempNonDraftArray);
          FetchTheValidation();
          FetchTheProduct(tempNonDraftArray);
          FetchTheParts(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            setLoading(false);
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
      });
  };

  const [selectBomDbId, setSelectBomDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (bomDbId, type) => {
    setSelectBomDbId(bomDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(bomDbId);
  };
  const SupplyValuesToModal = (bomDbId) => {
    let dateFrom = bomMaster.find((e) => e.bomDbId == bomDbId).effDateFrom;
    let dateTo = bomMaster.find((e) => e.bomDbId == bomDbId).effDateTo;
    console.log(bomMaster.find((e) => e.bomDbId == bomDbId).effDateTo);
    setBomObject((bomObject) => ({
      ...bomObject,
      productName: bomMaster.find((e) => e.bomDbId == bomDbId).productDbId,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      productName: bomMaster.find((e) => e.bomDbId == bomDbId).productDbId,
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      partName: bomMaster.find((e) => e.bomDbId == bomDbId).partDbId,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      partName: bomMaster.find((e) => e.bomDbId == bomDbId).partDbId,
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      quantityRequired: bomMaster.find((e) => e.bomDbId == bomDbId).qtyRequired,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      quantityRequired: bomMaster.find((e) => e.bomDbId == bomDbId).qtyRequired,
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      supplierName: bomMaster.find((e) => e.bomDbId == bomDbId).supplierDbId,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      supplierName: bomMaster.find((e) => e.bomDbId == bomDbId).supplierDbId,
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      effDateFrom: dateFrom,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      effDateFrom: dateFrom,
    }));
    setBomObject((bomObject) => ({
      ...bomObject,
      effDateTo: dateTo,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      effDateTo: dateTo,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      createdBy: bomMaster.find((e) => e.bomDbId == bomDbId).createdBy,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      updatedBy: bomMaster.find((e) => e.bomDbId == bomDbId).updatedBy,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      createdTimestamp: bomMaster.find((e) => e.bomDbId == bomDbId)
        .createdTimestamp,
    }));
    setBomObjectDelete((bomObjectDelete) => ({
      ...bomObjectDelete,
      updatedTimestamp: bomMaster.find((e) => e.bomDbId == bomDbId)
        .updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortPartMaster = [...bomArray];
    if (sortStyle === "Descending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.productName;
        const b_res = action === "Code" ? b.partCode : b.productName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedSupplier);
      setSortStyle("Ascending");
      setBomArray(updatedSupplier);
    } else if (sortStyle === "Ascending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.productName;
        const b_res = action === "Code" ? b.partCode : b.productName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedSupplier);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortPartMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    } else {
      for (let i = 0; i < SortPartMaster.length; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortPartMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setBomArray(SortPartMaster);
    setPaginatedArray(tempArray);
  };
  const [supplierPop, setSupplierPop] = useState(false);
  const [partsPop, setPartsPop] = useState(false);
  const [productPop, setProductPop] = useState(false);
  const OnClickFilter = (purpose) => {
    switch (purpose) {
      case "Product":
        let tempProduct = [];
        if (selectedProducts.length == 0) {
          for (let i = 0; i < product.length; i++) {
            tempProduct.push(product[i].productDbId);
          }
        } else {
          for (let i = 0; i < selectedProducts.length; i++) {
            tempProduct.push(selectedProducts[i]);
          }
        }
        setSelectedProducts(tempProduct);
        if (bomMaster.length == 0) {
          setProductPop(false);
        } else {
          setProductPop(!productPop);
        }
        break;
      case "Parts":
        // let tempParts = [];
        // if (selectedParts.length == 0) {
        //   for (let i = 0; i < partsMaster.length; i++) {
        //     tempParts.push(partsMaster[i].partDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedParts.length; i++) {
        //     tempParts.push(selectedParts[i]);
        //   }
        // }
        // setSelectedParts(tempParts);
        if (bomMaster.length == 0) {
          setPartsPop(false);
        } else {
          setPartsPop(!partsPop);
        }
        break;
      case "Suppliers":
        let tempSuppliers = [];
        if (selectedSupplier.length == 0) {
          for (let i = 0; i < supplierMaster.length; i++) {
            tempSuppliers.push(supplierMaster[i].supplierDbId);
          }
        } else {
          for (let i = 0; i < selectedSupplier.length; i++) {
            tempSuppliers.push(selectedSupplier[i]);
          }
        }
        setSelectedSupplier(tempSuppliers);
        if (bomMaster.length == 0) {
          setSupplierPop(false);
        } else {
          setSupplierPop(!supplierPop);
        }
        break;
    }
  };
  const OnClearProductsClicked = (productDbId) => {
    let tempselectedProducts = [];
    if (selectedProducts.length == 0) {
      for (let i = 0; i < product.length; i++) {
        tempselectedProducts.push(product[i].productDbId);
      }
    } else {
      tempselectedProducts.splice(
        tempselectedProducts.findIndex((e) => e === productDbId),
        1
      );
      setSelectedProducts(tempselectedProducts);
    }
    setSelectedProducts(tempselectedProducts);
  };
  const OnClearPartsClicked = (partDbId) => {
    let tempselectedParts = [];
    if (selectedProducts.length == 0) {
      for (let i = 0; i < partsMaster.length; i++) {
        tempselectedParts.push(partsMaster[i].partDbId);
      }
    } else {
      tempselectedParts.splice(
        tempselectedParts.findIndex((e) => e === partDbId),
        1
      );
      setSelectedParts(tempselectedParts);
    }
    setSelectedParts(tempselectedParts);
  };
  const OnClearSupplierClicked = (supplierDbId) => {
    let tempSelectedSupplier = [];
    if (selectedSupplier.length == 0) {
      for (let i = 0; i < supplierMaster.length; i++) {
        tempSelectedSupplier.push(supplierMaster[i].supplierDbId);
      }
    } else {
      tempSelectedSupplier.splice(
        tempSelectedSupplier.findIndex((e) => e === supplierDbId),
        1
      );
      setSelectedSupplier(tempSelectedSupplier);
    }
    setSelectedSupplier(tempSelectedSupplier);
  };

  const OnOkAllFilter = (purpose) => {
    switch (purpose) {
      case "Product":
        let tempProduct = [...bomMaster];
        let updatedProductArray = [];
        for (let i = 0; i < selectedProducts.length; i++) {
          for (let j = 0; j < tempProduct.length; j++) {
            if (
              tempProduct[j].productDbId === selectedProducts[i].productDbId
              // product.find((e) => e.productName == selectedProducts[i])
              //   .productDbId
            ) {
              if (
                new Date(tempProduct[j].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              )
                updatedProductArray.push(tempProduct[j]);
            }
          }
        }
        let tempProductArray = [];
        if (updatedProductArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempProductArray.push(updatedProductArray[i]);
          }
        } else {
          for (let i = 0; i < updatedProductArray.length; i++) {
            tempProductArray.push(updatedProductArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedProductArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );

        setProductPop(false);
        let filteredProducts = updatedProductArray.filter(
          (item) =>
            new Date(item.effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
        );
        setBomArray(filteredProducts);
        setPaginatedArray(tempProductArray);
        break;
      case "Supplier":
        let temp = [...bomMaster];
        let updatedArray = [];
        for (let i = 0; i < selectedSupplier.length; i++) {
          for (let j = 0; j < temp.length; j++) {
            if (
              temp[j].supplierDbId === selectedSupplier[i].supplierDbId
              // supplierMaster.find((e) => e.supplierName == selectedSupplier[i])
              //   .supplierDbId
            ) {
              if (
                new Date(temp[j].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              )
                updatedArray.push(temp[j]);
            }
          }
        }
        let tempArray = [];
        if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempArray.push(updatedArray[i]);
          }
        } else {
          for (let i = 0; i < updatedArray.length; i++) {
            tempArray.push(updatedArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setBomArray(updatedArray);
        setPaginatedArray(tempArray);
        setSupplierPop(false);
        break;
      case "Parts":
        let tempParts = [...bomMaster];
        let updatedPartsArray = [];
        for (let i = 0; i < selectedParts.length; i++) {
          for (let j = 0; j < tempParts.length; j++) {
            if (
              tempParts[j].partDbId === selectedParts[i].partDbId
              // partsMaster.find((e) => e.partDbID == selectedParts[i]).partDbId
            ) {
              if (
                new Date(tempParts[j].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              )
                updatedPartsArray.push(tempParts[j]);
            }
          }
        }
        let tempPartsArray = [];
        if (updatedPartsArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempPartsArray.push(updatedPartsArray[i]);
          }
        } else {
          for (let i = 0; i < updatedPartsArray.length; i++) {
            tempPartsArray.push(updatedPartsArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedPartsArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setBomArray(updatedPartsArray);
        setPaginatedArray(tempPartsArray);
        setPartsPop(false);
        break;
    }
  };
  const [search_supplier, setsearch_supplier] = useState("");
  const [search_supplier_array, setsearch_supplier_array] = useState([]);
  const [search_product, setsearch_product] = useState("");
  const [search_product_array, setsearch_product_array] = useState([]);
  const [search_parts, setsearch_parts] = useState("");
  const [search_parts_array, setsearch_parts_array] = useState([]);
  const OnChangeSearchFilter = (_option, e) => {
    switch (_option) {
      case "Product":
        let content = e.target.value;
        setsearch_product(content.toLowerCase());
        let temp = productArray.filter((e) =>
          e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
        );
        setsearch_product_array(temp);
        break;
      case "Supplier":
        let contentSupplier = e.target.value;
        setsearch_supplier(contentSupplier.toLowerCase());
        let tempSupplier = supplierArray.filter((e) =>
          e.supplierName
            .toLowerCase()
            .trim()
            .match(contentSupplier.toLowerCase().trim())
        );
        setsearch_supplier_array(tempSupplier);
        break;
      case "Parts":
        let contentParts = e.target.value;
        setsearch_parts(contentParts.toLowerCase());
        let tempParts = partsArray.filter((e) =>
          e.partName
            .toLowerCase()
            .trim()
            .match(contentParts.toLowerCase().trim())
        );
        setsearch_parts_array(tempParts);
        break;
    }
  };
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const AddRemoveSupplier = (id) => {
    let tempSelectedSupplier = [...selectedSupplier];
    let supplierIndex = tempSelectedSupplier.findIndex(
      (e) => e.supplierDbId == id
    );
    if (supplierIndex == -1) {
      tempSelectedSupplier.push(
        supplierMaster.find((e) => e.supplierDbId == id)
      );
    } else {
      tempSelectedSupplier.splice(supplierIndex, 1);
    }
    setSelectedSupplier(tempSelectedSupplier);
  };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const AddRemoveProduct = (id) => {
    let tempSelectedProduct = [...selectedProducts];
    let productIndex = tempSelectedProduct.findIndex(
      (e) => e.productDbId == id
    );
    if (productIndex == -1) {
      tempSelectedProduct.push(product.find((e) => e.productDbId == id));
    } else {
      tempSelectedProduct.splice(productIndex, 1);
    }
    setSelectedProducts(tempSelectedProduct);
  };
  const [selectedParts, setSelectedParts] = useState([]);
  const AddRemoveParts = (id) => {
    let tempSelectedParts = [...selectedParts];
    let partsIndex = tempSelectedParts.findIndex((e) => e.partDbId == id);
    if (partsIndex == -1) {
      tempSelectedParts.push(partsMaster.find((e) => e.partDbId == id));
    } else {
      tempSelectedParts.splice(partsIndex, 1);
    }
    setSelectedParts(tempSelectedParts);
  };
  return (
    <div className="flex flex-col justify-center">
      {isAuthenticated == true ? (
        <div className="flex flex-col w-full ">
          <Header />
          <div className="flex flex-row w-full">
            <div className="container-fluid">
              <div
                className="multipleSelection"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div
                  style={{
                    backgroundColor: mews_popup_color,
                    opacity:
                      (open_edit_modal === false && show_mews_popup === true) ||
                      multiple_codes.length > 0
                        ? 1
                        : 0,
                  }}
                  className="green-bar"
                >
                  <p>
                    {multiple_codes.length === 0
                      ? "" + mews_message
                      : `You have selected ${multiple_codes.length} records.`}
                    {""}
                    {multiple_codes === 0 ? "Click Here" : null}
                  </p>
                </div>
              </div>
              <div
                className="manageSplit"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                  <div className="manageHeader">Manage BOM</div>
                </div>
                <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                  <img
                    className="Icons"
                    onClick={() => OnClickShowDrafts()}
                    src={show_drafts === false ? UncheckboxIcon : CheckboxIcon}
                    alt=""
                  />
                  <label className="viewDrafts">View Drafts</label>
                </div>
                <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                  <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="searchInput">
                    <input
                      type="text"
                      placeholder="Search BOM"
                      value={search_content}
                      onChange={(e) => onChangeSearchContent(e)}
                      className="searchInside"
                    />
                    <img className="Icons" src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="tablerounds"
                style={{
                  opacity:
                    open_view_modal === true ||
                    open_add_modal === true ||
                    open_edit_modal === true ||
                    show_delete_confirmations === true
                      ? 0.25
                      : 4,
                }}
              >
                <div className="tableboxes">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "25%" }} />
                      <col span="1" style={{ width: "25%" }} />
                      <col span="1" style={{ width: "21%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                      <col span="1" style={{ width: "8%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>
                          {productPop === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Product"
                                        type="text"
                                        placeholder="Search"
                                        value={search_product}
                                        onChange={(e) =>
                                          OnChangeSearchFilter("Product", e)
                                        }
                                      />
                                      <div
                                        id="Product"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedProducts(productArray)
                                        }
                                      >
                                        <img
                                          id="Product"
                                          className="Icons"
                                          src={
                                            productArray.length ==
                                            selectedProducts.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Product">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_product.length === 0
                                        ? productArray.map((item, index) => (
                                            <div
                                              id="Product"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveProduct(
                                                  item.productDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="Product"
                                                className="Icons"
                                                src={
                                                  selectedProducts.findIndex(
                                                    (e) =>
                                                      e.productDbId ===
                                                      item.productDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="Product">
                                                {item.productName}
                                              </p>
                                            </div>
                                          ))
                                        : search_product.length > 0
                                        ? search_product_array.map(
                                            (item, index) => (
                                              <div
                                                id="Product"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveProduct(
                                                    item.productDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Product"
                                                  className="Icons"
                                                  src={
                                                    selectedProducts.findIndex(
                                                      (e) =>
                                                        e.productDbId ===
                                                        item.productDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Product">
                                                  {item.productName}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Product"
                                      className="popupfile_button"
                                      onClick={() => OnOkAllFilter("Product")}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Product"
                                      className="popupfile_button"
                                      onClick={() => OnClearProductsClicked()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="Product"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Product
                            <img
                              id="Product"
                              className="Icons"
                              onClick={() => OnClickFilter("Product")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>
                          {supplierPop === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Supplier"
                                        type="text"
                                        placeholder="Search"
                                        value={search_supplier}
                                        onChange={(e) =>
                                          OnChangeSearchFilter("Supplier", e)
                                        }
                                      />
                                      <div
                                        id="Supplier"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedSupplier(supplierArray)
                                        }
                                      >
                                        <img
                                          id="Supplier"
                                          className="Icons"
                                          src={
                                            supplierArray.length ==
                                            selectedSupplier.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Supplier">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_supplier.length === 0
                                        ? supplierArray.map((item, index) => (
                                            <div
                                              id="Supplier"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveSupplier(
                                                  item.supplierDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="Supplier"
                                                className="Icons"
                                                src={
                                                  selectedSupplier.findIndex(
                                                    (e) =>
                                                      e.supplierDbId ===
                                                      item.supplierDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="Supplier">
                                                {item.supplierName}
                                              </p>
                                            </div>
                                          ))
                                        : search_supplier.length > 0
                                        ? search_supplier_array.map(
                                            (item, index) => (
                                              <div
                                                id="Supplier"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveSupplier(
                                                    item.supplierDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Supplier"
                                                  className="Icons"
                                                  src={
                                                    selectedSupplier.findIndex(
                                                      (e) =>
                                                        e.supplierDbId ===
                                                        item.supplierDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Supplier">
                                                  {item.supplierName}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Supplier"
                                      className="popupfile_button"
                                      onClick={() => OnOkAllFilter("Supplier")}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Supplier"
                                      className="popupfile_button"
                                      onClick={() => OnClearSupplierClicked()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="Supplier"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Supplier
                            <img
                              id="Supplier"
                              className="Icons"
                              onClick={() => OnClickFilter("Suppliers")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>
                          {partsPop === true ? (
                            <div className="filterContainer">
                              <div className="filterPopPage">
                                <div className="popupfile">
                                  <div className="popupfile-data">
                                    <div className="flex flex-col gap-2">
                                      <input
                                        id="Parts"
                                        type="text"
                                        placeholder="Search"
                                        value={search_parts}
                                        onChange={(e) =>
                                          OnChangeSearchFilter("Parts", e)
                                        }
                                      />
                                      <div
                                        id="Parts"
                                        className="Imageflex"
                                        onClick={() =>
                                          setSelectedParts(partsArray)
                                        }
                                      >
                                        <img
                                          id="Parts"
                                          className="Icons"
                                          src={
                                            partsArray.length ==
                                            selectedParts.length
                                              ? CheckIcon
                                              : UnCheckIcon
                                          }
                                          alt=""
                                        />
                                        <p id="Parts">Select All</p>
                                      </div>
                                    </div>
                                    <div className="popupfile-data">
                                      {search_parts.length == 0
                                        ? partsArray.map((item, index) => (
                                            <div
                                              id="Parts"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveParts(item.partDbId)
                                              }
                                            >
                                              <img
                                                id="Parts"
                                                className="Icons"
                                                src={
                                                  selectedParts.findIndex(
                                                    (e) =>
                                                      e.partDbId ===
                                                      item.partDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="Parts">{item.partName}</p>
                                            </div>
                                          ))
                                        : search_parts.length > 0
                                        ? search_parts_array.map(
                                            (item, index) => (
                                              <div
                                                id="Parts"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveParts(item.partDbId)
                                                }
                                              >
                                                <img
                                                  id="Parts"
                                                  className="Icons"
                                                  src={
                                                    selectedParts.findIndex(
                                                      (e) =>
                                                        e.partDbId ===
                                                        item.partDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Parts">
                                                  {item.partName}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="file_button">
                                    <button
                                      id="Parts"
                                      className="popupfile_button"
                                      onClick={() => OnOkAllFilter("Parts")}
                                    >
                                      Ok
                                    </button>
                                    <button
                                      id="Parts"
                                      className="popupfile_button"
                                      onClick={() => OnClearPartsClicked()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="IconsSpace">
                            <img
                              id="Parts"
                              className="Icons opacity-0"
                              alt=""
                            />
                            Parts
                            <img
                              id="Parts"
                              className="Icons"
                              onClick={() => OnClickFilter("Parts")}
                              src={FilterIcon}
                              alt=""
                            />
                            <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        {/* <th>Quantity Required</th> */}
                        <th>Effective From</th>
                        <th>Effective To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {show_drafts === false && search_content.length === 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.bomDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {product.length > 0 &&
                                product.findIndex(
                                  (e) => e.productDbId == item.productDbId
                                ) != -1
                                  ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {supplierMaster.length > 0 &&
                                supplierMaster.findIndex(
                                  (e) => e.supplierDbId == item.supplierDbId
                                ) != -1
                                  ? supplierMaster.find(
                                      (e) => e.supplierDbId == item.supplierDbId
                                    ).supplierName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {partsMaster.length > 0 &&
                                partsMaster.findIndex(
                                  (e) => e.partDbId == item.partDbId
                                ) != -1
                                  ? partsMaster.find(
                                      (e) => e.partDbId == item.partDbId
                                    ).partName
                                  : null}
                              </td>
                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {item.qtyRequired}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.bomDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.bomDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.bomDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : show_drafts === true
                        ? display_draft_array.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.bomDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {product.length > 0 &&
                                product.findIndex(
                                  (e) => e.productDbId == item.productDbId
                                ) != -1
                                  ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {supplierMaster.length > 0 &&
                                supplierMaster.findIndex(
                                  (e) => e.supplierDbId == item.supplierDbId
                                ) != -1
                                  ? supplierMaster.find(
                                      (e) => e.supplierDbId == item.supplierDbId
                                    ).supplierName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {partsMaster.length > 0 &&
                                partsMaster.findIndex(
                                  (e) => e.partDbId == item.partDbId
                                ) != -1
                                  ? partsMaster.find(
                                      (e) => e.partDbId == item.partDbId
                                    ).partName
                                  : null}
                              </td>
                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {item.qtyRequired}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.bomDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.bomDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.bomDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : search_content.length > 0
                        ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.bomDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {product.length > 0 &&
                                product.findIndex(
                                  (e) => e.productDbId == item.productDbId
                                ) != -1
                                  ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {supplierMaster.length > 0 &&
                                supplierMaster.findIndex(
                                  (e) => e.supplierDbId == item.supplierDbId
                                ) != -1
                                  ? supplierMaster.find(
                                      (e) => e.supplierDbId == item.supplierDbId
                                    ).supplierName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {partsMaster.length > 0 &&
                                partsMaster.findIndex(
                                  (e) => e.partDbId == item.partDbId
                                ) != -1
                                  ? partsMaster.find(
                                      (e) => e.partDbId == item.partDbId
                                    ).partName
                                  : null}
                              </td>
                              {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {item.qtyRequired}
                              </td> */}
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.bomDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.bomDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.bomDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.bomDbId, "Edit")
                                    }
                                    src={EditIcon}
                                    alt=""
                                  />
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.bomDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center justify-center">
                    <p>
                      {" "}
                      {paginatedArray.length == 0 ? "No records found" : ""}
                    </p>
                  </div>
                  <div />
                </div>
                <div className="addTheFormSplits">
                  <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                    <div className="flex flex-row items-center gap-1">
                      <img
                        src={
                          current_page.current == 1
                            ? PreviousHiddenIcon
                            : PreviousIcon
                        }
                        onClick={() => onPressPrevious()}
                        className="PreviouesNextIcons"
                        alt=""
                        style={{
                          cursor:
                            current_page.current == 1 ? "auto" : "pointer",
                        }}
                      />
                      <img
                        src={
                          current_page.current == total_pages.current
                            ? NextHiddenIcon
                            : NextIcon
                        }
                        onClick={() => onPressNext()}
                        className="PreviouesNextIcons"
                        style={{
                          cursor:
                            current_page.current == total_pages.current
                              ? "auto"
                              : "pointer",
                        }}
                        alt=""
                      />
                    </div>
                    <p>
                      Page {current_page.current} of{" "}
                      {paginatedArray.length === 0
                        ? current_page.current
                        : total_pages.current}
                    </p>
                  </div>
                  <div className="specebutton">
                    <button className="addBtn" onClick={() => onClickAddItem()}>
                      Add
                    </button>
                    <button className="uploadBtn">Upload</button>
                    <button
                      className="deleteBtn"
                      onClick={() => OnDeleteSelectionClicked()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {/* //View box */}
              {open_view_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">View BOM Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            product.find(
                              (e) => e.productDbId === bomObject.productName
                            ).productName
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Supplier</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            supplierMaster.find(
                              (e) => e.supplierDbId === bomObject.supplierName
                            ).supplierName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Part</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            partsMaster.find(
                              (e) => e.partDbId === bomObject.partName
                            ).partName
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Required{" "}
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={bomObject.quantityRequired}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(bomObject.effDateFrom).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(bomObject.effDateFrom).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(bomObject.effDateFrom).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(bomObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(bomObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(bomObject.effDateTo).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectBomDbId, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">Edit BOM Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                          <label className="error">
                            {bomObjectErrors.productNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={bomObject.productName}
                          onChange={(e) =>
                            onChangeValue(e, "productName", null)
                          }
                        >
                          <option value={null}>Select....</option>
                          {product.length > 0
                            ? product.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.productDbId}>
                                    {item.productName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>{" "}
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Part</label>
                          <label className="error">
                            {bomObjectErrors.partNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={bomObject.partName}
                          onChange={(e) => onChangeValue(e, "partName", null)}
                        >
                          <option value={null}>Select....</option>
                          {partsMaster.length > 0
                            ? partsMaster.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.partDbId}>
                                    {item.partName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Supplier</label>
                          <label className="error">
                            {bomObjectErrors.supplierNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={bomObject.supplierName}
                          onChange={(e) =>
                            onChangeValue(e, "supplierName", null)
                          }
                        >
                          <option value={null}>Select....</option>
                          {supplierMaster.length > 0
                            ? supplierMaster.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.supplierDbId}>
                                    {item.supplierName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>{" "}
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quality Required
                          </label>
                          <label className="error">
                            {bomObjectErrors.quantityRequiredError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={bomObject.quantityRequired}
                          onChange={(e) =>
                            onChangeValue(e, "quantityRequired", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {bomObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(bomObject.effDateFrom).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(bomObject.effDateFrom).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(bomObject.effDateFrom).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {bomObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(bomObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(bomObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(bomObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEditBom()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations === true ? (
                <div className="deleteModelstyle">
                  <div className="delete_header_modal">Confirmation</div>
                  <div className=" Confirmation">
                    Are you sure you want to delete?
                  </div>
                  <div className="btnDeletewrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => CancelTheDeletion(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnDeleteItem(item_to_delete)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">Add BOM Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Product</label>
                          <label className="error">
                            {bomObjectErrors.productNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={bomObject.productName}
                          onChange={(e) =>
                            onChangeValue(e, "productName", null)
                          }
                        >
                          <option value={null}>Select....</option>
                          {product.length > 0
                            ? product.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.productDbId}>
                                    {item.productName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>{" "}
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Part</label>
                          <label className="error">
                            {bomObjectErrors.partNameError}
                          </label>
                        </div>
                        <select
                          className="child_input"
                          value={bomObject.partName}
                          onChange={(e) => onChangeValue(e, "partName", null)}
                        >
                          {/* <option value={null}>Select...</option> */}
                          {partsMaster.length > 0
                            ? partsMaster.map((item, index) =>
                                new Date(item.effDateTo).getTime() ===
                                new Date("9999-12-31").getTime() ? (
                                  <option key={index} value={item.partDbId}>
                                    {item.partName}
                                  </option>
                                ) : null
                              )
                            : null}
                        </select>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Supplier</label>
                          <label className="error">
                            {bomObjectErrors.supplierNameError}
                          </label>
                        </div>
                        {filterSupplier.length > 0 ? (
                          <select
                            className="child_input"
                            value={bomObject.supplierName}
                            onChange={(e) =>
                              onChangeValue(e, "supplierName", null)
                            }
                          >
                            <option value={null}>Select...</option>
                            {filterSupplier.length > 0
                              ? filterSupplier.map((item, index) => (
                                  <option key={index} value={item.supplierDbId}>
                                    {
                                      supplierMaster.find(
                                        (e) =>
                                          e.supplierDbId == item.supplierDbId
                                      ).supplierName
                                    }
                                  </option>
                                ))
                              : null}
                          </select>
                        ) : (
                          <input
                            disabled={true}
                            className="child_input opacity-[0.7] bg-light-default"
                            value={bomObject.supplierName}
                            onChange={(e) =>
                              onChangeValue(e, "supplierName", null)
                            }
                          />
                        )}
                      </div>{" "}
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Quantity Required
                          </label>
                          <label className="error">
                            {bomObjectErrors.quantityRequiredError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={bomObject.quantityRequired}
                          onChange={(e) =>
                            onChangeValue(e, "quantityRequired", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {bomObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(bomObject.effDateFrom).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(bomObject.effDateFrom).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(bomObject.effDateFrom).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        ></input>
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {bomObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(bomObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(bomObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(bomObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <NotificationWeb />
            </div>
          </div>
          <LogoutConfirmation />
        </div>
      ) : null}
      {productPop || supplierPop || partsPop == true ? (
        <div className="FilterMobile">
          {productPop === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Product"
                      type="text"
                      placeholder="Search"
                      value={search_product}
                      onChange={(e) => OnChangeSearchFilter("Product", e)}
                    />
                    <div
                      id="Product"
                      className="Imageflex"
                      onClick={() => setSelectedProducts(productArray)}
                    >
                      <img
                        id="Product"
                        className="Icons"
                        src={
                          productArray.length == selectedProducts.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Product">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_product.length === 0
                      ? productArray.map((item, index) => (
                          <div
                            id="Product"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveProduct(item.productDbId)}
                          >
                            <img
                              id="Product"
                              className="Icons"
                              src={
                                selectedProducts.findIndex(
                                  (e) => e.productDbId === item.productDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Product">{item.productName}</p>
                          </div>
                        ))
                      : search_product.length > 0
                      ? search_product_array.map((item, index) => (
                          <div
                            id="Product"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveProduct(item.productDbId)}
                          >
                            <img
                              id="Product"
                              className="Icons"
                              src={
                                selectedProducts.findIndex(
                                  (e) => e.productDbId === item.productDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Product">{item.productName}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Product"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("Product")}
                  >
                    Ok
                  </button>
                  <button
                    id="Product"
                    className="popupfile_button"
                    onClick={() => OnClearProductsClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {supplierPop === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Supplier"
                      type="text"
                      placeholder="Search"
                      value={search_supplier}
                      onChange={(e) => OnChangeSearchFilter("Supplier", e)}
                    />
                    <div
                      id="Supplier"
                      className="Imageflex"
                      onClick={() => setSelectedSupplier(supplierArray)}
                    >
                      <img
                        id="Supplier"
                        className="Icons"
                        src={
                          supplierArray.length == selectedSupplier.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Supplier">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_supplier.length === 0
                      ? supplierArray.map((item, index) => (
                          <div
                            id="Supplier"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveSupplier(item.supplierDbId)}
                          >
                            <img
                              id="Supplier"
                              className="Icons"
                              src={
                                selectedSupplier.findIndex(
                                  (e) => e.supplierDbId === item.supplierDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Supplier">{item.supplierName}</p>
                          </div>
                        ))
                      : search_supplier.length > 0
                      ? search_supplier_array.map((item, index) => (
                          <div
                            id="Supplier"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveSupplier(item.supplierDbId)}
                          >
                            <img
                              id="Supplier"
                              className="Icons"
                              src={
                                selectedSupplier.findIndex(
                                  (e) => e.supplierDbId === item.supplierDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Supplier">{item.supplierName}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Supplier"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("Supplier")}
                  >
                    Ok
                  </button>
                  <button
                    id="Supplier"
                    className="popupfile_button"
                    onClick={() => OnClearSupplierClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {partsPop === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Parts"
                      type="text"
                      placeholder="Search"
                      value={search_parts}
                      onChange={(e) => OnChangeSearchFilter("Parts", e)}
                    />
                    <div
                      id="Parts"
                      className="Imageflex"
                      onClick={() => setSelectedParts(partsArray)}
                    >
                      <img
                        id="Parts"
                        className="Icons"
                        src={
                          partsArray.length == selectedParts.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Parts">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_parts.length == 0
                      ? partsArray.map((item, index) => (
                          <div
                            id="Parts"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveParts(item.partDbId)}
                          >
                            <img
                              id="Parts"
                              className="Icons"
                              src={
                                selectedParts.findIndex(
                                  (e) => e.partDbID === item.partDbID
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Parts">{item.partName}</p>
                          </div>
                        ))
                      : search_parts.length > 0
                      ? search_parts_array.map((item, index) => (
                          <div
                            id="Parts"
                            key={index}
                            className="Imageflex"
                            onClick={() => AddRemoveParts(item.partDbId)}
                          >
                            <img
                              id="Parts"
                              className="Icons"
                              src={
                                selectedParts.findIndex(
                                  (e) => e.partDbId === item.partDbId
                                ) !== -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Parts">{item.partName}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Parts"
                    className="popupfile_button"
                    onClick={() => OnOkAllFilter("Parts")}
                  >
                    Ok
                  </button>
                  <button
                    id="Parts"
                    className="popupfile_button"
                    onClick={() => OnClearPartsClicked()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default BOM;
