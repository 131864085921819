import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import PreviousIcon from "../Assert/PreviousPage.png";
import NextIcon from "../Assert/NextPage.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_role_Hierarchy } from "./Redux/RoleHierarchySlice";
import { populate_Staff } from "./Redux/StaffSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_Facility } from "./Redux/FacilitySlice";
import { useNavigate } from "react-router-dom";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
import NotificationWeb from "./NotificationWeb";
import LogoutConfirmation from "./LogoutConfirmation";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
function Staff() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_event_notificationLog(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheFacility();
        FetchTheEventNotificationLog();
        FetchTheEventRule();

        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "Role") {
            setRoleHierarchy(false);
          }
        });
      }
    }, 2000);
  }, []);
  let roleHierarchyMaster = useSelector(
    (state) => state.callRoleHierarchy.value
  );
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );

  const FetchTheValidation = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
  };
  const IsValidEmail = (_email) => {
    if (_email.length > 0) {
      if (
        _email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const [roleHierarchyArray, setRoleHierarchyArray] = useState([]);
  const FetchTheRole = (_staff) => {
    if (roleHierarchyMaster.length === 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "roleHierarchyMaster?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempRoleHierarchyMaster = [];
            let tempRoleHierarchyArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempRoleHierarchyMaster.push(response.data[i]);
                if (
                  tempRoleHierarchyArray.findIndex(
                    (e) => e === response.data[i].jobFunction
                  ) == -1
                ) {
                  tempRoleHierarchyArray.push(response.data[i].jobFunction);
                }
              }
            }
            dispatch(populate_role_Hierarchy(tempRoleHierarchyMaster));
            let tempRoleHierarchy = [];
            for (let i = 0; i < tempRoleHierarchyMaster.length; i++) {
              if (
                _staff.findIndex(
                  (e) => e.roleDbId == tempRoleHierarchyMaster[i].roleDbId
                ) != -1
              ) {
                tempRoleHierarchy.push(tempRoleHierarchyMaster[i]);
              }
            }
            setSelectedRoleHierarchy(tempRoleHierarchy);
            setRoleHierarchyArray(tempRoleHierarchy);
          }
        });
    } else {
      // let tempRoleHierarchyMaster = [...roleHierarchyMaster];
      // let tempRoleHierarchy = [];
      // for (let i = 0; i < tempRoleHierarchyMaster.length; i++) {
      //   tempRoleHierarchy.push(tempRoleHierarchyMaster[i].jobFunction);
      // }
      let tempRoleHierarchy = [];
      for (let i = 0; i < roleHierarchyMaster.length; i++) {
        if (
          _staff.findIndex(
            (e) => e.roleDbId == roleHierarchyMaster[i].roleDbId
          ) != -1
        ) {
          tempRoleHierarchy.push(roleHierarchyMaster[i]);
        }
      }
      setSelectedRoleHierarchy(tempRoleHierarchy);
      setRoleHierarchyArray(tempRoleHierarchy);
    }
  };

  const [search_role, setsearch_role] = useState("");
  const [search_role_array, setsearch_role_array] = useState([]);
  const OnChangeSearchRole = (e) => {
    let content = e.target.value;
    setsearch_role(content.toLowerCase());
    let temp = roleHierarchyArray.filter((e) =>
      e.jobFunction.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setsearch_role_array(temp);
  };
  //Get all the facilities
  let facilities = useSelector((state) => state.callFacilities.value);
  const FetchTheFacility = () => {
    if (facilities.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempFacilityMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempFacilityMaster.push(response.data[i]);
              }
            }
            dispatch(populate_Facility(tempFacilityMaster));
          }
        });
    }
  };

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "staff";

  let staff = useSelector((state) => state.callStaff.value);
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [staffObjectErrors, setStaffObjectErrors] = useState({
    staffCodeError: "",
    firstNameError: "",
    lastNameError: "",
    staffTypeError: "",
    pictureError: "",
    workPhoneNumberError: "",
    homePhoneNumberError: "",
    mobileNumberError: "",
    workEmailError: "",
    personalEmailError: "",
    usernameError: "",
    passwdError: "",
    registeredDeviceIdError: "",
    managerStaffIdError: "",
    effDateFromError: "",
    effDateToError: "",
    facilityNameError: "",
    roleHierarchyError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "staffCodeError");
    OnChangeErrorValues("", "firstNameError");
    OnChangeErrorValues("", "lastNameError");
    OnChangeErrorValues("", "staffTypeError");
    OnChangeErrorValues("", "pictureError");
    OnChangeErrorValues("", "workPhoneNumberError");
    OnChangeErrorValues("", "homePhoneNumberError");
    OnChangeErrorValues("", "mobileNumberError");
    OnChangeErrorValues("", "workEmailError");
    OnChangeErrorValues("", "personalEmailError");
    OnChangeErrorValues("", "usernameError");
    OnChangeErrorValues("", "passwdError");
    OnChangeErrorValues("", "registeredDeviceIdError");
    OnChangeErrorValues("", "managerStaffIdError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
    OnChangeErrorValues("", "roleHierarchyError");
    OnChangeErrorValues("", "facilityNameError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "staffCodeError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          staffCodeError: error,
        }));
        break;
      case "firstNameError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          firstNameError: error,
        }));
        break;

      case "lastNameError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          lastNameError: error,
        }));
        break;
      case "staffTypeError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          staffTypeError: error,
        }));
        break;
      case "pictureError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          pictureError: error,
        }));
        break;
      case "workPhoneNumberError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          workPhoneNumberError: error,
        }));
        break;
      case "homePhoneNumberError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          homePhoneNumberError: error,
        }));
        break;
      case "mobileNumberError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          mobileNumberError: error,
        }));
        break;
      case "workEmailError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          workEmailError: error,
        }));
        break;

      case "personalEmailError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          personalEmailError: error,
        }));
        break;
      case "usernameError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          usernameError: error,
        }));
        break;
      case "passwdError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          passwdError: error,
        }));
        break;
      case "registeredDeviceIdError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          registeredDeviceIdError: error,
        }));
        break;
      case "managerStaffIdError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          managerStaffIdError: error,
        }));
        break;
      case "effDateFromError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          effDateToError: error,
        }));
        break;
      case "facilityNameError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          facilityNameError: error,
        }));
        break;
      case "roleHierarchyError":
        setStaffObjectErrors((staffObjectErrors) => ({
          ...staffObjectErrors,
          roleHierarchyError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value)
  //   current_page.current = 1;
  //   let content = e.target.value;

  //   let temp=[];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for(let i=0;i<staffArray.length;i++)
  //   {
  //     if(staffArray[i].lastName!==null)
  //     {
  //     if(staffArray[i].lastName
  //       .toLowerCase()
  //       .trim()
  //       .match(e.target.value.toLowerCase().trim()))
  //       {
  //         temp.push(staffArray[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const onChangeSearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = staff.filter((e1) =>
      e1.firstName.toLowerCase().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (staff.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(staff[i]);
        }
      } else {
        for (let i = 0; i < staff.length; i++) {
          tempArray.push(staff[i]);
        }
      }
      total_pages.current = Math.ceil(
        staff.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [staffArray, setStaffArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [staffObject, setStaffObject] = useState({
    staffCode: "",
    firstName: "",
    lastName: "",
    staffType: "",
    picture: "",
    workPhoneNumber: 0,
    homePhoneNumber: 0,
    mobileNumber: 0,
    workEmail: "",
    personalEmail: "",
    username: "",
    passwd: "",
    registeredDeviceId: "",
    managerStaffId: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    facilityName: "",
    roleHierarchy: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  //Declaration Of Delete States
  const [staffObjectDelete, setStaffObjectDelete] = useState({
    staffCode: "",
    firstName: "",
    lastName: "",
    staffType: "",
    picture: "",
    workPhoneNumber: 0,
    homePhoneNumber: 0,
    mobileNumber: 0,
    workEmail: "",
    personalEmail: "",
    username: "",
    passwd: "",
    registeredDeviceId: "",
    managerStaffId: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    facilityName: "",
    roleHierarchy: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });

  const onResetValue = () => {
    setStaffObject((staffObject) => ({
      ...staffObject,
      staffCode: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      firstName: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      lastName: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      staffType: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      picture: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      workPhoneNumber: 0,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      homePhoneNumber: 0,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      mobileNumber: 0,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      workEmail: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      personalEmail: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      username: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      passwd: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      registeredDeviceId: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      managerStaffId: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      effDateFrom: new Date(),
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      roleHierarchy: "",
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      facilityName: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "staffCode":
        setStaffObject((staffObject) => ({
          ...staffObject,
          staffCode: e.target.value,
        }));
        break;
      case "firstName":
        setStaffObject((staffObject) => ({
          ...staffObject,
          firstName: e.target.value,
        }));
        break;
      case "lastName":
        setStaffObject((staffObject) => ({
          ...staffObject,
          lastName: e.target.value,
        }));
        break;
      case "staffType":
        setStaffObject((staffObject) => ({
          ...staffObject,
          staffType: e.target.value,
        }));
        break;
      case "picture":
        setStaffObject((staffObject) => ({
          ...staffObject,
          picture: e.target.value,
        }));
        break;
      case "workPhoneNumber":
        setStaffObject((staffObject) => ({
          ...staffObject,
          workPhoneNumber: e.target.value,
        }));
        break;
      case "homePhoneNumber":
        setStaffObject((staffObject) => ({
          ...staffObject,
          homePhoneNumber: e.target.value,
        }));
        break;
      case "mobileNumber":
        setStaffObject((staffObject) => ({
          ...staffObject,
          mobileNumber: e.target.value,
        }));
        break;
      case "workEmail":
        setStaffObject((staffObject) => ({
          ...staffObject,
          workEmail: e.target.value,
        }));
        break;
      case "personalEmail":
        setStaffObject((staffObject) => ({
          ...staffObject,
          personalEmail: e.target.value,
        }));
        break;
      case "username":
        setStaffObject((staffObject) => ({
          ...staffObject,
          username: e.target.value,
        }));
        break;
      case "passwd":
        setStaffObject((staffObject) => ({
          ...staffObject,
          passwd: e.target.value,
        }));
        break;
      case "registeredDeviceId":
        setStaffObject((staffObject) => ({
          ...staffObject,
          registeredDeviceId: e.target.value,
        }));
        break;
      case "managerStaffId":
        setStaffObject((staffObject) => ({
          ...staffObject,
          managerStaffId: e.target.value,
        }));
        break;
      case "effDateFrom":
        setStaffObject((staffObject) => ({
          ...staffObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setStaffObject((staffObject) => ({
          ...staffObject,
          effDateTo: e.target.value,
        }));
        break;
      case "facilityName":
        setStaffObject((staffObject) => ({
          ...staffObject,
          facilityName: e.target.value,
        }));
        break;
      case "roleHierarchy":
        setStaffObject((staffObject) => ({
          ...staffObject,
          roleHierarchy: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          staffArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            staffArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(staffArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            staffArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (staffArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(staffArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(staffArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);
  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = staff.findIndex((e) => e.staffDbId === _code);
    select_deselect_the_record(staff[idx]);
    set_item_to_delete(staff[idx].staffDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup("❌" + error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup("❌" + error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    console.log("Facility:" + staffObject.facilityName);
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          staffCode: staffObject.staffCode,
          firstName: staffObject.firstName,
          lastName: staffObject.lastName,
          staffType: staffObject.staffType,
          workPhoneNumber: Number(staffObject.workPhoneNumber),
          homePhoneNumber: Number(staffObject.homePhoneNumber),
          mobileNumber: Number(staffObject.mobileNumber),
          workEmail: staffObject.workEmail,
          personalEmail: staffObject.personalEmail,
          username: staffObject.username,
          passwd: staffObject.passwd,
          registeredDeviceId: staffObject.registeredDeviceId,
          managerStaffId: staffObject.managerStaffId,
          effDateFrom: staffObject.effDateFrom,
          effDateTo:
            status === true ? new Date("1900-01-01") : staffObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : null,
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : null,
          updatedTimestamp: new Date().toISOString(),
          facilityDbId: Number(staffObject.facilityName),
          roleDbId: Number(staffObject.roleHierarchy),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup("❌" + error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = () => {
    let deleteData = {
      staffCode: staffObjectDelete.staffCode,
      firstName: staffObjectDelete.firstName,
      lastName: staffObjectDelete.lastName,
      staffType: staffObjectDelete.staffType,
      workPhoneNumber: Number(staffObjectDelete.workPhoneNumber),
      homePhoneNumber: Number(staffObjectDelete.homePhoneNumber),
      mobileNumber: Number(staffObjectDelete.mobileNumber),
      workEmail: staffObjectDelete.workEmail,
      personalEmail: staffObjectDelete.personalEmail,
      username: staffObjectDelete.username,
      passwd: staffObjectDelete.passwd,
      registeredDeviceId: staffObjectDelete.registeredDeviceId,
      managerStaffId: staffObjectDelete.managerStaffId,
      effDateFrom: GetServerDate(staffObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      facilityDbId: Number(staffObjectDelete.facilityName),
      roleDbId: Number(staffObjectDelete.roleHierarchy),
    };
    let data = {
      staffCode: staffObject.staffCode,
      firstName: staffObject.firstName,
      lastName: staffObject.lastName,
      staffType: staffObject.staffType,
      workPhoneNumber: Number(staffObject.workPhoneNumber),
      homePhoneNumber: Number(staffObject.homePhoneNumber),
      mobileNumber: Number(staffObject.mobileNumber),
      workEmail: staffObject.workEmail,
      personalEmail: staffObject.personalEmail,
      username: staffObject.username,
      passwd: staffObject.passwd,
      registeredDeviceId: staffObject.registeredDeviceId,
      managerStaffId: staffObject.managerStaffId,
      effDateFrom: GetServerDate(staffObject.effDateFrom),
      effDateTo: GetServerDate(staffObject.effDateFrom),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      facilityDbId: Number(staffObject.facilityName),
      roleDbId: Number(staffObject.roleHierarchy),
    };
    axios
      .patch(post_url + "/" + selectStaffDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios.post(post_url, data).then((response) => {
            if (response.status === 201) {
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000",

                "Patch"
              );
              ShowMEWSPopup("✅Successfully updated the item", "Success");
              set_mews_popup_color("#28A745");
              set_open_edit_modal(false);
            }
          });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectStaffDbId);
        console.log(data);
        ShowMEWSPopup("❌" + error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Staff") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "staffCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.staffCode.length === 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                staffCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              staff.findIndex((e) => e.staffCode === staffObject.staffCode) !=
                -1
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                staffCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "workEmail":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.workEmail.length === 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                workEmailError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "email" &&
              IsValidEmail(staffObject.workEmail) === false
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                workEmailError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          case "roleCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.roleHierarchy.length === 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                roleHierarchyError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "firstName":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.firstName.length == 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                firstNameError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
          case "lastName":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.lastName.length == 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                lastNameError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
          case "workPhoneNumber":
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(Number(staffObject.workPhoneNumber))
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                workPhoneNumberError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "homePhoneNumber":
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(Number(staffObject.homePhoneNumber))
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                homePhoneNumberError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "mobileNumber":
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(Number(staffObject.mobileNumber))
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                mobileNumberError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "personalEmail":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.personalEmail.length === 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                personalEmailError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "email" &&
              IsValidEmail(staffObject.personalEmail) === false
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                personalEmailError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          case "userName":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.username.length === 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                usernameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          case "effDateTo":
            if (
              clientSideValidations[i].validationType ===
                "greater_than_equalto_date" &&
              staffObject.effDateTo < staffObject.effDateFrom
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                effDateToError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "facilityName":
            if (
              clientSideValidations[i].validationType === "required" &&
              staffObject.facilityName.length == 0
            ) {
              temp++;
              setStaffObjectErrors((staffObjectErrors) => ({
                ...staffObjectErrors,
                facilityNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };

  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = staff.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        staff.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (staff.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < staff.length; i++) {
          temp_display_array.push(staff[i]);
        }
      } else {
        if (
          staff.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          staff.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < staff.length;
            i++
          ) {
            temp_display_array.push(staff[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(staff[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const [selected_form_id, set_selected_form_id] = useState(0);

  const onChangeFormID = (e, _code) => {
    let temp = [];
    for (let i = 0; i < staff.length; i++) {
      temp.push(staff[i]);
    }
    let updatedArray = [];
    if (e != 0) {
      updatedArray = temp.filter((e1) => e1.roleDbId == e);
    } else {
      updatedArray = temp;
    }
    console.log(updatedArray);
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }

    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setStaffArray(updatedArray);
    setPaginatedArray(tempArray);
    set_selected_form_id(e);
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_staffObject_master = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_staffObject_master.push(response.data[i]);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setStaffArray(tempNonDraftArray);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_Staff(temp_staffObject_master));
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheRole(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup("❌" + error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selectStaffDbId, setSelectStaffDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (staffDbId, type) => {
    setSelectStaffDbId(staffDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(staffDbId);
  };
  const SupplyValuesToModal = (staffDbId) => {
    let dateFrom = staff.find((e) => e.staffDbId == staffDbId).effDateFrom;
    let dateTo = staff.find((e) => e.staffDbId == staffDbId).effDateTo;

    setStaffObject((staffObject) => ({
      ...staffObject,
      staffCode: staff.find((e) => e.staffDbId == staffDbId).staffCode,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      staffCode: staff.find((e) => e.staffDbId == staffDbId).staffCode,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      firstName: staff.find((e) => e.staffDbId == staffDbId).firstName,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      firstName: staff.find((e) => e.staffDbId == staffDbId).firstName,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      lastName: staff.find((e) => e.staffDbId == staffDbId).lastName,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      lastName: staff.find((e) => e.staffDbId == staffDbId).lastName,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      staffType: staff.find((e) => e.staffDbId == staffDbId).staffType,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      staffType: staff.find((e) => e.staffDbId == staffDbId).staffType,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      picture: staff.find((e) => e.staffDbId == staffDbId).picture,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      picture: staff.find((e) => e.staffDbId == staffDbId).picture,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      workPhoneNumber: staff.find((e) => e.staffDbId == staffDbId)
        .workPhoneNumber,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      workPhoneNumber: staff.find((e) => e.staffDbId == staffDbId)
        .workPhoneNumber,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      homePhoneNumber: staff.find((e) => e.staffDbId == staffDbId)
        .homePhoneNumber,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      homePhoneNumber: staff.find((e) => e.staffDbId == staffDbId)
        .homePhoneNumber,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      mobileNumber: staff.find((e) => e.staffDbId == staffDbId).mobileNumber,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      mobileNumber: staff.find((e) => e.staffDbId == staffDbId).mobileNumber,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      workEmail: staff.find((e) => e.staffDbId == staffDbId).workEmail,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      workEmail: staff.find((e) => e.staffDbId == staffDbId).workEmail,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      personalEmail: staff.find((e) => e.staffDbId == staffDbId).personalEmail,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      personalEmail: staff.find((e) => e.staffDbId == staffDbId).personalEmail,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      username: staff.find((e) => e.staffDbId == staffDbId).username,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      username: staff.find((e) => e.staffDbId == staffDbId).username,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      passwd: staff.find((e) => e.staffDbId == staffDbId).passwd,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      passwd: staff.find((e) => e.staffDbId == staffDbId).passwd,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      registeredDeviceId: staff.find((e) => e.staffDbId == staffDbId)
        .registeredDeviceId,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      registeredDeviceId: staff.find((e) => e.staffDbId == staffDbId)
        .registeredDeviceId,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      managerStaffId: staff.find((e) => e.staffDbId == staffDbId)
        .managerStaffId,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      managerStaffId: staff.find((e) => e.staffDbId == staffDbId)
        .managerStaffId,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      effDateFrom: dateFrom,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      effDateFrom: dateFrom,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      effDateTo: dateTo,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      effDateTo: dateTo,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      facilityName: staff.find((e1) => e1.staffDbId == staffDbId).facilityDbId,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      facilityName: staff.find((e1) => e1.staffDbId == staffDbId).facilityDbId,
    }));
    setStaffObject((staffObject) => ({
      ...staffObject,
      roleHierarchy: staff.find((e1) => e1.staffDbId == staffDbId).roleDbId,
    }));
    setStaffObjectDelete((staffObjectDelete) => ({
      ...staffObjectDelete,
      roleHierarchy: staff.find((e1) => e1.staffDbId == staffDbId).roleDbId,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    console.log("Sorting:" + staffArray);
    let SortingStaff = [...staffArray];
    if (sortStyle === "Descending") {
      let updatedStaff = SortingStaff.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.staffCode
            : action === "LastName"
            ? a.lastName
            : action === "WorkEmail"
            ? a.workEmail
            : null;
        const b_res =
          action === "Code"
            ? b.staffCode
            : action === "LastName"
            ? b.lastName
            : action === "WorkEmail"
            ? b.workEmail
            : null;

        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedStaff);
      setSortStyle("Ascending");
    }
    if (sortStyle === "Ascending") {
      let updatedStaff = SortingStaff.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.staffCode
            : action === "LastName"
            ? a.lastName
            : action === "WorkEmail"
            ? a.workEmail
            : null;
        const b_res =
          action === "Code"
            ? b.staffCode
            : action === "LastName"
            ? b.lastName
            : action === "WorkEmail"
            ? b.workEmail
            : null;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedStaff);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingStaff.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingStaff[i]);
      }
    } else {
      for (let i = 0; i < SortingStaff.length; i++) {
        tempArray.push(SortingStaff[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingStaff.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setStaffArray(SortingStaff);
  };
  const [roleHierarchy, setRoleHierarchy] = useState(false);

  const OnClickRole = () => {
    let tempMultiSelected = [];
    if (selectedRoleHierarchy.length == 0) {
      for (let i = 0; i < roleHierarchyMaster.length; i++) {
        tempMultiSelected.push(roleHierarchyMaster[i].roleDbId);
      }
    } else {
      for (let i = 0; i < selectedRoleHierarchy.length; i++) {
        tempMultiSelected.push(selectedRoleHierarchy[i]);
      }
    }
    setSelectedRoleHierarchy(tempMultiSelected);
    if (staff.length == 0) {
      setRoleHierarchy(false);
    } else {
      setRoleHierarchy(!roleHierarchy);
    }
  };
  const [selectedRoleHierarchy, setSelectedRoleHierarchy] = useState([]);
  const AddRemoveRoleHierarchy = (id) => {
    let tempSelectedRole = [...selectedRoleHierarchy];
    let RoleIndex = tempSelectedRole.findIndex((e) => e.roleDbId == id);
    if (RoleIndex == -1) {
      tempSelectedRole.push(roleHierarchyMaster.find((e) => e.roleDbId == id));
    } else {
      tempSelectedRole.splice(RoleIndex, 1);
    }
    setSelectedRoleHierarchy(tempSelectedRole);
  };
  const OnPressedClearClicked = (roleDbId) => {
    let tempSelectedRoleHierarchy = [];
    if (selectedRoleHierarchy.length == 0) {
      for (let i = 0; i < roleHierarchyMaster.length; i++) {
        tempSelectedRoleHierarchy.push(roleHierarchyMaster[i].roleDbId);
      }
    } else {
      tempSelectedRoleHierarchy.splice(
        tempSelectedRoleHierarchy.findIndex((e) => e === roleDbId),
        1
      );
      setSelectedRoleHierarchy(tempSelectedRoleHierarchy);
    }
    setSelectedRoleHierarchy(tempSelectedRoleHierarchy);
  };
  const OnOkRole = () => {
    let temp = [...staff];
    let updatedArray = [];
    for (let i = 0; i < selectedRoleHierarchy.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].roleDbId === selectedRoleHierarchy[i].roleDbId
          // roleHierarchyMaster.find(
          //   (e) => e.jobFunction == selectedRoleHierarchy[i]
          // ).roleDbId
        ) {
          if (
            new Date(temp[j].effDateTo).getTime() ==
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setStaffArray(updatedArray);
    setPaginatedArray(tempArray);
    setRoleHierarchy(false);
  };
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="flex flex-row w-full">
              <div className="container-fluid">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                        multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className="green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                  <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                    <div className="manageHeader"> Manage Staff</div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UncheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Staff"
                        value={search_content}
                        onChange={(e) => onChangeSearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "9%" }} />
                        <col span="1" style={{ width: "11%" }} />
                        <col span="1" style={{ width: "12%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "20%" }} />
                        <col span="1" style={{ width: "12%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Staff Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>First Name</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("LastName")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Last Name
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Staff Type</th>
                          <th>Work Email</th>
                          <th>
                            {roleHierarchy === true ? (
                              <div className="filterContainer">
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="Role"
                                          type="text"
                                          placeholder="Search"
                                          value={search_role}
                                          onChange={(e) =>
                                            OnChangeSearchRole(e)
                                          }
                                        />
                                        <div
                                          id="Role"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedRoleHierarchy(
                                              roleHierarchyArray
                                            )
                                          }
                                        >
                                          <img
                                            id="Role"
                                            className="Icons"
                                            src={
                                              roleHierarchyArray.length ==
                                              selectedRoleHierarchy.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Role">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {search_role.length === 0
                                          ? roleHierarchyArray.map(
                                              (item, index) => (
                                                <div
                                                  id="Role"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveRoleHierarchy(
                                                      item.roleDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Role"
                                                    className="Icons"
                                                    src={
                                                      selectedRoleHierarchy.findIndex(
                                                        (e) =>
                                                          e.roleDbId ==
                                                          item.roleDbId
                                                      ) != -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Role">
                                                    {item.jobFunction}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : search_role.length > 0
                                          ? search_role_array.map(
                                              (item, index) => (
                                                <div
                                                  id="Role"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveRoleHierarchy(
                                                      item.roleDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Role"
                                                    className="Icons"
                                                    src={
                                                      selectedRoleHierarchy.findIndex(
                                                        (e) =>
                                                          e.roleDbId ==
                                                          item.roleDbId
                                                      ) != -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Role">
                                                    {item.jobFunction}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="Role"
                                        className="popupfile_button"
                                        onClick={() => OnOkRole()}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Role"
                                        className="popupfile_button"
                                        onClick={() =>
                                          setSelectedRoleHierarchy([])
                                        }
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="IconsSpace">
                              <img
                                className="ArrowIcons opacity-0"
                                src={sortStyle}
                                alt=""
                              />
                              Role
                              <img
                                id="Role"
                                className="Icons "
                                onClick={() => OnClickRole(true)}
                                src={FilterIcon}
                                alt=""
                              />
                              <label className="xl:hidden lg:hidden flex">
                                {" "}
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.staffDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.staffCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.firstName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.lastName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.staffType}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.workEmail}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.roleDbId)
                                  }
                                >
                                  {roleHierarchyMaster.length > 0 &&
                                  roleHierarchyMaster.findIndex(
                                    (e) => e.roleDbId == item.roleDbId
                                  ) != -1
                                    ? roleHierarchyMaster.find(
                                        (e) => e.roleDbId == item.roleDbId
                                      ).jobFunction
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.staffDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.staffDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.staffDbId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.staffDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.staffCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.firstName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.lastName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.staffType}
                                </td>
                                {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.workPhoneNumber}</td> 
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.homePhoneNumber}</td>
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.mobileNumber}</td>  */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.workEmail}
                                </td>
                                {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.managerStaffId}</td>  */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.roleDbId)
                                  }
                                >
                                  {roleHierarchyMaster.length > 0 &&
                                  roleHierarchyMaster.findIndex(
                                    (e) => e.roleDbId == item.roleDbId
                                  ) != -1
                                    ? roleHierarchyMaster.find(
                                        (e) => e.roleDbId == item.roleDbId
                                      ).jobFunction
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.staffDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.staffDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.staffDbId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.staffDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.staffCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.firstName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.lastName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.staffType}
                                </td>
                                {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.workPhoneNumber}</td> 
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.homePhoneNumber}</td>
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.mobileNumber}</td>  */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {item.workEmail}
                                </td>
                                {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.staffDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.staffDbId)}>{item.managerStaffId}</td>  */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.roleDbId)
                                  }
                                >
                                  {roleHierarchyMaster.length > 0 &&
                                  roleHierarchyMaster.findIndex(
                                    (e) => e.roleDbId == item.roleDbId
                                  ) != -1
                                    ? roleHierarchyMaster.find(
                                        (e) => e.roleDbId == item.roleDbId
                                      ).jobFunction
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.staffDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.staffDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.staffDbId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.staffDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.staffDbId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <div className="addTheFormSplits">
                    <div className="col-span-1 flex flex-row items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center h-full gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={
                            current_page.current == 1
                              ? PreviousHiddenIcon
                              : PreviousIcon
                          }
                          onClick={() => onPressPrevious()}
                          className="PreviouesNextIcons"
                          alt=""
                          style={{
                            cursor:
                              current_page.current == 1 ? "auto" : "pointer",
                          }}
                        />
                        <img
                          src={
                            current_page.current == total_pages.current
                              ? NextHiddenIcon
                              : NextIcon
                          }
                          onClick={() => onPressNext()}
                          className="PreviouesNextIcons"
                          style={{
                            cursor:
                              current_page.current == total_pages.current
                                ? "auto"
                                : "pointer",
                          }}
                          alt=""
                        />
                      </div>
                      <p>
                        Page {current_page.current} of{" "}
                        {paginatedArray.length === 0
                          ? current_page.current
                          : total_pages.current}
                      </p>
                    </div>
                    <div className="specebutton">
                      <button
                        className="addBtn"
                        onClick={() => onClickAddItem()}
                      >
                        Add
                      </button>
                      <button className="uploadBtn">Upload</button>
                      <button
                        className="deleteBtn"
                        onClick={() => OnDeleteSelectionClicked()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {/* //View box */}
                {open_view_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">View Staff Details</div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Staff Code</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.staffCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">First Name</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.firstName}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Role</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              roleHierarchyMaster.find(
                                (e) => e.roleDbId === staffObject.roleHierarchy
                              ).jobFunction
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Last Name</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.lastName}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Staff Type</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.staffType}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Facility</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              facilities.find(
                                (e) =>
                                  e.facilityDbId === staffObject.facilityName
                              ).name
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Work Phone Number
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.workPhoneNumber}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Home Phone Number
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.homePhoneNumber}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Mobile Number</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.mobileNumber}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Work Email</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.workEmail}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Personal Email
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.personalEmail}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">User Name</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={staffObject.username}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                staffObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    staffObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(staffObject.effDateFrom).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(staffObject.effDateTo).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(staffObject.effDateTo).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(staffObject.effDateTo).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_view_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="editButton"
                          onClick={() => OpenModal(selectStaffDbId, "Edit")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Edit Popup column */}
                {open_edit_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">Edit Staff Details</div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Staff Code</label>
                            <label className="error">
                              {staffObjectErrors.staffCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={false}
                            value={staffObject.staffCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">First Name</label>
                            <label className="error">
                              {staffObjectErrors.firstNameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={false}
                            value={staffObject.firstName}
                            onChange={(e) =>
                              onChangeValue(e, "firstName", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Last Name</label>
                            <label className="error">
                              {staffObjectErrors.lastNameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.lastName}
                            onChange={(e) => onChangeValue(e, "lastName", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Role</label>
                            <label className="error">
                              {staffObjectErrors.roleHierarchyError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={staffObject.roleHierarchy}
                            onChange={(e) =>
                              onChangeValue(e, "roleHierarchy", null)
                            }
                          >
                            <option value={null} className="Poppins">
                              Select....
                            </option>
                            {roleHierarchyMaster.length > 0
                              ? roleHierarchyMaster.map((item, index) =>
                                  new Date(item.effDateTo).getTime() ==
                                  new Date("9999-12-31").getTime() ? (
                                    <option key={index} value={item.roleDbId}>
                                      {item.jobFunction}
                                    </option>
                                  ) : null
                                )
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Staff Type</label>
                            <label className="error">
                              {staffObjectErrors.staffTypeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            maxLength={1}
                            value={staffObject.staffType}
                            onChange={(e) =>
                              onChangeValue(e, "staffType", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Work Phone Number
                            </label>
                            <label className="error">
                              {staffObjectErrors.workPhoneNumberError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.workPhoneNumber}
                            onChange={(e) =>
                              onChangeValue(e, "workPhoneNumber", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Home Phone Number
                            </label>
                            <label className="error">
                              {staffObjectErrors.homePhoneNumberError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.homePhoneNumber}
                            onChange={(e) =>
                              onChangeValue(e, "homePhoneNumber", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Facility</label>
                            <label className="error">
                              {staffObjectErrors.facilityName}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={staffObject.facilityName}
                            onChange={(e) =>
                              onChangeValue(e, "facilityName", null)
                            }
                          >
                            <option value={null} className="Poppins">
                              Select....
                            </option>
                            {facilities.length > 0
                              ? facilities.map((facility, facilityIndex) => (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Mobile Number</label>
                            <label className="error">
                              {staffObjectErrors.mobileNumberError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.mobileNumber}
                            onChange={(e) =>
                              onChangeValue(e, "mobileNumber", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Work Email</label>
                            <label className="error">
                              {staffObjectErrors.workEmailError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.workEmail}
                            onChange={(e) =>
                              onChangeValue(e, "workEmail", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Personal Email
                            </label>
                            <label className="error">
                              {staffObjectErrors.personalEmailError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.personalEmail}
                            onChange={(e) =>
                              onChangeValue(e, "personalEmail", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">User Name</label>
                            <label className="error">
                              {staffObjectErrors.usernameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.username}
                            onChange={(e) => onChangeValue(e, "username", null)}
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {staffObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                staffObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    staffObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(staffObject.effDateFrom).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {staffObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(staffObject.effDateTo).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(staffObject.effDateTo).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(staffObject.effDateTo).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_edit_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => UpdateTheItem()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* //Delete Popup column */}
                {show_delete_confirmations === true ? (
                  <div className="deleteModelstyle">
                    <div className="delete_header_modal">Confirmation</div>
                    <div className=" Confirmation">
                      Are you sure you want to delete?
                    </div>
                    <div className="btnDeletewrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => CancelTheDeletion(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => OnDeleteItem(item_to_delete)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* //Add Popup column */}
                {open_add_modal === true ? (
                  <div className="all_popup_box">
                    <div className="green_header_popup">Add Staff Details</div>
                    <div className="inputOverflow">
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Staff Code</label>
                            <label className="error">
                              {staffObjectErrors.staffCodeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={false}
                            value={staffObject.staffCode}
                            onChange={(e) =>
                              onChangeValue(e, "staffCode", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">First Name</label>
                            <label className="error">
                              {staffObjectErrors.firstNameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={false}
                            value={staffObject.firstName}
                            onChange={(e) =>
                              onChangeValue(e, "firstName", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Last Name</label>
                            <label className="error">
                              {staffObjectErrors.lastNameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.lastName}
                            onChange={(e) => onChangeValue(e, "lastName", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Role</label>
                            <label className="error">
                              {staffObjectErrors.roleHierarchyError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={staffObject.roleHierarchy}
                            onChange={(e) =>
                              onChangeValue(e, "roleHierarchy", null)
                            }
                          >
                            <option value={null} className="Poppins">
                              Select....
                            </option>
                            {roleHierarchyMaster.length > 0
                              ? roleHierarchyMaster.map((item, index) =>
                                  new Date(item.effDateTo).getTime() ==
                                  new Date("9999-12-31").getTime() ? (
                                    <option key={index} value={item.roleDbId}>
                                      {item.jobFunction}
                                    </option>
                                  ) : null
                                )
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Staff Type</label>
                            <label className="error">
                              {staffObjectErrors.staffTypeError}
                            </label>
                          </div>
                          <input
                            type="text"
                            maxLength={1}
                            value={staffObject.staffType}
                            onChange={(e) =>
                              onChangeValue(e, "staffType", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Work Phone Number
                            </label>
                            <label className="error">
                              {staffObjectErrors.workPhoneNumberError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.workPhoneNumber}
                            onChange={(e) =>
                              onChangeValue(e, "workPhoneNumber", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Home Phone Number
                            </label>
                            <label className="error">
                              {staffObjectErrors.homePhoneNumberError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.homePhoneNumber}
                            onChange={(e) =>
                              onChangeValue(e, "homePhoneNumber", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Facility</label>
                            <label className="error">
                              {staffObjectErrors.facilityNameError}
                            </label>
                          </div>
                          <select
                            className="child_input"
                            value={staffObject.facilityName}
                            onChange={(e) =>
                              onChangeValue(e, "facilityName", null)
                            }
                          >
                            <option value={null} className="Poppins">
                              Select....
                            </option>
                            {facilities.length > 0
                              ? facilities.map((facility, facilityIndex) => (
                                  <option
                                    key={facilityIndex}
                                    value={facility.facilityDbId}
                                  >
                                    {facility.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Mobile Number</label>
                            <label className="error">
                              {staffObjectErrors.mobileNumberError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.mobileNumber}
                            onChange={(e) =>
                              onChangeValue(e, "mobileNumber", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Work Email</label>
                            <label className="error">
                              {staffObjectErrors.workEmailError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.workEmail}
                            onChange={(e) =>
                              onChangeValue(e, "workEmail", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Personal Email
                            </label>
                            <label className="error">
                              {staffObjectErrors.personalEmailError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.personalEmail}
                            onChange={(e) =>
                              onChangeValue(e, "personalEmail", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">User Name</label>
                            <label className="error">
                              {staffObjectErrors.usernameError}
                            </label>
                          </div>
                          <input
                            type="text"
                            value={staffObject.username}
                            onChange={(e) => onChangeValue(e, "username", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                            <label className="error">
                              {staffObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(
                                staffObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    staffObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(staffObject.effDateFrom).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                            <label className="error">
                              {staffObjectErrors.effDateToError}
                            </label>
                          </div>

                          <input
                            type="date"
                            value={
                              new Date(staffObject.effDateTo).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(staffObject.effDateTo).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(staffObject.effDateTo).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          ></input>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                        <button
                          className="cancelButton"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="btn-wrapFirst">
                        <button
                          className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                          onClick={() => set_open_add_modal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="savedraftButton"
                          onClick={() => AddTheItem(true)}
                        >
                          Save as Draft
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => AddTheItem(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <NotificationWeb />
              </div>
            </div>
            <LogoutConfirmation />
          </>
        ) : null}
      </div>
      {roleHierarchy == true ? (
        <div className="FilterMobile">
          {roleHierarchy === true ? (
            <div className="filterPopPage">
              <div className="popupfile">
                <div className="popupfile-data">
                  <div className="flex flex-col gap-2">
                    <input
                      id="Role"
                      type="text"
                      placeholder="Search"
                      value={search_role}
                      onChange={(e) => OnChangeSearchRole(e)}
                    />
                    <div
                      id="Role"
                      className="Imageflex"
                      onClick={() =>
                        setSelectedRoleHierarchy(roleHierarchyArray)
                      }
                    >
                      <img
                        id="Role"
                        className="Icons"
                        src={
                          roleHierarchyArray.length ==
                          selectedRoleHierarchy.length
                            ? CheckIcon
                            : UnCheckIcon
                        }
                        alt=""
                      />
                      <p id="Role">Select All</p>
                    </div>
                  </div>
                  <div className="popupfile-data">
                    {search_role.length === 0
                      ? roleHierarchyArray.map((item, index) => (
                          <div
                            id="Role"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveRoleHierarchy(item.roleDbId)
                            }
                          >
                            <img
                              id="Role"
                              className="Icons"
                              src={
                                selectedRoleHierarchy.findIndex(
                                  (e) => e.roleDbId === item.roleDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Role">{item.jobFunction}</p>
                          </div>
                        ))
                      : search_role.length > 0
                      ? search_role_array.map((item, index) => (
                          <div
                            id="Role"
                            key={index}
                            className="Imageflex"
                            onClick={() =>
                              AddRemoveRoleHierarchy(item.roleDbId)
                            }
                          >
                            <img
                              id="Role"
                              className="Icons"
                              src={
                                selectedRoleHierarchy.findIndex(
                                  (e) => e.roleDbId === item.roleDbId
                                ) != -1
                                  ? CheckIcon
                                  : UnCheckIcon
                              }
                              alt=""
                            />
                            <p id="Role">{item.jobFunction}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="file_button">
                  <button
                    id="Role"
                    className="popupfile_button"
                    onClick={() => OnOkRole()}
                  >
                    Ok
                  </button>
                  <button
                    id="Role"
                    className="popupfile_button"
                    onClick={() => setSelectedRoleHierarchy([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default Staff;
